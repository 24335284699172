import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getManufacturers, deleteManufacturer } from "../../services/manufacturerService"; // ✅ Import delete function
import { toast } from "react-toastify";
import { FaEye, FaEdit, FaTrash, FaPlus, FaArrowLeft, FaArrowRight, FaIndustry } from "react-icons/fa";

const ManufacturerList = () => {
  const [manufacturers, setManufacturers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    manufacturerName: "",
    manufacturerCode: "",
    page: 0,
    size: 10,
  });

  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  const fetchManufacturers = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await getManufacturers(activeFilters);
      if (response.statusCode === 200) {
        setManufacturers(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch manufacturers!");
      }
    } catch (error) {
      toast.error("Error fetching manufacturers.");
      setManufacturers([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchManufacturers();
  }, [fetchManufacturers]);

  // NEW: Load user's permissions from localStorage
  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if(details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch(e) {
        // ...existing error handling...
      }
    }
  }, []);

  // ✅ Function to delete a manufacturer
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this manufacturer?")) {
      try {
        await deleteManufacturer(id);
        toast.success("Manufacturer deleted successfully!");
        fetchManufacturers(); // Refresh list
      } catch (error) {
        toast.error("Failed to delete manufacturer.");
      }
    }
  };

  return (
    <div className="container">
       {/* Page Title & Add Button */}
       <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaIndustry /> Manufacturer Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Track and organize supplier manufacturers efficiently.
          </p>
        </div>
        <div>
        {userPermissions.includes("ADD_MANUFACTURER") && (
          <button className="btn btn-primary" onClick={() => navigate("/manufacturers/add")}>
            <FaPlus /> Add Manufacturer
          </button>
        )}
        </div>
      </div>
      <div className="card shadow mb-3">
        <div className="card-body">
          {/* Filters */}
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                placeholder="Manufacturer Name"
                className="form-control"
                value={filters.manufacturerName}
                onChange={(e) => setFilters({ ...filters, manufacturerName: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                placeholder="Manufacturer Code"
                className="form-control"
                value={filters.manufacturerCode}
                onChange={(e) => setFilters({ ...filters, manufacturerCode: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchManufacturers}>
                Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
      <div className="card-body">
      {/* Manufacturer Table */}
      {!loading && manufacturers.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped bg-white text-dark">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Code</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {manufacturers.map((manufacturer) => (
                <tr key={manufacturer.id}>
                  <td>{manufacturer.id}</td>
                  <td>{manufacturer.manufacturerName}</td>
                  <td>{manufacturer.manufacturerCode}</td>
                  <td>{manufacturer.createdBy}</td>
                  <td>{manufacturer.createdAt ? new Date(manufacturer.createdAt).toLocaleDateString() : "N/A"}</td>
                  <td>{manufacturer.updatedAt ? new Date(manufacturer.updatedAt).toLocaleDateString() : "N/A"}</td>
                  <td>
                    {/* Conditionally render actions */}
                    {userPermissions.includes("VIEW_MANUFACTURER") && (
                      <button
                        className="btn btn-outline-secondary btn-sm me-2"
                        onClick={() => navigate(`/manufacturers/view/${manufacturer.id}`)}
                      >
                        <FaEye /> View
                      </button>
                    )}

                    {userPermissions.includes("EDIT_MANUFACTURER") && (
                      <button
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => navigate(`/manufacturers/edit/${manufacturer.id}`)}
                      >
                        <FaEdit /> Edit
                      </button>
                    )}

                    {userPermissions.includes("DELETE_MANUFACTURER") && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(manufacturer.id)}
                      >
                        <FaTrash /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default ManufacturerList;
