import React, { useEffect, useState, useCallback, useMemo } from "react";
import { getInventoryLogs } from "../../services/inventoryLoggerService"; // ✅ API Service
import "./InventoryLoggerList.css"; // Import styles in your component
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import {
  FaClipboardList,
  FaSearch,
  FaArrowLeft,
  FaArrowRight,
  FaEraser,
} from "react-icons/fa";

const InventoryLoggerList = () => {
  const [logs, setLogs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  // ✅ Filters & Pagination State
  const [filters, setFilters] = useState({
    sku: "",
    cfId: "",  // ✅ Added Barcode ID filter
    warehouseId: "",
    orderId: "",
    incrementId: "",
    transactionNumber: "",
    transactionType: "",
    transactionSubType: "",
    transactionSubSubType: "",
    page: 0,
    size: 10,
  });

  // ✅ Predefined transaction types
  const transactionTypes = [
    "STOCK_IN",
    "STOCK_OUT",
    "FULL_RETURN",
    "DEBIT_NOTE",
    "CREDIT_NOTE",
    "ORDER_CANCEL",
  ];

  // ✅ Fetch Inventory Logs
  const fetchLogs = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await getInventoryLogs(activeFilters);
      if (response?.statusCode === 200 && Array.isArray(response.data?.content)) {
        setLogs(response.data.content);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        setLogs([]);
        toast.error("No inventory logs found!");
      }
    } catch (error) {
      toast.error("Error fetching inventory logs.");
      console.error("API Error:", error);
      setLogs([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  // ✅ Debounce API Calls to Optimize Performance
  const debounceFetchLogs = useMemo(() => debounce(fetchLogs, 500), [fetchLogs]);

  useEffect(() => {
    debounceFetchLogs();
    return () => debounceFetchLogs.cancel();
  }, [debounceFetchLogs]); // ✅ Added missing dependency

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaClipboardList className="me-2" /> Inventory Leader</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            View item-wise stock inflow, outflow, and running balance with full transaction history.
          </p>
        </div>
        <div>
          {/* <button className="btn btn-primary" onClick={() => navigate("/create-grn")}>
                  <FaPlus /> Create GRN
                </button> */}
        </div>
      </div>
      <div className="card shadow mb-3">
      <div className="card-body">
      <div className="row g-3">
      <div className="col-md-2">
          <input
            type="text"
            placeholder="Barcode ID (cfId)"
            className="form-control"
            value={filters.cfId}
            onChange={(e) => setFilters((prev) => ({ ...prev, cfId: e.target.value }))}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="SKU"
            className="form-control"
            value={filters.sku}
            onChange={(e) => setFilters((prev) => ({ ...prev, sku: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <input
            type="number"
            placeholder="Warehouse ID"
            className="form-control"
            value={filters.warehouseId}
            onChange={(e) => setFilters((prev) => ({ ...prev, warehouseId: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <input
            type="number"
            placeholder="Order ID"
            className="form-control"
            value={filters.orderId}
            onChange={(e) => setFilters((prev) => ({ ...prev, orderId: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <input
            type="text"
            placeholder="Increment ID"
            className="form-control"
            value={filters.incrementId}
            onChange={(e) => setFilters((prev) => ({ ...prev, incrementId: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <input
            type="text"
            placeholder="Transaction Number"
            className="form-control"
            value={filters.transactionNumber}
            onChange={(e) => setFilters((prev) => ({ ...prev, transactionNumber: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <select
            className="form-select"
            value={filters.transactionType}
            onChange={(e) => setFilters((prev) => ({ ...prev, transactionType: e.target.value }))}
          >
            <option value="">Transaction Type</option>
            {transactionTypes.map((type) => (
              <option key={type} value={type}>
                {type.replace("_", " ")}
              </option>
            ))}
          </select>
        </div>

        <div className="col-md-2">
          <input
            type="text"
            placeholder="Transaction Sub Type"
            className="form-control"
            value={filters.transactionSubType}
            onChange={(e) => setFilters((prev) => ({ ...prev, transactionSubType: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <input
            type="text"
            placeholder="Transaction Sub Sub Type"
            className="form-control"
            value={filters.transactionSubSubType}
            onChange={(e) => setFilters((prev) => ({ ...prev, transactionSubSubType: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <button className="btn btn-primary w-100" onClick={fetchLogs}>
            <FaSearch /> Search
          </button>
        </div>

        <div className="col-md-2">
          <button
            className="btn btn-warning w-100"
            onClick={() =>
              setFilters({
                cfId: "",
                sku: "",
                warehouseId: "",
                orderId: "",
                incrementId: "",
                transactionNumber: "",
                transactionType: "",
                transactionSubType: "",
                transactionSubSubType: "",
                page: 0,
                size: 10,
              })
            }
          >
            <FaEraser /> Reset
          </button>
        </div>
      </div>
      </div>
      </div>
      <div className="card shadow">
      <div className="card-body">
      {/* 📋 Inventory Logs Table */}
      {loading ? (
        <div className="text-center mt-3">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      ) : logs.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped bg-white text-dark" style={{ whiteSpace: "nowrap" }}>
            <thead className="table-dark">
              <tr>
                <th>Leader Id</th>
                <th>Bar Code Id</th>
                <th>SKU</th>
                <th>Warehouse ID</th>
                <th>Order ID</th>
                <th>Increment ID</th>
                <th>Transaction Number</th>
                <th>Stock Change</th>
                <th>Batch Total Stock</th>
                <th>Batch Previous Stock</th>
                <th>Total Stock</th>
                <th>Previous Stock</th>
                <th>Transaction Type</th>
                <th>Transaction Sub Type</th>
                <th>Transaction Sub Sub Type</th>
                <th>Created At</th>
                <th>Rack No.</th>
                <th>Self No.</th>
                <th>Floor</th>
                <th>MRP</th>
                <th>Batch No.</th>
                <th>Exp Date</th>
                <th>Sr No</th>
                <th>Mfg Date</th>
                <th>Manufactured By</th>
              </tr>
            </thead>
            <tbody>
              {logs.map((log) => (
                <tr key={log.id}>
                  <td>{log.id}</td>
                  <td>{log.cfId}</td>
                  <td>{log.sku}</td>
                  <td>{log.warehouseId}</td>
                  <td>{log.orderId || "N/A"}</td>
                  <td>{log.incrementId || "N/A"}</td>
                  <td>{log.transactionNumber || "N/A"}</td>
                  <td className={log.prevTotalStock < log.totalStock ? "text-success fw-bold" : "text-danger fw-bold"}>
                    {log.prevTotalStock < log.totalStock ? `+${log.totalStock - log.prevTotalStock}` : log.totalStock - log.prevTotalStock}
                  </td>

                  <td>{log.batchTotalStock}</td>
                  <td>{log.batchPrevTotalStock || "N/A"}</td>
                  <td>{log.totalStock}</td>
                  <td>{log.prevTotalStock || "N/A"}</td>
                  <td>{log.transactionType.replace("_", " ")}</td>
                  <td>{log.transactionSubType || "N/A"}</td>
                  <td>{log.transactionSubSubType || "N/A"}</td>
                  <td>{new Date(log.createdAt).toLocaleString()}</td>
                  <td>{log.rackNo || "N/A"}</td>
                  <td>{log.shelfNo || "N/A"}</td>
                  <td>{log.floor || "N/A"}</td>
                  <td>{log.cfMrp || "N/A"}</td>
                  <td>{log.cfBatchLotNo || "N/A"}</td>
                  <td>{new Date(log.cfExpiryDate).toLocaleString()}</td>
                  <td>{log.cfSrNo || "N/A"}</td>
                  <td>{new Date(log.cfMfgDate).toLocaleString()}</td>
                  <td>{log.cfManufacturedBy || "N/A"}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      ) : (
        <p className="text-center mt-3 text-muted">No inventory logs found.</p>
      )}

      {/* 📌 Pagination */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0 || logs.length === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>
          Page {filters.page + 1} of {totalPages}
        </span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages || logs.length === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default InventoryLoggerList;
