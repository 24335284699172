import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/inter-branch-transfers`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Handler
const apiRequest = async (endpoint, method = "GET", body = null) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }

    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

// ✅ **Fix API Endpoint for Searching Inter-Branch Transfers**
export const getInterBranchTransfers = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return apiRequest(`/search?${query}`); // ✅ Fixed Endpoint
};

// ✅ Fetch Inter-Branch Transfer by ID
export const getInterBranchTransferById = async (id) => {
  return apiRequest(`/${id}`);
};

// ✅ Approve Transfer
export const approveInterBranchTransfer = async (payload) => {
  return apiRequest(`/approve`, "POST", payload);
};

// ✅ Dispatch Transfer
export const dispatchInterBranchTransfer = async (payload) => {
  return apiRequest(`/dispatch`, "POST", payload);
};

// ✅ Receive Transfer
export const receiveInterBranchTransfer = async (payload) => {
  return apiRequest(`/receive`, "POST", payload);
};

// ✅ Create a New Transfer
export const createInterBranchTransfer = async (data) => {
  return apiRequest("", "POST", data);
};

// ✅ Fetch Status History by Transfer ID
export const getStatusHistoryByTransferId = async (id) => {
  return apiRequest(`/${id}/status-history`);
};