import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCustomerGroupById, updateCustomerGroup } from "../../services/customerGroupService";
import { toast } from "react-toastify";

const EditCustomerGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: "",
    customerGroupCode: "",
    isActive: false,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getCustomerGroupById(id)
      .then((res) => {
        // Assuming response.data holds the customer group
        setFormData(res.data || res);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Failed to load customer group");
        setLoading(false);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCustomerGroup(id, formData);
      toast.success("Customer group updated successfully!");
      navigate("/customer-groups");
    } catch (error) {
      toast.error(error.message || "Failed to update customer group.");
    }
  };

  if (loading) return <div className="container mt-5">Loading...</div>;

  return (
    <div className="container mt-5">
      <h2>Edit Customer Group</h2>
      {/* Back button added */}
      <div className="mb-3">
        <button className="btn btn-secondary" onClick={() => navigate("/customer-groups")}>
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Group Code</label>
          <input
            type="text"
            className="form-control"
            name="customerGroupCode"
            value={formData.customerGroupCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
          <label className="form-check-label">Active</label>
        </div>
        <button type="submit" className="btn btn-success">Update</button>
      </form>
    </div>
  );
};

export default EditCustomerGroup;
