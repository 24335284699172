import React, { useEffect, useState, useCallback } from "react";
import { getPickAndPackList } from "../services/pickAndPackService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  FaShippingFast,
  FaSearch,
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaPlus
} from "react-icons/fa";

const PickAndPackList = () => {
  const [pickAndPack, setPickAndPack] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    warehouseId: "",
    orderId: "",  // ✅ Added Order ID
    status: "",
    startDate: "",
    endDate: "",
    incrementId: "",
    deliveryPerson: "",
    page: 0,
    size: 10, // Default pagination size
  });

  const [userPermissions, setUserPermissions] = useState([]);

  const fetchPickAndPackRecords = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPickAndPackList(filters);
      if (response.statusCode === 200) {
        setPickAndPack(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch Pick and Pack records!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setPickAndPack([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchPickAndPackRecords();
  }, [fetchPickAndPackRecords]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if(details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (error) {
        // handle error if needed
      }
    }
  }, []);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaShippingFast /> Pick and Pack Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Streamlines order fulfillment by selecting and packaging items for shipment efficiently.
          </p>
        </div>
        <div>
        {userPermissions.includes("CREAT_PICK_AND_PACK") && (
          <button
            className="btn btn-primary"
            onClick={() => navigate("/pick-and-pack/create")}>
            <FaPlus /> Create Pick & Pack
          </button>
        )}
        </div>
      </div>

      {/* 🔍 Filters */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="number"
                placeholder="📦 Warehouse ID"
                className="form-control"
                value={filters.warehouseId}
                onChange={(e) => setFilters({ ...filters, warehouseId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="number"
                placeholder="📜 Order ID" // ✅ Added Order ID input
                className="form-control"
                value={filters.orderId}
                onChange={(e) => setFilters({ ...filters, orderId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <select
                className="form-select"
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              >
                <option value="">📌 All Statuses</option>
                <option value="1">✅ Completed</option>
                <option value="0">⏳ Pending</option>
              </select>
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                placeholder="🔎 Increment ID"
                className="form-control"
                value={filters.incrementId}
                onChange={(e) => setFilters({ ...filters, incrementId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                placeholder="🛵 Delivery Person"
                className="form-control"
                value={filters.deliveryPerson}
                onChange={(e) => setFilters({ ...filters, deliveryPerson: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchPickAndPackRecords}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 📊 Data Table */}
      <div className="card shadow">
            <div className="card-body">
      {!loading && pickAndPack.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped bg-white text-dark">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Order ID</th>
                <th>Increment ID</th>
                <th>Warehouse ID</th>
                <th>Courier Type</th>
                <th>AWB</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {pickAndPack.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.orderId}</td>
                  <td>
                  <span className="text-link" onClick={() => navigate(`/pick-and-pack/view/${record.id}`)}>{record.incrementId}</span>
                  </td>
                  <td>{record.warehouseId}</td>
                  <td>{record.courierType}</td>
                  <td>{record.awb}</td>
                  <td>{new Date(record.createdAt).toLocaleString()}</td>
                  <td>
                    {userPermissions.includes("VIEW_PICK_AND_PACK") && (
                      <button 
                        className="btn btn-outline-secondary btn-sm"
                        onClick={() => navigate(`/pick-and-pack/view/${record.id}`)}
                      >
                        <FaEye /> View
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && pickAndPack.length === 0 && (
        <div className="text-center mt-3">
          <h5>No records found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default PickAndPackList;
