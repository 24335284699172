import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { searchCreditNotes } from "../services/creditNoteService";
import { getCouriers } from "../services/courierService";
import {
  getPickAndPackById,
  downloadInvoice,
  updatePickAndPackStatus,
  updatePickAndPackShipment,
  createPartialReturnCreditNote // ✅ Add this import
} from "../services/pickAndPackService";
import { getOrderById } from "../services/orderService";
import { getWarehouseById } from "../services/warehouseService";
import { toast } from "react-toastify";
import { FaSave, FaUndo, FaArrowLeft, FaDownload, FaTrash } from "react-icons/fa";
import { Modal, Button } from "react-bootstrap";
const PickAndPackDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [pickAndPack, setPickAndPack] = useState(null);
  const [order, setOrder] = useState(null);
  const [warehouse, setWarehouse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState("");
  const [showReturnModal, setShowReturnModal] = useState(false);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [availableItems, setAvailableItems] = useState([]);
  const [selectedReturnItems, setSelectedReturnItems] = useState([]);
  const [creditNotes, setCreditNotes] = useState([]);
  const [couriers, setCouriers] = useState([]);

  // ✅ Add State for Shipment Details
  const [shipmentDetails, setShipmentDetails] = useState({
    courierType: "",
    insuranceReferenceNumber: "",
    esTat: "",
    distance: "",
    awb: "",
    vehicleNumber: "",
    vehicleType: "",
    transportMode: "",
  });

  const fetchCouriers = async () => {
    try {
      const response = await getCouriers();
      if (response?.statusCode === 200 && Array.isArray(response.data?.content)) {
        setCouriers(response.data.content); // ✅ Extract `content` array
      } else {
        setCouriers([]); // ✅ Ensure it's always an array
        toast.error("❌ Failed to fetch couriers!");
      }
    } catch (error) {
      setCouriers([]); // ✅ Ensure it's always an array
      toast.error("⚠️ Error fetching couriers.");
    }
  };
  // ✅ Fetch couriers when the component loads
  useEffect(() => {
    fetchCouriers();
  }, []);
  // ✅ Fetch Pick & Pack Details + Order & Warehouse in Parallel
  const fetchDetails = useCallback(async () => {
    try {
      setLoading(true);
      const pickAndPackResponse = await getPickAndPackById(id);
      if (pickAndPackResponse.statusCode !== 200) {
        toast.error("❌ Pick and Pack details not found!");
        return;
      }

      setPickAndPack(pickAndPackResponse.data);

      // ✅ Update Shipment Details in State
      setShipmentDetails({
        courierType: pickAndPackResponse.data.courierType || "",
        insuranceReferenceNumber: pickAndPackResponse.data.insuranceReferenceNumber || "",
        esTat: pickAndPackResponse.data.esTat || "",
        distance: pickAndPackResponse.data.distance || "",
        awb: pickAndPackResponse.data.awb || "",
        vehicleNumber: pickAndPackResponse.data.vehicleNumber || "",
        vehicleType: pickAndPackResponse.data.vehicleType || "",
        transportMode: pickAndPackResponse.data.transportMode || "",
      });

      // ✅ Fetch Order and Warehouse Details
      const [orderResponse, warehouseResponse] = await Promise.all([
        getOrderById(pickAndPackResponse.data.orderId),
        pickAndPackResponse.data.warehouseId ? getWarehouseById(pickAndPackResponse.data.warehouseId) : Promise.resolve(null),
      ]);

      if (orderResponse?.statusCode === 200) setOrder(orderResponse.data);
      if (warehouseResponse?.statusCode === 200) setWarehouse(warehouseResponse.data);

      // ✅ Fetch Credit Notes for this PickAndPack ID
      const creditNoteResponse = await searchCreditNotes({ pickAndPackId: pickAndPackResponse.data.id });
      if (creditNoteResponse?.statusCode === 200) {
        setCreditNotes(creditNoteResponse.data.content); // ✅ Store Credit Notes in state
      }

    } catch (error) {
      toast.error("⚠️ Error fetching details.");
    } finally {
      setLoading(false);
    }
  }, [id]);


  useEffect(() => {
    fetchDetails();
  }, [fetchDetails]);

  // ✅ Memoized function to get allowed status options (Performance Improvement)
  const statusOptions = useMemo(() => {
    if (!pickAndPack) return [];

    switch (pickAndPack.status) {
      case 21: return [{ id: 22, name: "Generate Invoice" }];
      case 22: return [{ id: 1, name: "Dispatched" }];
      case 1: return [{ id: 2, name: "Delivered" }];
      case 2: return [{ id: 6, name: "Return" }];
      case 6: return [{ id: 6, name: "Return" }]; // Read-only
      default: return [];
    }
  }, [pickAndPack]);

  // ✅ Set initial status selection
  useEffect(() => {
    if (pickAndPack?.status !== undefined) {
      setSelectedStatus(statusOptions.length === 1 ? statusOptions[0].id : pickAndPack.status);
    }
  }, [pickAndPack, statusOptions]);

  // ✅ Handle Status Change
  const handleStatusChange = (event) => {
    setSelectedStatus(Number(event.target.value));
  };

  // ✅ Handle Save Action
  const handleSave = async () => {
    if (!selectedStatus) {
      toast.error("⚠️ Please select a status.");
      return;
    }

    try {
      const payload = { id: pickAndPack.id, status: selectedStatus, comment, createdBy: 113 };
      const response = await updatePickAndPackStatus(payload);

      if (response.statusCode === 200) {
        toast.success(`✅ Status updated successfully!`, { autoClose: 3000 });

        // ✅ Refresh Data after Delay
        setTimeout(() => {
          fetchDetails();
          setComment("");
        }, 1000);
      } else {
        toast.error("⚠️ Failed to update status.");
      }
    } catch (error) {
      toast.error("❌ Error updating status.");
    }
  };

  // ✅ Handle Invoice Download
  const handleDownloadInvoice = async () => {
    try {
      const result = await downloadInvoice(id);
      if (result.success && result.fileUrl) {
        const link = document.createElement("a");
        link.href = result.fileUrl;
        link.setAttribute("download", `Invoice_${id}.pdf`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      toast.error("❌ Failed to download invoice.");
    }
  };

  if (loading) return <h5 className="text-center mt-4">Loading... 🌀</h5>;
  if (!pickAndPack) return <h5 className="text-center text-danger">Pick and Pack details not found ❌</h5>;

  const billingAddress = warehouse
    ? `${warehouse.address}, ${warehouse.city}, ${warehouse.state}, ${warehouse.pincode}`
    : "N/A";

  // ✅ Fix: Ensure proper matching by using a strict equality check
  const shippingAddress = order?.addresses?.find(
    (addr) => addr.addressType === "shipping"
  );

  const formattedShippingAddress = shippingAddress
    ? `${shippingAddress.street || "N/A"}, ${shippingAddress.city || "N/A"}, ${shippingAddress.state || "N/A"}, ${shippingAddress.postcode || "N/A"}`
    : "N/A";

  // ✅ Open Partial Return Modal
  const openReturnModal = () => {
    const items = pickAndPack?.pickAndPackItems?.map((item) => ({
      sku: item.sku,
      name: item.name,
      qtyAvailable: item.qty,
      pickAndPackItemId: item.id,
      qtyToReturn: 0,
      batchWiseReturns: item.pickAndPackItemStocks?.map((stock) => ({
        stockId: stock.id,
        batchNo: stock.batchNo,
        qtyAvailable: stock.stock,
        qtyToReturn: 0,
      })),
    }));

    setAvailableItems(items);
    setShowReturnModal(true);
  };

  // ✅ Handle SKU Selection
  const handleSkuChange = (sku) => {
    if (!selectedReturnItems.some((item) => item.sku === sku)) {
      const selectedItem = availableItems.find((item) => item.sku === sku);
      if (selectedItem) {
        setSelectedReturnItems([...selectedReturnItems, { ...selectedItem }]);
      }
    }
  };

  // ✅ Remove SKU from Selection
  const removeSku = (sku) => {
    setSelectedReturnItems(selectedReturnItems.filter((item) => item.sku !== sku));
  };

  // ✅ Handle SKU-Level Return Quantity
  const handleSkuQtyChange = (sku, qty) => {
    setSelectedReturnItems((prev) =>
      prev.map((item) =>
        item.sku === sku ? { ...item, qtyToReturn: Math.min(qty, item.qtyAvailable) } : item
      )
    );
  };

  // ✅ Handle Batch-Level Return Quantity
  const handleBatchQtyChange = (sku, stockId, qty) => {
    setSelectedReturnItems((prev) =>
      prev.map((item) =>
        item.sku === sku
          ? {
            ...item,
            batchWiseReturns: item.batchWiseReturns.map((batch) =>
              batch.stockId === stockId ? { ...batch, qtyToReturn: qty } : batch
            ),
          }
          : item
      )
    );
  };

  // ✅ Submit Partial Return
  const handlePartialReturnSubmit = async () => {
    if (!selectedReturnItems.length) {
      toast.error("⚠️ Please select at least one SKU for return.");
      return;
    }

    const payload = {
      pickAndPackId: pickAndPack.id,
      userId: 113,
      items: selectedReturnItems.map((item) => {
        // ✅ Flatten first batch stock if available
        const firstBatch = item.batchWiseReturns.length > 0 ? item.batchWiseReturns[0] : null;

        return {
          pickAndPackItemId: item.pickAndPackItemId,
          pickAndPackItemQtyChosen: item.qtyToReturn,
          ...(firstBatch && {
            pickAndPackItemStockId: firstBatch.stockId,
            pickAndPackItemStockChosen: firstBatch.qtyToReturn,
          }),
        };
      }),
    };

    try {
      console.log("📤 Sending Corrected Payload:", payload);
      const response = await createPartialReturnCreditNote(payload);

      if (response.statusCode === 201) {
        toast.success("✅ Partial Return Created Successfully!");
        setShowReturnModal(false);
        fetchDetails();
      } else {
        toast.error("⚠️ Failed to create partial return.");
      }
    } catch (error) {
      toast.error("❌ Error processing partial return.");
    }
  };

  // ✅ Handle Shipment Detail Changes
  const handleShipmentChange = (e) => {
    const { name, value } = e.target;
    setShipmentDetails((prev) => ({ ...prev, [name]: value }));
  };

  // ✅ Save Shipment Details
  const handleSaveShipment = async () => {
    if (!pickAndPack) {
      toast.error("⚠️ Pick and Pack details not found.");
      return;
    }

    const payload = {
      id: pickAndPack.id,
      courierType: Number(shipmentDetails.courierType), // Ensure it's a number
      insuranceReferenceNumber: shipmentDetails.insuranceReferenceNumber,
      esTat: Number(shipmentDetails.esTat),
      distance: Number(shipmentDetails.distance),
      awb: shipmentDetails.awb,
      vehicleNumber: shipmentDetails.vehicleNumber,
      vehicleType: shipmentDetails.vehicleType,
      transportMode: shipmentDetails.transportMode,
      updatedBy: 113, // Hardcoded for now, ideally, get from auth
    };

    try {
      const response = await updatePickAndPackShipment(payload);

      if (response.statusCode === 200) {
        toast.success("✅ Shipment details updated successfully!");
        fetchDetails(); // Refresh Data
      } else {
        toast.error("⚠️ Failed to update shipment details.");
      }
    } catch (error) {
      toast.error("❌ Error updating shipment details.");
    }
  };

  const saveActionLabel = statusOptions.length > 0 ? ` ${statusOptions[0].name}` : "Save Status";

  return (
    <div className="container">
      {/* 🔙 Header Section */}
      
        {/* 🔙 Header Section */}
        <div className="row align-items-center justify-content-between mb-3">
          <div className="col-md-8 text-center text-md-start">
            <h1 className="welcomeTitle text-primary">
              📦 Pick ID #{pickAndPack?.id} | 🚚 Shipment ID #{pickAndPack?.shipmentId}
            </h1>
          </div>
          <div className="col-md-4 text-md-end mt-2 mt-md-0">
            <button className="btn btn-dark mx-2" onClick={handleDownloadInvoice}>
              <FaDownload /> Download Invoice
            </button>
            <button className="btn btn-secondary" onClick={() => navigate("/pick-and-pack")}>
              <FaArrowLeft /> Back to List
            </button>
          </div>
        </div>
    


      {/* Top Section: Courier & Transport Details */}
      <div className="card p-3 shadow mt-3">
        <div className="row">
          <div className="col-md-3">
            <label>Courier Type *</label>
            <select
              className="form-control"
              name="courierType"
              value={shipmentDetails.courierType}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            >
              <option value="">Select Courier</option>
              {couriers.map((courier) => (
                <option key={courier.courierId} value={courier.courierId}>
                  {courier.courierName}
                </option>
              ))}
            </select>
          </div>



          <div className="col-md-3">
            <label>Insurance Reference Number</label>
            <input
              className="form-control"
              name="insuranceReferenceNumber"
              value={shipmentDetails.insuranceReferenceNumber}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
          <div className="col-md-3">
            <label>Estimated TAT (Days) *</label>
            <input
              className="form-control"
              name="esTat"
              value={shipmentDetails.esTat}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
          <div className="col-md-3">
            <label>Distance (KM) *</label>
            <input
              className="form-control"
              name="distance"
              value={shipmentDetails.distance}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
        </div>

        <div className="row mt-3">
          <div className="col-md-3">
            <label>AWB *</label>
            <input
              className="form-control"
              name="awb"
              value={shipmentDetails.awb}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
          <div className="col-md-3">
            <label>Vehicle Number *</label>
            <input
              className="form-control"
              name="vehicleNumber"
              value={shipmentDetails.vehicleNumber}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
          <div className="col-md-3">
            <label>Vehicle Type *</label>
            <input
              className="form-control"
              name="vehicleType"
              value={shipmentDetails.vehicleType}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
          <div className="col-md-3">
            <label>Transport Mode</label>
            <input
              className="form-control"
              name="transportMode"
              value={shipmentDetails.transportMode}
              onChange={handleShipmentChange}
              disabled={pickAndPack?.status !== 21}
            />
          </div>
        </div>

        <div className="row mt-3">
          {/* ✅ Status Dropdown */}
          <div className="col-md-6">
            <label>Status *</label>
            <select
              className="form-control"
              value={selectedStatus || ""}
              onChange={handleStatusChange}
              disabled={pickAndPack?.status === 6} // Read-only when status is "Return"
            >
              {statusOptions.map((status) => (
                <option key={status.id} value={status.id}>
                  {status.name}
                </option>
              ))}
            </select>
          </div>

          {/* ✅ Add Comment Input */}
          <div className="col-md-6">
            <label>Comment</label>
            <textarea
              className="form-control"
              rows="3"
              placeholder="Enter your comment here"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={pickAndPack?.status === 6} // Read-only when status is "Return"
            ></textarea>
          </div>
        </div>



        {/* 🛠️ Action Buttons */}
        {/* Action Buttons */}
        <div className="container mt-4">
          {/* 🛠️ Action Buttons */}
          <div className="mt-4">
            {pickAndPack?.courierType !== null && pickAndPack?.status !== 6 && (
              <button className="btn btn-primary mx-2" onClick={handleSave}>
                <FaSave /> {saveActionLabel}
              </button>
            )}
            {pickAndPack?.status === 2 && (
              <button className="btn btn-danger mx-2" onClick={openReturnModal}>
                <FaUndo /> Partial Return
              </button>
            )}
            {/* ✅ Save Shipment Details Button (Only if Editable) */}
            {pickAndPack?.status === 21 && (
              <button className="btn btn-primary" onClick={handleSaveShipment}>
                💾 Save Shipment Details
              </button>
            )}
          </div>
        </div>


        {/* ✅ Partial Return Modal */}
        <Modal show={showReturnModal} onHide={() => setShowReturnModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>🔄 Partial Return Selection</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="mb-3">
              <label>Select SKU to Return:</label>
              <select className="form-control" onChange={(e) => handleSkuChange(e.target.value)}>
                <option value="">Select SKU</option>
                {availableItems.map((item) => (
                  <option key={item.sku} value={item.sku}>
                    {item.sku} - {item.name} (Available: {item.qtyAvailable})
                  </option>
                ))}
              </select>
            </div>

            {selectedReturnItems.map((item) => (
              <div key={item.sku} className="border p-3 mb-3">
                <h5>
                  {item.name} ({item.sku}){" "}
                  <FaTrash className="text-danger cursor-pointer" onClick={() => removeSku(item.sku)} />
                </h5>

                <label>Return Quantity:</label>
                <input
                  type="number"
                  className="form-control"
                  value={item.qtyToReturn}
                  onChange={(e) => handleSkuQtyChange(item.sku, Number(e.target.value))}
                  min="0"
                  max={item.qtyAvailable}
                />

                <h6 className="mt-3">Batch-wise Return</h6>
                {item.batchWiseReturns.map((batch) => (
                  <div key={batch.stockId}>
                    <span>Batch {batch.batchNo}: </span>
                    <input
                      type="number"
                      className="form-control"
                      value={batch.qtyToReturn}
                      onChange={(e) => handleBatchQtyChange(item.sku, batch.stockId, Number(e.target.value))}
                      min="0"
                      max={batch.qtyAvailable}
                    />
                  </div>
                ))}
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowReturnModal(false)}>Close</Button>
            <Button variant="danger" onClick={handlePartialReturnSubmit}>Confirm Return</Button>
          </Modal.Footer>
        </Modal>
      </div>

      {/* 🚚 Shipping & Billing Details */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card shadow p-3 text-white" style={{ backgroundColor: "#0088E0" }}>
            <h5>Billing Address (Warehouse)</h5>
            <p className="mb-0">{billingAddress}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card shadow p-3 text-white" style={{ backgroundColor: "#0088E0" }}>
            <h5>Shipping Address</h5>
            <p className="mb-0">{formattedShippingAddress}</p>
          </div>
        </div>
      </div>

      {/* 📦 Item Details with Multiple Batch Information */}
      {/* 📦 Item Details with Multiple Batch Information */}
      <div className="card p-3 shadow mt-4">
        <h4 className="text-primary">📦 Item Details</h4>
        <table className="table table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th>SKU</th>
              <th>Product Name</th>
              <th>Qty</th>
              <th>Price</th>
              <th>Discount %</th>
              <th>Discount Amt</th>
              <th>SGST %</th>
              <th>CGST %</th>
              <th>IGST %</th>
              <th>Total</th>
            </tr>
          </thead>
          <tbody>
            {pickAndPack?.pickAndPackItems?.map((item) => (
              <React.Fragment key={item.id}>
                {/* Main Product Row */}
                <tr>
                  <td>{item.sku}</td>
                  <td>{item.name}</td>
                  <td>{item.qty}</td>
                  <td>₹{item.price?.toFixed(2)}</td>
                  <td>{item.discountPercent}%</td>
                  <td>₹{item.discountAmount?.toFixed(2)}</td>
                  <td>{item.sgstPercent}%</td>
                  <td>{item.cgstPercent}%</td>
                  <td>{item.igstPercent}%</td>
                  <td>₹{item.rowTotal?.toFixed(2)}</td>
                </tr>

                {/* Batch-Level Details with SGST, CGST, IGST and Profit/Loss Calculation */}
                {item.pickAndPackItemStocks?.map((stock) => {


                  return (
                    <tr key={stock.id} className="table-light">
                      <td colSpan="2" className="text-end">🔹 Batch No: {stock.batchNo}</td>
                      <td>{stock.stock}</td>
                      <td>₹{stock.price?.toFixed(2)}</td>
                      <td colSpan="2">Expiry: {stock.expiryDate}</td>
                      <td>{stock.sgstPercent}%</td>
                      <td>{stock.cgstPercent}%</td>
                      <td>{stock.igstPercent}%</td>
                      <td>₹{stock.rowTotal?.toFixed(2)}</td>
                    </tr>
                  );
                })}

                {/* Profit/Loss Row Below Each Batch */}
                {item.pickAndPackItemStocks?.map((stock) => {
                  const profitLossPercent =
                    stock.cfPrice > 0
                      ? ((stock.price - stock.cfPrice) / stock.cfPrice) * 100
                      : 0;

                  return (
                    <tr key={`profit-${stock.id}`} className="table-warning">
                      <td colSpan="7" className="text-end">Cost Price: ₹{stock.cfPrice?.toFixed(2)}</td>
                      <td colSpan="2">
                        {profitLossPercent >= 0 ? (
                          <span className="text-success">✅ Profit: {profitLossPercent.toFixed(2)}%</span>
                        ) : (
                          <span className="text-danger">❌ Loss: {Math.abs(profitLossPercent.toFixed(2))}%</span>
                        )}
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>




      {/* Invoice Summary Section (Right Aligned) */}
      <div className="row mt-4">
        {/* Left Side: Placeholder for proper alignment */}
        <div className="col-md-8">

        </div>

        {/* Right Side: Invoice Summary */}
        <div className="col-md-4">
          <div className="card p-3 shadow">
            <h5 className="text-primary">📜 Invoice Summary</h5>
            <p><strong>Subtotal:</strong> ₹{pickAndPack?.subTotal?.toFixed(2)}</p>
            <p><strong>Discount:</strong> ₹{pickAndPack?.discountAmount?.toFixed(2)}</p>
            <p><strong>SGST:</strong> ₹{pickAndPack?.sgstAmount?.toFixed(2)}</p>
            <p><strong>CGST:</strong> ₹{pickAndPack?.cgstAmount?.toFixed(2)}</p>
            <p><strong>IGST:</strong> ₹{pickAndPack?.igstAmount?.toFixed(2)}</p>
            <h5><strong>Grand Total:</strong> ₹{pickAndPack?.grandTotal?.toFixed(2)}</h5>
          </div>
        </div>
      </div>



      {/* 📜 Credit Note List Section */}
      {creditNotes.length > 0 && (
        <div className="card p-3 shadow mt-4">
          <h4 className="text-primary">📜 Credit Notes</h4>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th>Credit Note #</th>
                  <th>Order ID</th>
                  <th>Subtotal</th>
                  <th>Tax</th>
                  <th>Discount</th>
                  <th>Grand Total</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {creditNotes.map((note) => (
                  <tr key={note.id}>
                    <td>{note.id || "N/A"}</td>
                    <td>{note.orderId}</td>
                    <td>₹{note.subTotal?.toFixed(2)}</td>
                    <td>₹{note.taxAmount?.toFixed(2)}</td>
                    <td>₹{note.discountAmount?.toFixed(2)}</td>
                    <td>₹{note.grandTotal?.toFixed(2)}</td>
                    <td>
                      <button
                        className="btn btn-primary btn-sm mx-2"
                        onClick={() => navigate(`/credit-notes/view/${note.id}`)}
                      >
                        View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

      {/* 📝 Comments Section */}
      {pickAndPack?.pickAndPackComments?.length > 0 && (
        <div className="card p-3 shadow mt-4">
          <h4 className="text-secondary">📝 Pick and Pack Comments</h4>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              {/* Fix Comments Table Header */}
              <thead className="table-light">
                <tr>
                  <th>Comment</th>
                  <th>Created By</th>
                  <th>Created At</th>
                </tr>
              </thead>
              <tbody>
                {pickAndPack.pickAndPackComments.map((comment, index) => (
                  <tr key={index}>
                    <td>{comment.comment || "N/A"}</td>
                    <td>{comment.createdBy || "N/A"}</td>
                    <td>
                      {comment.createdAt
                        ? new Date(comment.createdAt).toLocaleString()
                        : "N/A"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PickAndPackDetails;
