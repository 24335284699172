import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getSupplier } from "../../services/supplierService";
import { getSupplierAddresses, getSupplierAddressById, updateSupplierAddress } from "../../services/supplierAddressService";
import { getAllCountries } from "../../services/countryService";
import { searchRegions } from "../../services/countryRegionService";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";
import {
  Container,
  Row,
  Col,
  Card,
  Button,
  Tab,
  Nav,
  Form,
  Spinner,
  Modal
} from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

/**
 * A stylish, tabbed view for supplier details.
 * Tabs: Details, Supplier Products, KYC Details, Verification Status & Comments, T & C
 */
const SupplierView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState(null);
  const [addresses, setAddresses] = useState([]);
  const [addrLoading, setAddrLoading] = useState(true);
  const [loading, setLoading] = useState(true);

  // New state variables for modal handling
  const [modalShow, setModalShow] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState({
    firstname: "",
    lastname: "",
    company: "",
    street: "",
    city: "",
    region: "",
    postcode: "",
    countryId: "",
    dialCode: "",
    telephone: "",
    isActive: 0
  });

  // New state variables for countries and regions
  const [countries, setCountries] = useState([]);
  const [regionsData, setRegionsData] = useState([]);

  useEffect(() => {
    const fetchSupplierDetails = async () => {
      setLoading(true);
      try {
        const response = await getSupplier(id);
        if (response.statusCode === 200) {
          setSupplier(response.data);
        } else {
          toast.error("Failed to fetch supplier details!");
        }
      } catch (error) {
        toast.error("Error fetching supplier details.");
      }
      setLoading(false);
    };

    fetchSupplierDetails();
  }, [id]);

  useEffect(() => {
    const fetchAddresses = async () => {
      if (supplier) {
        setAddrLoading(true);
        // If API returns addresses in supplier data then use it directly
        if (supplier.addresses && supplier.addresses.length > 0) {
          setAddresses(supplier.addresses);
          setAddrLoading(false);
          return;
        }
        const sId = supplier.sellerId || supplier.id; // adjusted for seller response
        try {
          const response = await getSupplierAddresses(sId);
          if (response.statusCode === 200 || Array.isArray(response.data)) {
            setAddresses(response.data);
          } else {
            toast.error("Failed to fetch supplier addresses!");
          }
        } catch (error) {
          toast.error("Error fetching supplier addresses.");
        }
        setAddrLoading(false);
      }
    };

    fetchAddresses();
  }, [supplier]);

  // New: fetch countries and regions when the modal opens
  useEffect(() => {
    if (modalShow) {
      getAllCountries()
        .then((data) => {
          const countriesArray = Array.isArray(data)
            ? data
            : data.data && Array.isArray(data.data)
            ? data.data
            : [];
          setCountries(countriesArray);
        })
        .catch(() => toast.error("Failed to load countries"));
      if (selectedAddress.countryId) {
        fetchRegions(selectedAddress.countryId);
      }
    }
  }, [modalShow, selectedAddress.countryId]);

  const fetchRegions = (countryId, searchTerm = "") => {
    searchRegions({ countryId, code: searchTerm, page: 0, size: 10 })
      .then((res) => {
        const content = res.data && res.data.content ? res.data.content : [];
        setRegionsData(content);
      })
      .catch(() => {
        setRegionsData([]);
        toast.error("Failed to load regions");
      });
  };

  // Handlers for opening modal in add/edit mode
  const handleAddAddress = () => {
    setIsEditing(false);
    setSelectedAddress({
      firstname: "",
      lastname: "",
      company: "",
      street: "",
      city: "",
      region: "",
      postcode: "",
      countryId: "",
      dialCode: "",
      telephone: "",
      isActive: 0
    });
    setModalShow(true);
  };

  const handleEditAddress = async (address) => {
    try {
      const res = await getSupplierAddressById(address.entityId);
      if (res.statusCode === 200) {
        setIsEditing(true);
        setSelectedAddress(res.data);
        setModalShow(true);
      } else {
        toast.error("Failed to fetch address details!");
      }
    } catch (err) {
      toast.error("Error fetching address details.");
    }
  };

  const handleSaveAddress = async () => {
    if (isEditing) {
      try {
        const res = await updateSupplierAddress(selectedAddress);
        console.log("Update API response:", res);
        toast.success("Address updated successfully!");
      } catch (error) {
        console.error("Update failed:", error);
        toast.error("Failed to update address.");
      }
    } else {
      // ...call addSupplierAddress() if needed...
      toast.success("Address added successfully!");
    }
    setModalShow(false);
  };

  return (
    <Container fluid className="py-5 bg-light text-dark">
      <Row className="mb-4">
        <Col xs="auto">
          <Button variant="outline-secondary" onClick={() => navigate(-1)}>
            <FaArrowLeft /> Back
          </Button>
        </Col>
      </Row>

      {/* Update supplier title to use sellerName */}
      <Row className="mb-5">
        <Col>
          <h4 className="text-center fw-bold">
            Supplier | {supplier?.sellerName || "Loading..."}
          </h4>
        </Col>
      </Row>

      {loading ? (
        <Row className="justify-content-center mt-5">
          <Col xs="auto" className="text-center">
            <Spinner animation="border" role="status" />
            <h5 className="mt-3">Loading supplier details...</h5>
          </Col>
        </Row>
      ) : !supplier ? (
        <Row className="justify-content-center">
          <Col xs="auto">
            <h5 className="text-center">No supplier details found.</h5>
          </Col>
        </Row>
      ) : (
        <Tab.Container defaultActiveKey="details">
          {/* Tabs Navigation */}
          <Nav variant="tabs" className="mb-4 justify-content-center">
            <Nav.Item>
              <Nav.Link eventKey="details">Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="supplierProducts">Supplier Products</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="kycDetails">KYC Details</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="verification">
                Verification Status &amp; Comments
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="terms">T &amp; C</Nav.Link>
            </Nav.Item>
          </Nav>

          {/* Tabs Content */}
          <Tab.Content>
            {/* DETAILS TAB */}
            <Tab.Pane eventKey="details">
              {/* Supplier Details Card remains */}
              <Card className="shadow-lg mb-4">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">Supplier Details</h5>
                </Card.Header>
                <Card.Body className="p-4">
                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Label className="fw-bold">Supplier ID</Form.Label>
                      <p>{supplier.id}</p>
                    </Col>
                    <Col md={4}>
                      <Form.Label className="fw-bold">Supplier Type</Form.Label>
                      <p>{supplier.sellerType || "N/A"}</p>
                    </Col>
                    <Col md={4}>
                      <Form.Label className="fw-bold">Supplier Name</Form.Label>
                      <p>{supplier.sellerName || "N/A"}</p>
                    </Col>
                  </Row>
                  <Row className="mb-3">
                    <Col md={4}>
                      <Form.Label className="fw-bold">Relationship Manager</Form.Label>
                      <p>{supplier.relationshipManager || "N/A"}</p>
                    </Col>
                    <Col md={4}>
                      <Form.Label className="fw-bold">Relationship Manager Mobile</Form.Label>
                      <p>{supplier.relationshipManagerMobileNo || "N/A"}</p>
                    </Col>
                    <Col md={4}>
                      <Form.Label className="fw-bold">Order Auto Approve</Form.Label>
                      <p>{supplier.orderAutoApprove ? "Yes" : "No"}</p>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>

              {/* Supplier Addresses with Edit/Add Button */}
              <Row className="mb-3">
                <Col className="text-end">
                  <Button variant="primary" onClick={handleAddAddress}>
                    Add Address
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Card className="shadow-lg mb-4">
                    <Card.Header className="bg-primary text-white">
                      <h5 className="mb-0">Supplier Addresses</h5>
                    </Card.Header>
                    <Card.Body className="p-4">
                      {addrLoading ? (
                        <div className="text-center">
                          <Spinner animation="border" role="status" size="sm" />
                          <span className="ms-2">Loading addresses...</span>
                        </div>
                      ) : addresses.length > 0 ? (
                        <Row>
                          {addresses.map((address) => (
                            <Col md={6} key={address.entityId}>
                              <Card className="mb-3">
                                <Card.Body>
                                  <Row>
                                    <Col md={6}>
                                      <p>
                                        <strong>Name:</strong> {address.firstname} {address.lastname}
                                      </p>
                                      <p>
                                        <strong>Company:</strong> {address.company}
                                      </p>
                                      <p>
                                        <strong>Street:</strong> {address.street}
                                      </p>
                                    </Col>
                                    <Col md={6}>
                                      <p>
                                        <strong>City:</strong> {address.city}
                                      </p>
                                      <p>
                                        <strong>Region:</strong> {address.region}
                                      </p>
                                      <p>
                                        <strong>Postcode:</strong> {address.postcode}
                                      </p>
                                      <p>
                                        <strong>Telephone:</strong> {address.dialCode} {address.telephone}
                                      </p>
                                    </Col>
                                  </Row>
                                  <div className="text-end">
                                    <Button
                                      variant="outline-primary"
                                      size="sm"
                                      onClick={() => handleEditAddress(address)}
                                    >
                                      Edit
                                    </Button>
                                  </div>
                                </Card.Body>
                              </Card>
                            </Col>
                          ))}
                        </Row>
                      ) : (
                        <p>No addresses found.</p>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              <div className="text-end mb-4">
                <Button variant="success" className="px-4">
                  Submit
                </Button>
              </div>
            </Tab.Pane>

            {/* SUPPLIER PRODUCTS TAB */}
            <Tab.Pane eventKey="supplierProducts">
              <Card className="shadow-sm mb-4">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">Supplier Products</h5>
                </Card.Header>
                <Card.Body className="p-4">
                  <p>Display or manage supplier products here.</p>
                </Card.Body>
              </Card>
            </Tab.Pane>

            {/* KYC DETAILS TAB */}
            <Tab.Pane eventKey="kycDetails">
              <Card className="shadow-sm mb-4">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">KYC Details</h5>
                </Card.Header>
                <Card.Body className="p-4">
                  <p>Show or update KYC documents here.</p>
                </Card.Body>
              </Card>
            </Tab.Pane>

            {/* VERIFICATION STATUS & COMMENTS TAB */}
            <Tab.Pane eventKey="verification">
              <Card className="shadow-sm mb-4">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">Verification Status &amp; Comments</h5>
                </Card.Header>
                <Card.Body className="p-4">
                  <p>Display verification logs and any comments here.</p>
                </Card.Body>
              </Card>
            </Tab.Pane>

            {/* TERMS & CONDITIONS TAB */}
            <Tab.Pane eventKey="terms">
              <Card className="shadow-sm mb-4">
                <Card.Header className="bg-primary text-white">
                  <h5 className="mb-0">T &amp; C</h5>
                </Card.Header>
                <Card.Body className="p-4">
                  <p>Display or update Terms &amp; Conditions here.</p>
                </Card.Body>
              </Card>
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>
      )}

      {/* Modal for Add/Edit Address */}
      <Modal show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header closeButton>
          <Modal.Title>{isEditing ? "Edit Address" : "Add Address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="formFirstname">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.firstname}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, firstname: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formLastname">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.lastname}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, lastname: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>Company</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.company}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, company: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formStreet">
              <Form.Label>Street</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.street}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, street: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.city}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, city: e.target.value })
                }
              />
            </Form.Group>
            {/* New Country selection */}
            <Form.Group className="mb-3" controlId="formCountryId">
              <Form.Label>Country</Form.Label>
              <Form.Control
                as="select"
                value={selectedAddress.countryId}
                onChange={(e) => {
                  setSelectedAddress({ ...selectedAddress, countryId: e.target.value });
                  fetchRegions(e.target.value);
                }}
              >
                <option value="">Select Country</option>
                {countries.map((country) => (
                  <option key={country.id} value={country.countryId}>
                    {country.countryName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
            {/* New Region selection */}
            <Form.Group className="mb-3" controlId="formRegion">
              <Form.Label>Region</Form.Label>
              <Form.Control
                type="text"
                list="regionsList"
                value={selectedAddress.region}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, region: e.target.value })
                }
              />
              <datalist id="regionsList">
                {regionsData.map((region) => (
                  <option key={region.regionId} value={region.code}>
                    {region.defaultName}
                  </option>
                ))}
              </datalist>
            </Form.Group>
            <Form.Group className="mb-3" controlId="formPostcode">
              <Form.Label>Postcode</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.postcode}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, postcode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formDialCode">
              <Form.Label>Dial Code</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.dialCode}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, dialCode: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formTelephone">
              <Form.Label>Telephone</Form.Label>
              <Form.Control
                type="text"
                value={selectedAddress.telephone}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, telephone: e.target.value })
                }
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formIsActive">
              <Form.Check
                type="checkbox"
                label="Active"
                checked={selectedAddress.isActive === 1}
                onChange={(e) =>
                  setSelectedAddress({ ...selectedAddress, isActive: e.target.checked ? 1 : 0 })
                }
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setModalShow(false)}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleSaveAddress}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};

export default SupplierView;
