import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/pick-and-pack`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Handler
// ✅ API Request Function (Handles All API Calls)
const apiRequest = async (endpoint, method = "GET", body = null, isBlob = false) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const url = endpoint.startsWith("http") ? endpoint : `${BASE_URL}${endpoint}`; // ✅ Supports full URLs
    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(url, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }

    if (isBlob) return response.blob(); // For file downloads

    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

// ✅ Fetch Pick and Pack records with filters
export const searchPickAndPacks = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return apiRequest(`/search?${query}`);
};

// ✅ Update Pick and Pack Shipment Details
export const updatePickAndPackShipment = async (data) => {
  return apiRequest("/updatepickpack", "PUT", data);
};



export const createPartialReturnCreditNote = async (payload) => {
  return apiRequest(`${config.BASE_URL}/api/credit-notes/partial-return`, "POST", payload);
};


// ✅ Create a new Pick and Pack entry (FIXED)
export const createPickAndPack = async (data) => {
  return apiRequest("", "POST", data);
};

// ✅ Fetch all Pick and Pack records with pagination
export const getPickAndPackList = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return apiRequest(`/search?${query}`);
};

// ✅ Fetch Pick and Pack details by ID
export const getPickAndPackById = async (id) => {
  return apiRequest(`/${id}`);
};

// ✅ Update Pick and Pack Status
export const updatePickAndPackStatus = async (data) => {
  return apiRequest("/update-status", "PATCH", data);
};

// ✅ Download Invoice with Improved Handling
export const downloadInvoice = async (id) => {
  try {
    const blob = await apiRequest(`/../invoices/${id}/download`, "GET", null, true);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `invoice_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Cleanup Object URL
    setTimeout(() => window.URL.revokeObjectURL(url), 100);

    return { success: true, message: "Invoice downloaded successfully!" };
  } catch (error) {
    return { success: false, message: "Error downloading invoice." };
  }
};
