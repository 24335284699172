import React, { useEffect, useState, useCallback } from "react";
import { searchCategories, deleteCategory } from "../services/categoryService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaPlusCircle, FaEdit, FaTrash, FaSearch, FaEye, FaRegListAlt } from "react-icons/fa"; // Added FaEye

const initialFilters = {
  categoryName: "",
  isActive: 1,
  page: 0,
  size: 10,
};

const CategoryList = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);
  const [inputFilters, setInputFilters] = useState(initialFilters);
  const [filters, setFilters] = useState(initialFilters);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(1);
  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  // ✅ Fetch categories based on filters
  const fetchCategories = useCallback(async () => {
    setLoading(true);
    try {
      const data = await searchCategories(filters);
      // Changed from data.response.content to data.data.content
      setCategories(data.data.content || []);
      setTotalPages(data.data.totalPages || 1);
    } catch (err) {
      toast.error("Error fetching categories!");
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchCategories();
  }, [fetchCategories]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setInputFilters({ ...inputFilters, [name]: value, page: 0 });
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this category?")) {
      try {
        await deleteCategory(id);
        toast.success("Category deleted successfully! ✅");
        setCategories(categories.filter((cat) => cat.entityId !== id));
      } catch (error) {
        toast.error("Failed to delete category.");
      }
    }
  };

  const updatePage = (newPage) => {
    setFilters({ ...filters, page: newPage });
    setInputFilters({ ...inputFilters, page: newPage });
  };

  return (
    <div className="container" >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaRegListAlt /> Category Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Simplify product navigation through structured category control.
          </p>
        </div>
        <div>
          {userPermissions.includes("ADD_CATEGORY") && (
            <button className="btn btn-primary" onClick={() => navigate("/categories/add")}>
              <FaPlusCircle /> Add Category
            </button>
          )}
        </div>
      </div>

      {/* Updated Filter Area */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <form>
            <div className="row g-3">
              <div className="col-md-2">
                {/* <label htmlFor="categoryName" className="form-label">Category Name</label> */}
                <input
                  id="categoryName"
                  type="text"
                  className="form-control"
                  placeholder="Enter category name"
                  name="categoryName"
                  value={inputFilters.categoryName}
                  onChange={handleSearchChange}
                />
              </div>
              <div className="col-md-2">
                {/* <label htmlFor="isActive" className="form-label">Status</label> */}
                <select
                  id="isActive"
                  className="form-select"
                  name="isActive"
                  value={inputFilters.isActive}
                  onChange={handleSearchChange}
                >
                  <option value={1}>Active</option>
                  <option value={0}>Inactive</option>
                </select>
              </div>
              <div className="col-md-2">
                <button type="button" className="btn btn-primary w-100" onClick={() => setFilters(inputFilters)}>
                  <FaSearch className="me-2" /> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div className="card shadow mb-3">   
        <div className="card-body">
      <div className="table-responsive">
        <table className="table table-bordered table-hover">
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>Category Name</th>
              <th>Slug</th>
              <th>Parent Category</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {!loading && categories.length > 0 ? categories.map((category) => (
              <tr key={category.entityId}>
                <td>{category.entityId}</td>
                <td>{category.categoryName}</td>
                <td>{category.slug}</td>
                <td>{category.parentCategoryName || "None"}</td>
                <td>
                  {/* NEW: Conditionally render View, Edit, Delete actions */}
                  {userPermissions.includes("VIEW_CATEGORY") && (
                    <FaEye
                      onClick={() => navigate(`/categories/view/${category.entityId}`)}
                      style={{ cursor: "pointer", marginRight: "8px" }}
                    />
                  )}
                  {userPermissions.includes("EDIT_CATEGORY") && (
                    <FaEdit
                      onClick={() => navigate(`/categories/edit/${category.entityId}`)}
                      style={{ cursor: "pointer", marginRight: "8px" }}
                    />
                  )}
                  {userPermissions.includes("DELETE_CATEGORY") && (
                    <FaTrash
                      onClick={() => handleDelete(category.entityId)}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </td>
              </tr>
            )) : <tr><td colSpan="5">No categories found.</td></tr>}
          </tbody>
        </table>
      </div>

      <nav>
        <ul className="pagination justify-content-center">
          <li className={`page-item ${filters.page === 0 ? "disabled" : ""}`}>
            <button
              className="btn btn-primary"
              onClick={() => updatePage(filters.page - 1)}
              disabled={filters.page === 0}
            >
              Previous
            </button>
          </li>
          {Array.from({ length: totalPages }).map((_, index) => (
            <li key={index} className={`page-item ${filters.page === index ? "active" : ""}`}>
              <button className="btn btn-primary" onClick={() => updatePage(index)}>
                {index + 1}
              </button>
            </li>
          ))}
          <li className={`page-item ${filters.page === totalPages - 1 ? "disabled" : ""}`}>
            <button
              className="btn btn-primary"
              onClick={() => updatePage(filters.page + 1)}
              disabled={filters.page === totalPages - 1}
            >
              Next
            </button>
          </li>
        </ul>
      </nav>
    </div>
    </div>
    </div>
  );
};

export default CategoryList;
