import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getAllCustomerGroups } from "../../services/customerGroupService";
import { toast } from "react-toastify";
import { FaPlus, FaSearch, FaEye, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CustomerGroupList = () => {
  const navigate = useNavigate();
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    customerGroupCode: "",
    isActive: "",
    page: 0,
    size: 10,
  });
  const [totalPages, setTotalPages] = useState(0);

  const fetchGroups = useCallback(async () => {
    setLoading(true);
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await getAllCustomerGroups(query);
      if (response.statusCode === 200) {
        setGroups(response.data.content || []);
        setTotalPages(response.data.totalPages || 0);
      } else {
        toast.error("Failed to fetch customer groups!");
      }
    } catch (error) {
      toast.error("Error fetching customer groups.");
      setGroups([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchGroups();
  }, [fetchGroups]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h1 className="text-primary">Customer Group Management</h1>
        <button className="btn btn-primary" onClick={() => navigate("/customer-groups/add")}>
          <FaPlus /> Add Customer Group
        </button>
      </div>
      <div className="card shadow-sm mb-3">
        <div className="card-body">
          <div className="row g-2">
            <div className="col-md-4">
              <input
                type="text"
                placeholder="Search by Group Code"
                className="form-control"
                value={filters.customerGroupCode}
                onChange={(e) => setFilters({ ...filters, customerGroupCode: e.target.value })}
              />
            </div>
            <div className="col-md-4">
              <select
                className="form-select"
                value={filters.isActive}
                onChange={(e) => setFilters({ ...filters, isActive: e.target.value })}
              >
                <option value="">Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="col-md-4">
              <button className="btn btn-primary w-100" onClick={fetchGroups}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      {loading && <div className="text-center my-3">Loading customer groups...</div>}
      {!loading && groups.length > 0 && (
        <div className="table-responsive">
          <table className="table table-striped table-bordered">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Group Code</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {groups.map((group) => (
                <tr key={group.id}>
                  <td>{group.id}</td>
                  <td>{group.customerGroupCode}</td>
                  <td>{group.isActive ? "Yes" : "No"}</td>
                  <td>
                    <button className="btn btn-sm btn-outline-secondary" onClick={() => navigate(`/customer-groups/edit/${group.id}`)}>
                      <FaEye /> Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {!loading && groups.length === 0 && <div className="text-center my-3">No customer groups found.</div>}
      <div className="d-flex justify-content-between">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
        >
          <FaArrowLeft /> Previous
        </button>
        <span>
          Page {filters.page + 1} of {totalPages}
        </span>
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default CustomerGroupList;
