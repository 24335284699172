import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/inventory`;
const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorText = await response.text();
    console.error("API Error:", response.status, errorText); // ✅ Debugging log
    throw new Error(errorText || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

// ✅ Fetch inventory list with filters & pagination
export const getInventoryList = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/searchgroupsku?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch batch details for a specific SKU
// ✅ Fetch batch details for a specific SKU and warehouseId
export const getBatchDetails = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const url = `${BASE_URL}/search?${query}`; // ✅ Using search endpoint

  console.log("Fetching batch details from:", url);

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};



// ✅ Fetch warehouse list
export const getWarehouses = async () => {
  const url = `${config.BASE_URL}/api/warehouses`;

  console.log("Fetching warehouses from:", url); // ✅ Debugging log

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};
