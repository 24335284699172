import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getCreditNoteById, downloadCreditNote } from "../services/creditNoteService";
import { getWarehouseById } from "../services/warehouseService";
import { getOrderById } from "../services/orderService";
import { toast } from "react-toastify";
import { FaArrowLeft, FaDownload } from "react-icons/fa";

const CreditNoteDetails = () => {
  const { id } = useParams();
  const [creditNote, setCreditNote] = useState(null);
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null); // ✅ State for Warehouse

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        // Fetch Credit Note Data
        const response = await getCreditNoteById(id);
        if (response.statusCode === 200) {
          setCreditNote(response.data);

          // Fetch Order Details
          const orderResponse = await getOrderById(response.data.orderId);
          if (orderResponse.statusCode === 200) {
            setOrder(orderResponse.data);
          } else {
            toast.error("Order details not found!");
          }

          // ✅ Fetch Warehouse Details
          if (response.data.warehouseId) {
            const warehouseResponse = await getWarehouseById(response.data.warehouseId);
            if (warehouseResponse.statusCode === 200) {
              setWarehouse(warehouseResponse.data);
            } else {
              toast.error("Warehouse details not found!");
            }
          }
        } else {
          toast.error("Credit Note details not found!");
        }
      } catch (error) {
        toast.error("Error fetching details.");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [id]);


  // ✅ Invoice Download Handler
  const handleDownloadCreditNote = async () => {
    if (!creditNote?.creditNotePath) {
      toast.error("Credit Note PDF not available.");
      return;
    }

    const result = await downloadCreditNote(id);
    if (result.success) {
      toast.success(result.message);
    } else {
      toast.error(result.message);
    }
  };

  if (loading) return <h5 className="text-center mt-4">Loading... 🌀</h5>;
  if (!creditNote) return <h5 className="text-center text-danger">Credit Note details not found ❌</h5>;

  // ✅ Extract Billing Address from Order
  // Extract Billing Address Properly
const billingAddress = order?.addresses?.find(addr => addr.addressType === "billing");
const formattedBillingAddress = billingAddress
  ? `${billingAddress.street}, ${billingAddress.city}, ${billingAddress.postcode}, ${billingAddress.countryId}`
  : "N/A";

// Extract Shipping Address Properly
const shippingAddress = order?.addresses?.find(addr => addr.addressType === "shipping");
const formattedShippingAddress = shippingAddress
  ? `${shippingAddress.street}, ${shippingAddress.city}, ${shippingAddress.postcode}, ${shippingAddress.countryId}`
  : "N/A";


  return (
    <div className="container mt-4">
      {/* 🔙 Header Section */}
      <div className="d-flex align-items-center justify-content-between mb-3">
        <h2 className="text-primary mb-0">📝 Credit Note ID #{creditNote.id}</h2>
        <div>
          <button className="btn btn-dark mx-2" onClick={handleDownloadCreditNote}>
            <FaDownload /> Download Credit Note
          </button>
          <button className="btn btn-secondary" onClick={() => navigate("/credit-notes")}>
            <FaArrowLeft /> Back to Credit Notes List
          </button>
        </div>
      </div>

      {/* 📋 Credit Note Information */}
      <div className="card p-3 shadow mt-3">
        <div className="row">
          <div className="col-md-4">
            <label>Credit Note Number</label>
            <input className="form-control" value={creditNote.creditNoteNumber || "N/A"} disabled />
          </div>
          <div className="col-md-4">
            <label>Order ID</label>
            <input className="form-control" value={creditNote.orderId || "N/A"} disabled />
          </div>
          <div className="col-md-4">
            <label>Pick & Pack ID</label>
            <input className="form-control" value={creditNote.pickAndPackId || "N/A"} disabled />
          </div>
        </div>
      </div>
     {/* 🏢 Warehouse Details Section */}
<div className="row mt-8">
  <div className="col-md-12 offset-md-12">
    <div className="card shadow p-3 text-white" style={{ backgroundColor: "#007BFF" }}>
      <h5>🏢 Warehouse Details</h5>
      <p className="mb-0"><strong>Name:</strong> {warehouse?.name || "N/A"}</p>
      <p className="mb-0"><strong>Code:</strong> {warehouse?.warehouseCode || "N/A"}</p>
      <p className="mb-0"><strong>Incharge:</strong> {warehouse?.incharge || "N/A"}</p>
      <p className="mb-0"><strong>Email:</strong> {warehouse?.email || "N/A"}</p>
      <p className="mb-0"><strong>Contact:</strong> {warehouse?.contact || "N/A"}</p>
      <p className="mb-0"><strong>Address:</strong> {warehouse?.address}, {warehouse?.city}, {warehouse?.pincode}</p>
      <p className="mb-0"><strong>GST Number:</strong> {warehouse?.gstnNumber || "N/A"}</p>
    </div>
  </div>
</div>



      {/* 🏠 Billing & Shipping Addresses */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card shadow p-3 text-white" style={{ backgroundColor: "#0088E0" }}>
            <h5>Billing Address</h5>
            <p className="mb-0">{formattedBillingAddress}</p>
          </div>
        </div>
        <div className="col-md-6">
          <div className="card shadow p-3 text-white" style={{ backgroundColor: "#0088E0" }}>
            <h5>Shipping Address</h5>
            <p className="mb-0">{formattedShippingAddress}</p>
          </div>
        </div>
      </div>

     {/* 📦 Credit Note Item Details with Batch Information */}
     <div className="card p-3 shadow mt-4">
  <h4 className="text-primary">📦 Credit Note Items</h4>
  <div className="table-responsive">
    <table className="table table-bordered table-hover">
      <thead className="table-dark">
        <tr>
          <th>SKU</th>
          <th>Product Name</th>
          <th>Qty</th>
          <th>Price</th>
          <th>SGST</th>
          <th>CGST</th>
          <th>IGST</th>
          <th>Discount%</th>
          <th>Discount Amt</th>
          <th>Total</th>
        </tr>
      </thead>
      <tbody>
        {creditNote.creditInvoiceItems?.map((item) => (
          <React.Fragment key={item.id}>
            {/* ✅ Main Item Row */}
            <tr>
              <td>{item.sku}</td>
              <td>{item.name}</td>
              <td>{item.qty}</td>
              <td>₹{item.price?.toFixed(2)}</td>
              <td>{item.sgstPercent}% (₹{item.sgstAmount?.toFixed(2)})</td>
              <td>{item.cgstPercent}% (₹{item.cgstAmount?.toFixed(2)})</td>
              <td>{item.igstPercent}% (₹{item.igstAmount?.toFixed(2)})</td>
              <td>{item.discountPercent}%</td>
              <td>₹{item.discountAmount?.toFixed(2)}</td>
              <td>₹{item.rowTotal?.toFixed(2)}</td>
            </tr>

            {/* ✅ Batch-Level Details */}
            {item.creditInvoiceItemStocks?.map((batch) => (
              <tr key={batch.stockId} className="table-light">
                <td colSpan="2" className="text-end">
                  <strong>• Batch Stock ID: {batch.stockId}</strong>
                </td>
                <td>{batch.qty}</td>
                <td>₹{batch.price?.toFixed(2)}</td>
                <td>{batch.sgstPercent}% (₹{batch.sgstAmount?.toFixed(2)})</td>
                <td>{batch.cgstPercent}% (₹{batch.cgstAmount?.toFixed(2)})</td>
                <td>{batch.igstPercent}% (₹{batch.igstAmount?.toFixed(2)})</td>
                <td>{batch.discountPercent}%</td>
                <td>₹{batch.discountAmount?.toFixed(2)}</td>
                <td>₹{batch.rowTotal?.toFixed(2)}</td>
              </tr>
            ))}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  </div>
</div>



      {/* 📜 Summary */}
      <div className="row mt-4">
        <div className="col-md-8"></div>
        <div className="col-md-4">
          <div className="card p-3 shadow">
            <h5 className="text-primary">📜 Credit Note Summary</h5>
            <p><strong>Subtotal:</strong> ₹{creditNote?.subTotal?.toFixed(2)}</p>
            <p><strong>Discount:</strong> ₹{creditNote?.discountAmount?.toFixed(2)}</p>
            <p><strong>Total Tax:</strong> ₹{(creditNote?.sgstAmount + creditNote?.cgstAmount + creditNote?.igstAmount)?.toFixed(2)}</p>
            <h5><strong>Grand Total:</strong> ₹{creditNote?.grandTotal?.toFixed(2)}</h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreditNoteDetails;
