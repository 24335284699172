import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createCustomer } from "../../services/customerService";
import { toast } from "react-toastify";
import { getAllCountries, searchCountries } from "../../services/countryService";
import { searchRegions } from "../../services/countryRegionService";
import { searchCustomerGroups } from "../../services/customerGroupService";
import { searchUsers } from "../../services/userService";

const CreateCustomer = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstname: "",
    lastname: "",
    email: "",
    groupId: "",
    bdeId: "",
    userId: "",
    countryId: "IN", // default set to India
    dialCode: "+91", // default dial code for India
    mobileNumber: "",
    street: "",
    city: "",
    region: "",
    postcode: ""
  });

  const [errors, setErrors] = useState({});
  const [countries, setCountries] = useState([]);
  const [regionsData, setRegionsData] = useState([]);
  const [countryInput, setCountryInput] = useState("");
  const [customerGroups, setCustomerGroups] = useState([]);
  const [groupInput, setGroupInput] = useState("");
  const [bdeUsers, setBdeUsers] = useState([]);
  const [bdeInput, setBdeInput] = useState("");

  useEffect(() => {
    getAllCountries()
      .then((res) => {
        const responseData = res.data.data ? res.data.data : res.data;
        let countriesArr = Array.isArray(responseData.content) ? responseData.content : [];
        if (!countriesArr.some((c) => c.countryId === "IN")) {
          countriesArr.unshift({ countryId: "IN", countryName: "India", dialCode: "+91" });
        }
        setCountries(countriesArr);
      })
      .catch(() => {
        toast.error("Failed to load countries");
      });
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      const effectiveCountryId = formData.countryId;
      searchRegions({ countryId: effectiveCountryId, code: "", page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          const regionsArr = Array.isArray(responseData.content) ? responseData.content : [];
          setRegionsData(regionsArr);
        })
        .catch(() => {
          setRegionsData([]);
          toast.error("Failed to load regions");
        });
    }
  }, [formData.countryId]);

  useEffect(() => {
    const selected = countries.find((c) => c.countryId === formData.countryId);
    if (selected) setCountryInput(selected.countryName);
  }, [formData.countryId, countries]);

  useEffect(() => {
    if (formData.countryId && regionsData.length > 0 && !formData.region) {
      setFormData(prev => ({ ...prev, region: regionsData[0].code }));
    }
  }, [regionsData, formData.countryId, formData.region]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setFormData(prev => ({ ...prev, userId: parsed.id }));
      } catch (e) {
        console.error("Error parsing userDetails", e);
      }
    }
  }, []);

  const validateForm = () => {
    const newErrors = {};
    if (!formData.email.trim()) newErrors.email = "Email is required";
    if (!formData.firstname.trim()) newErrors.firstname = "First name is required";
    if (!formData.lastname.trim()) newErrors.lastname = "Last name is required";
    if (!formData.groupId) newErrors.groupId = "Group ID is required";
    if (!formData.bdeId) newErrors.bdeId = "BDE ID is required";
    if (!formData.dialCode.trim()) newErrors.dialCode = "Registration dialer code is required";
    if (!formData.countryId.trim()) newErrors.countryId = "Country ID is required";
    if (!formData.mobileNumber.trim()) newErrors.mobileNumber = "Registration mobile number is required";
    return newErrors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleCountryInputChange = (e) => {
    const inputVal = e.target.value;
    setCountryInput(inputVal);
    if (inputVal.length >= 2) {
      searchCountries({ countryName: inputVal, page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          let filteredCountries = Array.isArray(responseData.content)
            ? responseData.content
            : [];
          setCountries(filteredCountries);
        })
        .catch(() => {
          toast.error("Failed to load filtered countries");
        });
    }
    const selected = countries.find(
      (c) => c.countryName.toLowerCase() === inputVal.toLowerCase()
    );
    if (selected) {
      setFormData(prev => ({
        ...prev,
        countryId: selected.countryId,
        dialCode: selected.countryId === "IN" ? "+91" : "+1"
      }));
      const effectiveCountryId = selected.countryId;
      searchRegions({ countryId: effectiveCountryId, code: "", page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          const regionsArr = Array.isArray(responseData.content) ? responseData.content : [];
          setRegionsData(regionsArr);
        })
        .catch(() => {
          setRegionsData([]);
          toast.error("Failed to load regions");
        });
    }
  };

  const handleGroupInputChange = (e) => {
    const inputVal = e.target.value;
    setGroupInput(inputVal);
    if (inputVal.length >= 2) {
      searchCustomerGroups({ customerGroupCode: inputVal, page: 0, size: 10 })
        .then((res) => {
          const groups = res.data ? res.data.content : [];
          setCustomerGroups(groups);
        })
        .catch(() => {
          toast.error("Failed to load customer groups");
        });
    }
    const matchedGroup = customerGroups.find(
      (g) => g.customerGroupCode.toLowerCase() === inputVal.toLowerCase()
    );
    if (matchedGroup) {
      setFormData((prev) => ({ ...prev, groupId: matchedGroup.id }));
    }
  };

  const handleBDEInputChange = (e) => {
    const inputVal = e.target.value;
    setBdeInput(inputVal);
    if (inputVal.length >= 2) {
      searchUsers({ name: inputVal, page: 0, size: 10 })
        .then((res) => {
          const users = res.data ? res.data.content : [];
          setBdeUsers(users);
        })
        .catch(() => {
          toast.error("Failed to load BDE users");
        });
    }
    const matchedUser = bdeUsers.find(
      (user) => user.name.toLowerCase() === inputVal.toLowerCase()
    );
    if (matchedUser) {
      setFormData((prev) => ({ ...prev, bdeId: matchedUser.id }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      toast.error(Object.values(validationErrors)[0]);
      return;
    }
    
    // Dynamically update userId from localStorage
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setFormData(prev => ({ ...prev, userId: parsed.id }));
      } catch (e) {
        console.error("Error parsing userDetails", e);
      }
    }

    // Transform formData to match the required payload
    const payload = {
      email: formData.email,
      groupId: Number(formData.groupId),
      firstname: formData.firstname,
      lastname: formData.lastname,
      bdeId: Number(formData.bdeId),
      userId: Number(formData.userId),
      regDialerCode: formData.dialCode,
      countryId: formData.countryId,
      regMobileNumber: formData.mobileNumber,
      addresses: [
        {
          firstname: formData.firstname,
          lastname: formData.lastname,
          street: formData.street,
          city: formData.city,
          region: formData.region,
          regionId: 1, // update if you have the actual region ID
          postcode: formData.postcode,
          countryId: formData.countryId,
          telephone: formData.mobileNumber,
          dialCode: formData.dialCode,
          isActive: 1,
          company: "" // optional field
        }
      ]
    };

    try {
      await createCustomer(payload);
      toast.success("Customer created successfully!");
      navigate("/customers");
    } catch (error) {
      toast.error(error.message || "Failed to create customer.");
    }
  };

  return (
    <div className="container mt-5">
      <div className="card shadow">
        <div className="card-header bg-gradient-primary text-dark">
          <h3 className="mb-0">Create Customer</h3>
          <small className="d-block text-white-50">
            Fields marked with <span className="text-danger">*</span> are mandatory.
          </small>
        </div>
        <div className="card-body p-4">
          <form onSubmit={handleSubmit}>
            <fieldset className="border p-3 mb-4">
              <legend className="w-auto px-2">Basic Information</legend>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    First Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="firstname"
                    value={formData.firstname}
                    onChange={handleChange}
                  />
                  {errors.firstname && <small className="text-danger">{errors.firstname}</small>}
                </div>
                <div className="col-md-6">
                  <label className="form-label">
                    Last Name <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleChange}
                  />
                  {errors.lastname && <small className="text-danger">{errors.lastname}</small>}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    Email <span className="text-danger">*</span>
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="col-md-6">
                  <label className="form-label">
                    Select Customer Group <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="groupId"
                    list="customerGroupsList"
                    value={groupInput}
                    onChange={handleGroupInputChange}
                    placeholder="Type group code..."
                  />
                  <datalist id="customerGroupsList">
                    {customerGroups.map((group) => (
                      <option key={group.id} value={group.customerGroupCode} />
                    ))}
                  </datalist>
                  {errors.groupId && <small className="text-danger">{errors.groupId}</small>}
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-md-6">
                  <label className="form-label">
                    Select BDE <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="bdeId"
                    list="bdeUsersList"
                    value={bdeInput}
                    onChange={handleBDEInputChange}
                    placeholder="Type BDE name..."
                  />
                  <datalist id="bdeUsersList">
                    {bdeUsers.map((user) => (
                      <option key={user.id} value={user.name} />
                    ))}
                  </datalist>
                  {errors.bdeId && <small className="text-danger">{errors.bdeId}</small>}
                </div>
              </div>
            </fieldset>
            <fieldset className="border p-3 mb-4">
              <legend className="w-auto px-2">Address Details</legend>
              {/* Country in full row */}
              <div className="row mb-3">
                <div className="col-3">
                  <label className="form-label">
                    Country <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="countryId"
                    list="countriesList"
                    value={countryInput}
                    onChange={handleCountryInputChange}
                  />
                  <datalist id="countriesList">
                    {countries.map((country) => (
                      <option key={country.countryId} value={country.countryName} />
                    ))}
                  </datalist>
                </div>
                <div className="col-md-2">
                  <label className="form-label">
                    Dial Code <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" name="dialCode" value={formData.dialCode} readOnly />
                </div>
                <div className="col-md-4">
                  <label className="form-label">
                    Telephone <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" name="mobileNumber" value={formData.mobileNumber} onChange={handleChange} />
                </div>
                <div className="col-md-3">
                  <label className="form-label">
                    City <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} />
                </div>
              </div>
              {/* Three fields in one row: City, Region, Postcode */}
              <div className="row mb-3">
                
                <div className="col-md-3">
                  <label className="form-label">
                    Region <span className="text-danger">*</span>
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="region"
                    list="regionsList"
                    value={formData.region}
                    onChange={handleChange}
                  />
                  <datalist id="regionsList">
                    {regionsData.map((region) => (
                      <option key={region.regionId} value={region.code}>
                        {region.defaultName}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="col-md-2">
                  <label className="form-label">
                    Postcode <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" name="postcode" value={formData.postcode} onChange={handleChange} />
                </div>
                <div className="col-7">
                  <label className="form-label">
                    Street <span className="text-danger">*</span>
                  </label>
                  <input type="text" className="form-control" name="street" value={formData.street} onChange={handleChange} />
                </div>
              </div>
            </fieldset>
            <div className="d-grid">
              <button type="submit" className="btn btn-success btn-lg">Submit</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateCustomer;
