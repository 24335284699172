import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCustomerById, updateCustomer } from "../services/customerService";
import { getAllCountries } from "../services/countryService";
import { searchRegions } from "../services/countryRegionService";
import { updateAddress, createAddress } from "../services/customerAddressService";
import { toast } from "react-toastify";
import {
  FaArrowLeft,
  FaUser,
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaCheckCircle,
  FaEdit,
} from "react-icons/fa";
import { createStatusHistory } from "../services/customerStatusHistoryService";
import { searchCustomerGroups, getCustomerGroupById } from "../services/customerGroupService";
import { searchUsers } from "../services/userService";
import { searchCountries } from "../services/countryService";

const CustomerDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [customer, setCustomer] = useState(null);
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updateForm, setUpdateForm] = useState(null);
  const [countries, setCountries] = useState([]);
  const [regionsData, setRegionsData] = useState([]);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [createForm, setCreateForm] = useState({
    parentId: 1,
    firstname: "",
    lastname: "",
    street: "",
    city: "",
    region: "",
    regionId: null,
    postcode: "",
    countryId: "",
    telephone: "",
    dialCode: "",
    company: "",
    isActive: 1,
  });
  const [firstApprovalFormVisible, setFirstApprovalFormVisible] = useState(false);
  const [firstApprovalForm, setFirstApprovalForm] = useState({ status: "", remark: "" });
  const [secondApprovalFormVisible, setSecondApprovalFormVisible] = useState(false);
  const [secondApprovalForm, setSecondApprovalForm] = useState({ status: "", remark: "" });
  const [isCustomerUpdateModalOpen, setIsCustomerUpdateModalOpen] = useState(false);
  const [customerUpdateForm, setCustomerUpdateForm] = useState(null);
  const [updateGroupInput, setUpdateGroupInput] = useState("");
  const [updateCustomerGroups, setUpdateCustomerGroups] = useState([]);
  const [updateBdeInput, setUpdateBdeInput] = useState("");
  const [updateBdeUsers, setUpdateBdeUsers] = useState([]);
  const [updateCountryInput, setUpdateCountryInput] = useState("");

  useEffect(() => {
    const fetchCustomerDetails = async () => {
      try {
        const response = await getCustomerById(id);
        if (response.statusCode === 200) {
          setCustomer(response.data);
        } else {
          toast.error("Customer not found!");
          navigate("/customers");
        }
      } catch (error) {
        toast.error("Error fetching customer details.");
        navigate("/customers");
      } finally {
        setLoading(false);
      }
    };

    fetchCustomerDetails();
  }, [id, navigate]);

  useEffect(() => {
    if (isModalOpen || isCreateModalOpen) {
      getAllCountries()
        .then((data) => {
          const countriesArray = Array.isArray(data)
            ? data
            : data.data && Array.isArray(data.data)
            ? data.data
            : [];
          setCountries(countriesArray);
        })
        .catch((error) => {
          toast.error("Failed to load countries");
        });
      // If updateForm already has a country, fetch its regions
      if (updateForm && updateForm.countryId) {
        fetchRegions(updateForm.countryId);
      }
      // If createForm already has a country, fetch its regions
      if (createForm && createForm.countryId) {
        fetchRegions(createForm.countryId);
      }
    }
  }, [isModalOpen, isCreateModalOpen, updateForm, createForm]);

  const fetchRegions = (countryId, searchTerm = "") => {
    searchRegions({ countryId, code: searchTerm, page: 0, size: 10 })
      .then((res) => {
        const content = res.data && res.data.content ? res.data.content : [];
        setRegionsData(content);
      })
      .catch((err) => {
        setRegionsData([]);
        toast.error("Failed to load regions");
      });
  };

  const getStatusText = (status) => {
    if (status === 1) return "Approved";
    if (status === 0) return "Rejected";
    return "Pending";
  };

  const openUpdateModal = (address) => {
    setUpdateForm({ ...address });
    setIsModalOpen(true);
  };

  const closeUpdateModal = () => {
    setIsModalOpen(false);
  };

  const handleUpdateChange = (e) => {
    const { name, value, type, checked } = e.target;
    // When country changes, fetch regions for the selected country.
    if (name === "countryId") {
      fetchRegions(value);
    }
    setUpdateForm({
      ...updateForm,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  const handleSaveChanges = async () => {
    try {
      await updateAddress(updateForm.entityId, updateForm);
      toast.success("Address updated successfully!");
      closeUpdateModal();
    } catch (error) {
      toast.error("Failed to update address.");
    }
  };

  const openCreateModal = () => {
    setCreateForm({
      parentId: customer ? customer.entityId || 1 : 1,
      firstname: "",
      lastname: "",
      street: "",
      city: "",
      region: "",
      regionId: null,
      postcode: "",
      countryId: "",
      telephone: "",
      dialCode: "",
      company: "",
      isActive: 1,
    });
    setIsCreateModalOpen(true);
  };

  const closeCreateModal = () => {
    setIsCreateModalOpen(false);
  };

  const handleCreateChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name === "countryId") {
      fetchRegions(value);
    }
    setCreateForm({
      ...createForm,
      [name]: type === "checkbox" ? (checked ? 1 : 0) : value,
    });
  };

  const handleCreateSave = async () => {
    try {
      await createAddress(createForm);
      toast.success("Address created successfully!");
      closeCreateModal();
      // Optionally refresh the address list
    } catch (error) {
      toast.error("Failed to create address.");
    }
  };

  const handleSubmitFirstApprovalForm = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    // Determine dynamic status and verification flag based on selection
    const approvalStatus = firstApprovalForm.status; // Expected "Approve" or "Reject"
    const statusToSend = approvalStatus === "Approve" ? "Apprved" : "Reject";
    const verificationValue = approvalStatus === "Approve" ? 1 : 0;
    const payload = {
      customerId: customer.entityId,
      status: statusToSend,
      statusType: "FIRST LEVEL APPROVAL",
      remarks: firstApprovalForm.remark,
      changedBy: userDetails.userId,
      changedByName: userDetails.username,
    };
    setCustomer({ ...customer, isFirstLevelVerified: verificationValue });
    try {
      await createStatusHistory(payload);
      toast.success(`First Level ${approvalStatus}d! Remark: ${firstApprovalForm.remark}`);
    } catch (error) {
      toast.error("Failed to update status history for first level approval.");
    }
    setFirstApprovalFormVisible(false);
  };

  const handleSubmitSecondApprovalForm = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails") || "{}");
    const approvalStatus = secondApprovalForm.status; // Expected "Approve" or "Reject"
    const statusToSend = approvalStatus === "Approve" ? "Apprved" : "Reject";
    const verificationValue = approvalStatus === "Approve" ? 1 : 0;
    const payload = {
      customerId: customer.entityId,
      status: statusToSend,
      statusType: "SECAND LEVEL APPROVAL",
      remarks: secondApprovalForm.remark,
      changedBy: userDetails.userId,
      changedByName: userDetails.username,
    };
    setCustomer({ ...customer, isSecondLevelVerified: verificationValue });
    try {
      await createStatusHistory(payload);
      toast.success(`Second Level ${approvalStatus}d! Remark: ${secondApprovalForm.remark}`);
    } catch (error) {
      toast.error("Failed to update status history for second level approval.");
    }
    setSecondApprovalFormVisible(false);
  };

  const handleUpdateGroupInputChange = (e) => {
    const value = e.target.value;
    setUpdateGroupInput(value);
    if (value.length >= 2) {
      searchCustomerGroups({ customerGroupCode: value, page: 0, size: 10 })
        .then((res) => {
          const groups = res.data ? res.data.content : [];
          setUpdateCustomerGroups(groups);
          const matchedGroup = groups.find(
            (g) => g.customerGroupCode.toLowerCase() === value.toLowerCase()
          );
          if (matchedGroup) {
            setCustomerUpdateForm((prev) => ({ ...prev, groupId: matchedGroup.id }));
          }
        })
        .catch(() => {
          toast.error("Failed to load customer groups");
        });
    }
  };

  const handleUpdateBdeInputChange = (e) => {
    const value = e.target.value;
    setUpdateBdeInput(value);
    if (value.length >= 2) {
      searchUsers({ name: value, page: 0, size: 10 })
        .then((res) => {
          const users = res.data ? res.data.content : [];
          setUpdateBdeUsers(users);
          const matchedUser = users.find(
            (u) => u.name.toLowerCase() === value.toLowerCase()
          );
          if (matchedUser) {
            setCustomerUpdateForm((prev) => ({ ...prev, bdeId: matchedUser.id }));
          }
        })
        .catch(() => {
          toast.error("Failed to load BDE users");
        });
    }
  };

  const handleUpdateCountryInputChange = (e) => {
    const value = e.target.value;
    setUpdateCountryInput(value);
    if (value.length >= 2) {
      searchCountries({ countryName: value, page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          const filtered = Array.isArray(responseData.content)
            ? responseData.content
            : [];
          const matched = filtered.find(
            (c) => c.countryName.toLowerCase() === value.toLowerCase()
          );
          if (matched) {
            setCustomerUpdateForm((prev) => ({
              ...prev,
              countryId: matched.countryId,
              regDialerCode: matched.countryId === "IN" ? "+91" : "+1",
            }));
            // Optionally, fetch regions for the selected country
            fetchRegions(matched.countryId);
          }
        })
        .catch(() => {
          toast.error("Failed to load filtered countries");
        });
    }
  };

  const openCustomerUpdateModal = () => {
    // Set update form from current customer details
    setCustomerUpdateForm({
      email: customer.email,
      firstname: customer.firstname,
      lastname: customer.lastname,
      groupId: customer.groupId,
      bdeId: customer.bdeId, // optional
      regDialerCode: customer.regDialerCode,
      countryId: customer.countryId,
      regMobileNumber: customer.regMobileNumber,
    });
    // Show selected group name instead of "Group {groupId}"
    if (customer.groupId) {
      getCustomerGroupById(customer.groupId)
        .then((res) => {
          const groupName = res.data ? res.data.customerGroupCode : "";
          setUpdateGroupInput(groupName);
        })
        .catch(() => {
          setUpdateGroupInput("");
        });
    } else {
      setUpdateGroupInput("");
    }
    // Existing BDE and country input initialization
    setUpdateBdeInput(customer.bdeName || "");
    setUpdateCountryInput(customer.countryName || "");
    setIsCustomerUpdateModalOpen(true);
  };

  const closeCustomerUpdateModal = () => {
    setIsCustomerUpdateModalOpen(false);
  };

  const handleCustomerUpdateChange = (e) => {
    const { name, value } = e.target;
    setCustomerUpdateForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCustomerUpdateSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCustomer(customer.entityId, customerUpdateForm);
      toast.success("Customer updated successfully!");
      setCustomer({ ...customer, ...customerUpdateForm });
      closeCustomerUpdateModal();
    } catch (error) {
      toast.error(error.message || "Failed to update customer.");
    }
  };

  if (loading)
    return (
      <h5 className="text-center mt-4">
        Loading customer details... 🌀
      </h5>
    );
  if (!customer)
    return (
      <h5 className="text-center mt-4 text-danger">
        Customer not found ❌
      </h5>
    );

  return (
    <div className="container mt-4">
      {/* Page Header */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
        <h2 className="text-primary text-center text-md-start">
          <FaUser className="me-2" /> Customer: {customer.firstname}{" "}
          {customer.lastname}
        </h2>

        {/* Action Buttons */}
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <button
            className="btn btn-dark"
            onClick={() => navigate("/customers")}
          >
            <FaArrowLeft /> Back to Customers
          </button>
          <button className="btn btn-primary" onClick={openCustomerUpdateModal}>
            <FaEdit /> Update Customer
          </button>
        </div>
      </div>

      {/* Contact & Address Info */}
      <div className="row mt-3">
        <div className="col-12">
          <div
            className="card shadow-lg p-4"
            style={{ backgroundColor: "#fff", color: "#000" }}
          >
            <div className="row">
              <div className="col-12 col-md-4">
                <h5>
                  <FaEnvelope /> Email
                </h5>
                <p>{customer.email}</p>
                <h5>
                  <FaPhone /> Contact No
                </h5>
                <p>
                  {customer.regDialerCode} {customer.regMobileNumber}
                </p>
              </div>
              <div className="col-12 col-md-4">
                <h5>
                  <FaMapMarkerAlt /> Billing Address
                </h5>
                {customer.addresses.length > 0 ? (
                  <p>
                    {customer.addresses[0]?.street}, {customer.addresses[0]?.city},{" "}
                    {customer.addresses[0]?.postcode}, {customer.addresses[0]?.countryId}
                  </p>
                ) : (
                  <p>No billing address</p>
                )}
              </div>
              <div className="col-12 col-md-4">
                <h5>
                  <FaMapMarkerAlt /> Shipping Address
                </h5>
                {customer.addresses.length > 1 ? (
                  <p>
                    {customer.addresses[1]?.street}, {customer.addresses[1]?.city},{" "}
                    {customer.addresses[1]?.postcode}, {customer.addresses[1]?.countryId}
                  </p>
                ) : (
                  <p>No shipping address</p>
                )}
              </div>
            </div>
            {/* New Created By & BDE Name Sections */}
            <div className="row mt-3">
              <div className="col-12 col-md-6 mb-2">
                <div
                  className="p-3"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <strong>Created By:</strong> {customer.userName || "Not provided"}
                </div>
              </div>
              <div className="col-12 col-md-6 mb-2">
                <div
                  className="p-3"
                  style={{
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                >
                  <strong>BDE Name:</strong> {customer.bdeName || "Not provided"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Verification Status */}
      <div className="card shadow-lg p-4 mt-4">
        <h4 className="text-primary">
          <FaCheckCircle /> Verification & Status
        </h4>
        <div className="row">
          <div className="col-12 col-md-6">
            <p>
              <strong>First Level Verified:</strong> {getStatusText(customer.isFirstLevelVerified)}
              {customer.isFirstLevelVerified !== 1 && (
                <>
                  {!firstApprovalFormVisible && (
                    <button 
                      className="btn btn-sm btn-primary ms-2" 
                      onClick={() => setFirstApprovalFormVisible(true)}
                    >
                      Set Approval
                    </button>
                  )}
                  {firstApprovalFormVisible && (
                    <div className="mt-2 p-2 border rounded" style={{ backgroundColor: "#f8f9fa" }}>
                      <select 
                        className="form-select form-select-sm mb-2"
                        value={firstApprovalForm.status}
                        onChange={(e) =>
                          setFirstApprovalForm({ ...firstApprovalForm, status: e.target.value })
                        }
                      >
                        <option value="">Select status</option>
                        <option value="Approve">Approve</option>
                        <option value="Reject">Reject</option>
                      </select>
                      <input 
                        type="text"
                        placeholder="Enter remark"
                        className="form-control form-control-sm mb-2"
                        value={firstApprovalForm.remark}
                        onChange={(e) =>
                          setFirstApprovalForm({ ...firstApprovalForm, remark: e.target.value })
                        }
                      />
                      <button 
                        className="btn btn-sm btn-success me-2" 
                        onClick={handleSubmitFirstApprovalForm}
                      >
                        Submit
                      </button>
                      <button 
                        className="btn btn-sm btn-danger" 
                        onClick={() => {
                          setFirstApprovalFormVisible(false);
                          setFirstApprovalForm({ status: "", remark: "" });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </p>
            <p>
              <strong>Seller Account:</strong>{" "}
              {customer.isSeller ? "✅ Yes" : "❌ No"}
            </p>
          </div>
          <div className="col-12 col-md-6">
            <p>
              <strong>Second Level Verified:</strong> {getStatusText(customer.isSecondLevelVerified)}
              {customer.isFirstLevelVerified === 1 && customer.isSecondLevelVerified !== 1 && (
                <>
                  {!secondApprovalFormVisible && (
                    <button 
                      className="btn btn-sm btn-primary ms-2" 
                      onClick={() => setSecondApprovalFormVisible(true)}
                    >
                      Set Approval
                    </button>
                  )}
                  {secondApprovalFormVisible && (
                    <div className="mt-2 p-2 border rounded" style={{ backgroundColor: "#f8f9fa" }}>
                      <select 
                        className="form-select form-select-sm mb-2"
                        value={secondApprovalForm.status}
                        onChange={(e) =>
                          setSecondApprovalForm({ ...secondApprovalForm, status: e.target.value })
                        }
                      >
                        <option value="">Select status</option>
                        <option value="Approve">Approve</option>
                        <option value="Reject">Reject</option>
                      </select>
                      <input 
                        type="text"
                        placeholder="Enter remark"
                        className="form-control form-control-sm mb-2"
                        value={secondApprovalForm.remark}
                        onChange={(e) =>
                          setSecondApprovalForm({ ...secondApprovalForm, remark: e.target.value })
                        }
                      />
                      <button 
                        className="btn btn-sm btn-success me-2" 
                        onClick={handleSubmitSecondApprovalForm}
                      >
                        Submit
                      </button>
                      <button 
                        className="btn btn-sm btn-danger" 
                        onClick={() => {
                          setSecondApprovalFormVisible(false);
                          setSecondApprovalForm({ status: "", remark: "" });
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  )}
                </>
              )}
            </p>
            <p>
              <strong>Account Type:</strong>{" "}
              {customer.accountType || "Not specified"}
            </p>
            <p>
              <strong>Customer Group:</strong> {customer.groupId}
            </p>
            <p>
              <strong>Created From:</strong> {customer.customerCreatedFrom}
            </p>
          </div>
        </div>
      </div>

      {/* Status History Section */}
      {customer.statusHistory && customer.statusHistory.length > 0 && (
        <div className="card shadow-lg p-4 mt-4">
          <h4 className="text-primary">Status History</h4>
          <table className="table table-striped">
            <thead>
              <tr>
                <th>Status</th>
                <th>Remarks</th>
                <th>Status Type</th>
                <th>Changed By</th>
                <th>Changed At</th>
              </tr>
            </thead>
            <tbody>
              {customer.statusHistory.map((history) => (
                <tr key={history.id}>
                  <td>{history.status}</td>
                  <td>{history.remarks}</td>
                  <td>{history.statusType}</td>
                  <td>{history.changedByName}</td>
                  <td>{new Date(history.changedAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Address List Section */}
      <div className="card shadow-lg p-4 mt-4">
        <h4 className="text-primary mb-3">
          <FaMapMarkerAlt /> Address List
        </h4>
        <div className="d-flex justify-content-end mb-3">
          <button className="btn btn-success" onClick={openCreateModal}>
            Create Address
          </button>
        </div>
        <div className="row">
          {customer.addresses.map((address) => (
            <div key={address.entityId} className="col-12 col-md-6 mb-3">
              <div className="card border rounded p-3 bg-light position-relative">
                {/* Row 1: Name with edit button */}
                <div className="row mb-2">
                  <div className="col-12">
                    <h5 className="text-secondary m-0 p-0">
                      {address.firstname} {address.lastname}
                      <FaEdit
                        className="ms-2 text-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => openUpdateModal(address)}
                      />
                    </h5>
                  </div>
                </div>
                {/* Row 2: Full address */}
                <div className="row mb-2">
                  <div className="col-12">
                    <p className="mb-0">
                      <strong>Address:</strong> {address.street}, {address.city}, {address.postcode}, {address.countryId}
                    </p>
                  </div>
                </div>
                {/* Row 3: Telephone */}
                <div className="row mb-2">
                  <div className="col-12">
                    <p className="mb-0">
                      <strong>Telephone:</strong> {address.dialCode} {address.telephone}
                    </p>
                  </div>
                </div>
                {/* Row 4: Timestamps */}
                <div className="row">
                  <div className="col-12">
                    <p className="mb-0">
                      <small className="text-muted">
                        Created: {address.createdAt} | Updated: {address.updatedAt}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {/* Update Address Modal */}
      {isModalOpen && updateForm && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Address</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeUpdateModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="firstname"
                    className="form-control"
                    value={updateForm.firstname || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    className="form-control"
                    value={updateForm.lastname || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Street</label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    value={updateForm.street || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={updateForm.city || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Company</label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={updateForm.company || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Postcode</label>
                  <input
                    type="text"
                    name="postcode"
                    className="form-control"
                    value={updateForm.postcode || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <select
                    name="countryId"
                    className="form-control"
                    value={updateForm.countryId || ""}
                    onChange={handleUpdateChange}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.countryId}>
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Region</label>
                  <input
                    type="text"
                    name="region"
                    list="regionsList"
                    className="form-control"
                    value={updateForm.region || ""}
                    onChange={(e) => {
                      // Update the region field value and trigger region search
                      handleUpdateChange(e);
                      if (updateForm && updateForm.countryId) {
                        fetchRegions(updateForm.countryId, e.target.value);
                      }
                    }}
                  />
                  <datalist id="regionsList">
                    {regionsData.map((region) => (
                      <option key={region.regionId} value={region.code}>
                        {region.defaultName}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="mb-3">
                  <label className="form-label">Telephone</label>
                  <input
                    type="text"
                    name="telephone"
                    className="form-control"
                    value={updateForm.telephone || ""}
                    onChange={handleUpdateChange}
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    name="isActive"
                    className="form-check-input"
                    checked={updateForm.isActive === 1}
                    onChange={handleUpdateChange}
                  />
                  <label className="form-check-label">Active</label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeUpdateModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleSaveChanges}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Create Address Modal */}
      {isCreateModalOpen && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Create Address</h5>
                <button
                  type="button"
                  className="btn-close"
                  onClick={closeCreateModal}
                ></button>
              </div>
              <div className="modal-body">
                {/* Similar fields as update modal */}
                <div className="mb-3">
                  <label className="form-label">First Name</label>
                  <input
                    type="text"
                    name="firstname"
                    className="form-control"
                    value={createForm.firstname || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Last Name</label>
                  <input
                    type="text"
                    name="lastname"
                    className="form-control"
                    value={createForm.lastname || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Street</label>
                  <input
                    type="text"
                    name="street"
                    className="form-control"
                    value={createForm.street || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">City</label>
                  <input
                    type="text"
                    name="city"
                    className="form-control"
                    value={createForm.city || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Company</label>
                  <input
                    type="text"
                    name="company"
                    className="form-control"
                    value={createForm.company || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Postcode</label>
                  <input
                    type="text"
                    name="postcode"
                    className="form-control"
                    value={createForm.postcode || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3">
                  <label className="form-label">Country</label>
                  <select
                    name="countryId"
                    className="form-control"
                    value={createForm.countryId || ""}
                    onChange={handleCreateChange}
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.id} value={country.countryId}>
                        {country.countryName}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="mb-3">
                  <label className="form-label">Region</label>
                  <input
                    type="text"
                    name="region"
                    list="regionsList"
                    className="form-control"
                    value={createForm.region || ""}
                    onChange={(e) => {
                      handleCreateChange(e);
                      if (createForm.countryId) {
                        fetchRegions(createForm.countryId, e.target.value);
                      }
                    }}
                  />
                  <datalist id="regionsList">
                    {regionsData.map((region) => (
                      <option key={region.regionId} value={region.code}>
                        {region.defaultName}
                      </option>
                    ))}
                  </datalist>
                </div>
                <div className="mb-3">
                  <label className="form-label">Telephone</label>
                  <input
                    type="text"
                    name="telephone"
                    className="form-control"
                    value={createForm.telephone || ""}
                    onChange={handleCreateChange}
                  />
                </div>
                <div className="mb-3 form-check">
                  <input
                    type="checkbox"
                    name="isActive"
                    className="form-check-input"
                    checked={createForm.isActive === 1}
                    onChange={handleCreateChange}
                  />
                  <label className="form-check-label">Active</label>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={closeCreateModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleCreateSave}
                >
                  Save changes
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Customer Update Modal */}
      {isCustomerUpdateModalOpen && customerUpdateForm && (
        <div className="modal show d-block" tabIndex="-1" role="dialog">
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Update Customer Details</h5>
                <button type="button" className="btn-close" onClick={closeCustomerUpdateModal}></button>
              </div>
              <form onSubmit={handleCustomerUpdateSubmit}>
                <div className="modal-body">
                  <div className="mb-3">
                    <label className="form-label">First Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="firstname"
                      value={customerUpdateForm.firstname}
                      onChange={handleCustomerUpdateChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Last Name</label>
                    <input
                      type="text"
                      className="form-control"
                      name="lastname"
                      value={customerUpdateForm.lastname}
                      onChange={handleCustomerUpdateChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Email</label>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={customerUpdateForm.email}
                      onChange={handleCustomerUpdateChange}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Select Customer Group <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="groupId"
                      list="updateCustomerGroupsList"
                      value={updateGroupInput}
                      onChange={handleUpdateGroupInputChange}
                      placeholder="Type group code..."
                    />
                    <datalist id="updateCustomerGroupsList">
                      {updateCustomerGroups.map((group) => (
                        <option key={group.id} value={group.customerGroupCode} />
                      ))}
                    </datalist>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Select BDE <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="bdeId"
                      list="updateBdeUsersList"
                      value={updateBdeInput}
                      onChange={handleUpdateBdeInputChange}
                      placeholder="Type BDE name..."
                    />
                    <datalist id="updateBdeUsersList">
                      {updateBdeUsers.map((user) => (
                        <option key={user.id} value={user.name} />
                      ))}
                    </datalist>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Country <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="countryId"
                      list="updateCountriesList"
                      value={updateCountryInput}
                      onChange={handleUpdateCountryInputChange}
                      placeholder="Type country..."
                    />
                    <datalist id="updateCountriesList">
                      {countries.map((country) => (
                        <option key={country.countryId} value={country.countryName} />
                      ))}
                    </datalist>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Dial Code <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="regDialerCode"
                      value={customerUpdateForm.regDialerCode}
                      onChange={handleCustomerUpdateChange}
                      readOnly
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">
                      Mobile Number <span className="text-danger">*</span>
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="regMobileNumber"
                      value={customerUpdateForm.regMobileNumber}
                      onChange={handleCustomerUpdateChange}
                    />
                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" className="btn btn-secondary" onClick={closeCustomerUpdateModal}>
                    Cancel
                  </button>
                  <button type="submit" className="btn btn-primary">
                    Update Customer
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomerDetails;
