import React, { useState } from "react";
import { createManufacturer } from "../../services/manufacturerService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaPlusCircle, FaArrowLeft } from "react-icons/fa";

const AddManufacturer = () => {
  const navigate = useNavigate();
  const [manufacturer, setManufacturer] = useState({
    manufacturerName: "",
    manufacturerCode: "",
  });

  const handleChange = (e) => {
    setManufacturer({ ...manufacturer, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createManufacturer(manufacturer);
      toast.success("Manufacturer added successfully! ✅");
      navigate("/manufacturers");
    } catch (err) {
      toast.error("Failed to add manufacturer! ❌");
    }
  };

  return (
    <div className="container-fluid px-5 py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">
          <FaPlusCircle className="me-2" /> Add Manufacturer
        </h2>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/manufacturers")}>
          <FaArrowLeft /> Back to Manufacturers
        </button>
      </div>

      <div className="card shadow-lg p-4">
        <form onSubmit={handleSubmit} className="row g-4">
          <div className="col-md-6">
            <label className="form-label">Manufacturer Name</label>
            <input
              type="text"
              className="form-control"
              name="manufacturerName"
              value={manufacturer.manufacturerName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-md-6">
            <label className="form-label">Manufacturer Code</label>
            <input
              type="text"
              className="form-control"
              name="manufacturerCode"
              value={manufacturer.manufacturerCode}
              onChange={handleChange}
              required
            />
          </div>

          <div className="col-12 text-center mt-4">
            <button type="submit" className="btn btn-lg btn-primary">
              ✅ Add Manufacturer
            </button>
            <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/manufacturers")}>
              ❌ Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddManufacturer;
