import React, { useEffect, useState, useCallback } from "react";
import { getOrders } from "../services/orderService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {FaSearch, FaArrowLeft, FaArrowRight, FaEye } from "react-icons/fa";

const OrderList = () => {
  const [orders, setOrders] = useState([]); // ✅ Prevents crash
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    customerEmail: "",
    status: "",
    page: 0,
    size: 10, // Default pagination size
    startDate: "", // added filter: start date
    endDate: "",   // added filter: end date
    minTotal: "",  // added filter: minimum total
    maxTotal: "",  // added filter: maximum total
    entityId: "",  // added filter: entity ID
    incrementId: "" // added filter: increment ID
  });

  const fetchOrders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getOrders(filters);

      console.log("API Response:", response); // ✅ Debugging Step

      if (response.statusCode === 200) {
        setOrders(response.data?.content || []); // ✅ Use `content` array
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch orders!");
      }
    } catch (error) {
      toast.error("Error fetching orders.");
      setOrders([]); // ✅ Prevent crash
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  return (
    <div className="container" >
      <div className="text-start mb-4">
        <h1 className="welcomeTitle text-primary">Sales Orders</h1>
        <p className="welcomeSubtitle">
        Streamline order processing from placement to fulfillment with real-time visibility.
        </p>
      </div>
      {/* Stylish Filter Section - Four Columns per Row */}
      <div className="card shadow mb-3">
        {/* <div className="card-header py-1">Filter Orders</div> */}
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="email"
                placeholder="🔍 Customer Email"
                className="form-control"
                value={filters.customerEmail}
                onChange={(e) => setFilters({ ...filters, customerEmail: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <select
                className="form-select"
                value={filters.status}
                onChange={(e) => setFilters({ ...filters, status: e.target.value })}
              >
                <option value="">All Statuses</option>
                <option value="new">New</option>
                <option value="processing">Processing</option>
                <option value="COMPLETE">Completed</option>
                <option value="canceled">Canceled</option>
              </select>
            </div>
            <div className="col-md-2">
              <input
                type="number"
                placeholder="Entity ID"
                className="form-control"
                value={filters.entityId}
                onChange={(e) => setFilters({ ...filters, entityId: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                placeholder="Increment ID"
                className="form-control"
                value={filters.incrementId}
                onChange={(e) => setFilters({ ...filters, incrementId: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                placeholder="Start Date"
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                placeholder="End Date"
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                className="form-control"
                placeholder="Min Total"
                value={filters.minTotal}
                onChange={(e) => setFilters({ ...filters, minTotal: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="number"
                className="form-control"
                placeholder="Max Total"
                value={filters.maxTotal}
                onChange={(e) => setFilters({ ...filters, maxTotal: e.target.value })}
              />
            </div>
            <div className="col-md-2">
            <button className="btn btn-primary w-100" onClick={fetchOrders}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
          <div className="card-body">
      {/* 🌀 Loading State */}
      {loading && <div className="text-center mt-3"><h5>Loading orders... 🌀</h5></div>}

      {/* 📊 Order Table */}
      {!loading && orders.length > 0 && (
        
            <div className="table-responsive">
              <table className="table table-bordered table-hover table-striped bg-white text-dark">
                <thead className="table-dark">
                  <tr>
                    <th>Entity ID</th>
                    <th>Customer ID</th>
                    <th>Increment ID</th>
                    <th>Status</th>
                    <th>Procurement Status</th>
                    <th>Finance Status</th>
                    <th>Coupon Code</th>
                    <th>Discount (Rs)</th>
                    <th>Grand Total (Rs)</th>
                    <th>Shipping (Rs)</th>
                    <th>Shipping Tax (Rs)</th>
                    <th>Subtotal (Rs)</th>
                    <th>Subtotal Refunded (Rs)</th>
                    <th>Tax (Rs)</th>
                    <th>Email</th>
                    <th>Customer Name</th>
                    <th>Total Items</th>
                    <th>Shipping Incl Tax (Rs)</th>
                    <th>User ID</th>
                    <th>BDE ID</th>
                    <th>Reward Points</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {orders.map((order) => (
                    <tr key={order.entityId}>
                      <td>{order.entityId}</td>
                      <td>{order.customerId}</td>
                      <td><span className="text-link" onClick={() => navigate(`/orders/${order.entityId}`)}> {order.incrementId}</span></td>
                      <td>
                        <span className={`badge bg-${order.status.toLowerCase() === "success" ? "success" : "warning"}`}>
                          {order.status.toUpperCase()}
                        </span>
                      </td>
                      <td>
                        {order.procurementStatus === 11 ? 'Approved' : order.procurementStatus === 12 ? 'Rejected' : 'Pending'}
                      </td>
                      <td>
                        {order.financeStatus === 9 ? 'Approved' : order.financeStatus === 10 ? 'Rejected' : 'Pending'}
                      </td>
                      <td>{order.couponCode || "-"}</td>
                      <td>{order.discountAmount.toFixed(2)}</td>
                      <td>{order.grandTotal.toFixed(2)}</td>
                      <td>{order.shippingAmount.toFixed(2)}</td>
                      <td>{order.shippingTaxAmount != null ? order.shippingTaxAmount.toFixed(2) : "-"}</td>
                      <td>{order.subtotal.toFixed(2)}</td>
                      <td>{order.subtotalRefunded != null ? order.subtotalRefunded.toFixed(2) : "-"}</td>
                      <td>{order.taxAmount.toFixed(2)}</td>
                      <td>{order.customerEmail}</td>
                      <td>{order.customerFirstname} {order.customerLastname}</td>
                      <td>{order.totalItemCount}</td>
                      <td>{order.shippingInclTax.toFixed(2)}</td>
                      <td>{order.userId}</td>
                      <td>{order.bdeId}</td>
                      <td>{order.rewardPointsAmount != null ? order.rewardPointsAmount : "-"}</td>
                      <td>{new Date(order.createdAt).toLocaleString()}</td>
                      <td>
                        <button 
                          className="btn btn-info btn-sm"
                          onClick={() => navigate(`/orders/${order.entityId}`)}
                        >
                          <FaEye /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          
      )}

      {/* ❗ No Data Message */}
      {!loading && orders.length === 0 && (
        <div className="text-center mt-3">
          <h5>No orders found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
  </div>
  );
};

export default OrderList;
