const botName = "AI Inventory Assistant";

const config = {
  botName: botName,
  initialMessages: [
    { type: "text", content: "Hello! I am your AI Inventory Assistant 🤖. How can I help you today?" },
  ],
};

export default config;
