import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getCouriers, deleteCourier } from "../../services/courierService";
import { toast } from "react-toastify";
import { FaEdit, FaTrash, FaPlus, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { debounce } from "lodash";
import { Spinner, Table, Button, Form, Container, Row, Col } from "react-bootstrap";

const CourierList = () => {
  const [couriers, setCouriers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    courierName: "",
    status: "",
    page: 0,
    size: 10,
  });

  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  // Debounced fetch function
  const debouncedFetchCouriers = useMemo(
    () =>
      debounce(async (activeFilters) => {
        setLoading(true);
        try {
          const response = await getCouriers(activeFilters);
          if (response.statusCode === 200) {
            setCouriers(response.data?.content || []);
            setTotalPages(response.data?.totalPages || 1);
          } else {
            toast.error("Failed to fetch couriers!");
          }
        } catch (error) {
          toast.error("Error fetching couriers.");
          setCouriers([]);
        } finally {
          setLoading(false);
        }
      }, 300),
    []
  );

  const fetchCouriers = useCallback(() => {
    const activeFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== "")
    );
    debouncedFetchCouriers(activeFilters);
  }, [filters, debouncedFetchCouriers]);

  useEffect(() => {
    fetchCouriers();
  }, [fetchCouriers]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if(details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // ...error handling...
      }
    }
  }, []);

  // Delete Courier
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this courier?")) {
      try {
        await deleteCourier(id);
        toast.success("Courier deleted successfully!");
        fetchCouriers(); // Refresh List
      } catch (error) {
        toast.error("Failed to delete courier.");
      }
    }
  };

  return (
    <Container className="mt-4">
      <Row className="d-flex justify-content-between align-items-center">
        <Col>
          <h2 className="text-primary">🚚 Courier Management</h2>
        </Col>
        <Col className="text-end">
          {userPermissions.includes("ADD_COURIER") && (
            <Button variant="primary" onClick={() => navigate("/couriers/add")}>
              <FaPlus /> Add Courier
            </Button>
          )}
        </Col>
      </Row>

      {/* Filters */}
      <Row className="g-2 mt-3">
        <Col md={4}>
          <Form.Control
            type="text"
            placeholder="Courier Name"
            value={filters.courierName}
            onChange={(e) => setFilters({ ...filters, courierName: e.target.value })}
          />
        </Col>
        <Col md={4}>
          <Form.Select
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          >
            <option value="">All Status</option>
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </Form.Select>
        </Col>
        <Col md={4}>
          <Button variant="success" className="w-100" onClick={fetchCouriers}>
            Search
          </Button>
        </Col>
      </Row>

      {/* Loading Spinner */}
      {loading && (
        <div className="text-center mt-3">
          <Spinner animation="border" />
        </div>
      )}

      {/* Courier Table */}
      {!loading && couriers.length > 0 && (
        <div className="table-responsive mt-3">
          <Table bordered hover>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Courier Name</th>
              
                <th>Status</th>
                <th>Created By</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {couriers.map((courier) => (
                <tr key={courier.courierId}>
                  <td>{courier.courierId}</td>
                  <td>{courier.courierName}</td>
                
                  <td>{courier.status ? "Active ✅" : "Inactive ❌"}</td>
                  <td>{courier.createdBy || "N/A"}</td>
                  <td>{courier.createdAt ? new Date(courier.createdAt).toLocaleDateString() : "N/A"}</td>
                  <td>{courier.updatedAt ? new Date(courier.updatedAt).toLocaleDateString() : "N/A"}</td>
                  <td>
                    {userPermissions.includes("EDIT_COURIER") && (
                      <Button
                        variant="warning"
                        size="sm"
                        className="me-2"
                        onClick={() => navigate(`/couriers/edit/${courier.courierId}`)}
                      >
                        <FaEdit /> Edit
                      </Button>
                    )}
                    {userPermissions.includes("DELETE_COURIER") && (
                      <Button
                        variant="danger"
                        size="sm"
                        onClick={() => handleDelete(courier.courierId)}
                      >
                        <FaTrash /> Delete
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      )}

      {/* Pagination */}
      <Row className="d-flex justify-content-between align-items-center mt-3">
        <Col>
          <Button
            variant="outline-secondary"
            disabled={filters.page === 0}
            onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
          >
            <FaArrowLeft /> Previous
          </Button>
        </Col>

        <Col className="text-center">
          <span>Page {filters.page + 1} of {totalPages}</span>
        </Col>

        <Col className="text-end">
          <Button
            variant="outline-secondary"
            disabled={filters.page + 1 >= totalPages}
            onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
          >
            Next <FaArrowRight />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default CourierList;