import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getGRNById } from "../../services/grnService";
import { getPurchaseOrderById } from "../../services/purchaseOrderService";
import { toast } from "react-toastify";
import { approveGRNById, fullReturnGRN } from "../../services/grnService";
import { getDebitNoteList, createPartialDebitNote } from "../../services/debitNoteService";
import { FaArrowLeft, FaTruck, FaWarehouse, FaShoppingCart, FaUndo, FaMoneyBillWave, FaFileInvoice } from "react-icons/fa";
import Modal from "react-modal";
import "./GRNDetails.css";

// ✅ Set Modal styles globally
Modal.setAppElement("#root");

const GRNDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [grn, setGRN] = useState(null);
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedSKUs, setSelectedSKUs] = useState([]);
  const [returnItems, setReturnItems] = useState([]);
  const [debitNotes, setDebitNotes] = useState([]); // ✅ State for Debit
  // NEW: Load user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    async function fetchGRN() {
      try {
        const response = await getGRNById(id);
        if (response.statusCode === 200) {
          let grnData = response.data;
          setGRN(grnData);

          // ✅ Fetch related Debit Notes
          fetchDebitNotes(grnData.id);

          // ✅ Fetch PO details if missing in GRN
          if (!grnData.addresses || grnData.addresses.length === 0) {
            const poResponse = await getPurchaseOrderById(grnData.purchaseOrderId);
            if (poResponse.statusCode === 200) {
              const poData = poResponse.data;

              // ✅ Assign Supplier Details from PO if missing in GRN
              if (!grnData.supplierName) {
                grnData.supplierName = `${poData.supplierFirstname || ""} ${poData.supplierLastname || ""}`.trim();
              }
              if (!grnData.supplierEmail) {
                grnData.supplierEmail = poData.supplierEmail || "N/A";
              }

              setPurchaseOrder(poData);
            }
          }
        } else {
          toast.error("Failed to fetch GRN details!");
        }
      } catch (error) {
        toast.error("Error fetching GRN details.");
      }
    }

    fetchGRN();
  }, [id]);

  // NEW: Load user's permissions from localStorage
  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  const fetchDebitNotes = async (grnId) => {
    if (!grnId) return; // ✅ Prevent API call if `grnId` is undefined

    try {
      const response = await getDebitNoteList({ grnNumber: grnId });
      if (response.statusCode === 200) {
        setDebitNotes(response.data?.content || []);
      } else {
        toast.warn("No Debit Notes found for this GRN.");
      }
    } catch (error) {
      toast.error("Error fetching Debit Notes.");
    }
  };



  const handleApprove = async () => {
    if (!grn) {
      toast.error("GRN data is missing!");
      return;
    }

    try {
      const response = await approveGRNById(grn.id);

      if (response.statusCode === 200) {
        toast.success("GRN Approved Successfully!");
        setGRN((prev) => ({ ...prev, isActive: 1 })); // ✅ Update state
      } else {
        toast.error(`Approval Failed: ${response.message || "Try again"}`);
      }
    } catch (error) {
      console.error("Error approving GRN:", error);
      toast.error("Error approving GRN.");
    }
  };

  // Update function to use grnService method fullReturnGRN
  const handleFullReturn = async () => {
    if (!grn) return;
    try {
      const payload = { grnId: grn.id, userId: 113 };
      const response = await fullReturnGRN(payload);
      if (response.statusCode === 201) {
        toast.success("Full Return initiated successfully!");
        window.location.reload(); // Refresh the page to reflect changes
      } else {
        // Show complete error message from API response
        toast.error(`${response.message} ${response.data || ""}`);
      }
    } catch (error) {
      console.error("Error initiating Full Return:", error);
      toast.error(error.message);
    }
  };

  const openReturnModal = () => setModalIsOpen(true);
  const closeReturnModal = () => {
    setModalIsOpen(false);
    setSelectedSKUs([]);
    setReturnItems([]);
  };

  const handleSKUSelection = (sku) => {
    if (!selectedSKUs.includes(sku)) {
      setSelectedSKUs([...selectedSKUs, sku]);
      const item = grn.grnItems.find((i) => i.sku === sku);
      if (item) {
        setReturnItems([...returnItems, {
          ...item,
          returnQty: 0, // ✅ User will set this first
          batchSelection: []
        }]);
      }
    }
  };




  const handleRemoveSKU = (sku) => {
    setSelectedSKUs(selectedSKUs.filter((s) => s !== sku));
    setReturnItems(returnItems.filter((i) => i.sku !== sku));
  };

  const handleBatchSelection = (sku, batchNo, checked) => {
    setReturnItems((prev) =>
      prev.map((item) => {
        if (item.sku === sku) {
          let batchSelection = [...item.batchSelection];
          if (checked) {
            const batch = item.batchDetails.find((b) => b.batchNo === batchNo);
            batchSelection.push({ ...batch, returnBatchQty: 0 });
          } else {
            batchSelection = batchSelection.filter((b) => b.batchNo !== batchNo);
          }
          return { ...item, batchSelection };
        }
        return item;
      })
    );
  };



  const handleReturnQtyChange = (sku, batchNo, value) => {
    setReturnItems(prev =>
      prev.map(item => {
        if (item.sku === sku) {
          const updatedBatches = item.batchSelection.map(batch => {
            if (batch.batchNo === batchNo) {
              const enteredQty = parseInt(value) || 0;
              if (enteredQty > batch.receivedQty) {
                toast.error(
                  `Return qty for batch ${batch.batchNo} cannot exceed received qty (${batch.receivedQty})`
                );
                return batch; // ignore update if entered quantity is too high
              }
              return { ...batch, returnBatchQty: enteredQty };
            }
            return batch;
          });
          const totalReturn = updatedBatches.reduce(
            (sum, batch) => sum + (batch.returnBatchQty || 0),
            0
          );
          return { ...item, batchSelection: updatedBatches, returnQty: totalReturn };
        }
        return item;
      })
    );
  };

  const handleSubmitReturn = async () => {
    // Construct the payload from returnItems
    const payload = {
      grnId: grn.id,
      createdBy: 113, // hardcoded userId, adjust as needed
      items: returnItems.map(item => ({
        grnItemId: item.id,
        returnQuantity: item.returnQty,
        batchDetails: item.batchSelection.map(batch => ({
          batchId: batch.id,
          returnBatchQuantity: batch.returnBatchQty
        }))
      }))
    };

    try {
      const response = await createPartialDebitNote(payload);
      if (response.statusCode === 201) {
        toast.success("Partial Debit Note created successfully!");
        closeReturnModal();
        window.location.reload(); // Refresh page to reflect changes
      } else {
        toast.error(`${response.message} ${response.data || ""}`);
      }
    } catch (error) {
      console.error("Error creating Partial Debit Note:", error);
      toast.error(error.message);
    }
  };

  if (!grn) return <h4 className="text-center mt-4">Loading...</h4>;

  // ✅ Use PO details if missing from GRN
  const supplierAddresses = grn.addresses || purchaseOrder?.addresses || [];
  const warehouseAddresses = grn.warehouseAddresses || purchaseOrder?.warehouseAddresses || [];
  const billingAddress = supplierAddresses.find(addr => addr.addressType === "billing") || {};
  const shippingAddress = supplierAddresses.find(addr => addr.addressType === "shipping") || {};

  return (
    <div className="container mt-4 grn-details-page">

      {/* Top Section */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-primary">
          <FaTruck className="me-2" /> GRN #<span className="text-decoration-underline">{grn.id}</span>
        </h2>
        <div className="d-flex gap-2">
          {grn.isActive === 0 && (
            <button className="btn btn-success" onClick={handleApprove}>
              ✅ Approve
            </button>
          )}
          {grn.isActive === 1 && (
            <>
              {userPermissions.includes("FULL_RETURN_TO_VENDOR") && (
                <button className="btn btn-primary ms-2" onClick={handleFullReturn}>
                  Full Return to Vendor
                </button>
              )}
              {userPermissions.includes("PARTIAL_RETURN_TO_VENDOR") && (
                <button className="btn btn-warning" onClick={openReturnModal}>
                  <FaUndo /> Partial Return to Vendor
                </button>
              )}
            </>
          )}
          {grn.isActive === 3 && (
            <span className="badge bg-success p-2">Returned</span>
          )}
          <button className="btn btn-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft /> Back
          </button>
        </div>
      </div>
      {/* ✅ Return to Vendor Modal */}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeReturnModal}
        style={{
          overlay: {
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Darker overlay for focus
            zIndex: 10000,
          },
          content: {
            width: "60%", // Increased width for better visibility
            maxWidth: "800px",
            maxHeight: "90vh", // Ensures it doesn't exceed the screen height
            overflowY: "auto", // Enables scrolling if content is too large
            margin: "auto",
            padding: "20px",
            borderRadius: "10px",
            border: "1px solid #ccc",
            background: '#fff',
          },
        }}
        className="modal-dialog modal-lg"
      >
        <div className="modal-content p-4"> {/* Increased padding for better spacing */}
          <h4 className="text-primary">Return to Vendor</h4>

          {/* SKU Selection */}
          <div className="mb-3">
            <label className="fw-bold">Select SKU:</label>
            <select className="form-select form-control-lg" onChange={(e) => handleSKUSelection(e.target.value)}>
              <option value="">-- Select SKU --</option>
              {grn.grnItems.map((item) => (
                <option key={item.sku} value={item.sku}>{item.sku} - {item.name}</option>
              ))}
            </select>
          </div>

          {/* Return Items List */}
          {returnItems.map((item) => (
            <div key={item.sku} className="card p-3 mb-3">
              <div className="d-flex justify-content-between">
                <h5>{item.name} (SKU: {item.sku})</h5>
                <button className="btn btn-danger btn-sm" onClick={() => handleRemoveSKU(item.sku)}>Remove</button>
              </div>

              {/* SKU Level Return Quantity (Auto-calculated, Read Only) */}
              <div className="mt-2">
                <label className="fw-bold">Total Return Qty (Auto-Calculated):</label>
                <input
                  type="number"
                  className="form-control form-control-lg"
                  value={item.returnQty}
                  readOnly
                />
              </div>

              {/* Batch Selection */}
              {item.batchDetails.map((batch) => (
                <div key={batch.batchNo} className="form-check mt-2">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    onChange={(e) => handleBatchSelection(item.sku, batch.batchNo, e.target.checked)}
                  />
                  <label className="form-check-label">
                    {batch.batchNo} (Expiry: {batch.expiryDate}) - Received Qty: {batch.receivedQty}
                  </label>
                  {/* Batch Quantity Entry */}
                  {item.batchSelection.find(b => b.batchNo === batch.batchNo) && (
                    <div className="mt-1">
                      <label>
                        Return Qty for {batch.batchNo} (Max: {batch.receivedQty}):
                      </label>
                      <input
                        type="number"
                        className="form-control form-control-lg"
                        min="0"
                        max={batch.receivedQty}
                        onChange={(e) => handleReturnQtyChange(item.sku, batch.batchNo, e.target.value)}
                      />
                    </div>
                  )}
                </div>
              ))}
            </div>
          ))}

          {/* Submit Button */}
          <button className="btn btn-success w-100 mt-3 btn-lg" onClick={handleSubmitReturn}>Submit Return</button>
        </div>
      </Modal>




      {/* Supplier Addresses Section */}
      <div className="card shadow-lg p-4 mb-4">
        <h4 className="text-primary"><FaFileInvoice /> Supplier Addresses</h4>
        <div className="row">
          {/* Billing Address */}
          <div className="col-md-6">
            <div className="card p-3 mb-3">
              <h5 className="text-uppercase text-secondary">Billing Address</h5>
              <p>
                {billingAddress.street ? billingAddress.street : "N/A"}
                {billingAddress.city ? ", " + billingAddress.city : ""}
                {billingAddress.postcode ? ", " + billingAddress.postcode : ""}
              </p>
              <p>
                {billingAddress.email ? billingAddress.email : "N/A"} | {billingAddress.telephone ? billingAddress.telephone : "N/A"}
              </p>
            </div>
          </div>
          {/* Shipping Address */}
          <div className="col-md-6">
            <div className="card p-3 mb-3">
              <h5 className="text-uppercase text-secondary">Shipping Address</h5>
              <p>
                {shippingAddress.street ? shippingAddress.street : "N/A"}
                {shippingAddress.city ? ", " + shippingAddress.city : ""}
                {shippingAddress.postcode ? ", " + shippingAddress.postcode : ""}
              </p>
              <p>
                {shippingAddress.email ? shippingAddress.email : "N/A"} | {shippingAddress.telephone ? shippingAddress.telephone : "N/A"}
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* Warehouse Addresses */}
      <div className="card shadow-lg p-4 mb-4">
        <h4 className="text-primary"><FaWarehouse /> Warehouse Addresses</h4>
        <div className="row">
          {warehouseAddresses.map((warehouse, index) => (
            <div key={index} className="col-md-6">
              <div className="card p-3 mb-3">
                <h5 className="text-uppercase text-secondary">{warehouse.addressType} Address</h5>
                <p><strong>{warehouse.name}</strong></p>
                <p>{warehouse.address}, {warehouse.city}, {warehouse.pincode}</p>
                <p>{warehouse.email} | {warehouse.mobileNumber}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {/* GRN Items & Batch Details */}
      <div className="mt-4 grn-items-section">
        <h5 className="text-primary"><FaShoppingCart /> GRN Items</h5>
        <div className="table-responsive">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th className="custom-th">ID</th>
                <th className="custom-th">SKU</th>
                <th className="custom-th">Name</th>
                <th className="custom-th">HSN Code</th>
                <th className="custom-th">Received Qty</th>
                <th className="custom-th">Free Qty</th>
                <th className="custom-th">Price (₹)</th>
                <th className="custom-th">Subtotal (₹)</th>
                <th className="custom-th">Tax (%)</th>
                <th className="custom-th">CGST (₹)</th>
                <th className="custom-th">SGST (₹)</th>
                <th className="custom-th">IGST (₹)</th>
                <th className="custom-th">Tax (₹)</th>
              </tr>
            </thead>
            <tbody>
              {grn.grnItems?.map((item) => (
                <React.Fragment key={item.id}>
                  <tr>
                    <td>{item.id}</td>
                    <td>{item.sku}</td>
                    <td>{item.name}</td>
                    <td>{item.hsnCode}</td>
                    <td>{item.receivedQty ?? 0}</td>
                    <td>{item.receivedFreeQty ?? 0}</td>
                    <td>₹{(item.price ?? 0).toFixed(2)}</td>
                    <td>₹{(item.subtotal ?? 0).toFixed(2)}</td>
                    <td>{item.taxPercent ?? 0}</td>
                    <td>₹{(item.cgstAmount ?? 0).toFixed(2)}</td>
                    <td>₹{(item.sgstAmount ?? 0).toFixed(2)}</td>
                    <td>₹{(item.igstAmount ?? 0).toFixed(2)}</td>
                    <td>₹{(item.taxAmount ?? 0).toFixed(2)}</td>
                  </tr>
                  {item.batchDetails?.length > 0 && (
                    <tr>
                      <td colSpan="13">
                        <div className="table-responsive">
                          <table className="table table-sm table-bordered">
                            <thead>
                              <tr>
                                <th className="custom-th">ID</th>
                                <th className="custom-th">Received Qty</th>
                                <th className="custom-th">Free Qty</th>
                                <th className="custom-th">MRP</th>
                                <th className="custom-th">Batch No</th>
                                <th className="custom-th">Expiry Date</th>
                                <th className="custom-th">Sr No</th>
                                <th className="custom-th">Mfg Date</th>
                                <th className="custom-th">Marketed By</th>
                                <th className="custom-th">Manufactured By</th>
                                <th className="custom-th">Price (₹)</th>
                                <th className="custom-th">Discount %</th>
                                <th className="custom-th">Discount Amt (₹)</th>
                                <th className="custom-th">CGST (₹)</th>
                                <th className="custom-th">CGST %</th>
                                <th className="custom-th">SGST (₹)</th>
                                <th className="custom-th">SGST %</th>
                                <th className="custom-th">IGST (₹)</th>
                                <th className="custom-th">Tax (%)</th>
                                <th className="custom-th">Tax (₹)</th>
                                <th className="custom-th">Row Total (₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.batchDetails.map((batch, idx) => (
                                <tr key={idx}>
                                  <td>{batch.id}</td>
                                  <td>{batch.receivedQty ?? 0}</td>
                                  <td>{batch.receivedFreeQty ?? 0}</td>
                                  <td>{batch.mrp ?? 0}</td>
                                  <td>{batch.batchNo}</td>
                                  <td>{batch.expiryDate}</td>
                                  <td>{batch.srNo}</td>
                                  <td>{batch.mfgDate}</td>
                                  <td>{batch.marketedBy || "N/A"}</td>
                                  <td>{batch.manufacturedBy || "N/A"}</td>
                                  <td>₹{(batch.price ?? 0).toFixed(2)}</td>
                                  <td>{batch.discountPercent ?? 0}</td>
                                  <td>₹{(batch.discountAmount ?? 0).toFixed(2)}</td>
                                  <td>₹{(batch.cgstAmount ?? 0).toFixed(2)}</td>
                                  <td>{batch.cgstPercent ?? 0}</td>
                                  <td>₹{(batch.sgstAmount ?? 0).toFixed(2)}</td>
                                  <td>{batch.sgstPercent ?? 0}</td>
                                  <td>₹{(batch.igstAmount ?? 0).toFixed(2)}</td>
                                  <td>{batch.taxPercent ?? 0}</td>
                                  <td>₹{(batch.taxAmount ?? 0).toFixed(2)}</td>
                                  <td>₹{(batch.rowTotal ?? 0).toFixed(2)}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Payment Summary */}
      <div className="card payment-summary-card mt-4">
        <h5 className="text-primary mb-3 d-flex align-items-center">
          <FaMoneyBillWave className="me-2" /> Payment Summary
        </h5>
        <table className="table table-bordered table-striped">
          <tbody>
            <tr>
              <th className="bg-light">Subtotal</th>
              <td>₹{(grn.subtotal ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th className="bg-light">GST</th>
              <td>₹{(grn.taxAmount ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th className="bg-light">Shipping &amp; Handling</th>
              <td>₹{(grn.shippingCharges ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th className="bg-light">Discount</th>
              <td>₹{(grn.discountAmount ?? 0).toFixed(2)}</td>
            </tr>
            <tr>
              <th className="bg-light">Grand Total</th>
              <td>
                <strong className="text-success">₹{(grn.grandTotal ?? 0).toFixed(2)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
        <div className="mt-3 text-end">
          <button className="btn btn-success">Proceed to Payment</button>
        </div>
      </div>

      {/* 📌 Debit Notes Related to this GRN */}
      {debitNotes.length > 0 && (
        <div className="card shadow-lg p-4 mt-4 related-debit-notes">
          <h4 className="text-primary d-flex align-items-center">
            <FaFileInvoice className="me-2" /> Related Debit Notes
          </h4>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th>ID</th>
                  <th>PO Number</th>
                  <th>Warehouse ID</th>
                  <th>Subtotal</th>
                  <th>Tax Amount</th>
                  <th>Grand Total</th>
                  <th>Created At</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {debitNotes.map((record) => (
                  <tr key={record.id}>
                    <td>{record.id}</td>
                    <td>{record.poNumber}</td>
                    <td>{record.warehouseId}</td>
                    <td>₹{record.subtotal.toFixed(2)}</td>
                    <td>₹{record.taxAmount.toFixed(2)}</td>
                    <td>₹{record.grandTotal.toFixed(2)}</td>
                    <td>{new Date(record.createdAt).toLocaleString()}</td>
                    <td>
                      <button className="btn btn-info btn-sm" onClick={() => navigate(`/debit-note/view/${record.id}`)}>
                        <FaFileInvoice /> View
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}

    </div>
  );
};

export default GRNDetails;
