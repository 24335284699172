import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { isTokenExpired } from "../utils/auth"; // Import the utility function
import styles from "./Navbar.module.css"; // Import the CSS file
import logo from "../assets/logo.png"; // Ensure the path is correct
import {
    FaSignInAlt,
    FaSignOutAlt,
    FaGlobe,
    FaBell,
    FaExpand,
    FaSearch,
} from "react-icons/fa";
import { Dropdown } from "react-bootstrap";

const Topbar = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();
    const [user, setUser] = useState(null);
    
    useEffect(() => {
        // Fetch user details from localStorage
        const storedUser = localStorage.getItem("userDetails");
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        } else {
            navigate("/");
        }
    }, [navigate]);

    const handleLogout = useCallback(() => {
        localStorage.removeItem("authToken");
        localStorage.removeItem("userDetails");
        setIsLoggedIn(false);
        navigate("/");
    }, [navigate]);

    useEffect(() => {
        const token = localStorage.getItem("authToken");
        if (token && !isTokenExpired(token)) {
            setIsLoggedIn(true);
        } else {
            handleLogout();
        }
    }, [handleLogout]);

    return (
        <nav className="bg-primary text-light border-bottom position-relative topbar" style={{ zIndex: 1030 }}>
            <div className="d-flex justify-content-between align-items-center py-3 px-5 mobile-menu">
                {/* Left: Logo */}
                <Link className={`navbar-brand ${styles.logo}`} to="/">
                    <img src={logo} alt="Logo" height="25" />
                </Link>

                {/* Right: Icons + Auth */}
                <div className="d-flex align-items-center gap-3 ">
                    {/* 🔍 Search */}
                    <button className="btn btn-link text-light py-0 px-1 border-0 d-none d-md-block">
                        <FaSearch />
                    </button>
                    {/* 🌐 Language */}
                    <Dropdown className="d-none d-md-block">
                        <Dropdown.Toggle variant="link" className="text-light py-0 px-1 border-0">
                            <FaGlobe />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>English</Dropdown.Item>
                            <Dropdown.Item>Hindi</Dropdown.Item>
                            <Dropdown.Item>Thai Baht</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* 💲 Currency */}
                    <Dropdown className="d-none d-md-block">
                        <Dropdown.Toggle variant="link" className="text-light py-0 px-1 border-0 text-decoration-none fw-bold">
                        <span>₹</span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>USD</Dropdown.Item>
                            <Dropdown.Item>INR</Dropdown.Item>
                            <Dropdown.Item>EUR</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* 🔔 Notifications */}
                    <Dropdown className="d-none d-md-block">
                        <Dropdown.Toggle variant="link" className="text-light py-0 px-1 position-relative border-0">
                            <FaBell />
                            <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                0
                            </span>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item>No new notifications</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>

                    {/* ⛶ Fullscreen */}
                    <button
                    className="btn btn-link text-light py-0 px-1 border-0 d-none d-md-block"
                    onClick={() => {
                        if (!document.fullscreenElement) {
                        document.documentElement.requestFullscreen().catch((err) => {
                            console.error(`Error attempting to enable fullscreen: ${err.message}`);
                        });
                        } else {
                        document.exitFullscreen();
                        }
                    }}
                    >
                    <FaExpand />
                    </button>

                    {/* 👤 Profile */}
                    <Dropdown>
                        <Dropdown.Toggle
                            variant="link"
                            className="py-0 px-1 border-0 d-flex align-items-center gap-2 text-light text-decoration-none"
                        >
                            <span>{user ? user.username : "User"} </span>
                            <img
                            src="./man.png"
                            alt="Profile"
                            width="28"
                            height="28"
                            className="rounded-circle"
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu className="dropdown-menu-end">
                            {!isLoggedIn ? (
                            <Dropdown.Item as={Link} to="/" className="text-dark">
                                <FaSignInAlt className="me-2" />
                                Login
                            </Dropdown.Item>
                            ) : (
                            <>
                                <Dropdown.Item href="/profile">
                                My Profile
                                </Dropdown.Item>
                                <Dropdown.Item href="/settings">
                                Settings
                                </Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item onClick={handleLogout} className="text-danger">
                                <FaSignOutAlt className="me-2" />
                                Logout
                                </Dropdown.Item>
                            </>
                            )}
                        </Dropdown.Menu>
                        </Dropdown>
                </div>
            </div>
        </nav>
    );
};

export default Topbar;

