import config from "../config";
const BASE_URL = `${config.BASE_URL}/api/countries`;

const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

export const getAllCountries = async (query = "") => {
  const url = query 
    ? `${BASE_URL}/search?${query}` 
    : `${BASE_URL}/getAllCountries`;
  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const getCountryById = async (id) => {
  const response = await fetch(`${BASE_URL}/getCountryById/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const updateCountry = async (id, countryPayload) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(countryPayload),
  });
  return handleResponse(response);
};

export const addCountry = async (countryPayload) => {
  const response = await fetch(`${BASE_URL}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(countryPayload),
  });
  return handleResponse(response);
};

export const searchCountries = async (filters) => {
  // Expect filters to include keys: countryName, page, size
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};
