import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import config from "../config";
import { FaLock, FaEnvelope } from "react-icons/fa";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./Login.module.css"; // ✅ Import CSS Module
import logo from "../assets/logo.png"; // ✅ Import Logo

const Login = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const password = event.target.password.value;

    try {
      const response = await fetch(`${config.BASE_URL}/api/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        throw new Error("Invalid email or password");
      }

      const data = await response.json();
      // Destructure "name" from API response and set it as "username"
      const { token, userId, name, email: userEmail, roles, permissions } = data.data;

      localStorage.setItem("authToken", token);
      localStorage.setItem(
        "userDetails",
        JSON.stringify({ userId, username: name, email: userEmail, roles, permissions })
      );

      navigate("/dashboard");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <div className={`${styles.loginContainer} d-flex flex-column align-items-center justify-content-center min-vh-100`}>
      <div className={styles.overlay}></div>
      <div className={`${styles.loginBox} p-5 shadow-lg text-white text-center`}>

        {/* Logo at the top */}
        <Link to="/" className="d-block mb-4">
          <img src={logo} alt="Logo" height="35" />
        </Link>

        <h2 className="fw-light mb-4">Empower Your Business</h2>
        <p>AI-driven ERP & CRM for Streamlined Supply Chain, Procurement & Inventory.</p>

        {errorMessage && (
          <div className="alert alert-danger">{errorMessage}</div>
        )}

        <form onSubmit={handleLogin} className="w-100">
          <div className="mb-3 input-group">
            <span className={`${styles.inputGroupText} input-group-text`}><FaEnvelope /></span>
            <input
              type="email"
              className={`${styles.formControl} form-control`}
              name="email"
              placeholder="Business Email"
              required
            />
          </div>
          <div className="mb-3 input-group">
            <span className={`${styles.inputGroupText} input-group-text`}><FaLock /></span>
            <input
              type="password"
              className={`${styles.formControl} form-control`}
              name="password"
              placeholder="Secure Password"
              required
            />
          </div>
          <button type="submit" className={`${styles.btnLight} btn w-100`}>
            Access Dashboard
          </button>
        </form>

        <div className="mt-3">
          <button
            className={`${styles.btnLink} btn btn-link text-white`}
            onClick={() => alert("Reset password link coming soon!")}
          >
            Forgot Password?
          </button>
          {" | "}
          <button
            className={`${styles.btnLink} btn btn-link text-white`}
            onClick={() => navigate("/register")}
          >
            Join Us Today
          </button>
        </div>
      </div>

      

    </div>
  );
};

export default Login;
