import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import config from "../../config";
import { AsyncPaginate } from "react-select-async-paginate"; // NEW: import AsyncPaginate
import { FaArrowLeft } from "react-icons/fa";

function EditRole() {
  const { id } = useParams(); // Role ID from the URL
  const [roleData, setRoleData] = useState({ name: "", permissions: [] });
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [userPermissions, setUserPermissions] = useState([]); // NEW: load user's permissions
  const navigate = useNavigate();

  // NEW: Load user's permissions from localStorage
  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // Handle parse error if needed
      }
    }
  }, []);

  // Fetch role details and permissions
  useEffect(() => {
    setIsLoading(true);

    const fetchRoleDetails = fetch(`${config.BASE_URL}/api/roles/${id}`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });

    const fetchPermissions = fetch(`${config.BASE_URL}/api/permissions`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });

    Promise.all([fetchRoleDetails, fetchPermissions])
      .then(async ([roleResponse, permissionsResponse]) => {
        if (!roleResponse.ok || !permissionsResponse.ok) {
          throw new Error("Failed to fetch role or permissions data");
        }
        const roleData = await roleResponse.json();
        

        setRoleData({
          name: roleData.data.name,
          permissions: roleData.data.permissions || [], // ✅ Ensure it is an array
        });
      })
      .catch((error) => setErrorMessage(error.message))
      .finally(() => setIsLoading(false));
  }, [id]);

  // NEW: Function to load permission options with search and pagination
  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    const response = await fetch(`${config.BASE_URL}/api/permissions?name=${inputValue}&page=${page}&size=20`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });
    const json = await response.json();
    const options = (json.data?.content || []).map((p) => ({
      value: p.name,
      label: p.name,
    }));
    return {
      options,
      hasMore: page < (json.data.totalPages || 1) - 1,
      additional: {
        page: page + 1,
      },
    };
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${config.BASE_URL}/api/roles/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(roleData),
    })
      .then((response) => {
        if (!response.ok) throw new Error("Failed to update role");
        return response.json();
      })
      .then(() => {
        toast.success("Role updated successfully!");
        navigate("/role-management");
      })
      .catch((error) => setErrorMessage(error.message));
  };

  const hasEditPermission = userPermissions.includes("EDIT_ROLE");

  if (isLoading) {
    return <div className="container mt-5">Loading...</div>;
  }

  if (errorMessage) {
    return (
      <div className="container mt-5">
        <div className="alert alert-danger">{errorMessage}</div>
      </div>
    );
  }

  return (
    <div className="container">
      {/* Back button on top */}
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary">Edit Roles</h1>
          <p className="welcomeSubtitle d-none d-md-block">Add or remove roles.</p>
        </div>
        <div>
        <button className="btn btn-secondary" onClick={() => navigate("/role-management")}>
          <FaArrowLeft /> Back to Roles
        </button>
        </div>
      </div>
      {!hasEditPermission ? (
        <div className="alert alert-danger">
          You do not have permission to edit roles.
        </div>
      ) : (
        <div className="card shadow p-2">
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              {/* Role Name */}
              <div className="mb-3">
                <label className="form-label">Role Name</label>
                <input
                  type="text"
                  className="form-control"
                  value={roleData.name}
                  onChange={(e) => setRoleData({ ...roleData, name: e.target.value })}
                  required
                />
              </div>
              {/* Permissions Selection */}
              <div className="mb-3">
                <label className="form-label">Permissions</label>
                <AsyncPaginate
                  isMulti
                  value={roleData.permissions.map((p) => ({ value: p, label: p }))}
                  loadOptions={loadOptions}
                  additional={{ page: 0 }}
                  onChange={(selectedOptions) =>
                    setRoleData({
                      ...roleData,
                      permissions: selectedOptions.map((option) => option.value),
                    })
                  }
                  placeholder="Search and select permissions..."
                />
              </div>
              <div className="d-flex justify-content-end">
                <button className="btn btn-primary" type="submit">
                  Update Role
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default EditRole;
