import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createInterBranchTransfer } from "../../services/interBranchTransferService";
import { getAllWarehouses } from "../../services/warehouseService";
import { getProducts } from "../../services/productService";
import { getBatchDetails } from "../../services/inventoryService";
import { toast } from "react-toastify";
import { FaSave, FaClipboardList, FaBoxOpen } from "react-icons/fa";
import "./CreateInterBranchTransfer.css"; // Import custom CSS

const CreateInterBranchTransfer = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
  const userId = userDetails?.userId; // Extract userId from userDetails

  const [branches, setBranches] = useState([]);
  const [products, setProducts] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [batchData, setBatchData] = useState({});
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [selectedBatches, setSelectedBatches] = useState({});
  const [taxPercentages, setTaxPercentages] = useState({});
  const [transfer, setTransfer] = useState({
    sourceBranchId: localStorage.getItem("sourceBranchId") || "",
    destinationBranchId: localStorage.getItem("destinationBranchId") || "",
    transferItems: JSON.parse(localStorage.getItem("transferItems")) || [],
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    // Fetch branches for selection
    async function fetchBranches() {
      try {
        const response = await getAllWarehouses();
        if (response.data) {
          setBranches(Array.isArray(response.data) ? response.data : []); // Ensure data is an array
        } else {
          setBranches([]);
        }
      } catch (error) {
        toast.error("Failed to fetch branches");
      }
    }
    fetchBranches();
  }, []);

  useEffect(() => {
    async function fetchProducts() {
      try {
        // Set size based on searchTerm: 5 when empty, otherwise 10
        const size = searchTerm ? 10 : 5;
        // Pass name, minPrice, maxPrice and adjust page (API expects zero-indexed page)
        const response = await getProducts({
          name: searchTerm,
          minPrice: "",
          maxPrice: "",
          page: currentPage - 1,
          size
        });
        if (response.data) {
          setProducts(Array.isArray(response.data.content) ? response.data.content : []);
          setTotalPages(response.data.totalPages || 1);
        } else {
          setProducts([]);
          setTotalPages(1);
        }
      } catch (error) {
        toast.error("Failed to fetch products");
      }
    }
    fetchProducts();
  }, [searchTerm, currentPage]);

  // Fetch batch details for a selected SKU
  const fetchBatchDetails = async (sku, branchId) => {
    try {
      const response = await getBatchDetails({ sku, branchId });
      if (response.statusCode === 200) {
        const batches = response.data?.content.filter(batch => batch.quantity > 0) || [];
        setBatchData((prev) => ({ ...prev, [sku]: batches }));
      } else {
        toast.error("Failed to fetch batch details!");
      }
    } catch (error) {
      toast.error("Error fetching batch details.");
    }
  };

  // Handle branch selection
  const handleBranchChange = (e) => {
    const { name, value } = e.target;
    setTransfer({ ...transfer, [name]: value });
    localStorage.setItem(name, value);
  };

  // Handle SKU selection
  const handleSkuSelection = (sku) => {
    if (!selectedSkus.includes(sku)) {
      setSelectedSkus([...selectedSkus, sku]);
      fetchBatchDetails(sku, transfer.sourceBranchId);
    }
  };

  // Remove selected SKU
  const removeSku = (sku) => {
    const updatedSkus = selectedSkus.filter((s) => s !== sku);
    setSelectedSkus(updatedSkus);
    setBatchData((prev) => {
      const newBatchData = { ...prev };
      delete newBatchData[sku];
      return newBatchData;
    });
    setSelectedBatches((prev) => {
      const newSelectedBatches = { ...prev };
      delete newSelectedBatches[sku];
      return newSelectedBatches;
    });
    setTaxPercentages((prev) => {
      const newTaxPercentages = { ...prev };
      delete newTaxPercentages[sku];
      return newTaxPercentages;
    });
  };

  // Handle batch selection
  const handleBatchSelection = (sku, batchNo, selectedQty) => {
    const availableQty = batchData[sku]?.find(batch => batch.batchNo === batchNo)?.quantity || 0;
    if (selectedQty > availableQty) {
      toast.error("Selected quantity exceeds available quantity!");
      return;
    }
    setSelectedBatches((prev) => ({
      ...prev,
      [sku]: { ...(prev[sku] || {}), [batchNo]: selectedQty },
    }));
  };

  // Handle tax percentage selection
  const handleTaxPercentageChange = (sku, taxPercentage) => {
    setTaxPercentages((prev) => ({
      ...prev,
      [sku]: taxPercentage,
    }));
  };

  // Calculate total transfer quantity for each SKU
  const calculateTotalTransferQty = (sku) => {
    const selectedBatchesForItem = selectedBatches[sku] || {};
    return Object.values(selectedBatchesForItem).reduce((a, b) => a + b, 0);
  };

  // Submit transfer request
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validation: Ensure source and destination branches are not the same
    if (transfer.sourceBranchId === transfer.destinationBranchId) {
      toast.error("Source and destination branches cannot be the same!");
      return;
    }

    // Validation: Ensure total batch quantity matches required transfer quantity
    let isValid = true;
    const transferItems = selectedSkus.map((sku) => {
      const selectedBatchesForItem = selectedBatches[sku] || {};
      const totalTransferQty = calculateTotalTransferQty(sku);

      if (totalTransferQty === 0) {
        toast.error(`Total transfer quantity for SKU: ${sku} cannot be zero!`);
        isValid = false;
      }

      const batches = Object.entries(selectedBatchesForItem).map(([batchNo, quantity]) => {
        if (quantity === 0) {
          toast.error(`Transfer quantity for batch ${batchNo} of SKU: ${sku} cannot be zero!`);
          isValid = false;
        }
        const batch = batchData[sku].find(batch => batch.batchNo === batchNo);
        return {
          batchId: batch.id,
          batchNo: batch.batchNo,
          quantity,
          price: batch.price,
        };
      });

      return {
        sku,
        quantity: totalTransferQty,
        taxPercent: taxPercentages[sku] || 0,
        batches,
      };
    });

    if (!isValid) return;

    const payload = {
      sourceBranchId: transfer.sourceBranchId,
      destinationBranchId: transfer.destinationBranchId,
      createdBy: userId, // Use the actual user ID
      transferItems,
    };

    try {
      const response = await createInterBranchTransfer(payload);
      if (response.statusCode === 201) {
        toast.success("Transfer Created Successfully!");
        localStorage.removeItem("sourceBranchId");
        localStorage.removeItem("destinationBranchId");
        localStorage.removeItem("transferItems");
        navigate("/interbranch-transfers");
      } else {
        toast.error(`Error: ${response.message}`);
      }
    } catch (error) {
      toast.error("Failed to create transfer.");
    }
  };

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaClipboardList /> Create Inter-Branch Transfer</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Initiate stock movement between branches by selecting source, destination, and items to transfer.
          </p>
        </div>
        <div>
          {/* <button className="btn btn-primary" onClick={() => navigate("/interbranch-transfers/create")}>
            <FaPlus /> Create Branch Transfer
          </button> */}
        </div>
      </div>
      {/* 🔹 Page Header */}
      {/* <h2 className="text-primary mb-4">
        <FaClipboardList /> Create Inter-Branch Transfer
      </h2> */}
      <form onSubmit={handleSubmit} className="card p-4 shadow">
        <div className="row g-3">
          {/* Source Branch */}
          <div className="col-md-4">
            <label className="form-label">Source Branch</label>
            <select
              name="sourceBranchId"
              className="form-select"
              value={transfer.sourceBranchId}
              onChange={handleBranchChange}
              required
            >
              <option value="">-- Select Source Branch --</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>

          {/* Destination Branch */}
          <div className="col-md-4">
            <label className="form-label">Destination Branch</label>
            <select
              name="destinationBranchId"
              className="form-select"
              value={transfer.destinationBranchId}
              onChange={handleBranchChange}
              required
            >
              <option value="">-- Select Destination Branch --</option>
              {branches.map((branch) => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Product Search and Selection */}
        <div className="mt-4">
          <h4 className="text-primary">Search Products</h4>
          <input
            type="text"
            placeholder="Search products by name"
            className="form-control mb-3"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              setCurrentPage(1); // reset to first page for new search
            }}
          />
          <ul className="list-group">
            {products.map((product) => (
              <li
                key={product.id}
                className="list-group-item d-flex justify-content-between align-items-center"
                onClick={() => handleSkuSelection(product.sku)}
              >
                {product.name} - {product.sku}
                <button className="btn btn-primary btn-sm">Select</button>
              </li>
            ))}
          </ul>
          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-2">
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            >
              Previous
            </button>
            <span>
              Page {currentPage} of {totalPages}
            </span>
            <button
              className="btn btn-secondary btn-sm"
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
              disabled={currentPage === totalPages}
            >
              Next
            </button>
          </div>
        </div>

        {/* Selected SKUs and Batch Details */}
        <h4 className="text-primary mt-4">
          <FaBoxOpen /> Transfer Items
        </h4>
        {selectedSkus.map((sku, index) => (
          <div key={index} className="card p-3 mb-3">
            <h5 className="text-secondary">SKU: {sku} <button type="button" className="btn btn-sm btn-danger ms-2" onClick={() => removeSku(sku)}>Remove</button></h5>
            <div className="row">
              <div className="col-md-6">
                <label className="form-label">Total Transfer Qty</label>
                <input
                  type="number"
                  className="form-control form-control-sm"
                  value={calculateTotalTransferQty(sku)}
                  readOnly
                />
              </div>
              <div className="col-md-6">
                <label className="form-label">Tax Percent</label>
                <select
                  className="form-select form-select-sm"
                  value={taxPercentages[sku] || 0}
                  onChange={(e) => handleTaxPercentageChange(sku, parseInt(e.target.value))}
                >
                  <option value="0">0%</option>
                  <option value="5">5%</option>
                  <option value="12">12%</option>
                  <option value="18">18%</option>
                  <option value="28">28%</option>
                </select>
              </div>
            </div>
            <div className="table-responsive mt-2">
              <table className="table table-bordered">
                <thead className="table-dark">
                  <tr>
                    <th>Batch No</th>
                    <th>Expiry Date</th>
                    <th>Available Qty</th>
                    <th>Transfer Qty</th>
                    <th>HSN Code</th>
                    <th>MRP</th>
                    <th>Manufacture Date</th>
                    <th>Manufacturer</th>
                    <th>PTR Rate</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {batchData[sku]?.map((batch, batchIndex) => (
                    <tr key={batchIndex}>
                      <td>{batch.batchNo}</td>
                      <td>{batch.expiryDate}</td>
                      <td>{batch.quantity}</td>
                      <td>
                        <select
                          className="form-select form-select-sm"
                          value={selectedBatches[sku]?.[batch.batchNo] || ""}
                          onChange={(e) =>
                            handleBatchSelection(sku, batch.batchNo, parseInt(e.target.value) || 0)
                          }
                        >
                          <option value="">Select Qty</option>
                          {Array.from({ length: batch.quantity }, (_, i) => i + 1).map((num) => (
                            <option key={num} value={num}>
                              {num}
                            </option>
                          ))}
                        </select>
                      </td>
                      <td>{batch.hsnCode}</td>
                      <td>{batch.mrp}</td>
                      <td>{batch.manufactureDate}</td>
                      <td>{batch.manufacturer}</td>
                      <td>{batch.ptrRate}</td>
                      <td>{batch.price}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ))}

        <button type="submit" className="btn btn-primary mt-3">
          <FaSave /> Save
        </button>
      </form>
    </div>
  );
};

export default CreateInterBranchTransfer;