import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaKey, FaSearch, FaPlus, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getPermissions } from "../../services/permissionService"; // Service File

const PermissionManagement = () => {
  const [permissions, setPermissions] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]); // New state for user's permissions
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  // ✅ State for Filters & Pagination
  const [filters, setFilters] = useState({
    name: "",
    page: 0,
    size: 10, // Default page size
  });

  // New: Load user's permissions from localStorage
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      try {
        const parsed = JSON.parse(userDetails);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // ...existing error handling...
      }
    }
  }, []);

  // ✅ Fetch Permissions with Proper Filtering
  const fetchPermissions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPermissions({
        ...filters,
        name: filters.name.trim() ? filters.name : undefined, // Exclude empty filter
      });

      if (response.statusCode === 200) {
        setPermissions(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch permissions!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setPermissions([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  return (
    <div className="container mt-4">
      <div 
        className="card shadow" 
        style={{ backgroundColor: "white", color: "black" }}
      >
        <div 
          className="card-header text-center border-bottom" 
          style={{ backgroundColor: "white", color: "black", padding: "1.5rem" }}
        >
          <h2><FaKey /> Permission Management</h2>
        </div>
        <div className="card-body" style={{ padding: "2rem" }}>
          {/* 🔍 Filters */}
          <div className="row g-2 mb-3">
            <div className="col-md-4">
              <input
                type="text"
                placeholder="🔎 Search Permission Name"
                className="form-control"
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <button className="btn btn-success w-100" onClick={fetchPermissions}>
                <FaSearch /> Search
              </button>
            </div>
            {/* Conditionally render "Add Permission" if user has the ADD_PERMISSION permission */}
            {userPermissions.includes("ADD_PERMISSION") && (
              <div className="col-md-2 mx-auto">
                <button className="btn btn-primary w-100" onClick={() => navigate("/add-permission")}>
                  <FaPlus /> Add Permission
                </button>
              </div>
            )}
          </div>

          {/* 📊 Data Table */}
          {!loading && permissions.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Permission Name</th>
                  </tr>
                </thead>
                <tbody>
                  {permissions.map((permission) => (
                    <tr key={permission.id}>
                      <td>{permission.id}</td>
                      <td>{permission.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            !loading && (
              <div className="text-center">
                <h5>No permissions found ❌</h5>
              </div>
            )
          )}

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button
              className="btn btn-outline-secondary"
              disabled={filters.page === 0}
              onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
            >
              <FaArrowLeft /> Previous
            </button>
            <span>Page {filters.page + 1} of {totalPages}</span>
            <button
              className="btn btn-outline-secondary"
              disabled={filters.page + 1 >= totalPages}
              onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
            >
              Next <FaArrowRight />
            </button>
          </div>
        </div> {/* end card-body */}
      </div> {/* end card */}
    </div>
  );
};

export default PermissionManagement;
