import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/products`;

// ✅ Function to get the auth token from localStorage
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Handle API response with improved error handling
const handleResponse = async (response) => {
  if (!response.ok) {
    let errorMessage = "Something went wrong!";
    try {
      const errorData = await response.json(); // Try parsing JSON
      errorMessage = errorData.message || errorMessage;
    } catch {
      errorMessage = await response.text(); // Fallback to text
    }
    console.error("API Error:", errorMessage); // Log error for debugging
    throw new Error(errorMessage);
  }
  return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
};

// ✅ Function to construct headers with authentication
const getHeaders = () => {
  const authToken = getAuthToken();
  const headers = {
    "Content-Type": "application/json",
  };

  if (authToken) {
    headers["Authorization"] = `Bearer ${authToken}`; // ✅ Fix: Use "Bearer " prefix
  } else {
    console.warn("No authentication token found!"); // Debugging log
  }

  return headers;
};

// ✅ Fetch all products with search & pagination
export const getProducts = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: getHeaders(),
  });

  return handleResponse(response);
};

// ✅ Fetch a single product by ID
export const getProductById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: getHeaders(),
  });

  return handleResponse(response);
};

// ✅ Create a new product
export const createProduct = async (productData) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(productData),
  });

  return handleResponse(response);
};

// ✅ Update an existing product
export const updateProduct = async (id, productData) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(productData),
  });

  return handleResponse(response);
};

// ✅ Delete a product
export const deleteProduct = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  });

  return handleResponse(response);
};
