import React, { useState } from "react";
import { createBrand } from "../services/brandService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPlusCircle, FaArrowLeft } from "react-icons/fa";

const AddBrand = () => {
  const navigate = useNavigate();
  const [brand, setBrand] = useState({
    name: "",
    urlKey: "",
    logo: "",
    brandImage: "",
    sortOrder: "",
    isActive: 1,
    createdBy: "",
  });

  const handleChange = (e) => {
    setBrand({ ...brand, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createBrand(brand);
      toast.success("Brand added successfully! ✅");
      navigate("/brands");
    } catch (err) {
      toast.error("Failed to add brand! ❌");
    }
  };

  return (
    <div className="container-fluid px-5 py-4">
      {/* Page Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">
          <FaPlusCircle className="me-2" /> Add New Brand
        </h2>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/brands")}>
          <FaArrowLeft /> Back to Brands
        </button>
      </div>

      {/* Brand Form */}
      <div className="card shadow-lg p-4">
        <form onSubmit={handleSubmit} className="row g-4">
          {/* Brand Details */}
          <h5 className="text-primary border-bottom pb-2">🏷️ Brand Details</h5>
          <div className="col-md-6">
            <label className="form-label">Brand Name</label>
            <input type="text" className="form-control" name="name" value={brand.name} onChange={handleChange} required />
          </div>
          <div className="col-md-6">
            <label className="form-label">URL Key</label>
            <input type="text" className="form-control" name="urlKey" value={brand.urlKey} onChange={handleChange} required />
          </div>
          <div className="col-md-6">
            <label className="form-label">Sort Order</label>
            <input type="number" className="form-control" name="sortOrder" value={brand.sortOrder} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Created By (User ID)</label>
            <input type="number" className="form-control" name="createdBy" value={brand.createdBy} onChange={handleChange} required />
          </div>
          
          {/* Branding & Images */}
          <h5 className="text-primary border-bottom pb-2">📷 Branding & Images</h5>
          <div className="col-md-6">
            <label className="form-label">Logo URL</label>
            <input type="text" className="form-control" name="logo" value={brand.logo} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Brand Image URL</label>
            <input type="text" className="form-control" name="brandImage" value={brand.brandImage} onChange={handleChange} />
          </div>

          {/* Active Status */}
          <div className="col-md-6">
            <label className="form-label">Active Status</label>
            <select className="form-control" name="isActive" value={brand.isActive} onChange={handleChange}>
              <option value="1">Active</option>
              <option value="0">Inactive</option>
            </select>
          </div>

          {/* Submit Buttons */}
          <div className="col-12 text-center mt-4">
            <button type="submit" className="btn btn-lg btn-primary">✅ Add Brand</button>
            <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/brands")}>❌ Cancel</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default AddBrand;
