import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createCourier } from "../../services/courierService";
import { toast } from "react-toastify";

const CourierAdd = () => {
  const navigate = useNavigate();
  const [courierData, setCourierData] = useState({
    revenueStoreId: 0,
    courierName: "",
    status: 1, // Default active
    createdBy: null, // Initially set to null
  });

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userId = userDetails?.userId;
    if (userId) {
      setCourierData((prevData) => ({ ...prevData, createdBy: userId }));
    }
  }, []);

  // ✅ Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourierData({ ...courierData, [name]: value });
  };

  // ✅ Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCourier(courierData);
      toast.success("✅ Courier added successfully!");
      navigate("/couriers");
    } catch (error) {
      toast.error("❌ Failed to add courier.");
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-primary">➕ Add Courier</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Courier Name</label>
          <input
            type="text"
            name="courierName"
            value={courierData.courierName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Status</label>
          <select
            name="status"
            value={courierData.status}
            onChange={handleChange}
            className="form-control"
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <button type="submit" className="btn btn-success">Save</button>
        <button type="button" className="btn btn-secondary ms-2" onClick={() => navigate("/couriers")}>
          Cancel
        </button>
        <button type="button" className="btn btn-primary ms-2" onClick={() => navigate(-1)}>
          Back
        </button>
      </form>
    </div>
  );
};

export default CourierAdd;