import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify"; // Notifications
import { getAllRoles, createUser } from "../../services/userService"; // API calls
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPlusCircle, FaArrowLeft } from "react-icons/fa";

const AddUser = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    password: "",
    roles: [],
  });
  const [availableRoles, setAvailableRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  // ✅ Fetch available roles when component mounts
  useEffect(() => {
    async function fetchRoles() {
      try {
        const response = await getAllRoles();
        if (response.data?.content) {
          setAvailableRoles(response.data.content); // ✅ Fix for paginated response
        } else {
          setAvailableRoles(response.data || []);
        }
      } catch (error) {
        console.error("Error fetching roles:", error);
        toast.error("Failed to load roles.");
      }
    }
    fetchRoles();
  }, []);

  // ✅ Handle Role Selection (Toggle)
  const handleRoleChange = (roleName) => {
    setFormData((prevData) => {
      const roles = prevData.roles.includes(roleName)
        ? prevData.roles.filter((r) => r !== roleName) // Remove role
        : [...prevData.roles, roleName]; // Add role
      return { ...prevData, roles };
    });
  };

  // ✅ Submit Form Data
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.roles.length === 0) {
      toast.warn("Please select at least one role.");
      return;
    }

    setLoading(true);
    try {
      // ✅ Ensure roles are sent as an array
      const payload = {
        ...formData,
        roles: formData.roles, // Already an array, no need to split/join
      };

      await createUser(payload);
      toast.success("User added successfully! ✅");
      navigate("/user-management");
    } catch (error) {
      console.error("Error adding user:", error);
      toast.error("Failed to add user.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container">
      {/* 🏷️ Page Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h1 className="welcomeTitle text-primary">
          <FaPlusCircle className="me-2" /> Add New User
        </h1>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/user-management")}>
          <FaArrowLeft /> Back to Users
        </button>
      </div>

      {/* 📋 User Form */}
      <div className="card shadow p-2">
        <div className="card-body">
        <form onSubmit={handleSubmit} className="row g-4">
          {/* 📌 User Details */}
          <h5 className="text-primary border-bottom">👤 User Details</h5>
          <div className="col-md-4">
            <label className="form-label">Full Name</label>
            <input
              type="text"
              className="form-control"
              name="name"
              value={formData.name}
              onChange={(e) => setFormData({ ...formData, name: e.target.value })}
              required
              placeholder="Enter full name"
            />
          </div>

          <div className="col-md-4">
            <label className="form-label">Email</label>
            <input
              type="email"
              className="form-control"
              name="email"
              value={formData.email}
              onChange={(e) => setFormData({ ...formData, email: e.target.value })}
              required
              placeholder="Enter email"
            />
          </div>

          <div className="col-md-4">
            <label className="form-label">Password</label>
            <input
              type="password"
              className="form-control"
              name="password"
              value={formData.password}
              onChange={(e) => setFormData({ ...formData, password: e.target.value })}
              required
              placeholder="Enter password"
            />
          </div>

          {/* 🛡️ Roles Selection */}
          <h5 className="text-primary border-bottom pb-2">🛡️ Assign Roles</h5>
          <div className="col-12">
            <div className="">
            {availableRoles.length > 0 ? (
              availableRoles.map((role) => (
                <div key={role.id} className="form-check ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id={`role-${role.id}`}
                    checked={formData.roles.includes(role.name)}
                    onChange={() => handleRoleChange(role.name)}
                  />
                  <label htmlFor={`role-${role.id}`} className="form-check-label">
                    {role.name}
                  </label>
                </div>
              ))
            ) : (
              <p className="text-muted">No roles available</p>
            )}
            </div>
          </div>

          {/* ✅ Submit Buttons */}
          <div className="col-12 text-end mt-1">
            <button type="submit" className="btn btn-primary" disabled={loading}>
            <FaPlusCircle /> &nbsp;
              {loading ? "Adding User..." : "Add User"}
            </button>
            <button type="button" className="btn btn-outline-secondary ms-3" onClick={() => navigate("/user-management")}>
              Cancel
            </button>
          </div>
        </form>
        </div>
      </div>
    </div>
  );
};

export default AddUser;
