import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { searchCreditNotes } from "../services/creditNoteService";  // ✅ Updated import
import { toast } from "react-toastify";
import { FaEye, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CreditNoteList = () => {
  const [creditNotes, setCreditNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    pickAndPackId: "",
    orderId: "",
    creditNoteNumber: "",
    page: 0,
    size: 10,
  });

  const fetchCreditNotes = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await searchCreditNotes(activeFilters);  // ✅ Corrected function call
      if (response.statusCode === 200) {
        setCreditNotes(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch credit notes!");
      }
    } catch (error) {
      toast.error("Error fetching credit notes.");
      setCreditNotes([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchCreditNotes();
  }, [fetchCreditNotes]);

  return (
    <div className="container mt-4">
      <h2 className="text-primary">📜 Credit Note Management</h2>

      {/* Filters */}
      <div className="row g-2 mt-3">
        <div className="col-md-3">
          <input
            type="number"
            placeholder="Pick and Pack ID"
            className="form-control"
            value={filters.pickAndPackId}
            onChange={(e) => setFilters({ ...filters, pickAndPackId: e.target.value })}
          />
        </div>
        <div className="col-md-3">
          <input
            type="number"
            placeholder="Order ID"
            className="form-control"
            value={filters.orderId}
            onChange={(e) => setFilters({ ...filters, orderId: e.target.value })}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            placeholder="Credit Note Number"
            className="form-control"
            value={filters.creditNoteNumber}
            onChange={(e) => setFilters({ ...filters, creditNoteNumber: e.target.value })}
          />
        </div>
        <div className="col-md-3">
          <button className="btn btn-success w-100" onClick={fetchCreditNotes}>
            Search
          </button>
        </div>
      </div>

      {/* Credit Note Table */}
      {!loading && creditNotes.length > 0 && (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Pick and Pack ID</th>
                <th>Order ID</th>
                <th>Subtotal</th>
                <th>Total Tax</th>
                <th>Grand Total</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {creditNotes.map((note) => (
                <tr key={note.id}>
                  <td>{note.id}</td>
                  <td>{note.pickAndPackId}</td>
                  <td>{note.orderId}</td>
                  <td>₹{(note.subTotal ?? 0).toFixed(2)}</td>
                  <td>₹{(note.taxAmount ?? 0).toFixed(2)}</td>  {/* ✅ Ensure correct field */}
                  <td>₹{(note.grandTotal ?? 0).toFixed(2)}</td>
                  <td>{new Date(note.createdAt).toLocaleDateString()}</td>
                  <td>
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => navigate(`/credit-notes/view/${note.id}`)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: Math.max(prev.page - 1, 0) }))
          }
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: Math.min(prev.page + 1, totalPages - 1) }))
          }
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default CreditNoteList;
