import React, { useEffect, useState, useCallback } from "react";
import { Container, Card, Table, Button, Form, Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { getAllCountries } from "../../services/countryService";
import { useNavigate } from "react-router-dom";
import { FaArrowLeft, FaArrowRight, FaFlag, FaPlus, FaEdit } from "react-icons/fa";

const CountryCurrencyMaster = () => {
  const navigate = useNavigate();
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [filters, setFilters] = useState({
    countryName: "",
    currencyCode: "",
    dialCode: "",
    page: 0,
    size: 10,
  });

  const fetchCountries = useCallback(async () => {
    setLoading(true);
    try {
      const query = new URLSearchParams(filters).toString();
      const response = await getAllCountries(query); // assuming API supports query filters
      if (response.statusCode === 200) {
        setCountries(response.data.content || []);
        setTotalPages(response.data.totalPages || 1);
      } else {
        toast.error("Failed to fetch countries!");
      }
    } catch (error) {
      toast.error("Error fetching countries.");
      setCountries([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchCountries();
  }, [fetchCountries]);

  const handleEdit = (countryId) => {
    navigate(`/admin/country-currency-master/edit/${countryId}`);
  };

  return (
    <Container className="">
       {/* Page Title & Add Button */}
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaFlag /> Country & Currency Master</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            Manage supported countries and their currencies in one place.
          </p>
        </div>
        <div>
          <Button className="btn btn-primary"  onClick={() => navigate("/admin/country-currency-master/add")}>
            <FaPlus /> Add New Country
          </Button>
        </div>
      </div>

      <Card className="shadow mb-3">

        <Card.Body>
          {/* Attractive search form with optimized spacing */}
          <Form>
            <Row className="g-3">
              <Col md={2}>
                <Form.Control
                  type="text"
                  placeholder="Search by Country Name"
                  value={filters.countryName}
                  onChange={(e) => setFilters((prev) => ({ ...prev, countryName: e.target.value }))}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  type="text"
                  placeholder="Search by Currency Code"
                  value={filters.currencyCode}
                  onChange={(e) => setFilters((prev) => ({ ...prev, currencyCode: e.target.value }))}
                />
              </Col>
              <Col md={2}>
                <Form.Control
                  type="text"
                  placeholder="Search by Dial Code"
                  value={filters.dialCode}
                  onChange={(e) => setFilters((prev) => ({ ...prev, dialCode: e.target.value }))}
                />
              </Col>
              <Col md={2}>
                <Button variant="primary" onClick={fetchCountries} className="w-100">
                  Search
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <Card className="shadow">

        <Card.Body>

          {loading ? (
            <p>Loading countries...</p>
          ) : countries.length > 0 ? (
            <div className="table-responsive">
              <Table striped bordered hover responsive>
                <thead className="table-dark">
                  <tr>
                    <th>Country ID</th>
                    <th>Country Name</th>
                    <th>Currency Code</th>
                    <th>Conversion Rate</th>
                    <th>Dial Code</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {countries.map((country) => (
                    <tr key={country.countryId}>
                      <td>{country.countryId}</td>
                      <td>{country.countryName}</td>
                      <td>{country.currencyCode}</td>
                      <td>{country.currencyConversionRate}</td>
                      <td>{country.dialCode}</td>
                      <td>
                        <Button variant="warning" size="sm" onClick={() => handleEdit(country.id)}>
                          <FaEdit /> Edit
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          ) : (
            <p>No countries found.</p>
          )}


          <div className="d-flex justify-content-between align-items-center">
            <Button
              variant="outline-secondary"
              disabled={filters.page === 0}
              onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
            >
              <FaArrowLeft /> Previous
            </Button>
            <span>
              Page {filters.page + 1} of {totalPages}
            </span>
            <Button
              variant="outline-secondary"
              disabled={filters.page + 1 >= totalPages}
              onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
            >
              Next <FaArrowRight />
            </Button>
          </div>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CountryCurrencyMaster;
