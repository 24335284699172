import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createCustomerGroup } from "../../services/customerGroupService";
import { toast } from "react-toastify";

const AddCustomerGroup = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    customerGroupCode: "",
    isActive: true,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createCustomerGroup(formData);
      toast.success("Customer group created successfully!");
      navigate("/customer-groups");
    } catch (error) {
      toast.error(error.message || "Failed to create customer group.");
    }
  };

  return (
    <div className="container mt-5">
      <h2>Add Customer Group</h2>
      {/* Back button added */}
      <div className="mb-3">
        <button className="btn btn-secondary" onClick={() => navigate("/customer-groups")}>
          Back
        </button>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label">Group Code</label>
          <input
            type="text"
            className="form-control"
            name="customerGroupCode"
            value={formData.customerGroupCode}
            onChange={handleChange}
          />
        </div>
        <div className="form-check mb-3">
          <input
            type="checkbox"
            className="form-check-input"
            name="isActive"
            checked={formData.isActive}
            onChange={handleChange}
          />
          <label className="form-check-label">Active</label>
        </div>
        <button type="submit" className="btn btn-success">Submit</button>
      </form>
    </div>
  );
};

export default AddCustomerGroup;
