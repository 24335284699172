import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/interbranch-grns`;
const getAuthToken = () => localStorage.getItem("authToken");

const apiRequest = async (endpoint, method = "GET", body = null) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    });

    if (!response.ok) {
      throw new Error(await response.text());
    }

    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

export const getGRNs = async (filters = {}) => {
  const query = Object.keys(filters).length
    ? `?${new URLSearchParams(filters).toString()}`
    : "";
  return apiRequest(`/search${query}`);
};

export const getGRNById = async (id) => {
  return apiRequest(`/${id}`);
};

export const approveGRN = async (grnId, approvedBy, comment) => {
  return apiRequest(`/approve`, "POST", { grnId, approvedBy, comment });
};