import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getDebitNoteById, approveDebitNoteById } from "../../services/debitNoteService";
import { getWarehouseById } from "../../services/warehouseService";
import { getPurchaseOrderById } from "../../services/purchaseOrderService";
import { toast } from "react-toastify";
import {
    FaArrowLeft, FaClipboardCheck, FaMoneyBillWave, FaFilePdf, FaCheckCircle, FaWarehouse
} from "react-icons/fa";
import "./DebitNoteDetails.css";

const DebitNoteDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [debitNote, setDebitNote] = useState(null);
    const [warehouse, setWarehouse] = useState(null);
    const [purchaseOrder, setPurchaseOrder] = useState(null);

    useEffect(() => {
        async function fetchDebitNote() {
            try {
                const response = await getDebitNoteById(id);
                if (response.statusCode === 200) {
                    setDebitNote(response.data);

                    // ✅ Fetch Warehouse Details
                    fetchWarehouseDetails(response.data.warehouseId);

                    // ✅ Fetch Purchase Order Details (if required)
                    fetchPurchaseOrderDetails(response.data.purchaseOrderId);
                } else {
                    toast.error("Failed to fetch Debit Note details!");
                }
            } catch (error) {
                toast.error("Error fetching Debit Note.");
            }
        }
        fetchDebitNote();
    }, [id]);

    const fetchWarehouseDetails = async (warehouseId) => {
        if (!warehouseId) return;
        try {
            const response = await getWarehouseById(warehouseId);
            if (response.statusCode === 200) {
                setWarehouse(response.data);
            } else {
                toast.warn("Warehouse details not found.");
            }
        } catch (error) {
            toast.error("Error fetching warehouse details.");
        }
    };

    const fetchPurchaseOrderDetails = async (purchaseOrderId) => {
        if (!purchaseOrderId) return;
        try {
            const response = await getPurchaseOrderById(purchaseOrderId);
            if (response.statusCode === 200) {
                const poData = response.data;

                // ✅ Extract billing & shipping addresses
                const billingAddress = poData.addresses.find(addr => addr.addressType === "billing") || null;
                const shippingAddress = poData.addresses.find(addr => addr.addressType === "shipping") || null;

                // ✅ Set in state
                setPurchaseOrder({
                    ...poData,
                    billingAddress,
                    shippingAddress
                });
            } else {
                toast.warn("Purchase Order details not found.");
            }
        } catch (error) {
            toast.error("Error fetching purchase order details.");
        }
    };

    const handleApprove = async () => {
        try {
            const response = await approveDebitNoteById(debitNote.id);
            if (response.statusCode === 200) {
                toast.success("Debit Note Approved Successfully!");
                setDebitNote((prev) => ({ ...prev, isActive: 1 }));
            } else {
                toast.error(`Approval Failed: ${response.message}`);
            }
        } catch (error) {
            toast.error("Error approving Debit Note.");
        }
    };

    const handleGeneratePDF = () => {
        toast.success("Generating PDF... (This feature will be implemented)");
    };

    if (!debitNote) return <h4 className="text-center mt-4">Loading...</h4>;

    return (
        <div className="container mt-4">
            {/* Header */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="text-primary">
                    <FaClipboardCheck className="me-2" /> Debit Note #<span className="text-decoration-underline">{debitNote.id}</span>
                </h2>
                <div className="d-flex gap-2">
                    {debitNote.isActive === 0 ? (
                        <button className="btn btn-success" onClick={handleApprove}>
                            <FaCheckCircle /> Approve
                        </button>
                    ) : (
                        <span className="badge bg-success p-2">✔ Approved</span>
                    )}
                    <button className="btn btn-danger" onClick={handleGeneratePDF}>
                        <FaFilePdf /> Generate PDF
                    </button>
                    <button className="btn btn-dark" onClick={() => navigate(-1)}>
                        <FaArrowLeft /> Back
                    </button>
                </div>
            </div>

            {/* Purchase Order Details */}
            {purchaseOrder && (
                <div className="card shadow-lg p-4 mb-4 supplier-address-section">
                    <div className="row">
                        <div className="col-md-4">
                            <h5><strong>PO Number</strong></h5>
                            <p>{purchaseOrder.incrementId}</p>
                            <h5><strong>Supplier</strong></h5>
                            <p>{purchaseOrder.supplierFirstname} {purchaseOrder.supplierLastname}</p>
                            <h5><strong>Email</strong></h5>
                            <p>{purchaseOrder.supplierEmail}</p>
                        </div>

                        {/* Billing Address */}
                        <div className="col-md-4">
                            <h5><strong>Billing Address</strong></h5>
                            {purchaseOrder.billingAddress ? (
                                <p>
                                    {purchaseOrder.billingAddress.street}, {purchaseOrder.billingAddress.city},
                                    {purchaseOrder.billingAddress.postcode}, {purchaseOrder.billingAddress.countryId}
                                </p>
                            ) : <p className="text-muted">N/A</p>}
                        </div>

                        {/* Shipping Address */}
                        <div className="col-md-4">
                            <h5><strong>Shipping Address</strong></h5>
                            {purchaseOrder.shippingAddress ? (
                                <p>
                                    {purchaseOrder.shippingAddress.street}, {purchaseOrder.shippingAddress.city},
                                    {purchaseOrder.shippingAddress.postcode}, {purchaseOrder.shippingAddress.countryId}
                                </p>
                            ) : <p className="text-muted">N/A</p>}
                        </div>
                    </div>
                </div>
            )}

            {/* Warehouse Addresses */}
            {warehouse && (
                <div className="card shadow-lg p-4 mb-4 warehouse-address-section">
                    <h4 className="text-primary"><FaWarehouse /> Warehouse Details</h4>
                    <div className="row">
                        <div className="col-md-6">
                            <h5 className="text-uppercase text-secondary">Warehouse Address</h5>
                            <p><strong>{warehouse.name}</strong></p>
                            <p>{warehouse.address}, {warehouse.city}, {warehouse.pincode}</p>
                            <p>{warehouse.email} | {warehouse.mobileNumber}</p>
                        </div>
                    </div>
                </div>
            )}

            {/* Debit Note Items with Batches */}
            <div className="mt-4">
                <h5 className="text-primary"><FaClipboardCheck /> Debit Note Items</h5>
                <div className="table-responsive">
                    <table className="table table-bordered table-hover">
                        <thead className="table-dark">
                            <tr>
                                <th>SKU</th>
                                <th>Name</th>
                                <th>Price (₹)</th>
                                <th>Qty Returned</th>
                                <th>GST (%)</th>
                                <th>Discount (₹)</th>
                                <th>Total (₹)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {debitNote.debitNoteItems?.map((item) => (
                                <React.Fragment key={item.id}>
                                    <tr>
                                        <td>{item.sku}</td>
                                        <td>{item.name}</td>
                                        <td>₹{(item.price ?? 0).toFixed(2)}</td>
                                        <td>{item.returnQty ?? item.qty ?? 0}</td>
                                        <td>{(item.taxPercent ?? 0).toFixed(2)}</td>
                                        <td>₹{(item.discountAmount ?? 0).toFixed(2)}</td>
                                        <td>₹{(item.rowTotal ?? 0).toFixed(2)}</td>
                                    </tr>

                                    {/* Batch Details (nested table) */}
                                    {item.debitNoteItemBatches?.length > 0 && (
                                        <tr>
                                            <td colSpan="7">
                                                <h6 className="mt-2"><FaClipboardCheck /> Batch Details</h6>
                                                <table className="table table-sm table-bordered">
                                                    <thead className="table-light">
                                                        <tr>
                                                            <th>Batch Number</th>
                                                            <th>Expiry Date</th>
                                                            <th>Manufacturing Date</th>
                                                            <th>Return Qty</th>
                                                            <th>MRP (₹)</th>
                                                            <th>Price (₹)</th>
                                                            <th>GST (%)</th>
                                                            <th>Total (₹)</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {item.debitNoteItemBatches.map((batch) => (
                                                            <tr key={batch.id}>
                                                                <td>{batch.batchNumber}</td>
                                                                <td>{batch.expiryDate}</td>
                                                                <td>{batch.manufacturingDate}</td>
                                                                <td>{batch.returnQty}</td>
                                                                <td>₹{(batch.mrp ?? 0).toFixed(2)}</td>
                                                                <td>₹{(batch.price ?? 0).toFixed(2)}</td>
                                                                <td>{(batch.taxPercent ?? 0).toFixed(2)}</td>
                                                                <td>₹{(batch.rowTotal ?? 0).toFixed(2)}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    )}
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>

            {/* Payment Summary */}
            <div className="mt-4 payment-summary">
                <h5 className="text-primary d-flex align-items-center">
                    <FaMoneyBillWave className="me-2" /> Payment Summary
                </h5>
                <table className="table table-bordered table-striped">
                    <tbody>
                        <tr>
                            <th className="bg-light">Subtotal</th>
                            <td>₹{(debitNote.subtotal ?? 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th className="bg-light">GST</th>
                            <td>₹{(debitNote.taxAmount ?? 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th className="bg-light">Shipping</th>
                            <td>₹{(debitNote.shippingCharges ?? 0).toFixed(2)}</td>
                        </tr>
                        <tr>
                            <th className="bg-light">Grand Total</th>
                            <td><strong className="text-success">₹{(debitNote.grandTotal ?? 0).toFixed(2)}</strong></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default DebitNoteDetails;