import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaExchangeAlt,
} from "react-icons/fa";
import { getGRNs } from "../../services/interbranchGrnService"; // ✅ Import API service

const InterbranchGRNList = () => {
  const [grns, setGRNs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    transferId: "", // Added transferId filter
    sourceBranchId: "",
    destinationBranchId: "",
    status: "",
    startDate: "",
    endDate: "",
    page: 0,
    size: 5,
  });

  const fetchGRNs = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getGRNs(filters); // ✅ Fixed API call
      if (response.statusCode === 200) {
        setGRNs(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch GRNs!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setGRNs([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchGRNs();
  }, [fetchGRNs]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaExchangeAlt /> Interbranch Goods Receipt Notes (GRN)</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Record and verify goods received from other branches with accurate quantity and status.
          </p>
        </div>
        <div>
          {/* <button className="btn btn-primary" onClick={() => navigate("/interbranch-transfers/create")}>
            <FaPlus /> Create Branch Transfer
          </button> */}
        </div>
      </div>

      {/* 🔍 Filters */}
      <div className="card shadow mb-3">
        <div className="card-body">
      <div className="row g-3">
        <div className="col-md-2">
          <input
            type="number"
            placeholder="Transfer ID"
            className="form-control"
            value={filters.transferId}
            onChange={(e) =>
              setFilters({ ...filters, transferId: e.target.value })
            }
          />
        </div>

        <div className="col-md-2">
          <input
            type="number"
            placeholder="Source Branch ID"
            className="form-control"
            value={filters.sourceBranchId}
            onChange={(e) =>
              setFilters({ ...filters, sourceBranchId: e.target.value })
            }
          />
        </div>

        <div className="col-md-2">
          <input
            type="number"
            placeholder="Destination Branch ID"
            className="form-control"
            value={filters.destinationBranchId}
            onChange={(e) =>
              setFilters({ ...filters, destinationBranchId: e.target.value })
            }
          />
        </div>

        <div className="col-md-2">
          <select
            className="form-select"
            value={filters.status}
            onChange={(e) => setFilters({ ...filters, status: e.target.value })}
          >
            <option value="">Select Status</option>
            <option value="PENDING">Pending</option>
            <option value="COMPLETED">Completed</option>
          </select>
        </div>

        <div className="col-md-2">
          <input
            type="date"
            className="form-control"
            value={filters.startDate}
            onChange={(e) =>
              setFilters({ ...filters, startDate: e.target.value })
            }
          />
        </div>

        <div className="col-md-2">
          <input
            type="date"
            className="form-control"
            value={filters.endDate}
            onChange={(e) =>
              setFilters({ ...filters, endDate: e.target.value })
            }
          />
        </div>

        <div className="col-md-2">
          <button className="btn btn-primary w-100" onClick={fetchGRNs}>
            <FaSearch /> Search
          </button>
        </div>
      </div>
      </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
      {/* 📊 Data Table */}
      {!loading && grns.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Transfer ID</th>
                <th>Source Branch</th>
                <th>Destination Branch</th>
                <th>Status</th>
                <th>Subtotal (₹)</th>
                <th>CGST (₹)</th>
                <th>SGST (₹)</th>
                <th>Grand Total (₹)</th>
                <th>Received At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {grns.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.transferId}</td>
                  <td>{record.sourceBranchName}</td>
                  <td>{record.destinationBranchName}</td>
                  <td>
                    <span className={`badge bg-${getStatusColor(record.status)}`}>
                      {record.status}
                    </span>
                  </td>
                  <td>₹{record.subtotal ? record.subtotal.toFixed(2) : "0.00"}</td>
                  <td>₹{record.cgstAmount ? record.cgstAmount.toFixed(2) : "0.00"}</td>
                  <td>₹{record.sgstAmount ? record.sgstAmount.toFixed(2) : "0.00"}</td>
                  <td>₹{record.grandTotal ? record.grandTotal.toFixed(2) : "0.00"}</td>
                  <td>{new Date(record.receivedAt).toLocaleString()}</td>
                  <td>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => navigate(`/interbranch-grns/view/${record.id}`)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && grns.length === 0 && (
        <div className="text-center mt-3">
          <h5>No GRNs Found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page <= 0} // ✅ Prevent negative pages
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page - 1 }))
          }
        >
          <FaArrowLeft /> Previous
        </button>

        <span>
          Page {filters.page + 1} of {totalPages}
        </span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page + 1 }))
          }
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

// ✅ Helper function to determine status badge color
const getStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "COMPLETED":
      return "success";
    default:
      return "secondary";
  }
};

export default InterbranchGRNList;