import config from "../config";

const getAuthToken = () => localStorage.getItem("authToken");

export const createOrderStatusHistory = async (payload) => {
  const response = await fetch(`${config.BASE_URL}/api/order-status-history`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`
    },
    body: JSON.stringify(payload)
  });
  if (!response.ok) {
    throw new Error("Failed to create order status history");
  }
  return response.json();
};
