import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getQuoteById } from "../services/quoteService";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  FaArrowLeft,
  FaBox,
  FaMoneyBillWave,
  FaClipboardList
} from "react-icons/fa";

const QuoteDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [quote, setQuote] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQuote = async () => {
      try {
        const response = await getQuoteById(id);
        if (response.statusCode === 200) {
          setQuote(response.data);
        } else {
          toast.error("Quote not found!");
          navigate("/quotes");
        }
      } catch (error) {
        toast.error("Error fetching quote details.");
        navigate("/quotes");
      } finally {
        setLoading(false);
      }
    };
    fetchQuote();
  }, [id, navigate]);

  if (loading) return <h5 className="text-center mt-4">Loading quote details... 🌀</h5>;
  if (!quote) return <h5 className="text-center mt-4 text-danger">Quote not found ❌</h5>;

  return (
    <div className="container">
      {/* Back and Action Buttons */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-3">
        <h1 className="welcomeTitle text-primary"><FaClipboardList /> Quote ID #{quote.entityId}</h1>
        
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <button className="btn btn-dark" onClick={() => navigate("/quotes")}>
            <FaArrowLeft /> Back to Quotes
          </button>
          <button className="btn btn-warning">
            Edit
          </button>
          <button className="btn btn-secondary">More Actions ▼</button>
        </div>
      </div>

      {/* Quote Summary Section */}
      <div className="row mt-3">
        <div className="col-12">
          <div className="card shadow p-4 text-black" style={{ backgroundColor: "#009DFF" }}>
            <div className="row">
              <div className="col-md-4">
                <h5>Email ID</h5>
                <p>{quote.customerEmail}</p>
                <h5>Contact No</h5>
                <p>+91 9549093209</p>
              </div>
              <div className="col-md-4">
                <h5>Billing Address</h5>
                <p>{quote.quoteAddresses[0]?.street}, {quote.quoteAddresses[0]?.city}, {quote.quoteAddresses[0]?.postcode}, {quote.quoteAddresses[0]?.countryId}</p>
              </div>
              <div className="col-md-4">
                <h5>Shipping Address</h5>
                <p>{quote.quoteAddresses[1]?.street}, {quote.quoteAddresses[1]?.city}, {quote.quoteAddresses[1]?.postcode}, {quote.quoteAddresses[1]?.countryId}</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Quote Items Section */}
      <div className="card shadow p-3 mt-4">
        <h4 className="text-primary">
          <FaBox /> Quote Items | Total Units: {quote.itemsQty}
        </h4>
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>Product</th>
                <th>Price</th>
                <th>UOM</th>
                <th>Qty</th>
                <th>Subtotal</th>
              </tr>
            </thead>
            <tbody>
              {quote.quoteItems.map((item) => (
                <tr key={item.itemId}>
                  <td>{item.name}</td>
                  <td>₹{item.price.toFixed(2)}</td>
                  <td>Piece</td>
                  <td>{item.qty}</td>
                  <td>₹{item.subtotal.toFixed(2)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {/* Payment Details */}
      <div className="card shadow p-4 mt-4">
        <h4 className="text-primary">
          <FaMoneyBillWave /> Payment Details
        </h4>
        <div className="row">
          <div className="col-md-6">
            <p><strong>Subtotal:</strong> ₹{quote.subtotal.toFixed(2)}</p>
            <p><strong>Grand Total:</strong> <strong className="text-success">₹{quote.grandTotal.toFixed(2)}</strong></p>
          </div>
          <div className="col-md-6">
            <p><strong>Payment Terms:</strong> {quote.paymentTerms || "N/A"}</p>
            <p><strong>Credit Period:</strong> {quote.creditPeriod || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteDetails;
