import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";  // ✅ Import ToastContainer
import "react-toastify/dist/ReactToastify.css";  // ✅ Import Toast styles

import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import ProductList from "./pages/ProductList";
import EditProduct from "./pages/EditProduct";
import AddProduct from "./pages/AddProduct";
import CategoryList from "./pages/CategoryList";
import AddCategory from "./pages/AddCategory";
import EditCategory from "./pages/EditCategory";
import OrderList from "./pages/OrderList";
import OrderDetails from "./pages/OrderDetails";
import QuoteDetails from "./pages/QuoteDetails";
import QuoteList from "./pages/QuoteList";
import CustomerList from "./pages/CustomerList";
import CustomerDetails from "./pages/CustomerDetails";
import PickAndPackList from "./pages/PickAndPackList";
import PickAndPackDetails from "./pages/PickAndPackDetails";
import WarehouseList from "./pages/WarehouseList";
import WarehouseView from "./pages/WarehouseView";
import WarehouseForm from "./pages/WarehouseForm";
import InventoryList from "./pages/InventoryList";
import CreditNoteList from "./pages/CreditNoteList";
import CreditNoteDetails from "./pages/CreditNoteDetails";
import BrandList from "./pages/BrandList";
import AddBrand from "./pages/AddBrand";
import EditBrand from "./pages/EditBrand";
import ManufacturerList from "./pages/manufacturers/ManufacturerList";
import AddManufacturer from "./pages/manufacturers/AddManufacturer";
import EditManufacturer from "./pages/manufacturers/EditManufacturer";
import InventoryLoggerList from "./pages/inventory/InventoryLoggerList";
import SupplierList from "./pages/seller/SupplierList";
import SupplierView from "./pages/seller/SupplierView";
import CreateSupplier from "./pages/seller/CreateSupplier"; // added import
import PurchaseOrderList from "./pages/purchase/PurchaseOrderList";
import PurchaseOrderDetails from "./pages/purchase/PurchaseOrderDetails";
import GRNList from "./pages/grn/GRNList";
import GRNDetails from "./pages/grn/GRNDetails";
import UserManagement from "./pages/user/UserManagement";
import AddUser from "./pages/user/AddUser";
import RoleManagement from "./pages/role/RoleManagement";
import EditUser from "./pages/user/EditUser";
import AddRole from "./pages/role/AddRole";
import PermissionManagement from "./pages/permission/PermissionManagement";
import AddPermission from "./pages/permission/AddPermission";
import EditPermission from "./pages/permission/EditPermission";
import EditRole from "./pages/role/EditRole";
import CreateGRN from "./pages/grn/CreateGRN";
import InventoryDetails from "./pages/InventoryDetails";
import InventoryCharts from "./pages/InventoryCharts";
import InventoryForecastReport from "./pages/InventoryForecastReport";
import BatchView from "./pages/BatchView";
import DebitNoteDetails from "./pages/debitnote/DebitNoteDetails";
import DebitNoteList from "./pages/debitnote/DebitNoteList";
import InterBranchTransferDetails from "./pages/Interbranch/InterBranchTransferDetails";
import InterBranchTransferList from "./pages/Interbranch/InterBranchTransferList";
import CreateInterBranchTransfer from "./pages/Interbranch/CreateInterBranchTransfer";
import CreatePickAndPack from "./pages/CreatePickAndPack";
import CourierList from "./pages/courier/CourierList";
import CourierAdd from "./pages/courier/CourierAdd";
import CourierEdit from "./pages/courier/CourierEdit";
import InterbranchGRNList from "./pages/Interbranch/InterbranchGRNList";
import InterbranchGRNView from "./pages/Interbranch/InterbranchGRNView";
import ProductView from './pages/ProductView';
import CategoryView from "./pages/CategoryView";
import CountryCurrencyMaster from "./pages/admin/CountryCurrencyMaster"; // added import
import AddCountryCurrency from "./pages/admin/AddCountryCurrency.jsx";  // ensure correct import
import EditCountryCurrency from "./pages/admin/EditCountryCurrency.jsx";  // corrected import
import CreateCustomer from "./pages/customers/CreateCustomer";
import CustomerGroupList from "./pages/customer-groups/CustomerGroupList";
import AddCustomerGroup from "./pages/customer-groups/AddCustomerGroup";
import EditCustomerGroup from "./pages/customer-groups/EditCustomerGroup";
import CountryRegionList from "./pages/country-regions/CountryRegionList";
import AddCountryRegion from "./pages/country-regions/AddCountryRegion";
import EditCountryRegion from "./pages/country-regions/EditCountryRegion";

const App = () => {
  return (
    <Router>
      <ToastContainer />  {/* ✅ Add ToastContainer at the top level */}
      <MainLayout />
    </Router>
  );
};

const MainLayout = () => {
  const location = useLocation();
  const hideNavbarFooter = location.pathname === "/";

  return (
    <div className="d-flex flex-column min-vh-100">
      {!hideNavbarFooter && <Navbar />}

      <div className="container flex-grow-1">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/products" element={<ProductList />} />
          <Route path="/products/add" element={<AddProduct />} />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route path="/products/view/:id" element={<ProductView />} />
          <Route path="/categories" element={<CategoryList />} />
          <Route path="/categories/add" element={<AddCategory />} />
          <Route path="/categories/edit/:id" element={<EditCategory />} />
          <Route path="/categories/view/:id" element={<CategoryView />} />
          <Route path="/orders" element={<OrderList />} />
          <Route path="/orders/:id" element={<OrderDetails />} />
          <Route path="/quotes" element={<QuoteList />} />
          <Route path="/quotes/view/:id" element={<QuoteDetails />} />
          <Route path="/customers" element={<CustomerList />} />
          <Route path="/customers/create" element={<CreateCustomer />} />  {/* new route */}
          <Route path="/customers/view/:id" element={<CustomerDetails />} />
          <Route path="/pick-and-pack" element={<PickAndPackList />} />
          <Route path="/pick-and-pack/view/:id" element={<PickAndPackDetails />} />
          <Route path="/pick-and-pack/create" element={<CreatePickAndPack />} />
          <Route path="/warehouse" element={<WarehouseList />} />
          <Route path="/warehouse/view/:id" element={<WarehouseView />} />
          <Route path="/warehouse/add" element={<WarehouseForm />} />
          <Route path="/warehouse/edit/:id" element={<WarehouseForm />} />
          <Route path="/inventory" element={<InventoryList />} />
          <Route path="/inventory/:sku/details" element={<InventoryDetails />} />
          <Route path="/inventory/:sku/charts" element={<InventoryCharts />} />
          <Route path="/inventory/:sku/:warehouseId/batch-view" element={<BatchView />} />
          <Route path="/forecast" element={<InventoryForecastReport />} />
          <Route path="/credit-notes" element={<CreditNoteList />} />
          <Route path="/credit-notes/view/:id" element={<CreditNoteDetails />} />
          <Route path="/brands" element={<BrandList />} />
          <Route path="/brands/add" element={<AddBrand />} />
          <Route path="/brands/edit/:id" element={<EditBrand />} />
          <Route path="/manufacturers" element={<ManufacturerList />} />
          <Route path="/manufacturers/add" element={<AddManufacturer />} />
          <Route path="/manufacturers/edit/:id" element={<EditManufacturer />} />
          <Route path="/inventory-logs" element={<InventoryLoggerList />} />
          <Route path="/suppliers" element={<SupplierList />} />
          <Route path="/suppliers/view/:id" element={<SupplierView />} />
          <Route path="/suppliers/create" element={<CreateSupplier />} /> {/* new route */}
          {/* 🔵 Purchase Order Routes */}
          <Route path="/purchase-orders" element={<PurchaseOrderList />} />
          <Route path="/purchase-orders/:id" element={<PurchaseOrderDetails />} />

          <Route path="/interbranch-transfers" element={<InterBranchTransferList />} />
          <Route path="/interbranch-transfers/view/:id" element={<InterBranchTransferDetails />} />
          <Route path="/interbranch-transfers/create" element={<CreateInterBranchTransfer />} />

          <Route path="/couriers" element={<CourierList />} />
          <Route path="/couriers/add" element={<CourierAdd />} />
          <Route path="/couriers/edit/:id" element={<CourierEdit />} />

          <Route path="/grn" element={<GRNList />} />
          <Route path="/grn/view/:id" element={<GRNDetails />} />
          <Route path="/create-grn" element={<CreateGRN />} />
          {/* 🔹 Debit Note Routes */}
          <Route path="/debit-notes" element={<DebitNoteList />} />
          <Route path="/debit-note/view/:id" element={<DebitNoteDetails />} />

          <Route path="/user-management" element={<UserManagement />} />
          <Route path="/role-management" element={<RoleManagement />} />
          <Route path="/add-user" element={<AddUser />} />
          <Route path="/users/edit/:id" element={<EditUser />} />
          <Route path="/add-role" element={<AddRole />} />
          <Route path="/edit-role/:id" element={<EditRole />} />
          <Route path="/permission-management" element={<PermissionManagement />} />
          <Route path="/add-permission" element={<AddPermission />} />
          <Route path="/edit-permission/:id" element={<EditPermission />} />
          {/* 🔹 Interbranch GRN Routes */}
          <Route path="/interbranch-grns" element={<InterbranchGRNList />} />
          <Route path="/interbranch-grns/view/:id" element={<InterbranchGRNView />} />
          <Route path="/admin/country-currency-master" element={<CountryCurrencyMaster />} /> {/* new route */}
          <Route path="/admin/country-currency-master/add" element={<AddCountryCurrency />} />
          <Route path="/admin/country-currency-master/edit/:id" element={<EditCountryCurrency />} /> {/* new route */}
          <Route path="/customer-groups" element={<CustomerGroupList />} />
          <Route path="/customer-groups/add" element={<AddCustomerGroup />} />
          <Route path="/customer-groups/edit/:id" element={<EditCustomerGroup />} />
          {/* New region routes */}
          <Route path="/country-regions" element={<CountryRegionList />} />
          <Route path="/country-regions/add" element={<AddCountryRegion />} />
          <Route path="/country-regions/edit/:id" element={<EditCountryRegion />} />
        </Routes>
      </div>

      {!hideNavbarFooter && <Footer />}
    </div>
  );
};

export default App;
