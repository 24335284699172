import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getCourierById, updateCourier } from "../../services/courierService";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const CourierEdit = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  const [courierData, setCourierData] = useState({
    courierId: null,
    revenueStoreId: 0,
    courierName: "",
    status: true,
    updatedBy: null,
  });

  useEffect(() => {
    const fetchCourier = async () => {
      try {
        const response = await getCourierById(id);
        if (response.statusCode === 200) {
          const data = response.data;
          setCourierData({
            courierId: data.courierId,
            revenueStoreId: data.revenueStoreId,
            courierName: data.courierName,
            status: data.status === 1, // Convert status to boolean
            updatedBy: data.updatedBy,
          });
        } else {
          toast.error("❌ Failed to fetch courier details.");
          navigate("/couriers");
        }
      } catch (error) {
        toast.error("❌ Failed to fetch courier details.");
        navigate("/couriers");
      } finally {
        setLoading(false);
      }
    };

    fetchCourier();
  }, [id, navigate]);

  useEffect(() => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const userId = userDetails?.userId;
    if (userId) {
      setCourierData((prevData) => ({ ...prevData, updatedBy: userId }));
    }
  }, []);

  // ✅ Handle Input Change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCourierData({ ...courierData, [name]: name === "status" ? value === "1" : value });
  };

  // ✅ Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Convert boolean status back to 0 or 1
      const payload = { ...courierData, status: courierData.status ? 1 : 0 };
      await updateCourier(id, payload);
      toast.success("✅ Courier updated successfully!");
      navigate("/couriers");
    } catch (error) {
      toast.error("❌ Failed to update courier.");
    }
  };

  if (loading) return <h4>Loading...</h4>;

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-primary">✏️ Edit Courier</h2>
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          <FaArrowLeft /> Back
        </button>
      </div>
      <form onSubmit={handleSubmit} className="mt-4">
        

        <div className="mb-3">
          <label className="form-label">Courier Name</label>
          <input
            type="text"
            name="courierName"
            value={courierData.courierName}
            onChange={handleChange}
            className="form-control"
            required
          />
        </div>

        <div className="mb-3">
          <label className="form-label">Status</label>
          <select
            name="status"
            value={courierData.status ? "1" : "0"} // Convert boolean to string for select input
            onChange={handleChange}
            className="form-control"
          >
            <option value="1">Active</option>
            <option value="0">Inactive</option>
          </select>
        </div>

        <div className="d-flex justify-content-between">
          <button type="submit" className="btn btn-primary">Update</button>
          <button type="button" className="btn btn-secondary ms-2" onClick={() => navigate("/couriers")}>
            Cancel
          </button>
        </div>
      </form>
    </div>
  );
};

export default CourierEdit;