import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getRegionById, updateRegion } from "../../services/countryRegionService";
import { toast } from "react-toastify";

const EditCountryRegion = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    countryId: "",
    code: "",
    defaultName: "",
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    getRegionById(id)
      .then((res) => {
        setFormData(res.data || res);
        setLoading(false);
      })
      .catch(() => {
        toast.error("Failed to load region");
        setLoading(false);
      });
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateRegion(id, formData);
      toast.success("Region updated successfully!");
      navigate("/country-regions");
    } catch (error) {
      toast.error(error.message || "Failed to update region.");
    }
  };

  if (loading) return <div className="container mt-5">Loading...</div>;

  return (
    <div className="container my-5">
      {/* Card container with header indicating mandatory fields */}
      <div className="card shadow-lg border-0">
        <div className="card-header bg-info text-white">
          <h2 className="mb-0">Edit Country Region</h2>
          <small className="d-block text-white-50">Fields marked with <span className="text-danger">*</span> are mandatory.</small>
        </div>
        <div className="card-body">
          <div className="mb-4">
            {/* Removed stray closing tag */}
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Country ID</label>
              <input
                type="text"
                className="form-control"
                name="countryId"
                value={formData.countryId}
                onChange={handleChange}
                placeholder="Enter Country ID"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Region Code</label>
              <input
                type="text"
                className="form-control"
                name="code"
                value={formData.code}
                onChange={handleChange}
                placeholder="Enter Region Code"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Default Name</label>
              <input
                type="text"
                className="form-control"
                name="defaultName"
                value={formData.defaultName}
                onChange={handleChange}
                placeholder="Enter Default Name"
              />
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-success px-4">
                Update
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditCountryRegion;
