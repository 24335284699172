import React, { useEffect, useState, useCallback } from "react";
import { getProducts, deleteProduct } from "../services/productService";
import { getBrands } from "../services/brandService";
import { getManufacturers } from "../services/manufacturerService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FaPlus, FaEdit, FaTrash, FaSearch, FaArrowLeft, FaArrowRight, FaBox } from "react-icons/fa";
import "../css/ProductList.css";
import "../css/animate.css"; // Added for animations

const initialFilters = {
  id: "",
  sku: "",
  name: "",
  brandName: "",
  manufacturerName: "",
  categoryName: "",
  enableDisable: "",
  page: 0,
  size: 10
};

const ProductList = () => {
  const navigate = useNavigate();
  const [products, setProducts] = useState([]);
  const [brands, setBrands] = useState([]); // new state for brands
  const [manufacturers, setManufacturers] = useState([]); // new state for manufacturers
  const [inputFilters, setInputFilters] = useState(initialFilters);
  const [filters, setFilters] = useState(initialFilters);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [userPermissions, setUserPermissions] = useState([]);

  // Fetch products from API
  const fetchProducts = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {
      const data = await getProducts(filters);
      setProducts(data.data.content || []);
      setTotalPages(data.data.totalPages);
    } catch (err) {
      setError("Failed to fetch products. Please try again.");
      toast.error("Error fetching products! 🚨");
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchProducts();
  }, [fetchProducts]);

  // Fetch brands for the brand filter dropdown
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const res = await getBrands();
        setBrands(res.data.content || []); // updated: use res.data.content
      } catch (error) {
        toast.error("Error fetching brands!");
      }
    };
    fetchBrands();
  }, []);

  // Fetch manufacturers for the manufacturer filter dropdown
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const res = await getManufacturers();
        setManufacturers(res.data.content || []); // using res.data.content
      } catch (error) {
        toast.error("Error fetching manufacturers!");
      }
    };
    fetchManufacturers();
  }, []);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    // NEW: Load user's permissions
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  // Handle product deletion
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        await deleteProduct(id);
        toast.success("Product deleted successfully! ✅");
        fetchProducts();
      } catch (err) {
        toast.error("Failed to delete product! ❌");
      }
    }
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setInputFilters({ ...inputFilters, [name]: value, page: 0 });
  };

  return (
    <div className="container" >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaBox /> Product Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Create, update, and organize your product catalog with complete control over listings, pricing, and visibility.
          </p>
        </div>
        <div>
        {userPermissions.includes("ADD_PRODUCT") && (
          <button className="btn btn-primary" onClick={() => navigate("/products/add")}>
            <FaPlus /> Add Product
          </button>
        )}
        </div>
      </div>

      {/* Updated Filter UI */}
      <div className="card shadow mb-3">   
        <div className="card-body">
          <form>
            <div className="row g-3">
              <div className="col-md-2">
                <input type="number" className="form-control" placeholder="ID" name="id" value={inputFilters.id} onChange={handleSearchChange} />
              </div>
              <div className="col-md-2">
                <input type="text" className="form-control" placeholder="SKU" name="sku" value={inputFilters.sku} onChange={handleSearchChange} />
              </div>
              <div className="col-md-2">
                <input type="text" className="form-control" placeholder="Name" name="name" value={inputFilters.name} onChange={handleSearchChange} />
              </div>
              {/* Replace Brand Name text input with a searchable dropdown */}
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Brand Name"
                  name="brandName"
                  value={inputFilters.brandName}
                  onChange={handleSearchChange}
                  list="brandOptions"
                />
                <datalist id="brandOptions">
                  {brands.map((b) => (
                    <option value={b.name} key={b.id} />
                  ))}
                </datalist>
              </div>
              {/* Replace Manufacturer Name input with searchable dropdown */}
              <div className="col-md-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Manufacturer Name"
                  name="manufacturerName"
                  value={inputFilters.manufacturerName}
                  onChange={handleSearchChange}
                  list="manufacturerOptions"
                />
                <datalist id="manufacturerOptions">
                  {manufacturers.map((m) => (
                    <option value={m.manufacturerName} key={m.id} />
                  ))}
                </datalist>
              </div>
              <div className="col-md-2">
                <input type="text" className="form-control" placeholder="Category Name" name="categoryName" value={inputFilters.categoryName} onChange={handleSearchChange} />
              </div>
              {/* Replace Enable/Disable input with dropdown */}
              <div className="col-md-2">
                <select className="form-select" name="enableDisable" value={inputFilters.enableDisable} onChange={handleSearchChange}>
                  <option value="">Select Status</option>
                  <option value="1">Enable</option>
                  <option value="0">Disable</option>
                </select>
              </div>
              <div className="col-md-2">
                <button
                  type="button"
                  className="btn btn-primary w-100"
                  onClick={() => setFilters(inputFilters)}
                >
                  <FaSearch /> Search
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="card shadow mb-3">   
      <div className="card-body">

      {/* Product Table */}
      <div className="table-responsive">
        <table className="table table-bordered table-hover table-striped">
          <thead className="table-dark">
            <tr>
              <th>ID</th>
              <th>SKU</th>
              <th>Category ID</th>
              <th>Name</th>
              <th>Price</th>
              <th>MRP</th>
              <th>Tax Percent</th>
              <th>HSN Code</th>
              <th>Description</th>
              <th>Short Description</th>
              <th>Special Price</th>
              <th>URL Key</th>
              <th>Brand</th>
              <th>Brand Name</th>
              <th>Manufacturer Code</th>
              <th>Manufacturer Name</th>
              <th>Parent Category</th>
              <th>Parent Category ID</th>
              <th>Category Name</th>
              <th>Generic Name</th>
              <th>Country Of Origin</th>
              <th>Image URL</th>
              <th>Created At</th>
              <th>Updated At</th>
              <th>Enable/Disable</th>
              <th>Subcategory ID</th>
              <th>Subcategory</th>
              <th>Country Of Origin Name</th>
              <th>Primary UOM</th>
              <th>Alternate UOMs</th>
              <th>Slug</th>
              <th>Meta Title</th>
              <th>Meta Description</th>
              <th>Meta Keywords</th>
              <th>Canonical URL</th>
              <th>Image Alt Text</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="37" className="text-center">Loading products... 🌀</td>
              </tr>
            ) : error ? (
              <tr>
                <td colSpan="37" className="text-center text-danger">{error}</td>
              </tr>
            ) : products.length > 0 ? (
              products.map((product) => (
                <tr key={product.id}>
                  <td>{product.id}</td>
                  <td>{product.sku}</td>
                  <td>{product.categoryId}</td>
                  <td>{product.name}</td>
                  <td>₹{product.price.toFixed(2)}</td>
                  <td>₹{product.mrp.toFixed(2)}</td>
                  <td>{product.taxPercent !== null ? `${product.taxPercent}%` : "N/A"}</td>
                  <td>{product.hsnCode || "N/A"}</td>
                  <td>{product.description}</td>
                  <td>{product.shortDescription}</td>
                  <td>{product.specialPrice ? product.specialPrice : "N/A"}</td>
                  <td>{product.urlKey || "N/A"}</td>
                  <td>{product.brand || "N/A"}</td>
                  <td>{product.brandName || "N/A"}</td>
                  <td>{product.manufacturerCode || "N/A"}</td>
                  <td>{product.manufacturerName || "N/A"}</td>
                  <td>{product.parentCategory || "N/A"}</td>
                  <td>{product.parentCategoryId}</td>
                  <td>{product.categoryName || "N/A"}</td>
                  <td>{product.genericName || "N/A"}</td>
                  <td>{product.countryOfOrigin || "N/A"}</td>
                  <td>{product.imageUrl || "N/A"}</td>
                  <td>{product.createdAt}</td>
                  <td>{product.updatedAt}</td>
                  <td>
                    {product.enableDisable === 1 ? (
                      <span className="badge bg-success">Enabled</span>
                    ) : (
                      <span className="badge bg-danger">Disabled</span>
                    )}
                  </td>
                  <td>{product.subcategoryId || "N/A"}</td>
                  <td>{product.subcategory || "N/A"}</td>
                  <td>{product.countryOfOriginName || "N/A"}</td>
                  <td>{product.primaryUom}</td>
                  <td>{product.alternateUoms || "N/A"}</td>
                  <td>{product.slug || "N/A"}</td>
                  <td>{product.metaTitle || "N/A"}</td>
                  <td>{product.metaDescription || "N/A"}</td>
                  <td>{product.metaKeywords || "N/A"}</td>
                  <td>{product.canonicalUrl || "N/A"}</td>
                  <td>{product.imageAltText || "N/A"}</td>
                  <td>
                    {userPermissions.includes("VIEW_PRODUCT") && (
                      <button className="btn btn-sm btn-info me-2" onClick={() => navigate(`/products/view/${product.id}`)}>
                        View
                      </button>
                    )}
                    {userPermissions.includes("EDIT_PRODUCT") && (
                      <button className="btn btn-sm btn-warning me-2" onClick={() => navigate(`/products/edit/${product.id}`)}>
                        <FaEdit /> Edit
                      </button>
                    )}
                    {userPermissions.includes("DELETE_PRODUCT") && (
                      <button className="btn btn-sm btn-danger" onClick={() => handleDelete(product.id)}>
                        <FaTrash /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="37" className="text-center">No products found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      {/* Pagination Controls */}
      {!loading && !error && (
        <div className="d-flex justify-content-between align-items-center">
          <button
            className="btn btn-outline-secondary"
            disabled={filters.page <= 0}
            onClick={() => {
              const newPage = filters.page - 1;
              const updated = { ...filters, page: newPage };
              setFilters(updated);
              setInputFilters(updated);
            }}
          >
            <FaArrowLeft /> Previous
          </button>

          <span>
            Page {filters.page + 1} of {totalPages}
          </span>

          <button
            className="btn btn-outline-secondary"
            disabled={filters.page + 1 >= totalPages || totalPages === 0}
            onClick={() => {
              const newPage = filters.page + 1;
              const updated = { ...filters, page: newPage };
              setFilters(updated);
              setInputFilters(updated);
            }}
          >
            Next <FaArrowRight />
          </button>
        </div>
        
      )}
    </div>
    </div>
    </div>
  );
};

export default ProductList;