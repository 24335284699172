import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/permissions`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Request Handler
const apiRequest = async (endpoint, method = "GET", body = null) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }

    return response.status !== 204 ? response.json() : {}; // Handle No Content (204)
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

// ✅ Search Permissions (With Filters & Pagination) - Optimized
export const getPermissions = async (filters) => {
  const queryParams = new URLSearchParams();

  if (filters.name?.trim()) queryParams.append("name", filters.name); // Exclude empty `name`
  queryParams.append("page", filters.page);
  queryParams.append("size", filters.size);

  return apiRequest(`/search?${queryParams.toString()}`);
};

// ✅ Fetch All Permissions with Pagination
export const getPermissionList = async (filters) => {
  const queryParams = new URLSearchParams();

  queryParams.append("page", filters.page);
  queryParams.append("size", filters.size);

  return apiRequest(`?${queryParams.toString()}`);
};

// ✅ Fetch Permission by ID
export const getPermissionById = async (id) => {
  return apiRequest(`/${id}`);
};

// ✅ Create a New Permission
export const createPermission = async (data) => {
  return apiRequest("/", "POST", data);
};

// ✅ Update Permission
export const updatePermission = async (id, data) => {
  return apiRequest(`/${id}`, "PUT", data);
};

// ✅ Delete Permission
export const deletePermission = async (id) => {
  return apiRequest(`/${id}`, "DELETE");
};
