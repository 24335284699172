import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaUserShield, FaSearch, FaEdit, FaPlus, FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { getRoles } from "../../services/roleService"; // Service File

const RoleManagement = () => {
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  // Filters & Pagination
  const [filters, setFilters] = useState({
    name: "", // ✅ FIXED: Use correct key that API expects
    page: 0,
    size: 10, // Default page size
  });

  // Fetch Role Records
  const fetchRoleRecords = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getRoles(filters);
      if (response.statusCode === 200) {
        setRoles(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch roles!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setRoles([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchRoleRecords();
  }, [fetchRoleRecords]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaUserShield /> Role Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">Defines and assigns permissions to user roles for secure and organized access control.</p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/add-role")}>
            <FaPlus /> Add Roles
          </button>
        </div>
      </div>
      <div className="card shadow mb-3">
        <div className="card-body">

          {/* 🔍 Filters */}
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                placeholder="🔎 Search Role Name"
                className="form-control"
                value={filters.name} // ✅ FIXED: Correct API key for name filtering
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchRoleRecords}>
                <FaSearch /> Search
              </button>
            </div>

            {/* <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={() => navigate("/add-role")}>
                <FaPlus /> Add Role
              </button>
            </div> */}
          </div>
        </div>
      </div>
      <div className="card shadow mb-3">
      <div className="card-body">
      {/* 📊 Data Table */}
      {!loading && roles.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Role Name</th>
                <th>Permissions</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {roles.map((role) => (
                <tr key={role.id}>
                  <td>{role.id}</td>
                  <td>{role.name}</td>
                  <td>{role.permissions ? role.permissions.join(", ") : "No Permissions"}</td> {/* ✅ FIXED: Correct permission display */}
                  <td>
                    <button
                      className="btn btn-warning btn-sm me-2"
                      onClick={() => navigate(`/edit-role/${role.id}`)}
                    >
                      <FaEdit /> Edit
                    </button>
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && roles.length === 0 && (
        <div className="text-center mt-3">
          <h5>No roles found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default RoleManagement;
