import React, { useEffect, useState } from "react";
import { getCategoryById, searchCategories, updateCategory } from "../services/categoryService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEdit, FaArrowLeft } from "react-icons/fa";

const EditCategory = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState({
    entityId: "",
    categoryName: "",
    parentId: 0,
    isActive: 1,
    level: 2,
    description: "",
    slug: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    canonicalUrl: "",
    schemaMarkupJson: "{}",
  });

  const [categories, setCategories] = useState([]); // Store list of categories

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        // Directly call getCategoryById and set the category
        const response = await getCategoryById(id);
        setCategory(response.data);
      } catch (error) {
        toast.error("Error fetching category details.");
        navigate("/categories");
      }
    };

    // Updated: fetch categories using searchCategories for parent category dropdown
    const fetchCategories = async () => {
      try {
        const data = await searchCategories({ isActive: 1, page: 0, size: 100 });
        setCategories(data.data.content || []);
      } catch (error) {
        toast.error("Error fetching categories.");
      }
    };

    fetchCategory();
    fetchCategories();
  }, [id, navigate]);

  const handleChange = (e) => {
    setCategory({ ...category, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCategory(id, category);
      toast.success("Category updated successfully! ✅");
      navigate("/categories");
    } catch (err) {
      toast.error("Failed to update category! ❌");
    }
  };

  return (
    <div className="container-fluid px-5 py-4">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">
          <FaEdit className="me-2" /> Edit Category
        </h2>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/categories")}>
          <FaArrowLeft /> Back to Categories
        </button>
      </div>

      <div className="card shadow-lg p-4">
        <form onSubmit={handleSubmit} className="row g-4">
          
          <h5 className="text-primary border-bottom pb-2">📂 Category Details</h5>
          <div className="col-md-6">
            <label className="form-label">Category Name</label>
            <input type="text" className="form-control" name="categoryName" value={category.categoryName} onChange={handleChange} required />
          </div>
          {/* New Status drop down for isActive */}
          <div className="col-md-6">
            <label className="form-label">Status</label>
            <select className="form-select" name="isActive" value={category.isActive} onChange={handleChange}>
              <option value={1}>Enable</option>
              <option value={0}>Disable</option>
            </select>
          </div>
          
          {/* Parent Category Dropdown with search */}
          <div className="col-md-6">
            <label className="form-label">Parent Category</label>
            <input
              type="text"
              className="form-control"
              placeholder="Search and select parent category"
              list="parentCategoryOptions"
              name="parentId"
              value={
                category.parentId === 0
                  ? "None (Top-level Category)"
                  : categories.find((cat) => cat.entityId === Number(category.parentId))?.categoryName || ""
              }
              onChange={(e) => {
                // Update parentId based on the selected category name (case-insensitive)
                const selected = categories.find(
                  (cat) => cat.categoryName.toLowerCase() === e.target.value.toLowerCase()
                );
                setCategory({ ...category, parentId: selected ? selected.entityId : 0 });
              }}
            />
            <datalist id="parentCategoryOptions">
              <option value="None (Top-level Category)" />
              {categories.map((cat) => (
                <option key={cat.entityId} value={cat.categoryName} />
              ))}
            </datalist>
          </div>

          <h5 className="text-primary border-bottom pb-2">📝 Description</h5>
          <div className="col-12">
            <label className="form-label">Description</label>
            <textarea className="form-control" rows="4" name="description" value={category.description} onChange={handleChange}></textarea>
          </div>

          <h5 className="text-primary border-bottom pb-2">📈 SEO Details</h5>
          <div className="col-md-6">
            <label className="form-label">Slug</label>
            <input type="text" className="form-control" name="slug" value={category.slug} onChange={handleChange} required />
          </div>
          <div className="col-md-6">
            <label className="form-label">Meta Title</label>
            <input type="text" className="form-control" name="metaTitle" value={category.metaTitle} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Meta Description</label>
            <input type="text" className="form-control" name="metaDescription" value={category.metaDescription} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Meta Keywords</label>
            <input type="text" className="form-control" name="metaKeywords" value={category.metaKeywords} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Canonical URL</label>
            <input type="text" className="form-control" name="canonicalUrl" value={category.canonicalUrl} onChange={handleChange} />
          </div>

          <div className="col-12 text-center mt-4">
            <button type="submit" className="btn btn-lg btn-primary">✅ Update Category</button>
            <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/categories")}>❌ Cancel</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditCategory;
