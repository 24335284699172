import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/grns`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Handler
const apiRequest = async (endpoint, method = "GET", body = null, isBlob = false) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }

    if (isBlob) return response.blob(); // For file downloads

    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

// ✅ Fetch all GRN records with pagination
export const getGRNList = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return apiRequest(`/search?${query}`);
};

// ✅ Fetch GRN details by ID
export const getGRNById = async (id) => {
  return apiRequest(`/${id}`);
};

export const getPurchaseOrderById = async (poNumber) => {
  return apiRequest(`/by-po-number/${poNumber}`); // ✅ Ensure correct endpoint
};


// ✅ Update GRN Status
export const updateGRNStatus = async (data) => {
  return apiRequest("/update-status", "PATCH", data);
};

// ✅ Create a New GRN
export const createGRN = async (data) => {
  return apiRequest("", "POST", data); // ✅ Use "" instead of "/" to prevent double slashes
};

// ✅ Approve GRN by ID
export const approveGRNById = async (id) => {
  return apiRequest(`/${id}/approve`, "POST");
};

// New function for Full Return to Vendor
export const fullReturnGRN = async (payload) => {
  try {
    const response = await fetch(`${config.BASE_URL}/api/debit-notes/full`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${getAuthToken()}`,
      },
      body: JSON.stringify(payload),
    });
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Full Return failed");
    }
    return response.status !== 204 ? response.json() : {};
  } catch (error) {
    console.error("fullReturnGRN API Error:", error.message);
    throw error;
  }
};


// ✅ Download GRN Invoice
export const downloadGRNInvoice = async (id) => {
  try {
    const blob = await apiRequest(`/invoices/${id}/download`, "GET", null, true);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `GRN_invoice_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Cleanup Object URL
    setTimeout(() => window.URL.revokeObjectURL(url), 100);

    return { success: true, message: "GRN Invoice downloaded successfully!" };
  } catch (error) {
    return { success: false, message: "Error downloading GRN invoice." };
  }
};
