import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/quotes/search`;

// ✅ Get the auth token from localStorage
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Fetch quotes with filters
export const getQuotes = async (filters = {}) => {
  // Remove empty fields before making the API request
  const filteredParams = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== "")
  );

  // Convert filters into query parameters
  const queryParams = new URLSearchParams(filteredParams).toString();
  const url = `${BASE_URL}?${queryParams}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  if (!response.ok) throw new Error("Failed to fetch quotes");
  return response.json();
};

// ✅ Fetch quote details by ID
export const getQuoteById = async (quoteId) => {
  const response = await fetch(`${config.BASE_URL}/api/quotes/${quoteId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  if (!response.ok) throw new Error("Failed to fetch quote details");
  return response.json();
};
