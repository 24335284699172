import config from "../config";
const BASE_URL = `${config.BASE_URL}/api/customer-groups`;
const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
	if (!response.ok) {
		const errorMessage = await response.text();
		throw new Error(errorMessage || "Something went wrong!");
	}
	return response.status !== 204 ? response.json() : {};
};

export const createCustomerGroup = async (payload) => {
	const response = await fetch(`${BASE_URL}`, {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getAuthToken()}`,
		},
		body: JSON.stringify(payload),
	});
	return handleResponse(response);
};

export const getCustomerGroupById = async (id) => {
	const response = await fetch(`${BASE_URL}/${id}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getAuthToken()}`,
		},
	});
	return handleResponse(response);
};

export const getAllCustomerGroups = async (query = "") => {
	const url = query ? `${BASE_URL}?${query}` : BASE_URL;
	const response = await fetch(url, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getAuthToken()}`,
		},
	});
	return handleResponse(response);
};

export const updateCustomerGroup = async (id, payload) => {
	const response = await fetch(`${BASE_URL}/${id}`, {
		method: "PUT",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getAuthToken()}`,
		},
		body: JSON.stringify(payload),
	});
	return handleResponse(response);
};

export const searchCustomerGroups = async (filters) => {
	const query = new URLSearchParams(filters).toString();
	const response = await fetch(`${BASE_URL}/search?${query}`, {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Authorization: `Bearer ${getAuthToken()}`,
		},
	});
	return handleResponse(response);
};
