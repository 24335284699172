import React, { useEffect, useState, useCallback } from "react";
import { searchUsers, resetPassword } from "../../services/userService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaArrowLeft, FaArrowRight, FaEye, FaEdit, FaKey, FaUserPlus, FaTrash } from "react-icons/fa";
import { Container, Row, Col, Table, Button, Form, Spinner, Modal } from "react-bootstrap";
import "./UserManagement.css"; // Import custom CSS for styling

const UserManagement = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const [showResetModal, setShowResetModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [newPassword, setNewPassword] = useState("");
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    name: "",
    email: "",
    roles: "",
    page: 0,
    size: 10, // Default page size
  });

  // New: State for permissions
  const [permissions, setPermissions] = useState([]);
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      try {
        const parsedDetails = JSON.parse(userDetails);
        setPermissions(parsedDetails.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  // ✅ Fetch Users
  const fetchUsers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await searchUsers(filters);
      console.log("User Fetch Response:", response); // ✅ Debug API response

      if (response.statusCode === 200 && response.data) {
        setUsers(response.data.content || []);
        setTotalPages(response.data.totalPages || 1);
      } else {
        toast.error("Failed to fetch users!");
      }
    } catch (error) {
      console.error("Error fetching users:", error);
      toast.error("Error fetching users.");
      setUsers([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  // ✅ Handle Reset Password
  const handleResetPassword = async () => {
    try {
      const response = await resetPassword(selectedUser.email, newPassword);
      if (response.statusCode === 200) {
        toast.success("Password reset successfully!");
        setShowResetModal(false);
      } else {
        toast.error("Failed to reset password.");
      }
    } catch (error) {
      console.error("Error resetting password:", error);
      toast.error("Failed to reset password.");
    }
  };

  // New: Delete user placeholder handler
  const handleDeleteUser = async (user) => {
    if (window.confirm("Are you sure you want to delete this user?")) {
      // Placeholder; Integrate delete API as needed.
      toast.success("User deleted successfully!");
      fetchUsers();
    }
  };

  return (
    <Container >
      {/* 🔵 Page Header */}
      {/* <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-primary">👥 User Management</h2>
        {permissions.includes("CREATE_USER") && (
          <Button variant="primary" onClick={() => navigate("/add-user")}>
            <FaUserPlus /> Add User
          </Button>
        )}
      </div> */}

      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary">User Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Controls user access, roles, and permissions to ensure secure system interactions.
          </p>
        </div>
        <div>
        {permissions.includes("CREATE_USER") && (
          <Button className="btn btn-primary" variant="primary" onClick={() => navigate("/add-user")}>
            <FaUserPlus /> Add User
          </Button>
        )}
        </div>
      </div>

      {/* 🔍 Filters */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <Row className="g-3">
            <Col md={2}>
              <Form.Control
                type="text"
                placeholder="Search Name"
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              />
            </Col>

            <Col md={2}>
              <Form.Control
                type="text"
                placeholder="Search Email"
                value={filters.email}
                onChange={(e) => setFilters({ ...filters, email: e.target.value })}
              />
            </Col>

            <Col md={2}>
              <Form.Control
                type="text"
                placeholder="Search Role"
                value={filters.roles}
                onChange={(e) => setFilters({ ...filters, roles: e.target.value })}
              />
            </Col>

            <Col md={2}>
              <Button variant="primary" className="w-100" onClick={fetchUsers}>
                <FaSearch /> Search
              </Button>
            </Col>
          </Row>
        </div>
      </div>
      <div className="card shadow">
      <div className="card-body">
      {/* 📊 Data Table */}
      {loading ? (
        <div className="text-center mt-3">
          <Spinner animation="border" />
        </div>
      ) : users.length > 0 ? (
        <div className="table-responsive">
          <Table bordered hover>
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>Email</th>
                <th>Roles</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {users.map((user) => (
                <tr key={user.id}>
                  <td>{user.id}</td>
                  <td>{user.name}</td>
                  <td>{user.email}</td>
                  {/* ✅ Fix Role Display (Convert Comma-Separated String to Array) */}
                  <td>
                    {typeof user.roles === "string"
                      ? user.roles.split(", ").map(role => role.trim()).join(", ")
                      : "N/A"}
                  </td>
                  <td>
                    {/* Conditionally render action buttons based on permissions */}
                    {permissions.includes("USER_VIEW") && (
                      <Button
                        variant="outline-info"
                        size="sm"
                        className="me-2"
                        onClick={() => navigate(`/users/view/${user.id}`)}
                      >
                        <FaEye /> View
                      </Button>
                    )}
                    {permissions.includes("EDIT_USER") && (
                      <Button
                        variant="outline-warning"
                        size="sm"
                        className="me-2"
                        onClick={() => navigate(`/users/edit/${user.id}`)}
                      >
                        <FaEdit /> Edit
                      </Button>
                    )}
                    {permissions.includes("DELETE_USER") && (
                      <Button
                        variant="outline-danger"
                        size="sm"
                        className="me-2"
                        onClick={() => handleDeleteUser(user)}
                      >
                        <FaTrash /> Delete
                      </Button>
                    )}
                    <Button
                      variant="outline-secondary"
                      size="sm"
                      onClick={() => {
                        setSelectedUser(user);
                        setShowResetModal(true);
                      }}
                    >
                      <FaKey /> Reset Password
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="text-center mt-3">
          <h5>No users found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <Button
          variant="outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </Button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <Button
          variant="outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </Button>
      </div>

      {/* Reset Password Modal */}
      <Modal show={showResetModal} onHide={() => setShowResetModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Reset Password</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group controlId="formNewPassword">
              <Form.Label>New Password</Form.Label>
              <Form.Control
                type="password"
                placeholder="Enter new password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowResetModal(false)}>
            Close
          </Button>
          <Button variant="primary" onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Modal.Footer>
      </Modal>
      </div>
      </div>
    </Container>
  );
};

export default UserManagement;