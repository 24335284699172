import React, { useState } from "react";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addCountry } from "../../services/countryService";

const AddCountryCurrency = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    countryId: "",
    countryName: "",
    currencyCode: "",
    currencyConversionRate: "",
    dialCode: "",
  });

  const handleChange = (e) => {
    setFormData((prev) => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await addCountry(formData);
      toast.success("Country added successfully!");
      navigate("/admin/country-currency-master");
    } catch (error) {
      toast.error("Error adding country.");
    }
  };

  return (
    <Container className="mt-4">
      <Card className="shadow-lg rounded">
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">Add New Country & Currency</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3 mb-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="countryId"
                  placeholder="Country ID"
                  value={formData.countryId}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="countryName"
                  placeholder="Country Name"
                  value={formData.countryName}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row className="g-3 mb-3">
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="currencyCode"
                  placeholder="Currency Code"
                  value={formData.currencyCode}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="currencyConversionRate"
                  placeholder="Conversion Rate"
                  value={formData.currencyConversionRate}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="dialCode"
                  placeholder="Dial Code"
                  value={formData.dialCode}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={() => navigate("/admin/country-currency-master")} className="me-2">
                Back
              </Button>
              <Button variant="primary" type="submit">
                Submit
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default AddCountryCurrency;
