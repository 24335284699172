import config from "../config";

const BASE_URL = `${config.BASE_URL}`; // ✅ Ensure correct API base path
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Handler
const apiRequest = async (endpoint, method = "GET", body = null, isBlob = false) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) {
      let errorMessage = "Something went wrong!";
      try {
        const errorData = await response.json(); // Try JSON first
        errorMessage = errorData.message || errorMessage;
      } catch {
        errorMessage = await response.text(); // Fallback to text
      }
      throw new Error(errorMessage);
    }

    if (isBlob) return response.blob(); // Handle file downloads
    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

// ✅ Fetch Users with Search Filters & Pagination (FIXED)
export const searchUsers = async (filters) => {
  const queryParams = new URLSearchParams();

  if (filters.name) queryParams.append("name", filters.name);
  if (filters.email) queryParams.append("email", filters.email);
  if (filters.roles) queryParams.append("roles", filters.roles);
  if (filters.page !== undefined) queryParams.append("page", filters.page);
  if (filters.size !== undefined) queryParams.append("size", filters.size);

  return apiRequest(`/api/users/search?${queryParams.toString()}`);
};

// ✅ Fetch all Users with Pagination (FIXED)
export const getUserList = async (filters) => {
  const queryParams = new URLSearchParams();

  if (filters.name) queryParams.append("name", filters.name);
  if (filters.email) queryParams.append("email", filters.email);
  if (filters.roles) queryParams.append("roles", filters.roles);
  if (filters.page !== undefined) queryParams.append("page", filters.page);
  if (filters.size !== undefined) queryParams.append("size", filters.size);

  return apiRequest(`/api/users?${queryParams.toString()}`);
};

// ✅ Fetch User details by ID (FIXED)
export const getUserById = async (id) => {
  const response = await apiRequest(`/api/users/${id}`);

  if (response.statusCode === 200) {
    return {
      id: response.data.id,
      name: response.data.name,
      email: response.data.email,
      roles: response.data.roles ? response.data.roles.map((role) => role.name) : [],
    };
  } else {
    throw new Error(response.message || "Failed to fetch user details");
  }
};

// ✅ Fetch all available roles (FIXED)
export const getAllRoles = async () => {
  return apiRequest(`/api/roles`);
};

// ✅ Create a New User
export const createUser = async (data) => {
  return apiRequest(`/api/users`, "POST", data);
};

// ✅ Update User
export const updateUser = async (id, data) => {
  return apiRequest(`/api/users/${id}`, "PUT", data);
};

// ✅ Delete User
export const deleteUser = async (id) => {
  return apiRequest(`/api/users/${id}`, "DELETE");
};

// ✅ Download User List as CSV
export const downloadUserList = async () => {
  try {
    const blob = await apiRequest(`/api/users/export-csv`, "GET", null, true);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `User_List.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setTimeout(() => window.URL.revokeObjectURL(url), 100);

    return { success: true, message: "User list downloaded successfully!" };
  } catch (error) {
    return { success: false, message: "Error downloading user list." };
  }
};

// ✅ Reset Password
export const resetPassword = async (email, newPassword) => {
  return apiRequest(`/api/auth/reset-password`, "POST", { email, newPassword });
};