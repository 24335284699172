import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { createRegion } from "../../services/countryRegionService";
import { toast } from "react-toastify";

const AddCountryRegion = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    countryId: "",
    code: "",
    defaultName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await createRegion(formData);
      toast.success("Region created successfully!");
      navigate("/country-regions");
    } catch (error) {
      toast.error(error.message || "Failed to create region.");
    }
  };

  return (
    <div className="container my-5">
      <div className="card shadow-lg border-0">
        <div className="card-header bg-primary text-white">
          <h2 className="mb-0">Add Country Region</h2>
        </div>
        <div className="card-body">
          {/* Back button */}
          <div className="mb-4">
            <button className="btn btn-secondary" onClick={() => navigate("/country-regions")}>
              Back
            </button>
          </div>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label className="form-label">Country ID</label>
              <input
                type="text"
                className="form-control"
                name="countryId"
                value={formData.countryId}
                onChange={handleChange}
                placeholder="e.g. US"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Region Code</label>
              <input
                type="text"
                className="form-control"
                name="code"
                value={formData.code}
                onChange={handleChange}
                placeholder="e.g. NR01"
              />
            </div>
            <div className="mb-3">
              <label className="form-label">Default Name</label>
              <input
                type="text"
                className="form-control"
                name="defaultName"
                value={formData.defaultName}
                onChange={handleChange}
                placeholder="e.g. North Region"
              />
            </div>
            <div className="text-end">
              <button type="submit" className="btn btn-success px-4">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddCountryRegion;
