import React, { useEffect, useState } from "react";
import { getBrandById, updateBrand } from "../services/brandService";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEdit, FaArrowLeft } from "react-icons/fa";

const EditBrand = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [brand, setBrand] = useState({
    name: "",
    urlKey: "",
    logo: "",
    brandImage: "",
    sortOrder: "",
    isActive: 1,
    updatedBy: "",
  });

  useEffect(() => {
    const fetchBrand = async () => {
      try {
        const response = await getBrandById(id);
        if (response.statusCode === 200) {
          setBrand(response.data);
        } else {
          toast.error("Brand not found!");
          navigate("/brands");
        }
      } catch (error) {
        toast.error("Error fetching brand details.");
        navigate("/brands");
      }
    };
    fetchBrand();
  }, [id, navigate]);

  const handleChange = (e) => {
    setBrand({ ...brand, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateBrand(id, brand);
      toast.success("Brand updated successfully! ✅");
      navigate("/brands");
    } catch (err) {
      toast.error("Failed to update brand! ❌");
    }
  };

  return (
    <div className="container-fluid px-5 py-4">
      {/* Page Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">
          <FaEdit className="me-2" /> Edit Brand
        </h2>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/brands")}>
          <FaArrowLeft /> Back to Brands
        </button>
      </div>

      {/* Brand Form */}
      <div className="card shadow-lg p-4">
        <form onSubmit={handleSubmit} className="row g-4">
          <h5 className="text-primary border-bottom pb-2">🏷️ Brand Details</h5>
          <div className="col-md-6">
            <label className="form-label">Brand Name</label>
            <input type="text" className="form-control" name="name" value={brand.name} onChange={handleChange} required />
          </div>
          <div className="col-md-6">
            <label className="form-label">URL Key</label>
            <input type="text" className="form-control" name="urlKey" value={brand.urlKey} onChange={handleChange} required />
          </div>

          <h5 className="text-primary border-bottom pb-2">📷 Branding & Images</h5>
          <div className="col-md-6">
            <label className="form-label">Logo URL</label>
            <input type="text" className="form-control" name="logo" value={brand.logo} onChange={handleChange} />
          </div>
          <div className="col-md-6">
            <label className="form-label">Brand Image URL</label>
            <input type="text" className="form-control" name="brandImage" value={brand.brandImage} onChange={handleChange} />
          </div>

          {/* Submit Buttons */}
          <div className="col-12 text-center mt-4">
            <button type="submit" className="btn btn-lg btn-primary">✅ Update Brand</button>
            <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/brands")}>❌ Cancel</button>
          </div>

        </form>
      </div>
    </div>
  );
};

export default EditBrand;
