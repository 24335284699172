import React, { useState, useEffect } from "react";
import { Container, Card, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getCountryById, updateCountry } from "../../services/countryService";

const EditCountryCurrency = () => {
  const { id } = useParams(); // e.g. "1"
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    countryId: "",
    countryName: "",
    currencyCode: "",
    currencyConversionRate: "",
    dialCode: "",
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCountryById(id);
        const data = response.data; // expected structure
        setFormData({
          countryId: data.countryId,
          countryName: data.countryName,
          currencyCode: data.currencyCode,
          currencyConversionRate: data.currencyConversionRate,
          dialCode: data.dialCode,
        });
      } catch (error) {
        toast.error("Error fetching country details.");
      }
    };
    fetchData();
  }, [id]);

  const handleChange = (e) => {
    setFormData(prev => ({ ...prev, [e.target.name]: e.target.value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await updateCountry(id, formData);
      toast.success("Country updated successfully!");
      navigate("/admin/country-currency-master");
    } catch (error) {
      toast.error("Error updating country.");
    }
  };

  return (
    <Container className="mt-4">
      <Card className="shadow-lg rounded">
        <Card.Header className="bg-primary text-white">
          <h4 className="mb-0">Edit Country & Currency</h4>
        </Card.Header>
        <Card.Body>
          <Form onSubmit={handleSubmit}>
            <Row className="g-3 mb-3">
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="countryId"
                  placeholder="Country ID"
                  value={formData.countryId}
                  onChange={handleChange}
                  disabled
                />
              </Col>
              <Col md={6}>
                <Form.Control
                  type="text"
                  name="countryName"
                  placeholder="Country Name"
                  value={formData.countryName}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <Row className="g-3 mb-3">
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="currencyCode"
                  placeholder="Currency Code"
                  value={formData.currencyCode}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="currencyConversionRate"
                  placeholder="Conversion Rate"
                  value={formData.currencyConversionRate}
                  onChange={handleChange}
                  required
                />
              </Col>
              <Col md={4}>
                <Form.Control
                  type="text"
                  name="dialCode"
                  placeholder="Dial Code"
                  value={formData.dialCode}
                  onChange={handleChange}
                  required
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end">
              <Button variant="secondary" onClick={() => navigate("/admin/country-currency-master")} className="me-2">
                Back
              </Button>
              <Button variant="primary" type="submit">
                Update
              </Button>
            </div>
          </Form>
        </Card.Body>
      </Card>
    </Container>
  );
};

export default EditCountryCurrency;
