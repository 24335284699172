import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { searchRegions, deleteRegion } from "../../services/countryRegionService";
import { toast } from "react-toastify";
import { FaPlus, FaSearch, FaEdit, FaTrash, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CountryRegionList = () => {
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    countryId: "",
    code: "",
    defaultName: "",
    page: 0,
    size: 10,
  });
  const [totalPages, setTotalPages] = useState(0);

  const fetchRegions = useCallback(async () => {
    setLoading(true);
    try {
      const response = await searchRegions({ ...filters, defaultName: filters.defaultName, page: filters.page, size: filters.size });
      if (response.statusCode === 200) {
        setRegions(response.data.content || []);
        setTotalPages(response.data.totalPages || 0);
      } else {
        toast.error("Failed to fetch regions!");
      }
    } catch (error) {
      toast.error("Error fetching regions.");
      setRegions([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchRegions();
  }, [fetchRegions]);

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this region?")) {
      try {
        await deleteRegion(id);
        toast.success("Region deleted successfully!");
        fetchRegions();
      } catch (error) {
        toast.error("Failed to delete region.");
      }
    }
  };

  return (
    <div className="container my-5">
      {/* Wrap entire list in a stylish card container */}
      <div className="card shadow-lg border-0">
        <div className="card-header d-flex justify-content-between align-items-center bg-dark text-white">
          <h2 className="mb-0">Country Regions</h2>
          <button className="btn btn-primary" onClick={() => navigate("/country-regions/add")}>
            <FaPlus /> Add Region
          </button>
        </div>
        <div className="card-body">
          {/* Search Filters Section styled as a card */}
          <div className="card mb-4 shadow-sm">
            <div className="card-body">
              <div className="row g-2">
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Country ID"
                    className="form-control"
                    value={filters.countryId}
                    onChange={(e) => setFilters({ ...filters, countryId: e.target.value })}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Region Code"
                    className="form-control"
                    value={filters.code}
                    onChange={(e) => setFilters({ ...filters, code: e.target.value })}
                  />
                </div>
                <div className="col-md-4">
                  <input
                    type="text"
                    placeholder="Default Name"
                    className="form-control"
                    value={filters.defaultName}
                    onChange={(e) => setFilters({ ...filters, defaultName: e.target.value })}
                  />
                </div>
                <div className="col-md-2">
                  <button className="btn btn-primary w-100" onClick={fetchRegions}>
                    <FaSearch /> Search
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          {loading && (
            <div className="text-center my-3">
              <h5>Loading regions...</h5>
            </div>
          )}
          {!loading && regions.length > 0 && (
            <div className="table-responsive">
              <table className="table table-striped table-bordered">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Country ID</th>
                    <th>Code</th>
                    <th>Default Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {regions.map((region) => (
                    <tr key={region.regionId}>
                      <td>{region.regionId}</td>
                      <td>{region.countryId}</td>
                      <td>{region.code}</td>
                      <td>{region.defaultName}</td>
                      <td>
                        <button className="btn btn-sm btn-outline-secondary me-2" onClick={() => navigate(`/country-regions/edit/${region.regionId}`)}>
                          <FaEdit /> Edit
                        </button>
                        <button className="btn btn-sm btn-danger" onClick={() => handleDelete(region.regionId)}>
                          <FaTrash /> Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {!loading && regions.length === 0 && (
            <div className="text-center my-3">
              <h5>No regions found.</h5>
            </div>
          )}
          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center mt-3">
            <button className="btn btn-outline-secondary" disabled={filters.page === 0} onClick={() => setFilters({ ...filters, page: filters.page - 1 })}>
              <FaArrowLeft /> Previous
            </button>
            <span>Page {filters.page + 1} of {totalPages}</span>
            <button className="btn btn-outline-secondary" disabled={filters.page + 1 >= totalPages} onClick={() => setFilters({ ...filters, page: filters.page + 1 })}>
              Next <FaArrowRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CountryRegionList;
