import React from "react";
import { CSVLink } from "react-csv";
import { FaFileCsv, FaFilePdf } from "react-icons/fa";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from "recharts";

const InventoryForecastReport = () => {
  // Historical Data
  const historicalData = [
    { month: "2022-01", consumption: 100 },
    { month: "2022-02", consumption: 110 },
    { month: "2022-03", consumption: 105 },
    { month: "2022-04", consumption: 120 },
    { month: "2022-05", consumption: 130 },
    { month: "2022-06", consumption: 125 },
    { month: "2022-07", consumption: 140 },
    { month: "2022-08", consumption: 135 },
    { month: "2022-09", consumption: 145 },
    { month: "2022-10", consumption: 150 },
    { month: "2022-11", consumption: 155 },
    { month: "2022-12", consumption: 160 },
    { month: "2023-01", consumption: 165 },
    { month: "2023-02", consumption: 170 },
    { month: "2023-03", consumption: 175 },
    { month: "2023-04", consumption: 180 },
    { month: "2023-05", consumption: 190 },
    { month: "2023-06", consumption: 200 },
    { month: "2023-07", consumption: 210 },
    { month: "2023-08", consumption: 220 },
    { month: "2023-09", consumption: 230 },
    { month: "2023-10", consumption: 240 },
    { month: "2023-11", consumption: 250 },
    { month: "2023-12", consumption: 260 },
    { month: "2024-01", consumption: 270 },
    { month: "2024-02", consumption: 280 },
    { month: "2024-03", consumption: 290 },
    { month: "2024-04", consumption: 300 },
    { month: "2024-05", consumption: 310 },
    { month: "2024-06", consumption: 320 },
    { month: "2024-07", consumption: 330 },
    { month: "2024-08", consumption: 340 },
    { month: "2024-09", consumption: 350 },
    { month: "2024-10", consumption: 360 },
    { month: "2024-11", consumption: 370 },
    { month: "2024-12", consumption: 380 },
    { month: "2025-01", consumption: 390 },
    { month: "2025-02", consumption: 400 },
  ];

  // Forecasted Data
  const forecastData = [
    { model: "Exponential Smoothing", predicted: 410, min: 360, max: 460 },
    { model: "LSTM (Neural Network)", predicted: 408, min: 380, max: 436 },
    { model: "ARIMA (Time Series)", predicted: 410, min: 360, max: 460 },
  ];

  // CSV Export Data
  const csvData = [
    ["Model", "Predicted Consumption", "Confidence Interval (Min - Max)"],
    ...forecastData.map((item) => [item.model, item.predicted, `${item.min} - ${item.max}`]),
  ];

  // Export to PDF
  const exportToPDF = () => {
    const input = document.getElementById("report-section");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 120);
      pdf.save("Inventory_Forecast_Report.pdf");
    });
  };

  return (
    <div className="container mt-4" id="report-section">
      <h2 className="text-primary">📊 Inventory Consumption Forecast Report</h2>
      <p>This report provides insights into historical and forecasted inventory consumption.</p>

      {/* Historical Data Table */}
      <div className="card shadow-lg p-4 mt-3">
        <h4 className="text-primary">📅 Historical Consumption Data</h4>
        <table className="table table-bordered table-striped">
          <thead className="table-dark">
            <tr>
              <th>Month</th>
              <th>Consumption</th>
            </tr>
          </thead>
          <tbody>
            {historicalData.slice(-6).map((item, index) => (
              <tr key={index}>
                <td>{item.month}</td>
                <td>{item.consumption}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Forecasted Data */}
      <div className="card shadow-lg p-4 mt-4">
        <h4 className="text-primary">📈 Forecasted Inventory Consumption</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={forecastData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="model" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="predicted" fill="#4caf50" name="Predicted Consumption" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Model Comparison */}
<div className="card shadow-lg p-4 mt-4">
  <h4 className="text-primary">📊 Model Comparison - AI Forecasting Methods</h4>
  <p>
    Inventory forecasting relies on advanced AI models that analyze past consumption data to predict future trends.  
    Below is a detailed comparison of the three models used:
  </p>

  {/* LSTM Model */}
  <div className="mt-4">
    <h5 className="text-success">🧠 LSTM (Long Short-Term Memory - Neural Network)</h5>
    <p>
      <b>How It Works:</b>  
      LSTM is a **deep learning model** designed to process sequential data and capture complex patterns over time.  
      It retains memory of past data points and learns **long-term dependencies**, making it highly effective for time-series forecasting.
    </p>
    <b>✔ Strengths:</b>
    <ul>
      <li>Detects **seasonal trends** and **long-term dependencies** in inventory consumption.</li>
      <li>Handles **irregular demand fluctuations** better than traditional models.</li>
      <li>Works well with **large datasets** and complex relationships between variables.</li>
    </ul>
    <b>❌ Weaknesses:</b>
    <ul>
      <li>Computationally expensive—requires **powerful hardware (GPU/TPU)**.</li>
      <li>Requires **large amounts of training data** to achieve high accuracy.</li>
      <li>Harder to interpret than traditional statistical models.</li>
    </ul>
    <b>✅ Best Use Cases:</b>
    <ul>
      <li>Long-term forecasting where demand varies significantly over time.</li>
      <li>Large inventory datasets with **seasonal and trend-based variations**.</li>
      <li>Businesses with complex supply chains and unpredictable demand patterns.</li>
    </ul>
  </div>

  {/* Exponential Smoothing Model */}
  <div className="mt-4">
    <h5 className="text-info">📉 Exponential Smoothing</h5>
    <p>
      <b>How It Works:</b>  
      Exponential smoothing is a **statistical method** that gives more weight to recent data while exponentially decreasing the impact of older data.  
      It assumes that recent trends are more relevant for predicting future values.
    </p>
    <b>✔ Strengths:</b>
    <ul>
      <li>Quick and **computationally efficient**—can run on low-power systems.</li>
      <li>Works well for **short-term forecasting** where trends remain stable.</li>
      <li>Simple to understand and implement.</li>
    </ul>
    <b>❌ Weaknesses:</b>
    <ul>
      <li>Poor at handling **sudden demand spikes or rapid market changes**.</li>
      <li>Fails to capture **long-term dependencies or seasonal variations**.</li>
      <li>Less accurate for businesses with **highly volatile** inventory demand.</li>
    </ul>
    <b>✅ Best Use Cases:</b>
    <ul>
      <li>Short-term forecasting where **recent demand patterns remain stable**.</li>
      <li>Businesses that need **quick, low-cost predictions**.</li>
      <li>Environments with limited computing power where AI-based models aren't practical.</li>
    </ul>
  </div>

  {/* ARIMA Model */}
  <div className="mt-4">
    <h5 className="text-warning">📊 ARIMA (AutoRegressive Integrated Moving Average)</h5>
    <p>
      <b>How It Works:</b>  
      ARIMA is a **statistical time-series forecasting model** that uses past values and moving averages to predict future inventory consumption.  
      It is particularly good at identifying **consistent trends and cyclical patterns** in historical data.
    </p>
    <b>✔ Strengths:</b>
    <ul>
      <li>Highly effective for **time-series data with predictable trends**.</li>
      <li>More accurate than Exponential Smoothing for **seasonal and trend-based data**.</li>
      <li>Computationally less expensive than deep learning models like LSTM.</li>
    </ul>
    <b>❌ Weaknesses:</b>
    <ul>
      <li>Struggles with **sudden demand shifts** or highly fluctuating data.</li>
      <li>Requires careful tuning of parameters (p, d, q) for best performance.</li>
      <li>Assumes that past consumption patterns will continue in the future, which may not always be true.</li>
    </ul>
    <b>✅ Best Use Cases:</b>
    <ul>
      <li>Time-series forecasting where **patterns are stable over time**.</li>
      <li>Businesses with **consistent demand cycles or seasonal variations**.</li>
      <li>When **computational efficiency is needed**, but LSTM is too complex.</li>
    </ul>
  </div>

  {/* Key Takeaways */}
  <div className="mt-4">
    <h5 className="text-success">📌 Key Takeaways & Model Recommendation</h5>
    <ul>
      <li>✅ **LSTM is the most accurate model** for complex and long-term forecasting.</li>
      <li>📉 **Exponential Smoothing is best for short-term, quick predictions.**</li>
      <li>📊 **ARIMA is effective for regular time-series forecasting with stable trends.**</li>
      <li>🚀 **Final Recommendation:**  
        - Use **LSTM** for businesses needing highly accurate long-term forecasts.  
        - Use **ARIMA** for stable, structured time-series forecasting.  
        - Use **Exponential Smoothing** for short-term, quick calculations.</li>
    </ul>
  </div>
</div>


      {/* Export Options */}
      <div className="card shadow-lg p-4 mt-4">
        <h4 className="text-primary">📄 Export Report</h4>
        <div className="d-flex justify-content-center gap-3">
          <button className="btn btn-success">
            <CSVLink data={csvData} filename="Inventory_Forecast_Report.csv" className="text-white text-decoration-none">
              <FaFileCsv className="me-2" /> Export as CSV
            </CSVLink>
          </button>
          <button className="btn btn-danger" onClick={exportToPDF}>
            <FaFilePdf className="me-2" /> Export as PDF
          </button>
        </div>
      </div>
    </div>
  );
};

export default InventoryForecastReport;
