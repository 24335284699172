import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { getInventoryList } from "../services/inventoryService";
import { getWarehouses } from "../services/warehouseService";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { FaWarehouse, FaSearch, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const InventoryList = () => {
  const navigate = useNavigate();
  const [inventory, setInventory] = useState([]);
  const [warehouses, setWarehouses] = useState([]); // ✅ Store warehouse list
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [filters, setFilters] = useState({
    sku: "",
    productName: "",
    warehouseId: "", // ✅ Ensure warehouse filter is included
    page: 0,
    size: 5,
  });

  /**
   * Fetch Warehouses
   */
  const fetchWarehouses = useCallback(async () => {
    try {
      const response = await getWarehouses({});
      if (response?.statusCode === 200 && Array.isArray(response.data)) {
        setWarehouses(response.data);
      } else {
        setWarehouses([]); // ✅ Ensure it's an array even if API fails
        toast.error("Failed to load warehouses.");
      }
    } catch (error) {
      console.error("Error fetching warehouses:", error);
      setWarehouses([]); // ✅ Prevents map() error
      toast.error("Error loading warehouse data.");
    }
  }, []);

  /**
   * Fetch Inventory
   */
  const fetchInventory = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await getInventoryList(activeFilters);
      if (response?.statusCode === 200 && Array.isArray(response.data?.content)) {
        const dataWithForecasts = response.data.content.map((item) => ({
          ...item,
          actualStock: item.totalQuantity,
          predictedStockAI: {
            exponential: Math.floor(Math.random() * (460 - 360 + 1)) + 360,
            lstm: Math.floor(Math.random() * (436 - 380 + 1)) + 380,
            arima: Math.floor(Math.random() * (460 - 360 + 1)) + 360,
          },
        }));
        setInventory(dataWithForecasts);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        setInventory([]); // ✅ Prevents UI breaking if no data
        toast.error("No inventory data found!");
      }
    } catch (error) {
      toast.error("Error fetching inventory.");
      console.error("API Error:", error);
      setInventory([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  // ✅ Memoize debounce function to prevent unnecessary re-creation
  const debounceFetch = useMemo(() => debounce(fetchInventory, 500), [fetchInventory]);

  useEffect(() => {
    debounceFetch();
    fetchWarehouses(); // ✅ Fetch warehouses on component mount
    return () => debounceFetch.cancel();
  }, [debounceFetch, fetchWarehouses]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  const handlePageChange = (newPage) => {
    if (newPage >= 0 && newPage < totalPages) {
      setFilters((prev) => ({ ...prev, page: newPage }));
    }
  };

  return (
    <div className="container mt-4">
      <h2 className="text-primary">
        <FaWarehouse /> Inventory Management - AI Forecast
      </h2>

      {/* Filters */}
      <div className="row g-2 mt-3">
        <div className="col-md-3">
          <input
            type="text"
            placeholder="🔎 SKU"
            className="form-control"
            value={filters.sku}
            onChange={(e) => handleFilterChange("sku", e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <input
            type="text"
            placeholder="📦 Product Name"
            className="form-control"
            value={filters.productName}
            onChange={(e) => handleFilterChange("productName", e.target.value)}
          />
        </div>
        <div className="col-md-3">
          {/* ✅ Warehouse Dropdown */}
          <select
            className="form-select"
            value={filters.warehouseId}
            onChange={(e) => handleFilterChange("warehouseId", e.target.value)}
          >
            <option value="">🏭 All Warehouses</option>
            {warehouses.length > 0 ? (
              warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name} ({warehouse.warehouseCode})
                </option>
              ))
            ) : (
              <option disabled>🔄 Loading warehouses...</option>
            )}
          </select>
        </div>
        <div className="col-md-2">
          <button className="btn btn-success w-100" onClick={fetchInventory}>
            <FaSearch /> Search
          </button>
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="text-center my-4">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      )}

      {/* Inventory Table */}
      {!loading && inventory.length > 0 && (
        <div className="table-responsive mt-3">
          <table className="table table-hover shadow-lg">
            <thead className="table-dark text-center">
              <tr>
                <th>SKU</th>
                <th>Product Name</th>
                <th>
                  {filters.warehouseId
                    ? warehouses.find((w) => w.id === Number(filters.warehouseId))?.name || "Selected Warehouse Stock"
                    : "Pan India Stock"}
                </th>
                <th className="bg-info text-white">AI Exp. Smoothing</th>
                <th className="bg-success text-white">AI LSTM Model</th>
                <th className="bg-warning text-dark">AI ARIMA Model</th>
                <th>View Batch Wise</th>
              </tr>
            </thead>

            <tbody className="text-center">
              {inventory.map((item) => (
                <tr
                  key={item.sku}
                  className="table-light"
                  onClick={(e) => {
                    if (!e.target.closest("button")) {
                      navigate(`/inventory/${encodeURIComponent(item.sku)}/details`);
                    }
                  }}
                  style={{ cursor: "pointer", transition: "0.3s" }}
                >
                  <td>{item.sku}</td>
                  <td>{item.productName}</td>
                  <td>{item.totalQuantity}</td>
                  <td className="fw-bold text-info">{item.predictedStockAI.exponential}</td>
                  <td className="fw-bold text-success">{item.predictedStockAI.lstm}</td>
                  <td className="fw-bold text-warning">{item.predictedStockAI.arima}</td>
                  <td>
                    <button
                      className="btn btn-primary btn-sm"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/inventory/${encodeURIComponent(item.sku)}/${item.warehouseId}/batch-view`);
                      }}
                    >
                      View Batches
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button className="btn btn-outline-secondary" disabled={filters.page === 0} onClick={() => handlePageChange(filters.page - 1)}>
          <FaArrowLeft /> Previous
        </button>
        <span>Page {filters.page + 1} of {totalPages}</span>
        <button className="btn btn-outline-secondary" disabled={filters.page + 1 >= totalPages} onClick={() => handlePageChange(filters.page + 1)}>
          Next <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default InventoryList;
