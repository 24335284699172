import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getCustomers } from "../services/customerService";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaPlus, FaEye, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const CustomerList = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    entityId: "",    // added filter field
    email: "",
    groupId: "",     // added filter field
    firstname: "",   // added filter field
    isActive: "",    // added filter field (e.g., "true" or "false")
    page: 0,
    size: 10,
  });
  const [totalPages, setTotalPages] = useState(0); // added for pagination

  const fetchCustomers = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await getCustomers(queryParams);

      if (response.statusCode === 200) {
        setCustomers(response.data.content || []);
        setTotalPages(response.data.totalPages || 0); // update totalPages
      } else {
        toast.error("Failed to fetch customers!");
      }
    } catch (error) {
      toast.error("Error fetching customers.");
      setCustomers([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchCustomers();
  }, [fetchCustomers]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary">Customer Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            Seamlessly manage all customer interactions, KYC, and history in one centralized dashboard..
          </p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/customers/create")}>
            <FaPlus /> Create Customers
          </button>
        </div>
      </div>
      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                name="entityId"
                placeholder="Filter by ID"
                className="form-control"
                value={filters.entityId}
                onChange={(e) => setFilters({ ...filters, entityId: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <div className="input-group">
                <input
                  type="email"
                  name="email"
                  placeholder="Filter by Email"
                  className="form-control"
                  value={filters.email}
                  onChange={(e) => setFilters({ ...filters, email: e.target.value })}
                />
              </div>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                name="groupId"
                placeholder="Filter by Group ID"
                className="form-control"
                value={filters.groupId}
                onChange={(e) => setFilters({ ...filters, groupId: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                name="firstname"
                placeholder="Filter by Firstname"
                className="form-control"
                value={filters.firstname}
                onChange={(e) => setFilters({ ...filters, firstname: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <select
                name="isActive"
                className="form-select"
                value={filters.isActive}
                onChange={(e) => setFilters({ ...filters, isActive: e.target.value })}
              >
                <option value="">Status</option>
                <option value="true">Active</option>
                <option value="false">Inactive</option>
              </select>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchCustomers}>
                Search
              </button>
            </div>
          </div>
          </div>
          </div>
          <div className="card shadow">
            <div className="card-body">
          {/* 🌀 Loading State */}
          {loading && <div className="text-center my-3"><h5>Loading customers... 🌀</h5></div>}

          {/* 📊 Customer Table */}
          {!loading && customers.length > 0 && (
            <div className="table-responsive">
              <table className="table table-bordered table-hover table-striped bg-white text-dark">
                <thead className="table-secondary text-dark">
                  <tr>
                    <th>ID</th>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Email</th>
                    <th>Mobile</th>
                    <th>Country</th>
                    <th>Is First Level Verified</th>
                    <th>Is Second Level Verified</th>
                    <th>Active</th>
                    <th>BDE Name</th>
                    <th>User Name</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customers.map((customer) => (
                    <tr key={customer.entityId}>
                      <td>{customer.entityId}</td>
                      <td><span className="text-link" onClick={() => navigate(`/customers/view/${customer.entityId}`)}>{customer.firstname}</span></td>
                      <td>{customer.lastname}</td>
                      <td>{customer.email}</td>
                      <td>{customer.regDialerCode} {customer.regMobileNumber}</td>
                      <td>{customer.countryName}</td>
                      <td>{customer.isFirstLevelVerified ? "Yes" : "No"}</td>
                      <td>{customer.isSecondLevelVerified ? "Yes" : "No"}</td>
                      <td>{customer.isActive ? "Yes" : "No"}</td>
                      <td>{customer.bdeName}</td>
                      <td>{customer.userName}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-outline-secondary"
                          onClick={() => navigate(`/customers/view/${customer.entityId}`)}
                        >
                          <FaEye /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* ❗ No Data Message */}
          {!loading && customers.length === 0 && (
            <div className="text-center my-3">
              <h5>No customers found ❌</h5>
            </div>
          )}

          {/* ⏩ Pagination Controls */}
          {!loading && totalPages > 1 && (
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-outline-secondary me-2"
                onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
                disabled={filters.page === 0}
              >
               <FaArrowLeft /> Previous
              </button>
              <span>
                Page {filters.page + 1} of {totalPages}
              </span>
              <button
                className="btn btn-outline-secondary ms-2"
                onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
                disabled={filters.page + 1 === totalPages}
              >
                Next <FaArrowRight />
              </button>
            </div>
          )}
        </div>
      </div>
      </div>
    
  );
};

export default CustomerList;
