import config from "../config";
const BASE_URL = `${config.BASE_URL}/api/country-regions`;

const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

export const createRegion = async (regionData) => {
  const response = await fetch(`${BASE_URL}/createRegion`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(regionData),
  });
  return handleResponse(response);
};

export const getRegionById = async (id) => {
  const response = await fetch(`${BASE_URL}/getRegionById/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const getAllRegions = async () => {
  const response = await fetch(`${BASE_URL}/getAllRegions`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const updateRegion = async (id, regionData) => {
  const response = await fetch(`${BASE_URL}/updateRegion/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(regionData),
  });
  return handleResponse(response);
};

export const deleteRegion = async (id) => {
  const response = await fetch(`${BASE_URL}/deleteRegion/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const searchRegions = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/searchRegions?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  const result = await handleResponse(response);
  // Ensure consistent structure: if result.data is missing, wrap result
  if (!result.data) {
    return { data: { content: result } };
  }
  return result;
};
