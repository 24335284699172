import React, { useEffect, useState, useCallback, useMemo } from "react";
import { getWarehouses } from "../services/warehouseService";
//import { deleteWarehouse } from "../services/warehouseService";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import "./inventory/InventoryLoggerList.css";
import {
  FaWarehouse,
  FaSearch,
  FaPlus,
  FaEdit,
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaKey,
  FaClipboardList,
} from "react-icons/fa";

const WarehouseList = () => {
  const [warehouses, setWarehouses] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);
  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  // ✅ Filters with useState
  const [filters, setFilters] = useState({
    warehouseId: "",
    name: "",
    city: "",
    state: "",
    active: "",
    page: 0,
    size: 10,
  });

  // ✅ Memoized function to fetch warehouses
  const fetchWarehouses = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await getWarehouses(activeFilters);
      if (response.statusCode === 200) {
        setWarehouses(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch warehouses!");
      }
    } catch (error) {
      toast.error("Error fetching warehouses.");
      setWarehouses([]);
    } finally {
      setLoading(false);
    }
  }, [filters]); // ✅ Added `filters` as a dependency

  // ✅ Debounce the API call
  const debounceFetchWarehouses = useMemo(() => debounce(fetchWarehouses, 500), [fetchWarehouses]);

  // ✅ Fetch data when filters change
  useEffect(() => {
    debounceFetchWarehouses();
    return () => debounceFetchWarehouses.cancel();
  }, [debounceFetchWarehouses]); // ✅ Now properly included dependencies

  return (
    <div className="container">
      {/* Page Title & Add Button */}
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaWarehouse /> Warehouse Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Organize, track, and manage your inventory across warehouses for efficient operations and accurate stock visibility.
          </p>
        </div>
        <div>
        {userPermissions.includes("ADD_WAREHOUSE") && (
          <button className="btn btn-primary" onClick={() => navigate("/warehouse/add")}>
            <FaPlus /> Add Warehouse
          </button>
        )}
        </div>
      </div>
     
      <div className="card shadow mb-3">
        <div className="card-body">

      {/* 🔍 Filters */}
      <div className="row g-3">
        <div className="col-md-2">
          <input
            type="text"
            placeholder="🏢 Warehouse ID"
            className="form-control border-primary"
            value={filters.warehouseId}
            onChange={(e) => setFilters((prev) => ({ ...prev, warehouseId: e.target.value }))}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="🏢 Warehouse Name"
            className="form-control border-primary"
            value={filters.name}
            onChange={(e) => setFilters((prev) => ({ ...prev, name: e.target.value }))}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="📍 City"
            className="form-control border-info"
            value={filters.city}
            onChange={(e) => setFilters((prev) => ({ ...prev, city: e.target.value }))}
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="🏛️ State"
            className="form-control border-warning"
            value={filters.state}
            onChange={(e) => setFilters((prev) => ({ ...prev, state: e.target.value }))}
          />
        </div>

        <div className="col-md-2">
          <select
            className="form-select border-success"
            value={filters.active}
            onChange={(e) => setFilters({ ...filters, active: e.target.value })}
          >
            <option value="">📌 All Status</option>
            <option value="1">✅ Active</option>
            <option value="0">❌ Inactive</option>
          </select>
        </div>

        <div className="col-md-2">
          <button className="btn btn-primary w-100" onClick={fetchWarehouses}>
            <FaSearch /> Search
          </button>
        </div>
      </div>
      </div>
      </div>

      <div className="card shadow">
      <div className="card-body">
      {/* 📊 Warehouse Table */}
      {!loading && warehouses.length > 0 ? (
        <div className="table-responsive mt-3">
          <table className="table table-bordered table-hover table-striped bg-white text-dark">
            <thead className="table-dark text-center">
              <tr>
                <th>ID</th>
                <th>Code</th>
                <th>City</th>
                <th>State</th>
                <th>Contact</th>
                <th>Email</th>
                <th>GST</th>
                <th>Notifications</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {warehouses.map((warehouse) => (
                <tr key={warehouse.id}>
                  <td>{warehouse.id}</td>
                  <td>{warehouse.warehouseCode || "N/A"}</td>
                  <td>{warehouse.city}</td>
                  <td>{warehouse.state}</td>
                  <td>
                     {warehouse.contact || "N/A"}
                  </td>
                  <td>
                    
                    <a href={`mailto:${warehouse.email}`} className="text-decoration-none text-dark">
                      {warehouse.email}
                    </a>
                  </td>
                  <td>
                    <FaKey className="text-warning me-1" /> {warehouse.gstnNumber || "N/A"}
                  </td>
                  <td>
                    <FaClipboardList className="text-info me-1" /> {warehouse.notificationEmail || "N/A"}
                  </td>
                  <td>
                    <span className={`badge bg-${warehouse.active ? "success" : "danger"}`}>
                      {warehouse.active ? "Active" : "Inactive"}
                    </span>
                  </td>
                  <td className="text-center">
                    
                      {userPermissions.includes("VIEW_WAREHOUSE") && (
                        <button className="btn btn-outline-secondary btn-sm" onClick={() => navigate(`/warehouse/view/${warehouse.id}`)}>
                          <FaEye /> View
                        </button> 
                      )} &nbsp;
                      {userPermissions.includes("EDIT_WAREHOUSE") && (
                        <button className="btn btn-warning btn-sm" onClick={() => navigate(`/warehouse/edit/${warehouse.id}`)}>
                          <FaEdit /> Edit
                        </button>
                      )}
                      {/* <button className="btn btn-danger btn-sm shadow-lg rounded-pill px-3 py-1" onClick={() => deleteWarehouse(warehouse.id)}>
                        <FaTrash /> Delete
                      </button> */}
                    
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        !loading && <div className="text-center mt-3"><h5>No warehouses found ❌</h5></div>
      )}

      {/* 📑 Pagination */}
      <div className="d-flex justify-content-between align-items-center">
        <button className="btn btn-outline-secondary" disabled={filters.page === 0} onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}>
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button className="btn btn-outline-secondary" disabled={filters.page + 1 >= totalPages} onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}>
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default WarehouseList;
