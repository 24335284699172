import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/couriers`;

// ✅ Get auth token from localStorage
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Handle API response
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {}; // Return empty object for 204 No Content
};

// ✅ Fetch all couriers with search & pagination
export const getCouriers = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch a single courier by ID
export const getCourierById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Create a new courier
export const createCourier = async (courierData) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(courierData),
  });

  return handleResponse(response);
};

// ✅ Update an existing courier
export const updateCourier = async (id, courierData) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(courierData),
  });

  return handleResponse(response);
};

// ✅ Delete a courier
export const deleteCourier = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};
