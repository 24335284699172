import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';
import { AsyncPaginate } from "react-select-async-paginate"; // NEW: import AsyncPaginate
import {FaPlusCircle, FaArrowLeft } from "react-icons/fa";

function AddRole() {
  const [formData, setFormData] = useState({ name: '', permissions: [] });
  // Removed unused permissions state
  const [userPermissions, setUserPermissions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        setUserPermissions(JSON.parse(details).permissions || []);
      } catch (e) {}
    }
  }, []);

  // Removed useEffect that was calling setPermissions

  // NEW: Function to load permission options with search & pagination
  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    const response = await fetch(`${config.BASE_URL}/api/permissions?name=${inputValue}&page=${page}&size=20`, {
      headers: { Authorization: `Bearer ${localStorage.getItem("authToken")}` },
    });
    const json = await response.json();
    const options = (json.data?.content || []).map((p) => ({
      value: p.name,
      label: p.name,
    }));
    return {
      options,
      hasMore: page < (json.data.totalPages || 1) - 1,
      additional: { page: page + 1 },
    };
  };

  const handlePermissionChange = (selectedOptions) => {
    setFormData({
      ...formData,
      permissions: selectedOptions.map((option) => option.value)
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    console.log("Submitting Payload:", formData);

    fetch(`${config.BASE_URL}/api/roles`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to add role');
        navigate('/role-management');
      })
      .catch((error) => console.error('Error adding role:', error));
  };

  const hasAddPermission = userPermissions.includes("ADD_ROLE");

  return (
    <div className="container">
      {!hasAddPermission ? (
        <div className="alert alert-danger">
          You do not have permission to add roles.
        </div>
      ) : (
        <>
          <div className="d-flex justify-content-between align-items-center mb-4">
            <h1 className="welcomeTitle text-primary">
              <FaPlusCircle className="me-2" /> Add New Role
            </h1>
            <button className="btn btn-outline-secondary" onClick={() => navigate("/role-management")}>
              <FaArrowLeft /> Back to Roles
            </button>
          </div>
          <div className="card shadow p-2">
            <div className="card-body">
              <form onSubmit={handleSubmit}>
                <div className="row g-3">
                  <div className="col-md-3">
                    <label>Role Name</label>
                    <input
                      type="text"
                      className="form-control"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                      required
                    />
                  </div>


                  <div className="col-md-3">
                    <label>Permissions</label>
                    <AsyncPaginate
                      isMulti
                      value={formData.permissions.map(p => ({ value: p, label: p }))}
                      loadOptions={loadOptions}
                      additional={{ page: 0 }}
                      onChange={handlePermissionChange}
                      placeholder="Search and select permissions..."
                    />
                  </div>
                  <div className="col-md-12">

                    <button className="btn btn-primary" type="submit">
                      Add Role
                    </button>
                    <button className="btn btn-secondary ms-2" onClick={() => navigate("/role-management")}>
                      Back
                    </button>

                  </div>

                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default AddRole;
