import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { createSupplier } from "../../services/supplierService";
import { toast } from "react-toastify";
import { getAllCountries, searchCountries } from "../../services/countryService";
import { searchRegions } from "../../services/countryRegionService";
import { searchUsers } from "../../services/userService"; // New import for searching BDEs

const CreateSupplier = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    sellerName: "",
    supplierEmail: "", // will map to "email"
    sellerType: "",
    firstname: "",
    lastname: "",
    addressCompany: "", // will map to "companyName" and addresses[].company
    city: "",
    countryId: "IN",     // updated from "IND" to "IN"
    street: "",
    region: "",
    postcode: "",
    telephone: "",
    dialCode: "+91",      // default dial code for India
    bdeId: "",
    // New supplier details fields:
    sellerEnabled: true,
    companyLogo: "",
    shopBanner: "",
    description: "",
    metaKeywords: "",
    metaDescription: "",
    returnPolicy: "",
    shippingPolicy: "",
    paymentInfo: "",
    fbLink: "",
    googleLink: "",
    twitterLink: "",
    pageUrlKey: ""
  });

  const [countries, setCountries] = useState([]);
  const [regionsData, setRegionsData] = useState([]);
  const [countryInput, setCountryInput] = useState("");
  const [bdeInput, setBdeInput] = useState(""); // New state for BDE input
  const [bdeUsers, setBdeUsers] = useState([]); // New state for BDE users

  useEffect(() => {
    getAllCountries()
      .then((res) => {
        const responseData = res.data.data ? res.data.data : res.data;
        let countriesArr = Array.isArray(responseData.content) ? responseData.content : [];
        if (!countriesArr.some((c) => c.countryId === "IN")) {
          countriesArr.unshift({
            countryId: "IN",
            countryName: "India",
            dialCode: "+91"
          });
        }
        setCountries(countriesArr);
      })
      .catch(() => {
        toast.error("Failed to load countries");
      });
  }, []);

  useEffect(() => {
    if (formData.countryId) {
      const effectiveCountryId = formData.countryId === "IN" ? "IN" : formData.countryId;
      searchRegions({ countryId: effectiveCountryId, code: "", page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          const regionsArr = Array.isArray(responseData.content) ? responseData.content : [];
          setRegionsData(regionsArr);
        })
        .catch(() => {
          setRegionsData([]);
          toast.error("Failed to load regions");
        });
    }
  }, [formData.countryId]);

  useEffect(() => {
    if (formData.countryId && regionsData.length > 0 && !formData.region) {
      setFormData((prev) => ({ ...prev, region: regionsData[0].code }));
    }
  }, [regionsData, formData.countryId, formData.region]);

  useEffect(() => {
    const selected = countries.find((c) => c.countryId === formData.countryId);
    if (selected) setCountryInput(selected.countryName);
  }, [formData.countryId, countries]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value
    }));
  };

  const handleCountryInputChange = (e) => {
    const inputVal = e.target.value;
    setCountryInput(inputVal);
    if (inputVal.length >= 2) {
      searchCountries({ countryName: inputVal, page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          let filteredCountries = Array.isArray(responseData.content) ? responseData.content : [];
          setCountries(filteredCountries);
        })
        .catch(() => {
          toast.error("Failed to load filtered countries");
        });
    }
    const selected = countries.find(
      (c) => c.countryName.toLowerCase() === inputVal.toLowerCase()
    );
    if (selected) {
      setFormData((prev) => ({
        ...prev,
        countryId: selected.countryId,
        dialCode: selected.countryId === "IN" ? "+91" : "+1",
      }));
      const effectiveCountryId = selected.countryId === "IN" ? "IN" : selected.countryId;
      searchRegions({ countryId: effectiveCountryId, code: "", page: 0, size: 10 })
        .then((res) => {
          const responseData = res.data.data ? res.data.data : res.data;
          const regionsArr = Array.isArray(responseData.content) ? responseData.content : [];
          setRegionsData(regionsArr);
        })
        .catch(() => {
          setRegionsData([]);
          toast.error("Failed to load regions");
        });
    }
  };

  const handleBdeInputChange = (e) => {
    const inputVal = e.target.value;
    setBdeInput(inputVal);
    if (inputVal.length >= 2) {
      searchUsers({ name: inputVal, page: 0, size: 10 })
        .then((res) => {
          const users = res.data ? res.data.content : [];
          setBdeUsers(users);
          const matched = users.find(u => u.name.toLowerCase() === inputVal.toLowerCase());
          if (matched) {
            setFormData(prev => ({ ...prev, bdeId: matched.id }));
          }
        })
        .catch(() => {
          toast.error("Failed to load BDE users");
        });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Here you can hard-code or dynamically set userId, e.g.:
    const userId = 113;
    const payload = {
      bdeId: Number(formData.bdeId),
      userId: Number(userId),
      sellerType: formData.sellerType,
      sellerName: formData.sellerName,
      email: formData.supplierEmail,
      sellerEnabled: true,
      companyName: formData.addressCompany,
      contactNumber: formData.telephone,
      dialCode: formData.dialCode,
      companyLogo: formData.companyLogo,
      shopBanner: formData.shopBanner,
      description: formData.description,
      metaKeywords: formData.metaKeywords,
      metaDescription: formData.metaDescription,
      returnPolicy: formData.returnPolicy,
      shippingPolicy: formData.shippingPolicy,
      paymentInfo: formData.paymentInfo,
      fbLink: formData.fbLink,
      googleLink: formData.googleLink,
      twitterLink: formData.twitterLink,
      pageUrlKey: formData.pageUrlKey,
      addresses: [
        {
          city: formData.city,
          company: formData.addressCompany,
          countryId: formData.countryId,
          firstname: formData.firstname,
          lastname: formData.lastname,
          postcode: formData.postcode,
          region: formData.region,
          regionId: 1, // fixed value; update if needed
          street: formData.street,
          telephone: formData.telephone,
          dialCode: formData.dialCode,
        }
      ]
    };

    try {
      await createSupplier(payload);
      toast.success("Supplier created successfully!");
      navigate("/suppliers");
    } catch (error) {
      toast.error(error.message || "Failed to create supplier.");
    }
  };

  return (
    <div className="container-fluid mt-5 text-dark">
      <div className="row justify-content-center">
        <div className="col-xl-10 col-lg-12">
          <div className="card shadow">
            <div className="card-header bg-gradient-primary text-dark">
              <h3 className="mb-0">Create Supplier</h3>
            </div>
            <div className="card-body p-4">
              <form onSubmit={handleSubmit}>
                <fieldset className="border p-3 mb-4">
                  <legend className="w-auto px-2">Basic Information</legend>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label className="form-label">
                        Seller Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="sellerName" value={formData.sellerName} onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Supplier Email <span className="text-danger">*</span>
                      </label>
                      <input type="email" className="form-control" name="supplierEmail" value={formData.supplierEmail} onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">
                        Seller Type <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="sellerType" value={formData.sellerType} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label className="form-label">
                        Select BDE <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="bdeId"
                        list="bdeUsersList"
                        value={bdeInput}
                        onChange={handleBdeInputChange}
                        placeholder="Type BDE name..."
                      />
                      <datalist id="bdeUsersList">
                        {bdeUsers.map((user) => (
                          <option key={user.id} value={user.name} />
                        ))}
                      </datalist>
                    </div>
                  </div>
                </fieldset>

                <fieldset className="border p-3 mb-4">
                  <legend className="w-auto px-2">Address Details</legend>
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="firstname" value={formData.firstname} onChange={handleChange} />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="lastname" value={formData.lastname} onChange={handleChange} />
                    </div>
                    
                    <div className="col-md-3">
                      <label className="form-label">
                        City <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="city" value={formData.city} onChange={handleChange} />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">
                        Country <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="countryId"
                        list="countriesList"
                        value={countryInput}
                        onChange={handleCountryInputChange}
                      />
                      <datalist id="countriesList">
                        {countries.map((country) => (
                          <option key={country.countryId} value={country.countryName}>
                            {country.countryName}
                          </option>
                        ))}
                      </datalist>
                    </div>
                  </div>
                  <div className="row mb-3">
                  <div className="row mb-3">
                    <div className="col-md-3">
                      <label className="form-label">
                        Region <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="region"
                        list="regionsList"
                        value={formData.region}
                        onChange={handleChange}
                      />
                      <datalist id="regionsList">
                        {regionsData.map((region) => (
                          <option key={region.regionId} value={region.code}>
                            {region.defaultName}
                          </option>
                        ))}
                      </datalist>
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">
                        Dial Code
                      </label>
                      <input type="text" className="form-control" name="dialCode" value={formData.dialCode} readOnly />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">
                        Mobile Number <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="telephone" value={formData.telephone} onChange={handleChange} />
                    </div>
                    <div className="col-md-3">
                      <label className="form-label">
                        Postcode <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="postcode" value={formData.postcode} onChange={handleChange} />
                    </div>
                  </div>
                    <div className="col-md-6">
                      <label className="form-label">
                        Street <span className="text-danger">*</span>
                      </label>
                      <input type="text" className="form-control" name="street" value={formData.street} onChange={handleChange} />
                    </div>
                  </div>
                  
                </fieldset>

                <fieldset className="border p-3 mb-4">
                  <legend className="w-auto px-2">Description & SEO</legend>
                  <div className="mb-3">
                    <label className="form-label">Description</label>
                    <textarea className="form-control" name="description" rows="3" value={formData.description} onChange={handleChange} />
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">Meta Keywords</label>
                      <input type="text" className="form-control" name="metaKeywords" value={formData.metaKeywords} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Meta Description</label>
                      <textarea className="form-control" name="metaDescription" rows="2" value={formData.metaDescription} onChange={handleChange} />
                    </div>
                  </div>
                </fieldset>

                <fieldset className="border p-3 mb-4">
                  <legend className="w-auto px-2">Policies & Payment</legend>
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label className="form-label">Return Policy</label>
                      <textarea className="form-control" name="returnPolicy" rows="2" value={formData.returnPolicy} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Shipping Policy</label>
                      <textarea className="form-control" name="shippingPolicy" rows="2" value={formData.shippingPolicy} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col-md-4">
                      <label className="form-label">Payment Info</label>
                      <input type="text" className="form-control" name="paymentInfo" value={formData.paymentInfo} onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Facebook Link</label>
                      <input type="text" className="form-control" name="fbLink" value={formData.fbLink} onChange={handleChange} />
                    </div>
                    <div className="col-md-4">
                      <label className="form-label">Google Link</label>
                      <input type="text" className="form-control" name="googleLink" value={formData.googleLink} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <label className="form-label">Twitter Link</label>
                      <input type="text" className="form-control" name="twitterLink" value={formData.twitterLink} onChange={handleChange} />
                    </div>
                    <div className="col-md-6">
                      <label className="form-label">Page URL Key</label>
                      <input type="text" className="form-control" name="pageUrlKey" value={formData.pageUrlKey} onChange={handleChange} />
                    </div>
                  </div>
                </fieldset>

                <div className="d-grid">
                  <button type="submit" className="btn btn-success btn-lg">Submit</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateSupplier;