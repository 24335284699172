import React, { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { FaArrowLeft, FaFileCsv, FaFilePdf, FaChartLine, FaMoon, FaSun, FaCommentDots } from "react-icons/fa";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import Chatbot from "react-chatbot-kit";
import "react-chatbot-kit/build/main.css";
import config from "./chatbot/ChatbotConfig";
import MessageParser from "./chatbot/ChatbotMessageParser";
import ActionProvider from "./chatbot/ChatbotActionProvider";
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, Legend, ResponsiveContainer } from "recharts";

const InventoryDetails = () => {
  const { sku } = useParams();
  const navigate = useNavigate();
  const [darkMode, setDarkMode] = useState(false);
  const [showChat, setShowChat] = useState(false);

  // Dummy Product Data
  const product = {
    sku,
    productName: "AQUAPROT PROTEIN POWDER",
    price: "₹259.20",
    hsnCode: "12345678",
    totalQuantity: 498,
    predictedStockAI: {
      exponential: 450,
      lstm: 430,
      arima: 409,
    },
  };

  // AI Stock Prediction Data (for Bar Chart)
  const barChartData = [
    { model: "Current Stock", value: product.totalQuantity, color: "#4caf50" },
    { model: "Exp. Smoothing", value: product.predictedStockAI.exponential, color: "#2196F3" },
    { model: "LSTM Model", value: product.predictedStockAI.lstm, color: "#FF9800" },
    { model: "ARIMA Model", value: product.predictedStockAI.arima, color: "#F44336" },
  ];

  // CSV Data
  const csvData = [
    ["Product Name", "SKU", "Price", "HSN Code", "Total Quantity", "Exp. Smoothing", "LSTM Model", "ARIMA Model"],
    [product.productName, product.sku, product.price, product.hsnCode, product.totalQuantity,
      product.predictedStockAI.exponential, product.predictedStockAI.lstm, product.predictedStockAI.arima]
  ];

  // Export to PDF
  const exportToPDF = () => {
    const input = document.getElementById("report-section");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 100);
      pdf.save(`AI_Inventory_Report_${product.sku}.pdf`);
    });
  };

  return (
    <div className={darkMode ? "bg-dark text-white p-5" : "container mt-4"}>
      {/* Page Header */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
        <h2 className="text-primary text-center text-md-start">
          📦 Inventory Details - {product.productName} ({product.sku})
        </h2>

        {/* Action Buttons */}
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <button className="btn btn-dark" onClick={() => navigate("/inventory")}>
            <FaArrowLeft /> Back to Inventory
          </button>
          <button className="btn btn-sm btn-secondary" onClick={() => setDarkMode(!darkMode)}>
            {darkMode ? <FaSun /> : <FaMoon />} Toggle Dark Mode
          </button>
        </div>
      </div>

      {/* Product Info Section */}
      <div className="card shadow-lg p-4 text-white" style={{ backgroundColor: "#009BFE" }}>
        <div className="row">
          <div className="col-md-4">
            <h5>Price</h5>
            <h4 className="text-success fw-bold">{product.price}</h4>
          </div>
          <div className="col-md-4">
            <h5>HSN Code</h5>
            <h4 className="fw-bold">{product.hsnCode}</h4>
          </div>
          <div className="col-md-4">
            <h5>Total Quantity</h5>
            <h4 className="text-primary fw-bold">{product.totalQuantity}</h4>
          </div>
        </div>
      </div>

      {/* AI Predictions Bar Chart */}
      <div className="card shadow-lg p-4 mt-4">
        <h4 className="text-primary">📊 AI Predicted Stock Levels</h4>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={barChartData} barSize={50}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="model" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </div>

      {/* Export & View Chart Buttons */}
<div className="card shadow-lg p-4 mt-4">
  <h4 className="text-primary">📄 Reports & Charts</h4>
  <div className="d-flex justify-content-center gap-3">
    <button className="btn btn-success">
      <CSVLink data={csvData} filename={`AI_Inventory_Report_${product.sku}.csv`} className="text-white text-decoration-none">
        <FaFileCsv className="me-2" /> Export as CSV
      </CSVLink>
    </button>
    <button className="btn btn-danger" onClick={exportToPDF}>
      <FaFilePdf className="me-2" /> Export as PDF
    </button>
    <button className="btn btn-primary" onClick={() => navigate(`/inventory/${sku}/charts`)}>
      <FaChartLine className="me-2" /> View AI Charts
    </button>
    <button className="btn btn-info" onClick={() => navigate(`/forecast`)}>
      <FaChartLine className="me-2" /> View Forecast Page
    </button>
  </div>
</div>


      {/* AI Chat Assistant Button */}
      <button
        className="btn btn-info position-fixed bottom-0 end-0 m-3"
        onClick={() => setShowChat(!showChat)}
      >
        <FaCommentDots className="me-2" /> {showChat ? "Close Chat" : "Ask AI"}
      </button>

      {/* AI Chatbot UI */}
      {showChat && (
        <div className="position-fixed bottom-0 end-0 mb-5 me-3 shadow-lg" style={{ width: "350px" }}>
          <Chatbot
            config={config}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
          />
        </div>
      )}
    </div>
  );
};

export default InventoryDetails;
