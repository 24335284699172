import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  approveInterBranchTransfer,
  dispatchInterBranchTransfer,
  receiveInterBranchTransfer,
  getInterBranchTransferById,
  getStatusHistoryByTransferId
} from "../../services/interBranchTransferService";
import { getWarehouseById } from "../../services/warehouseService";
import { getGRNs } from "../../services/interbranchGrnService"; // Import the service to fetch GRNs
import { toast } from "react-toastify";
import { FaArrowLeft, FaCheck, FaTruck, FaBoxOpen, FaWarehouse, FaMoneyBillWave, FaFilePdf, FaFileCsv } from "react-icons/fa";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import "./InterBranchTransferDetails.css";

const InterBranchTransferDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [transfer, setTransfer] = useState(null);
  const [sourceBranch, setSourceBranch] = useState(null);
  const [destinationBranch, setDestinationBranch] = useState(null);
  const [loading, setLoading] = useState(true);
  const [comment, setComment] = useState(""); // State for comment
  const [statusHistory, setStatusHistory] = useState([]); // State for status history
  const [grns, setGRNs] = useState([]); // State for GRNs

  useEffect(() => {
    async function fetchTransfer() {
      try {
        const response = await getInterBranchTransferById(id);
        if (response.statusCode === 200) {
          setTransfer(response.data);

          // Fetch Source Branch Details
          fetchBranchDetails(response.data.sourceBranchId, setSourceBranch);

          // Fetch Destination Branch Details
          fetchBranchDetails(response.data.destinationBranchId, setDestinationBranch);

          // Fetch Status History
          const statusHistoryResponse = await getStatusHistoryByTransferId(id);
          if (statusHistoryResponse.statusCode === 200) {
            setStatusHistory(statusHistoryResponse.data);
          }

          // Fetch GRNs related to this transfer
          fetchGRNs(response.data.id);
        } else {
          toast.error("Failed to fetch transfer details!");
        }
      } catch (error) {
        toast.error("Error fetching transfer details.");
      } finally {
        setLoading(false);
      }
    }
    fetchTransfer();
  }, [id]);

  const fetchBranchDetails = async (branchId, setBranch) => {
    if (!branchId) return;
    try {
      const response = await getWarehouseById(branchId);
      if (response.statusCode === 200) {
        setBranch(response.data);
      } else {
        toast.warn("Branch details not found.");
      }
    } catch (error) {
      toast.error("Error fetching branch details.");
    }
  };

  const fetchGRNs = async (transferId) => {
    try {
      const response = await getGRNs({ transferId });
      if (response.statusCode === 200) {
        setGRNs(response.data?.content || []);
      } else {
        toast.warn("No GRNs found for this transfer.");
      }
    } catch (error) {
      toast.error("Error fetching GRNs.");
    }
  };

  // ✅ Approve Transfer
  const handleApprove = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
    const userId = userDetails?.userId; // Extract userId from userDetails

    const payload = {
      id,
      approvedBy: userId,
      comment: comment || "Approved by warehouse manager" // Include comment
    };

    try {
      const response = await approveInterBranchTransfer(payload);
      if (response.statusCode === 200) {
        toast.success("Transfer Approved Successfully!");
        setTransfer((prev) => ({ ...prev, status: "APPROVED" }));
      } else {
        toast.error(`Approval Failed: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error approving transfer.");
    }
  };

  // ✅ Dispatch Transfer
  const handleDispatch = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
    const userId = userDetails?.userId; // Extract userId from userDetails

    const payload = {
      id,
      dispatchedBy: userId,
      comment: comment || "Shipped via Blue Dart, Tracking ID: ABC123" // Include comment
    };

    try {
      const response = await dispatchInterBranchTransfer(payload);
      if (response.statusCode === 200) {
        toast.success("Transfer Dispatched Successfully!");
        setTransfer((prev) => ({ ...prev, status: "DISPATCHED" }));
      } else {
        toast.error(`Dispatch Failed: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error dispatching transfer.");
    }
  };

  // ✅ Receive Transfer
  const handleReceive = async () => {
    const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
    const userId = userDetails?.userId; // Extract userId from userDetails

    const payload = {
      id,
      receivedBy: userId,
      comment: comment || "Received and verified. No damages." // Include comment
    };

    try {
      const response = await receiveInterBranchTransfer(payload);
      if (response.statusCode === 200) {
        toast.success("Transfer Received Successfully!");
        setTransfer((prev) => ({ ...prev, status: "RECEIVED" }));
      } else {
        toast.error(`Receiving Failed: ${response.message}`);
      }
    } catch (error) {
      toast.error("Error receiving transfer.");
    }
  };

  // CSV Data
  const csvData = transfer ? [
    ["SKU", "Name", "Quantity", "Price (₹)", "Subtotal (₹)", "CGST (₹)", "SGST (₹)", "Total (₹)"],
    ...transfer.transferItems.map(item => [
      item.sku,
      item.name,
      item.quantity,
      `₹${item.price ? item.price.toFixed(2) : "0.00"}`,
      `₹${item.subtotal ? item.subtotal.toFixed(2) : "0.00"}`,
      `₹${item.cgstAmount ? item.cgstAmount.toFixed(2) : "0.00"}`,
      `₹${item.sgstAmount ? item.sgstAmount.toFixed(2) : "0.00"}`,
      `₹${item.taxAmount ? item.taxAmount.toFixed(2) : "0.00"}`
    ])
  ] : [];

  // Export to PDF
  const exportToPDF = () => {
    const input = document.getElementById("transfer-details-section");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 100);
      pdf.save(`InterBranch_Transfer_Details_${transfer.id}.pdf`);
    });
  };
  if (loading) return <h4 className="text-center mt-4">Loading...</h4>;
  if (!transfer) return <h4 className="text-center mt-4">Transfer Not Found ❌</h4>;
  return (
    <div className="container mt-4">
      {/* 🔹 Page Header */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <h2 className="text-primary">
          <FaBoxOpen className="me-2" /> Transfer #{transfer.id}
        </h2>
        <div className="d-flex gap-2">
          <button className="btn btn-dark" onClick={() => navigate(-1)}>
            <FaArrowLeft /> Back
          </button>
          <div className="btn-group">
            <button className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              More Actions ▼
            </button>
            <ul className="dropdown-menu">
              <li>
                <CSVLink data={csvData} filename={`InterBranch_Transfer_Details_${transfer.id}.csv`} className="dropdown-item">
                  <FaFileCsv className="me-2" /> Download as CSV
                </CSVLink>
              </li>
              <li>
                <button className="dropdown-item" onClick={exportToPDF}>
                  <FaFilePdf className="me-2" /> Download as PDF
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* 🔹 Transfer Details */}
      <div id="transfer-details-section">
        <div className="card shadow-lg p-4 mb-4 transfer-details-card">
          <h4 className="text-primary"><FaBoxOpen className="me-2" /> Transfer Details</h4>
          <table className="table table-bordered">
            <tbody>
              <tr>
                <th>Transfer Number</th>
                <td>{transfer.id}</td>
              </tr>
              <tr>
                <th>Source Branch</th>
                <td>{transfer.sourceBranchName}</td>
              </tr>
              <tr>
                <th>Destination Branch</th>
                <td>{transfer.destinationBranchName}</td>
              </tr>
              <tr>
                <th>Status</th>
                <td>
                  <span className={`badge ${getStatusBadgeClass(transfer.status)}`}>
                    {transfer.status}
                  </span>
                </td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{new Date(transfer.createdAt).toLocaleString()}</td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* 🔹 Branch Details */}
        <div className="row">
          {/* Source Branch Details */}
          {sourceBranch && (
            <div className="col-md-6">
              <div className="card shadow-lg p-4 mb-4 warehouse-address-section">
                <h4 className="text-primary"><FaWarehouse /> Source Branch Details</h4>
                <div>
                  <h5 className="text-uppercase text-secondary">Source Branch Address</h5>
                  <p><strong>{sourceBranch.name}</strong></p>
                  <p>{sourceBranch.address}, {sourceBranch.city}, {sourceBranch.pincode}</p>
                  <p>{sourceBranch.email} | {sourceBranch.contact}</p>
                </div>
              </div>
            </div>
          )}

          {/* Destination Branch Details */}
          {destinationBranch && (
            <div className="col-md-6">
              <div className="card shadow-lg p-4 mb-4 warehouse-address-section">
                <h4 className="text-primary"><FaWarehouse /> Destination Branch Details</h4>
                <div>
                  <h5 className="text-uppercase text-secondary">Destination Branch Address</h5>
                  <p><strong>{destinationBranch.name}</strong></p>
                  <p>{destinationBranch.address}, {destinationBranch.city}, {destinationBranch.pincode}</p>
                  <p>{destinationBranch.email} | {destinationBranch.contact}</p>
                </div>
              </div>
            </div>
          )}
        </div>

        {/* 🔹 Transfer Items */}
        <div className="card shadow-lg p-4 mb-4 transfer-items-card">
          <h4 className="text-primary"><FaBoxOpen className="me-2" /> Transfer Items</h4>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Quantity</th>
                  <th>Price (₹)</th>
                  <th>Subtotal (₹)</th>
                  <th>CGST (₹)</th>
                  <th>SGST (₹)</th>
                  <th>Total (₹)</th>
                </tr>
              </thead>
              <tbody>
                {transfer.transferItems.map((item) => (
                  <React.Fragment key={item.id}>
                    <tr>
                      <td>{item.sku}</td>
                      <td>{item.name}</td>
                      <td>{item.quantity}</td>
                      <td>₹{item.price ? item.price.toFixed(2) : "0.00"}</td>
                      <td>₹{item.subtotal ? item.subtotal.toFixed(2) : "0.00"}</td>
                      <td>₹{item.cgstAmount ? item.cgstAmount.toFixed(2) : "0.00"}</td>
                      <td>₹{item.sgstAmount ? item.sgstAmount.toFixed(2) : "0.00"}</td>
                      <td>₹{item.taxAmount ? item.taxAmount.toFixed(2) : "0.00"}</td>
                    </tr>

                    {/* Batch Details (nested table) */}
                    {item.batchDetails?.length > 0 && (
                      <tr>
                        <td colSpan="8">
                          <h6 className="mt-2">Batch Details</h6>
                          <table className="table table-sm table-bordered">
                            <thead className="table-light">
                              <tr>
                                <th>Batch Number</th>
                                <th>Stock Quantity</th>
                                <th>Cost Price (₹)</th>
                                <th>Selling Price (₹)</th>
                                <th>Subtotal (₹)</th>
                                <th>CGST (₹)</th>
                                <th>SGST (₹)</th>
                                <th>Total (₹)</th>
                              </tr>
                            </thead>
                            <tbody>
                              {item.batchDetails.map((batch) => (
                                <tr key={batch.id}>
                                  <td>{batch.batchNumber}</td>
                                  <td>{batch.stockQuantity}</td>
                                  <td>₹{batch.costPrice ? batch.costPrice.toFixed(2) : "0.00"}</td>
                                  <td>₹{batch.sellingPrice ? batch.sellingPrice.toFixed(2) : "0.00"}</td>
                                  <td>₹{batch.subtotal ? batch.subtotal.toFixed(2) : "0.00"}</td>
                                  <td>₹{batch.cgstAmount ? batch.cgstAmount.toFixed(2) : "0.00"}</td>
                                  <td>₹{batch.sgstAmount ? batch.sgstAmount.toFixed(2) : "0.00"}</td>
                                  <td>₹{batch.taxAmount ? batch.taxAmount.toFixed(2) : "0.00"}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* 🔹 Payment Summary */}
        <div className="card shadow-lg p-4 mb-4 payment-summary">
          <h4 className="text-primary d-flex align-items-center">
            <FaMoneyBillWave className="me-2" /> Payment Summary
          </h4>
          <table className="table table-bordered table-striped">
            <tbody>
              <tr>
                <th className="bg-light">Subtotal</th>
                <td>₹{(transfer.subtotal ?? 0).toFixed(2)}</td>
              </tr>
              <tr>
                <th className="bg-light">CGST</th>
                <td>₹{(transfer.cgstAmount ?? 0).toFixed(2)}</td>
              </tr>
              <tr>
                <th className="bg-light">SGST</th>
                <td>₹{(transfer.sgstAmount ?? 0).toFixed(2)}</td>
              </tr>
              <tr>
                <th className="bg-light">Grand Total</th>
                <td><strong className="text-success">₹{(transfer.grandTotal ?? 0).toFixed(2)}</strong></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      {/* 🔹 Transfer Actions */}
      <div className="d-flex gap-2">
        {transfer.status === "PENDING" && (
          <>
            <input
              type="text"
              className="form-control"
              placeholder="Enter approval comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button className="btn btn-success" onClick={handleApprove}>
              <FaCheck /> Approve
            </button>
          </>
        )}
        {transfer.status === "APPROVED" && (
          <>
            <input
              type="text"
              className="form-control"
              placeholder="Enter dispatch comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            <button className="btn btn-warning" onClick={handleDispatch}>
              <FaTruck /> Dispatch
            </button>
          </>
        )}
        {transfer.status === "DISPATCHED" && (
          <button className="btn btn-primary" onClick={handleReceive}>
            <FaBoxOpen /> Receive
          </button>
        )}
      </div>

      {/* 🔹 Status History */}
      {statusHistory.length > 0 && (
        <div className="card shadow-lg p-4 mb-4 status-history-card">
          <h4 className="text-primary"><FaBoxOpen className="me-2" /> Status History</h4>
          <table className="table table-bordered">
            <thead className="table-dark">
              <tr>
                <th>Status</th>
                <th>Updated By</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody>
              {statusHistory.map((history) => (
                <tr key={history.id}>
                  <td>{history.status}</td>
                  <td>{history.updatedByName}</td>
                  <td>{new Date(history.updatedAt).toLocaleString()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      {/* 🔹 GRN Details Navigation */}
      {grns.length > 0 && (
        <div className="card shadow-lg p-4 mb-4 grn-details-card">
          <h4 className="text-primary"><FaBoxOpen className="me-2" /> Goods Receipt Notes (GRNs)</h4>
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead className="table-dark">
                <tr>
                  <th>GRN ID</th>
                  <th>Transfer Number</th>
                  <th>Source Branch</th>
                  <th>Destination Branch</th>
                  <th>Status</th>
                  <th>Subtotal (₹)</th>
                  <th>Tax Amount (₹)</th>
                  <th>Grand Total (₹)</th>
                  <th>Received At</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {grns.map((grn) => (
                  <tr key={grn.id}>
                    <td>{grn.id}</td>
                    <td>{grn.transferNumber}</td>
                    <td>{grn.sourceBranchName}</td>
                    <td>{grn.destinationBranchName}</td>
                    <td>{grn.status}</td>
                    <td>₹{grn.subtotal.toFixed(2)}</td>
                    <td>₹{grn.taxAmount.toFixed(2)}</td>
                    <td>₹{grn.grandTotal.toFixed(2)}</td>
                    <td>{new Date(grn.receivedAt).toLocaleString()}</td>
                    <td>
                      <button
                        className="btn btn-info"
                        onClick={() => navigate(`/interbranch-grns/view/${grn.id}`)}
                      >
                        View GRN Details
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

// ✅ Status Badge Helper
const getStatusBadgeClass = (status) => {
  switch (status) {
    case "PENDING":
      return "bg-secondary";
    case "APPROVED":
      return "bg-success";
    case "DISPATCHED":
      return "bg-warning text-dark";
    case "RECEIVED":
      return "bg-primary";
    case "CANCELLED":
      return "bg-danger";
    default:
      return "bg-secondary";
  }
};

export default InterBranchTransferDetails;