import React from "react";
import { FaFacebook, FaTwitter, FaInstagram, FaHeart } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className="bg-light border-top py-1">
  <div className="container d-flex flex-column flex-md-row justify-content-between align-items-center text-center text-muted small gap-2">
    <div>
      © {new Date().getFullYear()} <strong>PAX</strong> — Made with <FaHeart className="text-danger" /> by <strong>VIRAMYND AI Softwares</strong>
    </div>
    <div className="d-flex gap-3">
      <a href="/privacy-policy" className="text-decoration-none text-muted">Privacy</a>
      <a href="/terms" className="text-decoration-none text-muted">Terms</a>
      <a href="/contact" className="text-decoration-none text-muted">Contact</a>
      <a href="https://facebook.com" target="_blank" rel="noopener noreferrer" className="text-muted"><FaFacebook /></a>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer" className="text-muted"><FaTwitter /></a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer" className="text-muted"><FaInstagram /></a>
    </div>
  </div>
</footer>

  );
};

export default Footer;
