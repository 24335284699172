import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProductById } from '../services/productService';
import { ToastContainer, toast } from 'react-toastify';
import "../css/ProductView.css";
import "../css/animate.css";

const ProductView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {
        // handle error if needed
      }
    }
  }, []);

  useEffect(() => {
    const fetchProduct = async () => {
      setLoading(true);
      try {
        const res = await getProductById(id);
        setProduct(res.data);
      } catch (error) {
        toast.error("Error fetching product details!");
      } finally {
        setLoading(false);
      }
    };
    fetchProduct();
  }, [id]);

  if (loading) return <div className="container text-center mt-4">Loading...</div>;
  if (!product) return <div className="container text-center mt-4">No product found.</div>;

  if (!userPermissions.includes("VIEW_PRODUCT")) {
    return (
      <div className="container text-center mt-4">
        <h4 className="text-danger">You do not have permission to view this product.</h4>
      </div>
    );
  }

  return (
    <div 
      className="container mt-4 p-4 product-view-container animate__animated animate__fadeIn" 
      style={{ background: "#fff", color: "#000" }}
    >
      <ToastContainer position="top-right" autoClose={3000} />
      <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          &larr; Back to List
        </button>
        <div className="d-flex gap-2">
          {userPermissions.includes("EDIT_PRODUCT") && (
            <button className="btn btn-warning" onClick={() => navigate(`/products/edit/${product.id}`)}>
              Edit Product
            </button>
          )}
          {userPermissions.includes("DELETE_PRODUCT") && (
            <button className="btn btn-danger" onClick={() => alert("Delete Product functionality here")}>
              Delete Product
            </button>
          )}
          {userPermissions.includes("ADD_PRODUCT") && (
            <button className="btn btn-primary" onClick={() => navigate("/products/add")}>
              Add Product
            </button>
          )}
        </div>
      </div>
      <div className="card bg-white text-dark shadow-sm border">
        <div className="card-header">
          <h2>{product.name}</h2>
          <p className="mb-0">
            {product.enableDisable === 1 ? (
              <span className="badge bg-success">Enabled</span>
            ) : (
              <span className="badge bg-danger">Disabled</span>
            )}
          </p>
        </div>
        <div className="card-body">
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>ID:</strong>
              <div>{product.id}</div>
            </div>
            <div className="col-md-3">
              <strong>SKU:</strong>
              <div>{product.sku || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Category ID:</strong>
              <div>{product.categoryId || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Price:</strong>
              <div>₹{product.price ? product.price.toFixed(2) : "N/A"}</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>MRP:</strong>
              <div>₹{product.mrp ? product.mrp.toFixed(2) : "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Tax Percent:</strong>
              <div>{product.taxPercent !== null ? `${product.taxPercent}%` : "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>HSN Code:</strong>
              <div>{product.hsnCode || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>URL Key:</strong>
              <div>{product.urlKey || "N/A"}</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>Brand:</strong>
              <div>{product.brand || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Brand Name:</strong>
              <div>{product.brandName || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Manufacturer Code:</strong>
              <div>{product.manufacturerCode || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Manufacturer Name:</strong>
              <div>{product.manufacturerName || "N/A"}</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>Parent Category:</strong>
              <div>{product.parentCategory || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Parent Category ID:</strong>
              <div>{product.parentCategoryId !== undefined ? product.parentCategoryId : "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Category Name:</strong>
              <div>{product.categoryName || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Generic Name:</strong>
              <div>{product.genericName || "N/A"}</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>Country Of Origin:</strong>
              <div>{product.countryOfOrigin || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Created At:</strong>
              <div>{product.createdAt || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Updated At:</strong>
              <div>{product.updatedAt || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Subcategory ID:</strong>
              <div>{product.subcategoryId || "N/A"}</div>
            </div>
          </div>
          <div className="row mb-3">
            <div className="col-md-3">
              <strong>Subcategory:</strong>
              <div>{product.subcategory || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Country Of Origin Name:</strong>
              <div>{product.countryOfOriginName || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Primary UOM:</strong>
              <div>{product.primaryUom || "N/A"}</div>
            </div>
            <div className="col-md-3">
              <strong>Alternate UOMs:</strong>
              <div>{product.alternateUoms || "N/A"}</div>
            </div>
          </div>
          {product.imageUrl && (
            <div className="row mb-3">
              <div className="col-12">
                <strong>Image:</strong>
                <div>
                  <img src={product.imageUrl} alt={product.imageAltText || product.name} className="img-fluid" />
                </div>
              </div>
            </div>
          )}

          <div className="card mt-4 bg-light text-dark">
            <div className="card-header">
              SEO &amp; Description Details
            </div>
            <div className="card-body">
              <div className="mb-3">
                <strong>Slug:</strong>
                <div>{product.slug || "N/A"}</div>
              </div>
              <div className="mb-3">
                <strong>Meta Title:</strong>
                <div>{product.metaTitle || "N/A"}</div>
              </div>
              <div className="mb-3">
                <strong>Meta Description:</strong>
                <div>{product.metaDescription || "N/A"}</div>
              </div>
              <div className="mb-3">
                <strong>Meta Keywords:</strong>
                <div>{product.metaKeywords || "N/A"}</div>
              </div>
              <div className="mb-3">
                <strong>Canonical URL:</strong>
                <div>
                  {product.canonicalUrl ? (
                    <a href={product.canonicalUrl} target="_blank" rel="noopener noreferrer" className="text-dark">
                      {product.canonicalUrl}
                    </a>
                  ) : "N/A"}
                </div>
              </div>
              <div className="mb-3">
                <strong>Image Alt Text:</strong>
                <div>{product.imageAltText || "N/A"}</div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <strong>Description:</strong>
                  <div>{product.description || "N/A"}</div>
                </div>
                <div className="col-md-6">
                  <strong>Short Description:</strong>
                  <div>{product.shortDescription || "N/A"}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button className="btn btn-secondary mt-3" onClick={() => navigate(-1)}>
          &larr; Back to List
        </button>
      </div>
    </div>
  );
};

export default ProductView;
