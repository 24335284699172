import React, { useEffect, useState, useMemo } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { getPurchaseOrderById } from "../../services/purchaseOrderService";
import { getDebitNoteList } from "../../services/debitNoteService";
import { getGRNList } from "../../services/grnService";
import { toast } from "react-toastify";

import {
  FaArrowLeft,
  FaEdit,
  FaClipboardList,
  FaBox,
  FaMoneyBillWave,
  FaWarehouse,
  FaMapMarkerAlt,
  FaFileInvoice,
  FaClipboardCheck,
  FaFileCsv,
  FaFilePdf,
  FaPlusCircle
} from "react-icons/fa";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./PurchaseOrderDetails.css"; // Import custom CSS for styling
import { updateStatusHistory } from "../../services/poStatusHistoryService";

const PurchaseOrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [grns, setGRNs] = useState([]); // ✅ State for GRNs
  const [debitNotes, setDebitNotes] = useState([]);
  const [userPermissions, setUserPermissions] = useState([]);

  // NEW: Procurement and Finance approval states
  const [procurementSelect, setProcurementSelect] = useState("");
  const [procurementRemark, setProcurementRemark] = useState("");
  const [financeSelect, setFinanceSelect] = useState("");
  const [financeRemark, setFinanceRemark] = useState("");

  useEffect(() => {
    const fetchDetails = async () => {
      try {
        const response = await getPurchaseOrderById(id);
        if (response.statusCode === 200) {
          setPurchaseOrder(response.data);

          // ✅ Fetch related GRNs
          const grnResponse = await getGRNList({ purchaseOrderId: id });
          if (grnResponse.statusCode === 200) {
            setGRNs(grnResponse.data?.content || []);
          } else {
            toast.warn("No GRNs found for this purchase order.");
          }

          // ✅ Fetch related Debit Notes
          const debitNoteResponse = await getDebitNoteList({ purchaseOrderId: id });
          if (debitNoteResponse.statusCode === 200) {
            setDebitNotes(debitNoteResponse.data?.content || []);
          } else {
            toast.warn("No Debit Notes found for this purchase order.");
          }
        } else {
          toast.error("Purchase Order not found!");
          navigate("/purchase-orders");
        }
      } catch (error) {
        toast.error("Error fetching purchase order details.");
        navigate("/purchase-orders");
      } finally {
        setLoading(false);
      }
    };

    fetchDetails();
  }, [id, navigate]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if(details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch(e) {
        // handle error if needed
      }
    }
  }, []);

  // NEW: Procurement Approval handler using PoStatusHistoryService
  const handleProcurementSubmit = async () => {
    if (!procurementSelect) {
      toast.warn("Please select a status for Superior Approval.");
      return;
    }
    const status = procurementSelect === "Approve" ? "Approve" : "Reject";
    try {
      await updateStatusHistory(purchaseOrder.id, "Superior", status, procurementRemark);
      toast.success("Superior approval submitted.");
      setPurchaseOrder(prev => ({ ...prev, procurementStatus: status, procurementRemark }));
      window.location.reload(); // Reload page after approval
    } catch (error) {
      toast.error("Error submitting Superior approval.");
    }
  };

  // NEW: Finance Approval handler using PoStatusHistoryService
  const handleFinanceSubmit = async () => {
    if (!financeSelect) {
      toast.warn("Please select a status for finance.");
      return;
    }
    const status = financeSelect === "Approve" ? "Approve" : "Reject";
    try {
      await updateStatusHistory(purchaseOrder.id, "Finance", status, financeRemark);
      toast.success("Finance approval submitted.");
      setPurchaseOrder(prev => ({ ...prev, financeStatus: status, financeRemark }));
      window.location.reload(); // Reload page after approval
    } catch (error) {
      toast.error("Error submitting finance approval.");
    }
  };

  // CSV Data memoized for optimisation
  const csvData = useMemo(() => (
    purchaseOrder ? [
      ["Product Name", "Price", "UOM", "Qty Ordered", "GST (%)", "GST (₹)", "Total (₹)"],
      ...purchaseOrder.items.map(item => [
        item.name,
        `₹${item.price.toFixed(2)}`,
        item.primaryUom,
        item.qtyOrdered,
        item.taxPercent.toFixed(2),
        `₹${item.taxAmount.toFixed(2)}`,
        `₹${item.rowTotal.toFixed(2)}`
      ])
    ] : []
  ), [purchaseOrder]);

  const pendingInwardQty = useMemo(() => {
    if (!purchaseOrder) return 0;
    return purchaseOrder.items.reduce(
      (sum, item) => sum + (item.qtyOrdered - ((item.qtyInwarded || 0) + (item.qtyCanceled || 0))),
      0
    );
  }, [purchaseOrder]);

  // Export to PDF
  const exportToPDF = () => {
    const input = document.getElementById("purchase-order-details-section");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 100);
      pdf.save(`Purchase_Order_Details_${purchaseOrder.incrementId}.pdf`);
    });
  };

  if (loading) return <h5 className="text-center mt-4">Loading purchase order details... 🌀</h5>;
  if (!purchaseOrder) return <h5 className="text-center mt-4 text-danger">Purchase Order not found ❌</h5>;

  return (
    <div className="container">
      {/* Page Header */}
      <div className="d-flex flex-column flex-md-row justify-content-between align-items-center mb-4">
        <h1 className="welcomeTitle text-primary">
          <FaClipboardList className="me-2" /> Purchase Order #{purchaseOrder.incrementId}
        </h1>
        
        {/* Action Buttons */}
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <button className="btn btn-dark btn-sm" onClick={() => navigate("/purchase-orders")}>
            <FaArrowLeft /> Back to Purchase Orders
          </button>
          {/* Conditionally render Create GRN button based on pendingInwardQty and CREATE_GRN permission */}
          {pendingInwardQty !== 0 && userPermissions.includes("CREATE_GRN") && (
            <Link to={`/create-grn?poNumber=${purchaseOrder.incrementId}`} className="btn btn-outline-success">
              <FaPlusCircle className="me-2" /> Create GRN
            </Link>
          )}
          <button className="btn btn-sm btn-outline-secondary">
            <FaEdit /> Edit
          </button>
          <div className="btn-group">
            <button className="btn btn-sm btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              More Actions ▼
            </button>
            <ul className="dropdown-menu">
              <li>
                <CSVLink data={csvData} filename={`Purchase_Order_Details_${purchaseOrder.incrementId}.csv`} className="dropdown-item">
                  <FaFileCsv className="me-2" /> Download as CSV
                </CSVLink>
              </li>
              <li>
                <button className="dropdown-item" onClick={exportToPDF}>
                  <FaFilePdf className="me-2" /> Download as PDF
                </button>
              </li>
              
            </ul>
          </div>
        </div>
      </div>

      {/* Supplier & Address Info */}
      <div id="purchase-order-details-section">
        <div className="card shadow p-4 rounded">
          <h4 className="text-primary">
            <FaClipboardList className="me-2" /> Supplier & Address Info
          </h4>
          <div className="row">
            <div className="col-md-6">
              <div className="card p-3">
                <h5><FaMapMarkerAlt className="me-2" /> Billing Address</h5>
                {purchaseOrder.addresses?.filter(addr => addr.addressType === "billing").map(addr => (
                  <div key={addr.id}>
                    <p><strong>{addr.firstname} {addr.lastname}</strong></p>
                    <p>{addr.street}</p>
                    <p>{addr.city}, {addr.postcode}</p>
                    <p>{addr.region}</p>
                    <p>{addr.email} | {addr.telephone}</p>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-md-6">
              <div className="card p-3">
                <h5><FaMapMarkerAlt className="me-2" /> Shipping Address</h5>
                {purchaseOrder.addresses?.filter(addr => addr.addressType === "shipping").map(addr => (
                  <div key={addr.id}>
                    <p><strong>{addr.firstname} {addr.lastname}</strong></p>
                    <p>{addr.street}</p>
                    <p>{addr.city}, {addr.postcode}</p>
                    <p>{addr.region}</p>
                    <p>{addr.email} | {addr.telephone}</p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Warehouse Address Details */}
        <div className="card shadow p-4 mt-4 rounded">
          <h4 className="text-primary">
            <FaWarehouse /> Warehouse Addresses
          </h4>
          <div className="row">
            {purchaseOrder.warehouseAddresses.map((warehouse, index) => (
              <div key={index} className="col-md-6">
                <div className="card p-3">
                  <h5><FaMapMarkerAlt /> {warehouse.addressType.toUpperCase()} Address</h5>
                  <p><strong>{warehouse.name}</strong></p>
                  <p>{warehouse.address}, {warehouse.city}, {warehouse.pincode}</p>
                  <p>{warehouse.email} | {warehouse.mobileNumber}</p>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Item Details */}
        <div className="card shadow p-4 mt-4 rounded">
          <div className="d-flex justify-content-between align-items-center">
            <h4 className="text-primary mb-0">
              <FaBox /> Ordered Items
            </h4>
            <div>
              <span className="badge bg-secondary me-2">
                Total Units: {purchaseOrder.totalItemCount}
              </span>
              <span className="badge bg-info">
                Pending Inward Qty: {pendingInwardQty}
              </span>
            </div>
          </div>
          <div className="table-responsive mt-3">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th>ID</th>
                  <th>Order ID</th>
                  <th>Product ID</th>
                  <th>SKU</th>
                  <th>Name</th>
                  <th>Qty Ordered</th>
                  <th>Inward Status</th> {/* Replaces Qty Inwarded */}
                  <th>Qty Canceled</th>
                  <th>Price</th>
                  <th>MRP</th>
                  <th>Tax (%)</th>
                  <th>Tax Amount</th>
                  <th>Discount (%)</th>
                  <th>Discount Amount</th>
                  <th>Row Total</th>
                  <th>Price Incl Tax</th>
                  <th>Row Total Incl Tax</th>
                  <th>HSN Code</th>
                  <th>Primary UOM</th>
                  <th>Alternate UOM</th>
                  <th>Conversion Factor</th>
                </tr>
              </thead>
              <tbody>
                {purchaseOrder.items.map((item) => (
                  <tr key={item.id}>
                    <td>{item.id}</td>
                    <td>{item.orderId}</td>
                    <td>{item.productId}</td>
                    <td>{item.sku}</td>
                    <td>{item.name}</td>
                    <td>{item.qtyOrdered}</td>
                    <td>
                      <div className="d-flex flex-column">
                        <span className="badge bg-success mb-1">
                          Inwarded: {item.qtyInwarded ?? 0}
                        </span>
                        <span className="badge bg-warning">
                          Pending: {item.qtyOrdered - ((item.qtyInwarded || 0) + (item.qtyCanceled || 0))}
                        </span>
                      </div>
                    </td>
                    <td>{item.qtyCanceled != null ? item.qtyCanceled.toFixed() : "0.0000"}</td>
                    <td>₹{item.price.toFixed(2)}</td>
                    <td>₹{item.mrp.toFixed(2)}</td>
                    <td>{item.taxPercent.toFixed(2)}</td>
                    <td>₹{item.taxAmount.toFixed(2)}</td>
                    <td>{item.discountPercent.toFixed(2)}</td>
                    <td>₹{item.discountAmount.toFixed(2)}</td>
                    <td>₹{item.rowTotal.toFixed(2)}</td>
                    <td>₹{item.priceInclTax.toFixed(2)}</td>
                    <td>₹{item.rowTotalInclTax.toFixed(2)}</td>
                    <td>{item.hsnCode}</td>
                    <td>{item.primaryUom}</td>
                    <td>{item.alternateUom}</td>
                    <td>{item.conversionFactor.toFixed(2)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Payment Details */}
        <div className="card shadow p-4 mt-4 rounded" style={{ background: "linear-gradient(135deg, #fdfbfb, #ebedee)" }}>
          <h4 className="text-primary border-bottom pb-2">
            <FaMoneyBillWave className="me-2" /> Payment Summery
          </h4>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              <p className="fs-5"><strong>Subtotal:</strong> <span className="text-dark">₹{purchaseOrder.subtotal.toFixed(2)}</span></p>
              <p className="fs-5"><strong>GST (Including Shipping Tax):</strong> <span className="text-dark">₹{purchaseOrder.taxAmount.toFixed(2)}</span></p>
              <p className="fs-5"><strong>Shipping & Handling:</strong> <span className="text-dark">₹{purchaseOrder.shippingAmount.toFixed(2)}</span></p>
            </div>
            <div className="col-12 col-md-6">
              <p className="fs-5"><strong>Discount:</strong> <span className="text-dark">₹{purchaseOrder.discountAmount.toFixed(2)}</span></p>
              <p className="fs-5"><strong>Grand Total:</strong> <span className="text-success fs-4">₹{purchaseOrder.grandTotal.toFixed(2)}</span></p>
              <p className="fs-5"><strong>Payment Terms:</strong> <span className="text-dark">{purchaseOrder.paymentTerms}</span></p>
            </div>
          </div>
        </div>

        {/* Approval Section */}
        {(purchaseOrder.superiorStatus === null || purchaseOrder.superiorStatus === 13) ? (
          // Superior Approval Card (Form)
          <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px" }}>
            <h4 className="text-primary mb-3">Superior Approval</h4>
            {purchaseOrder.procurementStatus == null ? (
              <div className="p-3 border rounded bg-light">
                <div className="mb-2">
                  <label className="form-label">Select Status</label>
                  <select 
                    className="form-select"
                    value={procurementSelect} 
                    onChange={(e) => setProcurementSelect(e.target.value)}
                  >
                    <option value="">Select Status</option>
                    <option value="Approve">Approve</option>
                    <option value="Reject">Reject</option>
                  </select>
                </div>
                <div className="mb-2">
                  <label className="form-label">Remarks</label>
                  <input 
                    type="text" 
                    className="form-control" 
                    placeholder="Enter remarks" 
                    value={procurementRemark} 
                    onChange={(e) => setProcurementRemark(e.target.value)}
                  />
                </div>
                <div className="text-end">
                  <button className="btn btn-primary" onClick={handleProcurementSubmit}>
                    Submit Superior Approval
                  </button>
                </div>
              </div>
            ) : (
              <p className="text-success">Superior Status: Approved</p>
            )}
          </div>
        ) : (
          purchaseOrder.superiorStatus === 12 && (
            // When superiorStatus is 12, show Finance Approval Card (if needed)
            <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px" }}>
              <h4 className="text-primary">Superior Approval</h4>
              <p className="text-success">Superior Status: Approved</p>
              {purchaseOrder.financeStatus == null ? (
                <div className="card shadow-lg p-4 mt-4" style={{ borderRadius: "10px" }}>
                  <h4 className="text-primary">Finance Approval</h4>
                  <div className="d-flex flex-column gap-2">
                    <select 
                      className="form-select" 
                      value={financeSelect} 
                      onChange={(e) => setFinanceSelect(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value="Approve">Approve</option>
                      <option value="Reject">Reject</option>
                    </select>
                    <input 
                      type="text" 
                      className="form-control" 
                      placeholder="Enter remarks" 
                      value={financeRemark} 
                      onChange={(e) => setFinanceRemark(e.target.value)}
                    />
                    <button className="btn btn-primary" onClick={handleFinanceSubmit}>
                      Submit Finance Approval
                    </button>
                  </div>
                </div>
              ) : (
                (purchaseOrder.financeStatus === "Approve" || purchaseOrder.financeStatus === 9) && (
                  <p className="text-success mt-3">Finance Status: Approved</p>
                )
              )}
            </div>
          )
        )}

        {/* Status History Table */}
        {purchaseOrder.statusHistories && purchaseOrder.statusHistories.length > 0 && (
          <div className="card shadow p-4 mt-4 rounded">
            <h4 className="text-primary mb-3">Status History</h4>
            <div className="table-responsive">
              <table className="table table-striped mb-0">
                <thead>
                  <tr>
                    <th className="px-3">Status Type</th>
                    <th className="px-3">Status</th>
                    <th className="px-3">Comment</th>
                    <th className="px-3">User</th>
                    <th className="px-3">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {purchaseOrder.statusHistories.map((history, index) => (
                    <tr key={index}>
                      <td className="px-3">{history.statusType}</td>
                      <td className="px-3">{history.status}</td>
                      <td className="px-3">{history.comment}</td>
                      <td className="px-3">{history.createdByName}</td>
                      <td className="px-3">{new Date(history.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* GRN List Section */}
        {grns.length > 0 && (
          <div className="card p-3 shadow mt-4 rounded">
            <h4 className="text-primary"><FaFileInvoice /> Goods Receipt Notes (GRNs)</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Purchase Order ID</th>
                    <th>PO Number</th>
                    <th>Seller ID</th>
                    <th>Warehouse ID</th>
                    <th>Subtotal</th>
                    <th>Is Active</th>
                    <th>CGST Amount</th>
                    <th>SGST Amount</th>
                    <th>IGST Amount</th>
                    <th>Tax Amount</th>
                    <th>Grand Total</th>
                    <th>Shipping Charges</th>
                    <th>Rounding Off</th>
                    <th>TCS</th>
                    <th>Debit Eway Bill No</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {grns.map((grn) => (
                    <tr key={grn.id}>
                      <td>{grn.id}</td>
                      <td>{grn.purchaseOrderId}</td>
                      <td>{grn.poNumber}</td>
                      <td>{grn.sellerId}</td>
                      <td>{grn.warehouseId}</td>
                      <td>₹{grn.subtotal.toFixed(2)}</td>
                      <td>{grn.isActive}</td>
                      <td>{grn.cgstAmount != null ? `₹${grn.cgstAmount.toFixed(2)}` : '-'}</td>
                      <td>{grn.sgstAmount != null ? `₹${grn.sgstAmount.toFixed(2)}` : '-'}</td>
                      <td>{grn.igstAmount != null ? `₹${grn.igstAmount.toFixed(2)}` : '-'}</td>
                      <td>₹{grn.taxAmount.toFixed(2)}</td>
                      <td>₹{grn.grandTotal.toFixed(2)}</td>
                      <td>₹{grn.shippingCharges.toFixed(2)}</td>
                      <td>₹{grn.roundingOff.toFixed(2)}</td>
                      <td>₹{grn.tcs.toFixed(2)}</td>
                      <td>{grn.debitEwayBillNo || '-'}</td>
                      <td>{grn.createdBy || '-'}</td>
                      <td>{new Date(grn.createdAt).toLocaleString()}</td>
                      <td>
                        <button 
                          className="btn btn-info btn-sm"
                          onClick={() => navigate(`/grn/view/${grn.id}`)}
                        >
                          <FaFileInvoice /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}

        {/* Debit Note List Section */}
        {debitNotes.length > 0 && (
          <div className="card p-3 shadow mt-4 rounded">
            <h4 className="text-primary"><FaClipboardCheck /> Debit Notes</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>PO Number</th>
                    <th>Warehouse ID</th>
                    <th>Subtotal</th>
                    <th>Tax Amount</th>
                    <th>Grand Total</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {debitNotes.map((note) => (
                    <tr key={note.id}>
                      <td>{note.id}</td>
                      <td>{note.poNumber}</td>
                      <td>{note.warehouseId}</td>
                      <td>₹{note.subtotal.toFixed(2)}</td>
                      <td>₹{note.taxAmount.toFixed(2)}</td>
                      <td>₹{note.grandTotal.toFixed(2)}</td>
                      <td>{new Date(note.createdAt).toLocaleString()}</td>
                      <td>
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => navigate(`/debit-note/view/${note.id}`)}
                        >
                          <FaFileInvoice /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PurchaseOrderDetails;