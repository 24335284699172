import React, { useEffect, useState } from "react";
import { getProductById, updateProduct } from "../services/productService";
import { getCategoryTree } from "../services/categoryService"; // added import
import { getBrands } from "../services/brandService"; // new import
import { getManufacturers } from "../services/manufacturerService"; // new import
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaEdit } from "react-icons/fa";

const EditProduct = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [product, setProduct] = useState({
    sku: "",
    categoryId: [],
    parentSku: "",
    name: "",
    price: "",
    mrp: "",
    taxPercent: "",
    customDutyPercent: "",
    hsnCode: "",
    description: "",
    shortDescription: "",
    specialPrice: "",
    brand: "",
    brandName: "",
    manufacturerCode: "",
    manufacturerName: "",
    parentCategoryId: "",
    categoryName: "",
    uom: "",
    genericName: "",
    collection: "",
    type: "",
    certifications: "",
    countryOfOrigin: "",
    packSizeUom: "",
    imageUrl: "",
    hasOptions: 0,
    requiredOptions: 0,
    moq: "",
    websiteUom: "",
    websiteConversionFactor: "",
    barcodeLabelUom: "",
    storageTemperature: "",
    isColdStorage: 0,
    slug: "",
    metaTitle: "",
    metaDescription: "",
    metaKeywords: "",
    canonicalUrl: "",
    imageAltText: "",
    schemaMarkupJson: "",
    enableDisable: 1, // added field
  });
  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await getProductById(id);
        if (response.statusCode === 200) {
          const formattedData = Object.fromEntries(
            Object.entries(response.data).map(([key, value]) => [key, value ?? ""])
          );
          // Set categoryId from the "categories" field if present
          if (response.data.categories && response.data.categories.length > 0) {
            formattedData.categoryId = response.data.categories.map((cat) => String(cat.entityId));
          } else if (!Array.isArray(formattedData.categoryId)) {
            formattedData.categoryId = [];
          }
          setProduct(formattedData);
        } else {
          toast.error("Product not found!");
          navigate("/products");
        }
      } catch (error) {
        toast.error("Error fetching product details.");
        navigate("/products");
      }
    };
    fetchProduct();
  }, [id, navigate]);

  // Added effect to fetch category tree
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await getCategoryTree();
        setCategories(response.data || []);
      } catch (error) {
        toast.error("Error fetching category tree.");
      }
    };
    fetchCategories();
  }, []);

  // NEW: Fetch Brands
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const res = await getBrands();
        setBrands(res.data.content || []);
      } catch (error) {
        toast.error("Error fetching brands.");
      }
    };
    fetchBrands();
  }, []);

  // NEW: Fetch Manufacturers
  useEffect(() => {
    const fetchManufacturers = async () => {
      try {
        const res = await getManufacturers();
        setManufacturers(res.data.content || []);
      } catch (error) {
        toast.error("Error fetching manufacturers.");
      }
    };
    fetchManufacturers();
  }, []);

  const handleChange = (e) => {
    setProduct({ ...product, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e, id) => {
    const checked = e.target.checked;
    let newSelected;
    if (checked) {
      newSelected = [...product.categoryId, String(id)];
    } else {
      newSelected = product.categoryId.filter((catId) => catId !== String(id));
    }
    setProduct({ ...product, categoryId: newSelected });
  };

  const renderCategoryCheckboxes = (categories, level = 0) => {
    return categories.map((cat) => (
      <div key={cat.entityId} className="form-check" style={{ marginLeft: `${level * 20}px` }}>
        <input
          className="form-check-input"
          type="checkbox"
          value={cat.entityId}
          id={`cat-${cat.entityId}`}
          checked={product.categoryId.includes(String(cat.entityId))}
          onChange={(e) => handleCheckboxChange(e, cat.entityId)}
        />
        <label className="form-check-label" htmlFor={`cat-${cat.entityId}`}>
          {cat.categoryName}
        </label>
        {cat.subcategories && cat.subcategories.length > 0 && renderCategoryCheckboxes(cat.subcategories, level + 1)}
      </div>
    ));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Build payload mapping the product's categoryId array to categoryIds key
      const payload = { ...product, categoryIds: product.categoryId };
      await updateProduct(id, payload);
      toast.success("Product updated successfully! ✅");
      navigate("/products");
    } catch (err) {
      toast.error("Failed to update product! ❌");
    }
  };

  return (
    <div className="container-fluid px-5 py-4 bg-light">
      <div className="card shadow-lg p-5 mb-5" style={{ borderRadius: "20px" }}>
        <div className="card-header bg-primary text-white text-center" style={{ borderRadius: "20px 20px 0 0" }}>
          <h2><FaEdit className="me-2" /> Edit Product</h2>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit} className="row g-4">
            {/* Product Details */}
            <h5 className="text-primary border-bottom pb-2">🛍️ Product Details</h5>
            <div className="col-md-6">
              <label className="form-label">Product Name</label>
              <input type="text" className="form-control" name="name" value={product.name} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <label className="form-label">SKU</label>
              <input type="text" className="form-control" name="sku" value={product.sku} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <label className="form-label">Categories</label>
              <div className="border p-2" style={{ maxHeight: "200px", overflowY: "auto" }}>
                {renderCategoryCheckboxes(categories)}
              </div>
            </div>
            <div className="col-md-4">
              <label className="form-label">Price (₹)</label>
              <input type="number" className="form-control" name="price" value={product.price} onChange={handleChange} required />
            </div>
            <div className="col-md-4">
              <label className="form-label">MRP (₹)</label>
              <input type="number" className="form-control" name="mrp" value={product.mrp} onChange={handleChange} />
            </div>
            {/* New fields: HSN Code and Status */}
            <div className="col-md-6">
              <label className="form-label">HSN Code</label>
              <input
                type="text"
                className="form-control"
                name="hsnCode"
                value={product.hsnCode}
                onChange={handleChange}
                placeholder="Enter HSN Code"
              />
            </div>
            <div className="col-md-6">
              <label className="form-label">Status</label>
              <select
                className="form-select"
                name="enableDisable"
                value={product.enableDisable}
                onChange={handleChange}
              >
                <option value={1}>Enable</option>
                <option value={0}>Disable</option>
              </select>
            </div>
            {/* New field: Tax Percent dropdown */}
            <div className="col-md-6">
              <label className="form-label">Tax Percent (%)</label>
              <select
                className="form-select"
                name="taxPercent"
                value={product.taxPercent || "0"}
                onChange={handleChange}
              >
                <option value="0">0</option>
                <option value="5">5</option>
                <option value="12">12</option>
                <option value="18">18</option>
              </select>
            </div>
            {/* Branding & Images */}
            <h5 className="text-primary border-bottom pb-2">📷 Brand & Images</h5>
            <div className="col-md-6">
              <label className="form-label">Brand</label>
              <input
                type="text"
                className="form-control"
                name="brand"
                value={product.brand}
                onChange={handleChange}
                list="brandOptions"
              />
              <datalist id="brandOptions">
                {brands.map((b) => (
                  <option key={b.id} value={b.name} />
                ))}
              </datalist>
            </div>
            <div className="col-md-6">
              <label className="form-label">Manufacturer</label>
              <input
                type="text"
                className="form-control"
                name="manufacturerName"
                value={product.manufacturerName}
                onChange={handleChange}
                list="manufacturerOptions"
              />
              <datalist id="manufacturerOptions">
                {manufacturers.map((m) => (
                  <option key={m.id} value={m.manufacturerName} />
                ))}
              </datalist>
            </div>

            {/* Product Descriptions */}
            <h5 className="text-primary border-bottom pb-2">📝 Descriptions</h5>
            <div className="col-12">
              <label className="form-label">Short Description</label>
              <input type="text" className="form-control" name="shortDescription" value={product.shortDescription} onChange={handleChange} />
            </div>
            <div className="col-12">
              <label className="form-label">Full Description</label>
              <textarea className="form-control" rows="4" name="description" value={product.description} onChange={handleChange}></textarea>
            </div>

            {/* SEO Section */}
            <h5 className="text-primary border-bottom pb-2">📈 SEO Details</h5>
            <div className="col-md-6">
              <label className="form-label">Slug</label>
              <input type="text" className="form-control" name="slug" value={product.slug} onChange={handleChange} required />
            </div>
            <div className="col-md-6">
              <label className="form-label">Meta Title</label>
              <input type="text" className="form-control" name="metaTitle" value={product.metaTitle} onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <label className="form-label">Meta Description</label>
              <input type="text" className="form-control" name="metaDescription" value={product.metaDescription} onChange={handleChange} />
            </div>
            <div className="col-md-6">
              <label className="form-label">Meta Keywords</label>
              <input type="text" className="form-control" name="metaKeywords" value={product.metaKeywords} onChange={handleChange} />
            </div>

            {/* Additional Details */}
            <div className="col-md-4">
              <label className="form-label">Country of Origin</label>
              <input type="text" className="form-control" name="countryOfOrigin" value={product.countryOfOrigin} onChange={handleChange} />
            </div>
            <div className="col-md-4">
              <label className="form-label">Minimum Order Quantity (MOQ)</label>
              <input type="number" className="form-control" name="moq" value={product.moq} onChange={handleChange} />
            </div>
            
            

            {/* Submit Buttons */}
            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn btn-lg btn-primary">✅ Update Product</button>
              <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/products")}>❌ Cancel</button>
            </div>

          </form>
        </div>
      </div>
    </div>
  );
};

export default EditProduct;
