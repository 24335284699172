import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  ResponsiveContainer,
} from "recharts";
import { FaArrowLeft } from "react-icons/fa";

const InventoryCharts = () => {
  const { sku } = useParams();
  const navigate = useNavigate();

  // Dummy Data for AI Model Charts
  const chartData = [
    { month: "Jan", actual: 500, exponential: 450, lstm: 430, arima: 409 },
    { month: "Feb", actual: 520, exponential: 470, lstm: 440, arima: 415 },
    { month: "Mar", actual: 540, exponential: 490, lstm: 460, arima: 430 },
  ];

  return (
    <div className="container mt-5">
      {/* Back to Details Button */}
      <button className="btn btn-outline-primary mb-3" onClick={() => navigate(`/inventory/${sku}/details`)}>
        <FaArrowLeft className="me-2" /> Back to Details
      </button>

      <div className="text-center mb-4">
        <h2 className="fw-bold text-dark">
          📊 AI Forecasting Charts - <span className="text-primary">{sku}</span>
        </h2>
      </div>

      <div className="row">
        {/* Line Chart: Actual vs. AI Predictions */}
        <div className="col-lg-6 mb-4">
          <div className="card shadow-lg border-0">
            <div className="card-header bg-primary text-white text-center">
              <h5 className="mb-0">📈 Actual vs. AI Predicted Stock Levels</h5>
            </div>
            <div className="card-body">
              <ResponsiveContainer width="100%" height={300}>
                <LineChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Line type="monotone" dataKey="actual" stroke="#8884d8" name="Actual Stock" />
                  <Line type="monotone" dataKey="exponential" stroke="#82ca9d" name="AI - Exp. Smoothing" />
                  <Line type="monotone" dataKey="lstm" stroke="#ff7300" name="AI - LSTM Model" />
                  <Line type="monotone" dataKey="arima" stroke="#ff0000" name="AI - ARIMA Model" />
                </LineChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        {/* Bar Chart: AI Model Comparison */}
        <div className="col-lg-6">
          <div className="card shadow-lg border-0">
            <div className="card-header bg-success text-white text-center">
              <h5 className="mb-0">📊 AI Model Predictions Comparison</h5>
            </div>
            <div className="card-body">
              <ResponsiveContainer width="100%" height={300}>
                <BarChart data={chartData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="month" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="exponential" fill="#82ca9d" name="Exp. Smoothing" />
                  <Bar dataKey="lstm" fill="#ff7300" name="LSTM Model" />
                  <Bar dataKey="arima" fill="#ff0000" name="ARIMA Model" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryCharts;
