import config from "../config";
const BASE_URL = `${config.BASE_URL}/api/customer-status-history`;

const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

export const createStatusHistory = async (data) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(data),
  });
  return handleResponse(response);
};
