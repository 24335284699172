import React, { useEffect, useState } from "react";
import { getCategoryById } from "../services/categoryService";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FaArrowLeft } from "react-icons/fa";

const CategoryView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [category, setCategory] = useState(null);

  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await getCategoryById(id);
        setCategory(response.data);
      } catch (error) {
        toast.error("Failed to fetch category details.");
        navigate("/categories");
      }
    };
    fetchCategory();
  }, [id, navigate]);

  if (!category) return <div className="container mt-4">Loading...</div>;

  return (
    <div className="container mt-4">
      <button
        className="btn btn-outline-secondary mb-4"
        onClick={() => navigate("/categories")}
      >
        <FaArrowLeft /> Back to Categories
      </button>
      <div className="card shadow-lg p-4">
        {/* New Attractive Card Header */}
        <div className="bg-primary text-white p-3 mb-4" style={{ borderRadius: "4px" }}>
          <h2 className="mb-0">Category Details</h2>
        </div>
        
        {/* Row 1: Basic Info */}
        <div className="row mb-3">
          <div className="col-md-3">
            <h6>ID:</h6>
            <p>{category.entityId}</p>
          </div>
          <div className="col-md-3">
            <h6>Category Name:</h6>
            <p>{category.categoryName}</p>
          </div>
          <div className="col-md-3">
            <h6>Slug:</h6>
            <p>{category.slug}</p>
          </div>
          <div className="col-md-3">
            <h6>Status:</h6>
            <p>{category.isActive === 1 ? "Enabled" : "Disabled"}</p>
          </div>
        </div>
        
        {/* Row 2: Description */}
        <div className="row mb-3">
          <div className="col-12">
            <h6>Description:</h6>
            <p>{category.description}</p>
          </div>
        </div>
        
        {/* Row 3: SEO Details - First Row */}
        <div className="row mb-3">
          <div className="col-md-6">
            <h6>Meta Title:</h6>
            <p>{category.metaTitle}</p>
          </div>
          <div className="col-md-6">
            <h6>Meta Description:</h6>
            <p>{category.metaDescription}</p>
          </div>
        </div>
        
        {/* Row 4: SEO Details - Second Row */}
        <div className="row mb-3">
          <div className="col-md-6">
            <h6>Meta Keywords:</h6>
            <p>{category.metaKeywords}</p>
          </div>
          <div className="col-md-6">
            <h6>Canonical URL:</h6>
            <p>
              <a
                href={category.canonicalUrl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {category.canonicalUrl}
              </a>
            </p>
          </div>
        </div>
        
        {/* Row 5: Additional Information */}
        <div className="row">
          <div className="col-md-6">
            <h6>Level:</h6>
            <p>{category.level}</p>
          </div>
          <div className="col-md-6">
            <div className="mb-2">
              <h6>Parent:</h6>
              <p>
                {category.parentId
                  ? `ID: ${category.parentId} - ${category.parentCategoryName || "N/A"}`
                  : "None"}
              </p>
            </div>
            <div>
              <h6>Schema Markup JSON:</h6>
              <pre className="bg-light p-2">
                {category.schemaMarkupJson
                  ? JSON.stringify(category.schemaMarkupJson, null, 2)
                  : "N/A"}
              </pre>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CategoryView;
