import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { createWarehouse, updateWarehouse, getWarehouseById } from "../services/warehouseService";
import { toast } from "react-toastify";
import { FaSave, FaArrowLeft } from "react-icons/fa";

const WarehouseForm = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState({
    warehouseCode: "",
    name: "",
    incharge: "",
    email: "",
    contact: "",
    address: "",
    city: "",
    state: "",
    pincode: "",
    gstnNumber: "",
    drugLicenceNumber: "",
    notificationEmail: "",
    stockEditAccess: "",
    pickAndPackNotificationEmail: "",
    active: true,
  });

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id) {
      setLoading(true);
      getWarehouseById(id)
        .then((response) => {
          if (response.statusCode === 200) {
            setWarehouse(response.data);
          } else {
            toast.error("Warehouse not found!");
            navigate("/warehouse");
          }
        })
        .catch(() => toast.error("Error fetching warehouse details"))
        .finally(() => setLoading(false));
    }
  }, [id, navigate]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setWarehouse({ ...warehouse, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (id) {
        await updateWarehouse(id, warehouse);
        toast.success("Warehouse updated successfully!");
      } else {
        await createWarehouse(warehouse);
        toast.success("Warehouse created successfully!");
      }
      navigate("/warehouse");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="container mt-4">
      {/* Back Button & Title Aligned Horizontally */}
<div className="d-flex align-items-center justify-content-between mb-3">
  <button className="btn btn-secondary" onClick={() => navigate("/warehouse")}>
    <FaArrowLeft /> Back to Warehouse List
  </button>
  
  <h2 className="text-primary mb-0">{id ? "Edit Warehouse" : "Add Warehouse"}</h2>
</div>


      <form onSubmit={handleSubmit} className="card p-4 shadow mt-3">
        <div className="row g-3">
          {/* Warehouse Code */}
          <div className="col-md-6">
            <label className="form-label">Warehouse Code *</label>
            <input type="text" className="form-control" name="warehouseCode" value={warehouse.warehouseCode} onChange={handleChange} required />
          </div>

          {/* Warehouse Name */}
          <div className="col-md-6">
            <label className="form-label">Warehouse Name *</label>
            <input type="text" className="form-control" name="name" value={warehouse.name} onChange={handleChange} required />
          </div>

          {/* Incharge Name */}
          <div className="col-md-6">
            <label className="form-label">Incharge Name *</label>
            <input type="text" className="form-control" name="incharge" value={warehouse.incharge} onChange={handleChange} required />
          </div>

          {/* Email */}
          <div className="col-md-6">
            <label className="form-label">Email *</label>
            <input type="email" className="form-control" name="email" value={warehouse.email} onChange={handleChange} required />
          </div>

          {/* Contact Number */}
          <div className="col-md-6">
            <label className="form-label">Contact *</label>
            <input type="text" className="form-control" name="contact" value={warehouse.contact} onChange={handleChange} required />
          </div>

          {/* Address */}
          <div className="col-md-12">
            <label className="form-label">Address *</label>
            <textarea className="form-control" name="address" value={warehouse.address} onChange={handleChange} required></textarea>
          </div>

          {/* City */}
          <div className="col-md-4">
            <label className="form-label">City *</label>
            <input type="text" className="form-control" name="city" value={warehouse.city} onChange={handleChange} required />
          </div>

          {/* State */}
          <div className="col-md-4">
            <label className="form-label">State *</label>
            <input type="text" className="form-control" name="state" value={warehouse.state} onChange={handleChange} required />
          </div>

          {/* Pincode */}
          <div className="col-md-4">
            <label className="form-label">Pincode *</label>
            <input type="text" className="form-control" name="pincode" value={warehouse.pincode} onChange={handleChange} required />
          </div>

          {/* GST Number */}
          <div className="col-md-6">
            <label className="form-label">GST Number</label>
            <input type="text" className="form-control" name="gstnNumber" value={warehouse.gstnNumber} onChange={handleChange} />
          </div>

          {/* Drug Licence Number */}
          <div className="col-md-6">
            <label className="form-label">Drug Licence Number</label>
            <input type="text" className="form-control" name="drugLicenceNumber" value={warehouse.drugLicenceNumber} onChange={handleChange} />
          </div>

          {/* Notification Emails */}
          <div className="col-md-6">
            <label className="form-label">Notification Email</label>
            <input type="email" className="form-control" name="notificationEmail" value={warehouse.notificationEmail} onChange={handleChange} />
          </div>

          <div className="col-md-6">
            <label className="form-label">Stock Edit Access Email</label>
            <input type="email" className="form-control" name="stockEditAccess" value={warehouse.stockEditAccess} onChange={handleChange} />
          </div>

          <div className="col-md-6">
            <label className="form-label">Pick & Pack Notification Email</label>
            <input type="email" className="form-control" name="pickAndPackNotificationEmail" value={warehouse.pickAndPackNotificationEmail} onChange={handleChange} />
          </div>

          {/* Status */}
          <div className="col-md-6">
            <label className="form-label">Status</label>
            <select className="form-select" name="active" value={warehouse.active} onChange={(e) => setWarehouse({ ...warehouse, active: e.target.value === "true" })}>
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>

          {/* Submit Button */}
          <div className="col-md-12 text-center mt-4">
            <button type="submit" className="btn btn-primary btn-lg" disabled={loading}>
              <FaSave /> {loading ? "Saving..." : "Save Warehouse"}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default WarehouseForm;
