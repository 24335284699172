import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getBatchDetails } from "../services/inventoryService";
import { toast } from "react-toastify";
import debounce from "lodash.debounce";
import { FaSearch, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const BatchView = () => {
  const { sku, warehouseId } = useParams();
  const navigate = useNavigate();

  const [batches, setBatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [filters, setFilters] = useState({
    batchNo: "",
    expiryFrom: "",
    expiryTo: "",
    page: 0,
    size: 5,
  });

  const fetchBatchData = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = { ...filters, sku, warehouseId };
      const response = await getBatchDetails(activeFilters);
      if (response.statusCode === 200) {
        setBatches(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch batch details.");
      }
    } catch (error) {
      console.error("Error fetching batch details:", error);
      toast.error("Error fetching batch data.");
      setBatches([]);
    } finally {
      setLoading(false);
    }
  }, [filters, sku, warehouseId]);

  const debounceFetch = useMemo(() => debounce(fetchBatchData, 500), [fetchBatchData]);

  useEffect(() => {
    debounceFetch();
    return () => debounceFetch.cancel();
  }, [debounceFetch]);

  const handleFilterChange = (key, value) => {
    setFilters((prev) => ({ ...prev, [key]: value }));
  };

  return (
    <div className="container mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h2 className="text-primary">
          Batch Details for SKU: {sku} (Warehouse: {warehouseId})
        </h2>
        <button className="btn btn-secondary" onClick={() => navigate(-1)}>
          <FaArrowLeft /> Back
        </button>
      </div>

      {/* Filters */}
      <div className="row g-2 mt-3">
        <div className="col-md-3">
          <input
            type="text"
            placeholder="🔎 Batch No"
            className="form-control"
            value={filters.batchNo}
            onChange={(e) => handleFilterChange("batchNo", e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <input
            type="date"
            placeholder="📅 Expiry From"
            className="form-control"
            value={filters.expiryFrom}
            onChange={(e) => handleFilterChange("expiryFrom", e.target.value)}
          />
        </div>
        <div className="col-md-3">
          <input
            type="date"
            placeholder="📅 Expiry To"
            className="form-control"
            value={filters.expiryTo}
            onChange={(e) => handleFilterChange("expiryTo", e.target.value)}
          />
        </div>
        <div className="col-md-2">
          <button className="btn btn-success w-100" onClick={fetchBatchData}>
            <FaSearch /> Search
          </button>
        </div>
      </div>

      {/* Loading Spinner */}
      {loading && (
        <div className="text-center my-4">
          <div className="spinner-border text-primary" role="status"></div>
        </div>
      )}

      {/* Table Container for Scrollability */}
      {!loading && batches.length > 0 && (
        <div className="table-responsive mt-3" style={{ overflowX: "auto", maxHeight: "500px" }}>
          <table className="table table-hover shadow-lg" style={{ minWidth: "1200px" }}>
            <thead className="table-dark text-center">
              <tr>
                <th>ID</th>
                <th>Batch No</th>
                <th>Expiry Date</th>
                <th>Inward Quantity</th>
                <th>Inward Scheme Qty</th>
                <th>Quantity</th>
                <th>HSN Code</th>
                <th>MRP</th>
                <th>Product Name</th>
                <th>Price</th>
                <th>UOM</th>
                <th>Created By</th>
                <th>Updated By</th>
                <th>Created At</th>
                <th>Updated At</th>
              </tr>
            </thead>
            <tbody className="text-center">
              {batches.map((batch, index) => (
                <tr key={index} className="table-light">
                  <td>{batch.id}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{batch.batchNo}</td>
                  <td>{batch.expiryDate}</td>
                  <td>{batch.inwardQuantity}</td>
                  <td>{batch.inwardSchemeQty}</td>
                  <td>{batch.quantity}</td>
                  <td>{batch.hsnCode}</td>
                  <td>{batch.mrp || "N/A"}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{batch.productName}</td>
                  <td>{batch.price ? `₹${batch.price.toFixed(2)}` : "N/A"}</td>
                  <td>{batch.uom}</td>
                  <td>{batch.createdBy || "N/A"}</td>
                  <td>{batch.updatedBy || "N/A"}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{batch.createdAt}</td>
                  <td style={{ whiteSpace: "nowrap" }}>{batch.updatedAt}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* No Data Message */}
      {!loading && batches.length === 0 && (
        <div className="text-center text-danger mt-3">
          No batch data available.
        </div>
      )}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center mt-3">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => handleFilterChange("page", filters.page - 1)}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>
          Page {filters.page + 1} of {totalPages}
        </span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => handleFilterChange("page", filters.page + 1)}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
  );
};

export default BatchView;
