import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { getWarehouseById } from "../services/warehouseService";
import { toast } from "react-toastify";
import {
  FaArrowLeft,
  FaWarehouse,
  FaUserTie,
  FaBuilding,
  FaKey,
  FaClipboardList
} from "react-icons/fa";

const WarehouseView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [warehouse, setWarehouse] = useState(null);

  useEffect(() => {
    getWarehouseById(id)
      .then((response) => {
        if (response.statusCode === 200) {
          setWarehouse(response.data);
        } else {
          toast.error("Warehouse not found!");
          navigate("/warehouse");
        }
      })
      .catch(() => toast.error("Error fetching warehouse details"));
  }, [id, navigate]);

  if (!warehouse) return <h5 className="text-center mt-4">Loading... 🌀</h5>;

  return (
    <div className="container mt-4">
      {/* Back Button & Title */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <button className="btn btn-secondary" onClick={() => navigate("/warehouse")}>
          <FaArrowLeft /> Back to Warehouse List
        </button>
        <h2 className="text-primary">
          <FaWarehouse /> Warehouse Details
        </h2>
      </div>

      {/* Warehouse Information */}
      <div className="card shadow p-4">
        <div className="row">
          <div className="col-md-6">
            <h4 className="text-dark"><FaBuilding /> General Information</h4>
            <hr />
            <p><strong>🏷️ Warehouse Code:</strong> {warehouse.warehouseCode || "N/A"}</p>
            <p><strong>📛 Name:</strong> {warehouse.name || "N/A"}</p>
            <p><strong>📌 City:</strong> {warehouse.city || "N/A"}</p>
            <p><strong>🏛️ State:</strong> {warehouse.state || "N/A"}</p>
            <p>
              <strong>🟢 Status:</strong>  
              <span className={`badge bg-${warehouse.active ? "success" : "danger"} ms-2`}>
                {warehouse.active ? "Active" : "Inactive"}
              </span>
            </p>
          </div>

          <div className="col-md-6">
            <h4 className="text-dark"><FaUserTie /> Contact Details</h4>
            <hr />
            <p><strong>📧 Email:</strong> <a href={`mailto:${warehouse.email}`} className="text-decoration-none">{warehouse.email}</a></p>
            <p><strong>📞 Contact:</strong> {warehouse.contact || "N/A"}</p>
            <p><strong>👤 In-Charge:</strong> {warehouse.incharge || "N/A"}</p>
            <p><strong>📍 Address:</strong> {warehouse.address || "N/A"}</p>
            <p><strong>📮 Pincode:</strong> {warehouse.pincode || "N/A"}</p>
          </div>
        </div>
      </div>

      {/* Additional Information */}
      <div className="row mt-4">
        <div className="col-md-6">
          <div className="card shadow p-3">
            <h5><FaKey /> Regulatory Information</h5>
            <hr />
            <p><strong>🧾 GST Number:</strong> {warehouse.gstnNumber || "N/A"}</p>
            <p><strong>💊 Drug License Number:</strong> {warehouse.drugLicenceNumber || "N/A"}</p>
          </div>
        </div>

        <div className="col-md-6">
          <div className="card shadow p-3">
            <h5><FaClipboardList /> Notifications & Permissions</h5>
            <hr />
            <p><strong>📩 Notification Email:</strong> {warehouse.notificationEmail || "N/A"}</p>
            <p><strong>🛠 Stock Edit Access:</strong> {warehouse.stockEditAccess || "N/A"}</p>
            <p><strong>📦 Pick & Pack Notification Email:</strong> {warehouse.pickAndPackNotificationEmail || "N/A"}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarehouseView;
