import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getSuppliers } from "../../services/supplierService";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import {FaPlus, FaSearch, FaEye, FaArrowLeft, FaArrowRight } from "react-icons/fa";

const SupplierList = () => {
  const navigate = useNavigate();
  const [suppliers, setSuppliers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);

  const [filters, setFilters] = useState({
    sellerId: "",
    sellerType: "",
    sellerName: "",
    sellerEnabled: "",
    companyName: "",
    contactNumber: "",
    page: 0,        // ✅ Page starts from 0 (zero-based index)
    size: 10,       // ✅ Default page size
  });

  const fetchSuppliers = useCallback(async () => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams(filters).toString();
      const response = await getSuppliers(queryParams);

      if (response.statusCode === 200) {
        setSuppliers(response.data.content || []);
        setTotalPages(response.data.totalPages || 1); // ✅ Set total pages
      } else {
        toast.error("Failed to fetch suppliers!");
      }
    } catch (error) {
      toast.error("Error fetching suppliers.");
      setSuppliers([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchSuppliers();
  }, [fetchSuppliers]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary">Supplier Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Seamlessly manage all supplier interactions, KYC, and history in one centralized dashboard..
          </p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/suppliers/create")}>
          <FaPlus /> Create Supplier
          </button>
        </div>
      </div>

      {/* 🔍 Search & Filters wrapped in a card for better spacing */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                name="sellerId"
                placeholder="🔍 Search by Seller ID"
                className="form-control"
                value={filters.sellerId}
                onChange={(e) => setFilters({ ...filters, sellerId: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <select
                name="sellerType"
                className="form-select"
                value={filters.sellerType}
                onChange={(e) => setFilters({ ...filters, sellerType: e.target.value })}
              >
                <option value="">Select Seller Type</option>
                <option value="Distributer">Distributer</option>
                <option value="Retailer">Retailer</option>
                <option value="Wholesaler">Wholesaler</option>
              </select>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                name="sellerName"
                placeholder="🔍 Search by Seller Name"
                className="form-control"
                value={filters.sellerName}
                onChange={(e) => setFilters({ ...filters, sellerName: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <select
                name="sellerEnabled"
                className="form-select"
                value={filters.sellerEnabled}
                onChange={(e) => setFilters({ ...filters, sellerEnabled: e.target.value })}
              >
                <option value="">Enabled?</option>
                <option value="true">Yes</option>
                <option value="false">No</option>
              </select>
            </div>
            <div className="col-md-2">
              <input
                type="text"
                name="companyName"
                placeholder="🏢 Search by Company Name"
                className="form-control"
                value={filters.companyName}
                onChange={(e) => setFilters({ ...filters, companyName: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                name="contactNumber"
                placeholder="📞 Search by Contact Number"
                className="form-control"
                value={filters.contactNumber}
                onChange={(e) => setFilters({ ...filters, contactNumber: e.target.value })}
              />
            </div>
          
            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchSuppliers}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
          <div className="card-body">
      {/* 🌀 Loading State */}
      {loading && (
        <div className="text-center mt-3">
          <h5>Loading suppliers... 🌀</h5>
        </div>
      )}

      {/* 📊 Supplier Table */}
      {!loading && suppliers.length > 0 && (
        

          
        <div className="table-responsive">
          <table className="table table-striped table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Seller ID</th>
                <th>Seller Type</th>
                <th>Seller Name</th>
                <th>Enabled</th>
                <th>Company Name</th>
                <th>Contact Number</th>
                <th>Created At</th>
                <th>Updated At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {suppliers.map((supplier) => (
                <tr key={supplier.id}>
                  <td>{supplier.id}</td>
                  <td>{supplier.sellerId || "N/A"}</td>
                  <td>{supplier.sellerType || "N/A"}</td>
                  <td>{supplier.sellerName || "N/A"}</td>
                  <td>{supplier.sellerEnabled ? "Yes" : "No"}</td>
                  <td>{supplier.companyName || "N/A"}</td>
                  <td>{supplier.contactNumber || "N/A"}</td>
                  <td>
                    {supplier.createdAt
                      ? new Date(supplier.createdAt).toLocaleString()
                      : "N/A"}
                  </td>
                  <td>
                    {supplier.updatedAt
                      ? new Date(supplier.updatedAt).toLocaleString()
                      : "N/A"}
                  </td>
                  <td>
                    <button
                      className="btn btn-sm btn-outline-secondary"
                      onClick={() =>
                        navigate(`/suppliers/view/${supplier.id}`)
                      }
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        
      )}

      {/* ❗ No Data Message */}
      {!loading && suppliers.length === 0 && (
        <div className="text-center mt-3">
          <h5>No suppliers found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page - 1 }))
          }
        >
          <FaArrowLeft /> Previous
        </button>
        <span>
          Page {filters.page + 1} of {totalPages}
        </span>
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page + 1 }))
          }
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default SupplierList;
