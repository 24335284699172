import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/supplier-address`;
const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

// ✅ Fetch supplier addresses by supplierId
export const getSupplierAddresses = async (supplierId) => {
  const response = await fetch(`${BASE_URL}?supplierId=${supplierId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

// Function to add supplier address (POST)
export const addSupplierAddress = async (addressData) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(addressData),
  });
  return handleResponse(response);
};

// Function to update supplier address (PUT)
export const updateSupplierAddress = async (addressData) => {
  console.log("Calling updateSupplierAddress with:", addressData);
  const response = await fetch(BASE_URL, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(addressData),
  });
  console.log("Response from updateSupplierAddress:", response);
  return handleResponse(response);
};

// Function to fetch supplier address by entityId
export const getSupplierAddressById = async (entityId) => {
  const response = await fetch(`${BASE_URL}/${entityId}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};
