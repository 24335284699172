import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getOrderById } from "../services/orderService";
import { toast } from "react-toastify";
import { searchCreditNotes } from "../services/creditNoteService";
import { searchPickAndPacks } from "../services/pickAndPackService";
import {
  FaArrowLeft,
  FaEdit,
  FaClipboardList,
  FaBox,
  FaMoneyBillWave,
  FaTruckLoading,
  FaFileCsv,
  FaFilePdf
} from "react-icons/fa";
import { CSVLink } from "react-csv";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import "./OrderDetails.css"; // Import custom CSS for styling
import { createOrderStatusHistory } from "../services/orderStatusHistoryService";

const OrderDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [order, setOrder] = useState(null);
  const [loading, setLoading] = useState(true);
  const [creditNotes, setCreditNotes] = useState([]);
  const [pickAndPackList, setPickAndPackList] = useState([]);

  // New state for approval forms
  const [procurementSelect, setProcurementSelect] = useState("");
  const [procurementRemark, setProcurementRemark] = useState("");
  const [financeSelect, setFinanceSelect] = useState("");
  const [financeRemark, setFinanceRemark] = useState("");

  // Updated approval handlers with dynamic userId
  const handleProcurementSubmit = async () => {
    if (!procurementSelect || !procurementRemark) {
      toast.warn("Select status and add remarks for procurement approval.");
      return;
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const currentUserId = userDetails?.userId; // dynamic userId
    const payload = {
      parentId: order.entityId,
      status: procurementSelect, // use dropdown value directly ("Approve" or "Reject")
      statusType: "Procurement",
      comment: procurementRemark,
      userId: currentUserId
    };
    try {
      const res = await createOrderStatusHistory(payload);
      setOrder({ ...order, procurementStatus: procurementSelect });
      toast.success(`Procurement ${procurementSelect}`);
      console.log("Procurement payload", payload, res);
    } catch (error) {
      toast.error("Failed to update procurement status");
    }
  };

  const handleFinanceSubmit = async () => {
    if (!financeSelect || !financeRemark) {
      toast.warn("Select status and add remarks for finance approval.");
      return;
    }
    const userDetails = JSON.parse(localStorage.getItem("userDetails"));
    const currentUserId = userDetails?.userId; // dynamic userId
    const payload = {
      parentId: order.entityId,
      status: financeSelect, // status is exactly the dropdown value
      statusType: "Finance",
      comment: financeRemark,
      userId: currentUserId
    };
    try {
      const res = await createOrderStatusHistory(payload);
      setOrder({ ...order, financeStatus: financeSelect });
      toast.success(`Finance ${financeSelect}`);
      console.log("Finance payload", payload, res);
    } catch (error) {
      toast.error("Failed to update finance status");
    }
  };

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await getOrderById(id);
        if (response.statusCode === 200) {
          setOrder(response.data);

          // Fetch Credit Notes for the Order
          const creditNoteResponse = await searchCreditNotes({ orderId: id });
          if (creditNoteResponse.statusCode === 200) {
            setCreditNotes(creditNoteResponse.data?.content || []);
          } else {
            toast.warn("No credit notes found for this order.");
          }

          // ✅ Fetch Pick and Pack details for the Order
          const pickAndPackResponse = await searchPickAndPacks({ orderId: id });
          if (pickAndPackResponse.statusCode === 200) {
            setPickAndPackList(pickAndPackResponse.data?.content || []);
          } else {
            toast.warn("No Pick and Pack records found.");
          }

        } else {
          toast.error("Order not found!");
          navigate("/orders");
        }
      } catch (error) {
        toast.error("Error fetching order details.");
        navigate("/orders");
      } finally {
        setLoading(false);
      }
    };

    fetchOrderDetails();
  }, [id, navigate]);

  // CSV Data
  const csvData = order ? [
    ["Product Name", "SKU", "Price", "Qty Ordered", "GST (%)", "GST (₹)", "Total (₹)"],
    ...order.orderItems.map(item => [
      item.name,
      item.sku,
      `₹${item.price.toFixed(2)}`,
      item.qtyOrdered,
      "5.00",
      `₹${item.taxAmount.toFixed(2)}`,
      `₹${item.rowTotal.toFixed(2)}`
    ])
  ] : [];

  // Export to PDF
  const exportToPDF = () => {
    const input = document.getElementById("order-details-section");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "PNG", 10, 10, 180, 100);
      pdf.save(`Order_Details_${order.incrementId}.pdf`);
    });
  };

  // After order is fetched, compute totals for processing:
  const totalOrdered = order ? order.orderItems.reduce((acc, item) => acc + Number(item.qtyOrdered), 0) : 0;
  const totalProcessed = order ? order.orderItems.reduce((acc, item) => acc + (Number(item.qtyPicked) + Number(item.qtyCanceled)), 0) : 0;
  const pendingQty = totalOrdered - totalProcessed;

  if (loading) return <h5 className="text-center mt-4">Loading order details... 🌀</h5>;
  if (!order) return <h5 className="text-center mt-4 text-danger">Order not found ❌</h5>;

  return (
    <div className="container">
      {/* Updated Page Header */}
      <div
        className="d-flex flex-column flex-md-row justify-content-between align-items-center">
         <h1 className="welcomeTitle text-primary"><FaClipboardList className="me-2" />  Order Details</h1>
        <div className="d-flex flex-wrap gap-2 justify-content-center">
          <button className="btn btn-dark" onClick={() => navigate("/orders")}>
            <FaArrowLeft /> Back to Orders
          </button>
          {/* Conditionally show Create Pick & Pack button */}
          { totalOrdered > totalProcessed && (
            <button
              className="btn btn-success rounded-pill"
              onClick={() => navigate(`/pick-and-pack/create?orderId=${order.entityId}`)}
            >
              Create Pick & Pack
            </button>
          )}
          <button className="btn btn-warning">
            <FaEdit /> Edit
          </button>
          <div className="btn-group">
            <button className="btn btn-light btn-sm dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
              More Actions ▼
            </button>
            <ul className="dropdown-menu">
              <li>
                <CSVLink data={csvData} filename={`Order_Details_${order.incrementId}.csv`} className="dropdown-item">
                  <FaFileCsv className="me-2" /> Download as CSV
                </CSVLink>
              </li>
              <li>
                <button className="dropdown-item" onClick={exportToPDF}>
                  <FaFilePdf className="me-2" /> Download as PDF
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      {/* Replace the existing Customer & Address Info block with the following */}
      <div id="order-details-section">
        <div className="row mt-3">
          <div className="col-12 col-md-6">
            <div className="card shadow p-3" style={{ backgroundColor: "#f8f9fa", borderRadius: "8px" }}>
              <h5 className="mb-3">Billing Address</h5>
              <div className="row">
                <div className="col-3">
                  <p><strong>Name:</strong></p>
                  <p><strong>Email:</strong></p>
                  <p><strong>Telephone:</strong></p>
                </div>
                <div className="col-3">
                  <p>{order.addresses[0]?.firstname} {order.addresses[0]?.lastname}</p>
                  <p>{order.addresses[0]?.email}</p>
                  <p>{order.addresses[0]?.telephone}</p>
                </div>
                <div className="col-3">
                  <p><strong>Region:</strong></p>
                  <p><strong>City:</strong></p>
                  <p><strong>Postcode:</strong></p>
                </div>
                <div className="col-3">
                  <p>{order.addresses[0]?.region}</p>
                  <p>{order.addresses[0]?.city}</p>
                  <p>{order.addresses[0]?.postcode}</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6">
            <div className="card shadow p-3" style={{ backgroundColor: "#f8f9fa", borderRadius: "8px" }}>
              <h5 className="mb-3">Shipping Address</h5>
              <div className="row">
                <div className="col-3">
                  <p><strong>Name:</strong></p>
                  <p><strong>Email:</strong></p>
                  <p><strong>Telephone:</strong></p>
                </div>
                <div className="col-3">
                  <p>{order.addresses[1]?.firstname} {order.addresses[1]?.lastname}</p>
                  <p>{order.addresses[1]?.email}</p>
                  <p>{order.addresses[1]?.telephone}</p>
                </div>
                <div className="col-3">
                  <p><strong>Region:</strong></p>
                  <p><strong>City:</strong></p>
                  <p><strong>Postcode:</strong></p>
                </div>
                <div className="col-3">
                  <p>{order.addresses[1]?.region}</p>
                  <p>{order.addresses[1]?.city}</p>
                  <p>{order.addresses[1]?.postcode}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Updated Item Details Card */}
        <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px" }}>
          <div className="d-flex flex-wrap justify-content-between align-items-center mb-3">
            <h4 className="text-primary mb-0">
              <FaBox className="me-2" /> Item Details
            </h4>
            <div className="d-flex flex-wrap gap-3">
              <span className="fw-bold">Total Units: {order.totalItemCount}</span>
              <span className="fw-bold text-danger">Pending to Process Qty: {pendingQty}</span>
            </div>
          </div>
          <div className="table-responsive">
            <table className="table table-bordered table-hover">
              <thead className="table-dark">
                <tr>
                  <th>Item ID</th>
                  <th>Order ID</th>
                  <th>Product ID</th>
                  <th>SKU</th>
                  <th>Product Name</th>
                  <th>Primary UOM</th>
                  <th>Alternate UOM</th>
                  <th>Conversion Factor</th>
                  <th>Qty Ordered</th>
                  {/* New header for Qty Picked */}
                  <th>Qty Picked</th>
                  <th>Qty Shipped</th>
                  <th>Qty Delivered</th>
                  <th>Qty Refunded</th>
                  <th>Qty Canceled</th>
                  <th>Price</th>
                  <th>Row Total</th>
                  <th>Tax (%)</th>
                  <th>Tax Amount</th>
                  <th>Discount (%)</th>
                  <th>Discount Amount</th>
                  <th>HSN Code</th>
                </tr>
              </thead>
              <tbody>
                {order.orderItems.map((item) => (
                  <tr key={item.itemId}>
                    <td>{item.itemId}</td>
                    <td>{item.orderId}</td>
                    <td>{item.productId}</td>
                    <td>{item.sku}</td>
                    <td>{item.name}</td>
                    <td>{item.primaryUom}</td>
                    <td>{item.alternateUom}</td>
                    <td>{item.conversionFactor}</td>
                    <td>{item.qtyOrdered}</td>
                    {/* New cell for Qty Picked, defaulting to 0 if not available */}
                    <td>{(Number(item.qtyPicked) || 0)}</td>
                    <td>{item.qtyShipped}</td>
                    <td>{item.qtyDelivered}</td>
                    <td>{item.qtyRefunded}</td>
                    <td>{item.qtyCanceled}</td>
                    <td>₹{item.price.toFixed(2)}</td>
                    <td>₹{item.rowTotal.toFixed(2)}</td>
                    <td>{item.taxPercent.toFixed(2)}</td>
                    <td>₹{item.taxAmount.toFixed(2)}</td>
                    <td>{item.discountPercent.toFixed(2)}</td>
                    <td>₹{item.discountAmount.toFixed(2)}</td>
                    <td>{item.hsnCode}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* Updated Payment Summery Card */}
        <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px", backgroundColor: "#f8f9fa", borderLeft: "5px solid #007bff" }}>
          <h4 className="text-primary">
            <FaMoneyBillWave /> Payment Summery
          </h4>
          <div className="row">
            <div className="col-12 col-md-6">
              <p><strong>Subtotal:</strong> ₹{order.subtotal.toFixed(2)}</p>
              <p><strong>GST (Including Shipping Tax Charges):</strong> ₹{order.taxAmount.toFixed(2)}</p>
              <p><strong>Shipping and Handling:</strong> ₹0.00</p>
            </div>
            <div className="col-12 col-md-6">
              <p><strong>Discount:</strong> ₹0.00</p>
              <p><strong>Grand Total:</strong> <strong className="text-success">₹{order.grandTotal.toFixed(2)}</strong></p>
              <p><strong>Payment Method:</strong> NetPaymentMethod</p>
            </div>
          </div>
        </div>

        {/* New Procurement Approval Card */}
        <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px" }}>
          <h4 className="text-primary mb-3">Procurement Approval</h4>
          { order.procurementStatus === null ? (
            <div className="p-3 border rounded bg-light">
              <div className="mb-2">
                <label className="form-label">Select Status</label>
                <select 
                  className="form-select"
                  value={procurementSelect} 
                  onChange={(e) => setProcurementSelect(e.target.value)}
                >
                  <option value="">Select Status</option>
                  <option value="Approve">Approve</option>
                  <option value="Reject">Reject</option>
                </select>
              </div>
              <div className="mb-2">
                <label className="form-label">Remarks</label>
                <input 
                  type="text" 
                  className="form-control" 
                  placeholder="Enter remarks" 
                  value={procurementRemark} 
                  onChange={(e) => setProcurementRemark(e.target.value)}
                />
              </div>
              <div className="text-end">
                <button className="btn btn-primary" onClick={handleProcurementSubmit}>
                  Submit Procurement Approval
                </button>
              </div>
            </div>
          ) : (
            <p className="text-muted">Status: {order.procurementStatus === 11 ? "Approved" : order.procurementStatus === 12 ? "Rejected" : "Pending"}</p>
          )}
        </div>

        {/* New Finance Approval Card */}
        <div className="card shadow p-4 mt-4" style={{ borderRadius: "10px" }}>
          <h4 className="text-primary">Finance Approval</h4>
          { order.procurementStatus === 11 && order.financeStatus === null ? (
            <div className="d-flex flex-column gap-2">
              <select 
                className="form-select" 
                value={financeSelect} 
                onChange={(e) => setFinanceSelect(e.target.value)}
              >
                <option value="">Select Status</option>
                <option value="Approve">Approve</option>
                <option value="Reject">Reject</option>
              </select>
              <input 
                type="text" 
                className="form-control" 
                placeholder="Enter remarks" 
                value={financeRemark} 
                onChange={(e) => setFinanceRemark(e.target.value)}
              />
              <button className="btn btn-primary" onClick={handleFinanceSubmit}>
                Submit Finance Approval
              </button>
            </div>
          ) : (
            <p>Status: {order.financeStatus === 9 ? "Approved" : order.financeStatus === 10 ? "Rejected" : "Pending"}</p>
          )}
        </div>

        {/* Updated Pick and Pack and Credit Notes Cards (if present) */}
        {pickAndPackList.length > 0 && (
          <div className="card p-3 shadow mt-4" style={{ borderRadius: "10px" }}>
            <h4 className="text-primary"><FaTruckLoading /> Pick and Pack Details</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Shipment ID</th>
                    <th>Order ID</th>
                    <th>Increment ID</th>
                    <th>Warehouse ID</th>
                    <th>Courier Type</th>
                    <th>Delivery Person</th>
                    <th>Invoice No</th>
                    <th>Insurance Reference</th>
                    <th>EsTat</th>
                    <th>AWB</th>
                    <th>BDE Person</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>Status</th>
                    <th>Mbgo Vehicle</th>
                    <th>Mb Vehicle Owner</th>
                    <th>GST</th>
                    <th>Distance</th>
                    <th>Transporter Name</th>
                    <th>GST No</th>
                    <th>Vehicle Type</th>
                    <th>Vehicle Number</th>
                    <th>Transport Mode</th>
                    <th>Eway Bill No</th>
                    <th>Invoice Path</th>
                    <th>SubTotal</th>
                    <th>Tax Amount</th>
                    <th>Discount Amount</th>
                    <th>SGST Amount</th>
                    <th>CGST Amount</th>
                    <th>IGST Amount</th>
                    <th>Grand Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {pickAndPackList.map((record) => (
                    <tr key={record.id}>
                      <td>{record.id}</td>
                      <td>{record.shipmentId || "N/A"}</td>
                      <td>{record.orderId}</td>
                      <td>{record.incrementId || "N/A"}</td>
                      <td>{record.warehouseId}</td>
                      <td>{record.courierType || "N/A"}</td>
                      <td>{record.deliveryPerson || "N/A"}</td>
                      <td>{record.invoiceNo || "N/A"}</td>
                      <td>{record.insuranceReferenceNumber || "N/A"}</td>
                      <td>{record.esTat || "N/A"}</td>
                      <td>{record.awb || "N/A"}</td>
                      <td>{record.bdePerson || "N/A"}</td>
                      <td>{record.createdBy || "N/A"}</td>
                      <td>{new Date(record.createdAt).toLocaleString()}</td>
                      <td>{new Date(record.updatedAt).toLocaleString()}</td>
                      <td>{record.status}</td>
                      <td>{record.mbgoVehicle || "N/A"}</td>
                      <td>{record.mbVehicleOwner || "N/A"}</td>
                      <td>{record.gst || "N/A"}</td>
                      <td>{record.distance || "N/A"}</td>
                      <td>{record.transporterName || "N/A"}</td>
                      <td>{record.gstno || "N/A"}</td>
                      <td>{record.vehicleType || "N/A"}</td>
                      <td>{record.vehicleNumber || "N/A"}</td>
                      <td>{record.transportMode || "N/A"}</td>
                      <td>{record.ewayBillNo || "N/A"}</td>
                      <td>{record.invoicePath || "N/A"}</td>
                      <td>₹{record.subTotal?.toFixed(2)}</td>
                      <td>₹{record.taxAmount?.toFixed(2)}</td>
                      <td>₹{record.discountAmount?.toFixed(2)}</td>
                      <td>₹{record.sgstAmount?.toFixed(2)}</td>
                      <td>₹{record.cgstAmount?.toFixed(2)}</td>
                      <td>₹{record.igstAmount?.toFixed(2)}</td>
                      <td>₹{record.grandTotal?.toFixed(2)}</td>
                      <td>
                        <button
                          className="btn btn-info btn-sm"
                          onClick={() => navigate(`/pick-and-pack/view/${record.id}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {/* Updated Credit Notes Card to show all fields */}
        {creditNotes.length > 0 && (
          <div className="card p-3 shadow mt-4" style={{ borderRadius: "10px" }}>
            <h4 className="text-primary">📜 Credit Notes</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Pick & Pack ID</th>
                    <th>Order ID</th>
                    <th>Increment ID</th>
                    <th>Subtotal</th>
                    <th>Tax Amount</th>
                    <th>Discount Amount</th>
                    <th>SGST Amount</th>
                    <th>CGST Amount</th>
                    <th>IGST Amount</th>
                    <th>Grand Total</th>
                    <th>Created By</th>
                    <th>Created At</th>
                    <th>Credit Note Number</th>
                    <th>Credit Note Path</th>
                    <th>Credit EwayBillNo</th>
                    <th>Warehouse ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {creditNotes.map((note) => (
                    <tr key={note.id}>
                      <td>{note.id}</td>
                      <td>{note.pickAndPackId || "N/A"}</td>
                      <td>{note.orderId}</td>
                      <td>{note.incrementId || "N/A"}</td>
                      <td>₹{note.subTotal?.toFixed(2)}</td>
                      <td>₹{note.taxAmount?.toFixed(2)}</td>
                      <td>₹{note.discountAmount?.toFixed(2)}</td>
                      <td>₹{note.sgstAmount?.toFixed(2)}</td>
                      <td>₹{note.cgstAmount?.toFixed(2)}</td>
                      <td>₹{note.igstAmount?.toFixed(2)}</td>
                      <td>₹{note.grandTotal?.toFixed(2)}</td>
                      <td>{note.createdBy || "N/A"}</td>
                      <td>{new Date(note.createdAt).toLocaleString()}</td>
                      <td>{note.creditNoteNumber || "N/A"}</td>
                      <td>{note.creditNotePath || "N/A"}</td>
                      <td>{note.creditEwayBillNo || "N/A"}</td>
                      <td>{note.warehouseId || "N/A"}</td>
                      <td>
                        <button
                          className="btn btn-primary btn-sm mx-2"
                          onClick={() => navigate(`/credit-notes/view/${note.id}`)}
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
        {/* New Status History Card */}
        {order.statusHistories && order.statusHistories.length > 0 && (
          <div className="card shadow p-3 mt-4" style={{ borderRadius: "10px" }}>
            <h4 className="text-primary mb-3">Status History</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>Status</th>
                    <th>Status Type</th>
                    <th>Comment</th>
                    <th>Created By</th>
                    <th>Created At</th>
                  </tr>
                </thead>
                <tbody>
                  {order.statusHistories.map(history => (
                    <tr key={history.entityId}>
                      <td>{history.status}</td>
                      <td>{history.statusType || "N/A"}</td>
                      <td>{history.comment}</td>
                      <td>{history.createdByName || "N/A"}</td>
                      <td>{new Date(history.createdAt).toLocaleString()}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderDetails;