class ActionProvider {
    constructor(createChatBotMessage, setStateFunc) {
      this.createChatBotMessage = createChatBotMessage;
      this.setState = setStateFunc;
    }
  
    recommendStock() {
      const message = this.createChatBotMessage(
        "Based on AI models, we recommend stocking 500 units for the next month to prevent shortages."
      );
      this.updateChatbotState(message);
    }
  
    bestModel() {
      const message = this.createChatBotMessage(
        "The LSTM Model provides the best long-term predictions, while Exponential Smoothing is ideal for short-term trends."
      );
      this.updateChatbotState(message);
    }
  
    defaultResponse() {
      const message = this.createChatBotMessage(
        "I'm here to help with inventory recommendations! Try asking about stock levels or best AI models."
      );
      this.updateChatbotState(message);
    }
  
    updateChatbotState(message) {
      this.setState((prev) => ({
        ...prev,
        messages: [...prev.messages, message],
      }));
    }
  }
  
  export default ActionProvider;
  