import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/debit-notes`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Centralized API Handler
const apiRequest = async (endpoint, method = "GET", body = null, isBlob = false) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    };

    const options = {
      method,
      headers,
      body: body ? JSON.stringify(body) : null,
    };

    const response = await fetch(`${BASE_URL}${endpoint}`, options);

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }

    if (isBlob) return response.blob(); // For file downloads

    return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};


// ✅ Fetch all Debit Notes with filters & pagination
export const getDebitNoteList = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  return apiRequest(`/search?${query}`);
};

// ✅ Fetch Debit Note details by ID
export const getDebitNoteById = async (id) => {
  return apiRequest(`/${id}`);
};

// ✅ Create a Full Debit Note by GRN ID
export const createFullDebitNote = async (grnId) => {
  return apiRequest(`/full/${grnId}`, "POST");
};

// ✅ Create a Partial Debit Note
export const createPartialDebitNote = async (data) => {
  return apiRequest("/partial", "POST", data);
};

// ✅ Update Debit Note
export const updateDebitNote = async (id, data) => {
  return apiRequest(`/${id}`, "PATCH", data);
};

// ✅ Approve Debit Note by ID
export const approveDebitNoteById = async (id) => {
  return apiRequest(`/${id}/approve`, "POST");
};

// ✅ Download Debit Note PDF
export const downloadDebitNote = async (id) => {
  try {
    const blob = await apiRequest(`/invoices/${id}/download`, "GET", null, true);

    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `Debit_Note_${id}.pdf`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Cleanup Object URL
    setTimeout(() => window.URL.revokeObjectURL(url), 100);

    return { success: true, message: "Debit Note downloaded successfully!" };
  } catch (error) {
    return { success: false, message: "Error downloading Debit Note." };
  }
};
