import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/sellers/search`;
const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

// ✅ Fetch supplier list with filters & pagination
export const getSuppliers = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch single supplier details
export const getSupplier = async (id) => {
  const response = await fetch(`${config.BASE_URL}/api/sellers/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Create a new supplier
export const createSupplier = async (supplierData) => {
  const response = await fetch(`${config.BASE_URL}/api/sellers`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(supplierData),
  });
  return handleResponse(response);
};
