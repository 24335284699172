import config from "../config";
const BASE_URL = `${config.BASE_URL}/api/customer-addresses`;

const getAuthToken = () => localStorage.getItem("authToken");

const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {};
};

export const createAddress = async (addressData) => {
  const response = await fetch(`${BASE_URL}/createAddress`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(addressData),
  });
  return handleResponse(response);
};

export const updateAddress = async (id, addressData) => {
  const response = await fetch(`${BASE_URL}/updateAddress/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(addressData),
  });
  return handleResponse(response);
};

export const getAddressById = async (id) => {
  const response = await fetch(`${BASE_URL}/getAddressById/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const getAllAddresses = async () => {
  const response = await fetch(`${BASE_URL}/getAllAddresses`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const deleteAddress = async (id) => {
  const response = await fetch(`${BASE_URL}/deleteAddress/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};

export const searchAddresses = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/searchAddresses?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  return handleResponse(response);
};
