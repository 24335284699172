import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/brands`;

// ✅ Function to get the auth token from localStorage
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Handle API response
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {}; // Return empty object for 204 No Content
};

// ✅ Fetch all brands with search & pagination
export const getBrands = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch a single brand by ID
export const getBrandById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Create a new brand
export const createBrand = async (brandData) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(brandData),
  });

  return handleResponse(response);
};

// ✅ Update an existing brand
export const updateBrand = async (id, brandData) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(brandData),
  });

  return handleResponse(response);
};

// ✅ Delete a brand
export const deleteBrand = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};
