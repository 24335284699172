import React, { useEffect, useState, useCallback } from "react";
import { getDebitNoteList } from "../../services/debitNoteService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaArrowLeft, FaArrowRight, FaEye, FaFileInvoice } from "react-icons/fa";
import "./DebitNoteList.css";

const DebitNoteList = () => {
  const [debitNotes, setDebitNotes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    warehouseId: "",
    purchaseOrderId: "",
    poNumber: "",
    grnNumber: "",
    startDate: "",
    endDate: "",
    page: 0,
    size: 5,
  });

  const fetchDebitNotes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getDebitNoteList(filters);
      if (response.statusCode === 200) {
        setDebitNotes(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch Debit Notes!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setDebitNotes([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchDebitNotes();
  }, [fetchDebitNotes]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaFileInvoice /> Debit Note Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            Tracks and records supplier debits for returned goods or billing discrepancies.
          </p>
        </div>
        <div>
          {/* <button className="btn btn-primary" onClick={() => navigate("/create-grn")}>
            <FaPlus /> Create GRN
          </button> */}
        </div>
      </div>

      {/* 🔍 Filters */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                placeholder="📜 GRN Number"
                className="form-control"
                value={filters.grnNumber}
                onChange={(e) => setFilters({ ...filters, grnNumber: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="number"
                placeholder="🛒 Purchase Order ID"
                className="form-control"
                value={filters.purchaseOrderId}
                onChange={(e) => setFilters({ ...filters, purchaseOrderId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="number"
                placeholder="📦 Warehouse ID"
                className="form-control"
                value={filters.warehouseId}
                onChange={(e) => setFilters({ ...filters, warehouseId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                placeholder="🔎 PO Number"
                className="form-control"
                value={filters.poNumber}
                onChange={(e) => setFilters({ ...filters, poNumber: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchDebitNotes}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card shadow">
        <div className="card-body">
      {/* 📊 Data Table */}
      {!loading && debitNotes.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped bg-white text-dark">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>PO Number</th>
                <th>Warehouse ID</th>
                <th>Subtotal</th>
                <th>Tax Amount</th>
                <th>Grand Total</th>
                <th>Created At</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {debitNotes.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.poNumber}</td>
                  <td>{record.warehouseId}</td>
                  <td>₹{record.subtotal.toFixed(2)}</td>
                  <td>₹{record.taxAmount.toFixed(2)}</td>
                  <td>₹{record.grandTotal.toFixed(2)}</td>
                  <td>{new Date(record.createdAt).toLocaleString()}</td>
                  <td>
                    <button
                      className="btn btn-outline-secondary btn-sm"
                      onClick={() => navigate(`/debit-note/view/${record.id}`)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && debitNotes.length === 0 && (
        <div className="text-center mt-3">
          <h5>No Debit Notes found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default DebitNoteList;