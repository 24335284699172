import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/credit-notes`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Generic API Response Handler
const handleResponse = async (response) => {
  if (!response.ok) {
    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }
  return response.status !== 204 ? response.json() : {}; // Handle 204 No Content
};

// ✅ Fetch credit notes based on filters (for PickAndPack & search pages)
export const searchCreditNotes = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch credit note details by ID
export const getCreditNoteById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Download Credit Note PDF
export const downloadCreditNote = async (id) => {
  try {
    const response = await fetch(`${BASE_URL}/${id}/download`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${getAuthToken()}`,
      },
    });

    if (response.ok) {
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = `credit_note_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      return { success: true, message: "✅ Credit Note downloaded successfully!" };
    } else {
      return { success: false, message: "⚠️ Failed to download Credit Note." };
    }
  } catch (error) {
    return { success: false, message: "❌ Error downloading Credit Note." };
  }
};
