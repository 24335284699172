import React, { useState, useEffect } from "react";
import { getOrders, getOrderById } from "../services/orderService";
import { getWarehouses } from "../services/warehouseService";
import { getBatchDetails } from "../services/inventoryService";
import { createPickAndPack } from "../services/pickAndPackService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaSave, FaBoxOpen, FaClipboardList } from "react-icons/fa";
import { useSearchParams } from "react-router-dom";

const CreatePickAndPack = () => {
  const navigate = useNavigate();
  const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
  const userId = userDetails?.userId; // Extract userId from userDetails
  const [searchParams] = useSearchParams(); // Added to extract URL query parameters

  const [formData, setFormData] = useState({
    orderId: "",
    warehouseId: "",
    pickerId: "",
    packagingType: "Box",
    notes: "",
    selectedItems: [],
    pickAndPackID: "",
    createdBy: userId, // Add createdBy to formData
  });

  const [orders, setOrders] = useState([]);
  const [orderDetails, setOrderDetails] = useState(null);
  const [warehouses, setWarehouses] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState([]);
  const [batchData, setBatchData] = useState({});
  const [selectedBatches, setSelectedBatches] = useState({});
  const [pickQuantities, setPickQuantities] = useState({});
  const [orderSearchQuery, setOrderSearchQuery] = useState("");
  const [orderPage, setOrderPage] = useState(0);
  const [hasMoreOrders, setHasMoreOrders] = useState(false);

  useEffect(() => {
    fetchOrders("", 0);
    fetchWarehouses();
    // Check if coming from Order Details page via query param "orderId"
    const orderIdFromUrl = searchParams.get("orderId");
    if (orderIdFromUrl) {
      fetchOrderDetails(orderIdFromUrl);
      setFormData((prev) => ({ ...prev, orderId: orderIdFromUrl }));
    }
  }, [searchParams]);

  // Add a useEffect to load stored values on component mount
  useEffect(() => {
    const storedWarehouse = localStorage.getItem("selectedWarehouse");
    if (storedWarehouse) {
      setFormData((prev) => ({ ...prev, warehouseId: storedWarehouse }));
    }
    const storedSkus = localStorage.getItem("selectedSkus");
    if (storedSkus) {
      setSelectedSkus(JSON.parse(storedSkus));
    }
  }, []);

  const fetchOrders = async (query, page = 0) => {
    try {
      const response = await getOrders({ incrementId: query, page, size: 10 });
      if (response.statusCode === 200) {
        const newOrders = response.data?.content || [];
        if (page === 0) {
          setOrders(newOrders);
        } else {
          setOrders(prev => [...prev, ...newOrders]);
        }
        setHasMoreOrders(response.data.totalPages > page + 1);
        setOrderPage(page);
      } else {
        toast.error("Failed to fetch orders!");
      }
    } catch (error) {
      toast.error("Error fetching orders.");
    }
  };

  const fetchWarehouses = async () => {
    try {
      const response = await getWarehouses({ page: 0, size: 10 });
      if (response && response.statusCode === 200 && response.data?.content?.length > 0) {
        setWarehouses(response.data.content);
      } else {
        setWarehouses([]);
        toast.warn("No warehouses found!");
      }
    } catch (error) {
      toast.error("Error fetching warehouses.");
    }
  };

  const fetchOrderDetails = async (orderId) => {
    try {
      const response = await getOrderById(orderId);
      if (response.statusCode === 200) {
        setOrderDetails(response.data);
        setFormData((prev) => ({ ...prev, orderId }));
        // NEW: Update search order field to show selected order details
        setOrderSearchQuery(
          `${response.data.incrementId} - ${response.data.customerFirstname} ${response.data.customerLastname}`
        );
      } else {
        toast.error("Failed to fetch order details!");
      }
    } catch (error) {
      toast.error("Error fetching order details.");
    }
  };

  const fetchBatchDetails = async (sku, warehouseId) => {
    try {
      const response = await getBatchDetails({ sku, warehouseId });
      if (response.statusCode === 200) {
        const batches = response.data?.content.filter(batch => batch.quantity > 0) || [];
        setBatchData((prev) => ({ ...prev, [sku]: batches }));
      } else {
        toast.error("Failed to fetch batch details!");
      }
    } catch (error) {
      toast.error("Error fetching batch details.");
    }
  };

  // Modify handleWarehouseChange to store selection in localStorage
  const handleWarehouseChange = (e) => {
    const whId = e.target.value;
    setFormData((prev) => ({ ...prev, warehouseId: whId }));
    localStorage.setItem("selectedWarehouse", whId);
  };

  // Modify handleSkuSelection to store selected SKUs in localStorage
  const handleSkuSelection = (sku) => {
    if (!selectedSkus.includes(sku)) {
      const newSkus = [...selectedSkus, sku];
      setSelectedSkus(newSkus);
      localStorage.setItem("selectedSkus", JSON.stringify(newSkus));
      fetchBatchDetails(sku, formData.warehouseId);
    }
  };

  const removeSku = (sku) => {
    const updatedSkus = selectedSkus.filter((s) => s !== sku);
    setSelectedSkus(updatedSkus);
  };

  const handlePickQuantityChange = (sku, pickQty) => {
    const orderedQty = orderDetails.orderItems.find(item => item.sku === sku)?.qtyOrdered || 0;
    if (pickQty < 1) {
      toast.error("Pick quantity must be at least 1!");
      return;
    }
    if (pickQty > orderedQty) {
      toast.error("Pick quantity cannot exceed ordered quantity!");
      return;
    }
    setPickQuantities((prev) => ({ ...prev, [sku]: pickQty }));
  };

  const handleBatchSelection = (sku, batchNo, selectedQty) => {
    const availableQty = batchData[sku]?.find(batch => batch.batchNo === batchNo)?.quantity || 0;
    if (selectedQty > availableQty) {
      toast.error("Selected quantity exceeds available quantity!");
      return;
    }
    setSelectedBatches((prev) => ({
      ...prev,
      [sku]: { ...(prev[sku] || {}), [batchNo]: selectedQty },
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Allow Pick & Pack creation only for orders with status "success" or "processing" (case-insensitive)
    if (
      orderDetails?.status &&
      !["success", "processing"].includes(orderDetails.status.toLowerCase())
    ) {
      toast.error("Error: Order status completed, cancelled not allowed for creating Pick & Pack.");
      return;
    }
    
    // Validation: Ensure for every selected SKU, Ordered Qty >= (Qty Picked + Canceled Qty + Pick Qty)
    let isValid = true;
    const pickedItems = selectedSkus.map((sku) => {
      const item = orderDetails.orderItems.find(item => item.sku === sku);
      const pickQty = pickQuantities[sku];
      const selectedBatchesForItem = selectedBatches[sku] || {};
      const totalPickedQty = Object.values(selectedBatchesForItem).reduce((a, b) => a + b, 0);
  
      // New validation condition:
      if (item.qtyOrdered < (Number(item.qtyPicked || 0) + Number(item.qtyCanceled || 0) + pickQty)) {
        toast.error(`Insufficient quantity for ${item.name}. Ordered Qty must be >= (Qty Picked + Canceled Qty + Pick Qty).`);
        isValid = false;
      }
  
      if (totalPickedQty !== pickQty) {
        toast.error(`Mismatch in picked quantity for ${item.name}!`);
        isValid = false;
      }
  
      const batches = Object.entries(selectedBatchesForItem).map(([batchNo, quantity]) => {
        const batch = batchData[sku].find(batch => batch.batchNo === batchNo);
        return {
          batchId: batch.id,
          batchNo: batch.batchNo,
          quantity,
        };
      });
  
      return { sku: item.sku, quantity: pickQty, batches };
    });
    
    if (!isValid) return;
    
    const payload = {
      orderId: formData.orderId,
      warehouseId: formData.warehouseId,
      pickAndPackID: formData.pickAndPackID, // Include pickAndPackID in the payload
      pickedItems,
      createdBy: formData.createdBy, // Include createdBy in the payload
    };
    
    try {
      const response = await createPickAndPack(payload);
      if (response.statusCode === 201) {
        toast.success("Pick & Pack created successfully!");
        navigate(`/pick-and-pack/view/${response.data.id}`);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error("Error creating pick & pack.");
    }
  };

  // Add a reset function to clear stored selections and state
  const resetSelections = () => {
    localStorage.removeItem("selectedWarehouse");
    localStorage.removeItem("selectedSkus");
    setFormData((prev) => ({ ...prev, warehouseId: "" }));
    setSelectedSkus([]);
    setBatchData({});
    setSelectedBatches({});
    setPickQuantities({});
  };

  return (
    <div className="container mt-4 bg-light p-4 rounded shadow-sm">
      <h2 className="text-primary text-center mb-4">
        <FaClipboardList className="me-2" /> Create Pick & Pack
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row mb-3">
          <div className="col-md-4 mb-3">
            <label className="form-label">Search Order</label>
            <input
              type="text"
              className="form-control"
              placeholder="Search order by Order Number"
              value={orderSearchQuery}
              onChange={(e) => {
                const query = e.target.value;
                setOrderSearchQuery(query);
                fetchOrders(query, 0);
              }}
              required
            />
            <div className="dropdown-list border mt-1" style={{ maxHeight: "150px", overflowY: "auto" }}>
              {orders.map((order) => (
                <div
                  key={order.entityId}
                  className="dropdown-item"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    fetchOrderDetails(order.entityId);
                    setOrderSearchQuery(`${order.incrementId} - ${order.customerFirstname} ${order.customerLastname}`);
                    setOrders([]); // Hide dropdown after selection
                  }}
                >
                  {order.incrementId} - {order.customerFirstname} {order.customerLastname}
                </div>
              ))}
              {hasMoreOrders && (
                <button
                  type="button"
                  className="btn btn-link"
                  onClick={() => fetchOrders(orderSearchQuery, orderPage + 1)}
                >
                  Load More...
                </button>
              )}
            </div>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Select Warehouse</label>
            <select className="form-select" onChange={handleWarehouseChange} required>
              <option value="">🏭 Select Warehouse</option>
              {warehouses.map((warehouse) => (
                <option key={warehouse.id} value={warehouse.id}>
                  {warehouse.name} - {warehouse.warehouseCode}
                </option>
              ))}
            </select>
          </div>
          <div className="col-md-4 mb-3">
            <label className="form-label">Select SKU(s)</label>
            <select className="form-select" onChange={(e) => handleSkuSelection(e.target.value)}>
              <option value="">Select SKU</option>
              {orderDetails && orderDetails.orderItems
                .filter(item => item.qtyOrdered > (Number(item.qtyPicked || 0) + Number(item.qtyCanceled || 0)))
                .map((item) => (
                  <option key={item.sku} value={item.sku}>
                    {item.sku} - {item.name}
                  </option>
              ))}
            </select>
            <div className="mt-2">
              {selectedSkus.map((sku) => (
                <span key={sku} className="badge bg-primary mx-1">
                  {sku}{" "}
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => removeSku(sku)}>
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>

        <h4 className="text-primary mb-3">
          <FaBoxOpen className="me-2" /> Pick & Pack Items
        </h4>
        { orderDetails && selectedSkus.map((sku, index) => {
          const item = orderDetails.orderItems.find(item => item.sku === sku);
          if (!item) return null; // Guard: if no matching order item, skip rendering
          return (
            <div key={index} className="card p-3 mb-3">
              <h5 className="text-secondary">{item.name} (SKU: {item.sku})</h5>
              <div className="table-responsive">
                {/* Updated table header with Qty Picked column added after Ordered Qty */}
                <table className="table table-bordered table-striped">
                  <thead className="table-dark">
                    <tr>
                      <th>Ordered Qty</th>
                      <th>Qty Picked</th>
                      <th>Shipped Qty</th>
                      <th>Delivered Qty</th>
                      <th>Refunded Qty</th>
                      <th>Canceled Qty</th>
                      <th>Primary UOM</th>
                      <th>Alternate UOM</th>
                      <th>Conversion Factor</th>
                      <th>Pick Qty</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{(Number(item.qtyOrdered) || 0).toFixed(0)}</td>
                      <td>{(Number(item.qtyPicked) || 0).toFixed(0)}</td>
                      <td>{(Number(item.qtyShipped) || 0).toFixed(0)}</td>
                      <td>{(Number(item.qtyDelivered) || 0).toFixed(0)}</td>
                      <td>{(Number(item.qtyRefunded) || 0).toFixed(0)}</td>
                      <td>{(Number(item.qtyCanceled) || 0).toFixed(0)}</td>
                      <td>{item.primaryUom}</td>
                      <td>{item.alternateUom}</td>
                      <td>{(Number(item.conversionFactor) || 0).toFixed(2)}</td>
                      <td>
                        <input
                          type="number"
                          className="form-control"
                          value={pickQuantities[item.sku] || ""}
                          onChange={(e) => handlePickQuantityChange(item.sku, parseInt(e.target.value) || 0)}
                          min="1"
                          required
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {batchData[item.sku]?.length > 0 && (
                <div className="table-responsive mt-2">
                  <table className="table table-bordered table-striped">
                    <thead className="table-dark">
                      <tr>
                        <th>Batch No</th>
                        <th>Expiry Date</th>
                        <th>Available Qty</th>
                        <th>Picked Qty</th>
                      </tr>
                    </thead>
                    <tbody>
                      {batchData[item.sku].map((batch, batchIndex) => (
                        <tr key={batchIndex}>
                          <td>{batch.batchNo}</td>
                          <td>{batch.expiryDate}</td>
                          <td>{batch.quantity}</td>
                          <td>
                            <input
                              type="number"
                              className="form-control"
                              value={selectedBatches[item.sku]?.[batch.batchNo] || ""}
                              onChange={(e) => handleBatchSelection(item.sku, batch.batchNo, parseInt(e.target.value) || 0)}
                              max={batch.quantity}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          );
        })}
        {/* Updated buttons container with improved style and spacing */}
        <div className="d-flex flex-column flex-sm-row justify-content-center align-items-center gap-3 mt-4">
          <button type="submit" className="btn btn-success btn-lg px-4">
            <FaSave className="me-2" /> Save
          </button>
          <button type="button" className="btn btn-secondary btn-lg px-4" onClick={resetSelections}>
            Reset
          </button>
        </div>
      </form>
    </div>
  );
};

export default CreatePickAndPack;