import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getQuotes } from "../services/quoteService";
import { toast } from "react-toastify";
import "bootstrap/dist/css/bootstrap.min.css";
import { FaClipboardList, FaSearch, FaEye } from "react-icons/fa";

const QuoteList = () => {
  const navigate = useNavigate();
  const [quotes, setQuotes] = useState([]); // ✅ Ensure it's always an array
  const [loading, setLoading] = useState(true);
  const [filters, setFilters] = useState({
    customerEmail: "",
    userId: "",
    page: 0,
    size: 5,
  });
  const [totalPages, setTotalPages] = useState(1);

  const fetchQuotes = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getQuotes(filters);

      if (response.statusCode === 200) {
        setQuotes(response.data.content || []);
        // Assuming the API returns a 'totalPages' property
        setTotalPages(response.data.totalPages || 1);
      } else {
        toast.error("Failed to fetch quotes!");
      }
    } catch (error) {
      toast.error("Error fetching quotes.");
      setQuotes([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchQuotes();
  }, [fetchQuotes]);

  return (
    <div className="container">
      <div className="text-start mb-4">
        <h1 className="welcomeTitle text-primary"><FaClipboardList /> Quote Management</h1>
        <p className="welcomeSubtitle">
           Streamlines the process of creating, sending, and tracking price quotations to customers.
        </p>
      </div>

      {/* 🔍 Search & Filters */}
      <div className="card shadow">
        <div className="card-body">

          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="email"
                name="customerEmail"
                placeholder="🔍 Search by Customer Email"
                className="form-control"
                value={filters.customerEmail}
                onChange={(e) => setFilters({ ...filters, customerEmail: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                name="userId"
                placeholder="User ID"
                className="form-control"
                value={filters.userId}
                onChange={(e) => setFilters({ ...filters, userId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchQuotes}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 🌀 Loading State */}
      {loading && <div className="text-center mt-3"><h5>Loading quotes... 🌀</h5></div>}

      {/* 📊 Quote Details */}
      {!loading && quotes.length > 0 && (
        <div className="card mt-4">
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-bordered table-striped table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>Entity ID</th>
                    <th>Customer ID</th>
                    <th>Customer Group</th>
                    <th>User ID</th>
                    <th>Email</th>
                    <th>First Name</th>
                    <th>Middle Name</th>
                    <th>Last Name</th>
                    <th>Is Active</th>
                    <th>Grand Total (Rs)</th>
                    <th>Base Grand Total (Rs)</th>
                    <th>Checkout Method</th>
                    <th>Remote IP</th>
                    <th>Reserved Order ID</th>
                    <th>Coupon Code</th>
                    <th>Subtotal</th>
                    <th>Created At</th>
                    <th>Updated At</th>
                    <th>BDE ID</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {quotes.map((quote) => (
                    <tr key={quote.entityId}>
                      <td>{quote.entityId}</td>
                      <td>{quote.customerId}</td>
                      <td>{quote.customerGroupId}</td>
                      <td>{quote.userId}</td>
                      <td>{quote.customerEmail}</td>
                      <td>{quote.customerFirstname}</td>
                      <td>{quote.customerMiddlename}</td>
                      <td>{quote.customerLastname}</td>
                      <td>{quote.isActive}</td>
                      <td>₹{quote.grandTotal.toFixed(2)}</td>
                      <td>₹{quote.baseGrandTotal.toFixed(2)}</td>
                      <td>{quote.checkoutMethod || "-"}</td>
                      <td>{quote.remoteIp}</td>
                      <td>{quote.reservedOrderId || "-"}</td>
                      <td>{quote.couponCode || "-"}</td>
                      <td>{quote.subtotal || "-"}</td>
                      <td>{new Date(quote.createdAt).toLocaleString()}</td>
                      <td>{new Date(quote.updatedAt).toLocaleString()}</td>
                      <td>{quote.bdeId || "-"}</td>
                      <td>
                        <button
                          className="btn btn-sm btn-info"
                          onClick={() => navigate(`/quotes/view/${quote.entityId}`)}
                        >
                          <FaEye /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {/* Pagination Controls */}
            <div className="d-flex justify-content-between align-items-center">
              <button
                className="btn btn-secondary"
                disabled={filters.page <= 0}
                onClick={() => setFilters({ ...filters, page: filters.page - 1 })}
              >
                Previous
              </button>
              <span>
                Page {filters.page + 1} of {totalPages}
              </span>
              <button
                className="btn btn-secondary"
                disabled={filters.page >= totalPages - 1}
                onClick={() => setFilters({ ...filters, page: filters.page + 1 })}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && quotes.length === 0 && (
        <div className="text-center mt-3">
          <h5>No quotes found ❌</h5>
        </div>
      )}
    </div>
  );
};

export default QuoteList;
