import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/purchase-order-status-history`;

const apiRequest = async (endpoint, method = "GET", body = null, isBlob = false) => {
  try {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authToken")}`,
    };
    const options = { method, headers, body: body ? JSON.stringify(body) : null };
    const response = await fetch(`${BASE_URL}${endpoint}`, options);
    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(errorText || "Something went wrong!");
    }
    if (isBlob) return response.blob();
    return response.status !== 204 ? response.json() : {};
  } catch (error) {
    console.error(`API Error: ${error.message}`);
    throw error;
  }
};

export const updateStatusHistory = async (parentId, statusType, status, comment, userId) => {
  // Retrieve userId dynamically if not provided
  if (!userId) {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        userId = parsed.userId;
      } catch (e) {
        console.error("Error parsing userDetails from localStorage");
      }
    }
  }
  // Token is sent in headers like in the roles service
  return apiRequest("", "POST", { parentId, status, statusType, comment, userId });
};
