import React, { useEffect, useState, useCallback } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  FaSearch,
  FaArrowLeft,
  FaArrowRight,
  FaEye,
  FaExchangeAlt,
  FaPlus,
} from "react-icons/fa";
import { getInterBranchTransfers } from "../../services/interBranchTransferService"; // ✅ Import API service

const InterBranchTransferList = () => {
  const [transfers, setTransfers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    sourceBranchId: "",
    destinationBranchId: "",
    transferNumber: "",
    status: "",
    startDate: "",
    endDate: "",
    page: 0,
    size: 5,
  });

  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  useEffect(() => {
    const details = localStorage.getItem("userDetails");
    if (details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch (e) {}
    }
  }, []);

  const fetchTransfers = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getInterBranchTransfers(filters);
      if (response.statusCode === 200) {
        setTransfers(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch inter-branch transfers!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setTransfers([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchTransfers();
  }, [fetchTransfers]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaExchangeAlt /> Inter-Branch Transfers</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            Record and track stock movement across branches with real-time transfer status and logs.
          </p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/interbranch-transfers/create")}>
            <FaPlus /> Create Branch Transfer
          </button>
        </div>
      </div>

      {/* Conditionally render transfers list based on VIEW_INTER_BRANCH_TRANSFERS permission */}
      {!userPermissions.includes("VIEW_INTER_BRANCH_TRANSFERS") ? (
        <div className="alert alert-danger">
          You do not have permission to view inter-branch transfers.
        </div>
      ) : (
        <>
          {/* 🔍 Filters */}
          <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="number"
                placeholder="🏢 Source Branch ID"
                className="form-control"
                value={filters.sourceBranchId}
                onChange={(e) =>
                  setFilters({ ...filters, sourceBranchId: e.target.value })
                }
              />
            </div>

            <div className="col-md-2">
              <input
                type="number"
                placeholder="📦 Destination Branch ID"
                className="form-control"
                value={filters.destinationBranchId}
                onChange={(e) =>
                  setFilters({ ...filters, destinationBranchId: e.target.value })
                }
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                placeholder="🔎 Transfer Number"
                className="form-control"
                value={filters.transferNumber}
                onChange={(e) =>
                  setFilters({ ...filters, transferNumber: e.target.value })
                }
              />
            </div>

            <div className="col-md-2">
              <select
                className="form-select"
                value={filters.status}
                onChange={(e) =>
                  setFilters({ ...filters, status: e.target.value })
                }
              >
                <option value="">📜 Select Status</option>
                <option value="PENDING">Pending</option>
                <option value="APPROVED">Approved</option>
                <option value="DISPATCHED">Dispatched</option>
                <option value="RECEIVED">Received</option>
                <option value="CANCELLED">Cancelled</option>
              </select>
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.startDate}
                onChange={(e) =>
                  setFilters({ ...filters, startDate: e.target.value })
                }
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.endDate}
                onChange={(e) =>
                  setFilters({ ...filters, endDate: e.target.value })
                }
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchTransfers}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
          </div>
          </div>
          <div className="card shadow">
          <div className="card-body">
          {/* 📊 Data Table */}
          {!loading && transfers.length > 0 && (
            <div className="table-responsive">
              <table className="table table-bordered table-hover">
                <thead className="table-dark">
                  <tr>
                    <th>ID</th>
                    <th>Source Branch</th>
                    <th>Destination Branch</th>
                    <th>Status</th>
                    <th>Approved By</th>
                    <th>Dispatched By</th>
                    <th>Received By</th>
                    <th>Subtotal (₹)</th>
                    <th>CGST (₹)</th>
                    <th>SGST (₹)</th>
                    <th>Grand Total (₹)</th>
                    <th>Created At</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {transfers.map((record) => (
                    <tr key={record.id}>
                      <td>{record.id}</td>
                      <td>{record.sourceBranchName || record.sourceBranchId}</td>
                      <td>{record.destinationBranchName || record.destinationBranchId}</td>
                      <td>
                        <span className={`badge bg-${getStatusColor(record.status)}`}>
                          {record.status}
                        </span>
                      </td>
                      <td>{record.approvedByName}</td>
                      <td>{record.dispatchedByName}</td>
                      <td>{record.receivedByName || "N/A"}</td>
                      <td>₹{record.subtotal ? record.subtotal.toFixed(2) : "0.00"}</td>
                      <td>₹{record.cgstAmount ? record.cgstAmount.toFixed(2) : "0.00"}</td>
                      <td>₹{record.sgstAmount ? record.sgstAmount.toFixed(2) : "0.00"}</td>
                      <td>₹{record.grandTotal ? record.grandTotal.toFixed(2) : "0.00"}</td>
                      <td>{new Date(record.createdAt).toLocaleString()}</td>
                      <td>
                        <button
                          className="btn btn-outline-secondary btn-sm"
                          onClick={() => navigate(`/interbranch-transfers/view/${record.id}`)}
                        >
                          <FaEye /> View
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* ❗ No Data Message */}
          {!loading && transfers.length === 0 && (
            <div className="text-center mt-3">
              <h5>No Transfers Found ❌</h5>
            </div>
          )}

          {/* Pagination Controls */}
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="btn btn-outline-secondary"
              disabled={filters.page === 0}
              onClick={() =>
                setFilters((prev) => ({ ...prev, page: prev.page - 1 }))
              }
            >
              <FaArrowLeft /> Previous
            </button>

            <span>
              Page {filters.page + 1} of {totalPages}
            </span>

            <button
              className="btn btn-outline-secondary"
              disabled={filters.page + 1 >= totalPages}
              onClick={() =>
                setFilters((prev) => ({ ...prev, page: prev.page + 1 }))
              }
            >
              Next <FaArrowRight />
            </button>
          </div>
          </div>
        </div>
        </>
        
      )}
    </div>
  );
};

// ✅ Helper function to determine status badge color
const getStatusColor = (status) => {
  switch (status) {
    case "PENDING":
      return "warning";
    case "APPROVED":
      return "primary";
    case "DISPATCHED":
      return "info";
    case "RECEIVED":
      return "success";
    case "CANCELLED":
      return "danger";
    default:
      return "secondary";
  }
};

export default InterBranchTransferList;