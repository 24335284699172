import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserById, updateUser, getAllRoles } from "../../services/userService";
import { FaEdit, FaArrowLeft } from "react-icons/fa";

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState({
    name: "",
    email: "",
    roles: [], // Ensure roles are an array
  });
  const [availableRoles, setAvailableRoles] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]); // Tracks selected roles
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  // ✅ Fetch user details & available roles
  useEffect(() => {
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const userResponse = await getUserById(id);
        const rolesResponse = await getAllRoles();

        if (userResponse && userResponse.id) {
          // ✅ Convert roles string to an array if needed
          let userRoles = userResponse.roles;

          if (typeof userRoles === "string") {
            userRoles = userRoles.split(",").map((role) => role.trim());
          }

          setUser({
            name: userResponse.name,
            email: userResponse.email,
            roles: userRoles.filter(Boolean), // Remove null values
          });

          setSelectedRoles(userRoles.filter(Boolean)); // ✅ Filter out null values
        } else {
          toast.error("User not found!");
          navigate("/user-management");
        }

        if (rolesResponse && Array.isArray(rolesResponse.data?.content)) {
          setAvailableRoles(rolesResponse.data.content);
        } else {
          toast.warn("No roles available.");
        }
      } catch (error) {
        toast.error("Error fetching user details.");
        navigate("/user-management");
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, [id, navigate]);

  // ✅ Handle Input Changes
  const handleChange = (e) => {
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  // ✅ Handle Role Selection (Checkboxes)
  const handleRoleChange = (roleName) => {
    setSelectedRoles((prevRoles) => {
      const updatedRoles = prevRoles.includes(roleName)
        ? prevRoles.filter((role) => role !== roleName) // Remove role
        : [...prevRoles, roleName]; // Add role

      return updatedRoles.filter(Boolean); // ✅ Ensure no null values
    });
  };

  // ✅ Handle Form Submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (selectedRoles.length === 0) {
      toast.warn("Please select at least one role.");
      return;
    }

    setUpdating(true);
    try {
      await updateUser(id, {
        name: user.name,
        email: user.email,
        roles: selectedRoles, // ✅ Send as an array without null values
      });

      toast.success("User updated successfully! ✅");
      navigate("/user-management");
    } catch (err) {
      toast.error("Failed to update user! ❌");
    } finally {
      setUpdating(false);
    }
  };

  return (
    <div className="container-fluid px-5 py-4">
      {/* 🏷️ Page Header */}
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h2 className="text-primary">
          <FaEdit className="me-2" /> Edit User
        </h2>
        <button className="btn btn-outline-secondary" onClick={() => navigate("/user-management")}>
          <FaArrowLeft /> Back to Users
        </button>
      </div>

      {/* 📋 User Edit Form */}
      <div className="card shadow-lg p-4">
        {loading ? (
          <div className="text-center">Loading user details...</div>
        ) : (
          <form onSubmit={handleSubmit} className="row g-4">
            {/* 📌 Name Field */}
            <div className="col-md-6">
              <label className="form-label">Name</label>
              <input
                type="text"
                className="form-control"
                name="name"
                value={user.name}
                onChange={handleChange}
                disabled={updating}
                required
              />
            </div>

            {/* 📌 Email Field */}
            <div className="col-md-6">
              <label className="form-label">Email</label>
              <input
                type="email"
                className="form-control"
                name="email"
                value={user.email}
                onChange={handleChange}
                disabled={updating}
                required
              />
            </div>

            {/* 🛡️ Roles Selection */}
            <div className="col-md-6">
              <label className="form-label">Roles</label>
              <div>
                {availableRoles.length > 0 ? (
                  availableRoles.map((role) => (
                    <div key={role.id} className="form-check">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id={`role-${role.name}`}
                        checked={selectedRoles.includes(role.name)}
                        onChange={() => handleRoleChange(role.name)}
                        disabled={updating}
                      />
                      <label htmlFor={`role-${role.name}`} className="form-check-label">
                        {role.name}
                      </label>
                    </div>
                  ))
                ) : (
                  <p className="text-muted">No roles available</p>
                )}
              </div>
            </div>

            {/* ✅ Submit Buttons */}
            <div className="col-12 text-center mt-4">
              <button type="submit" className="btn btn-lg btn-primary" disabled={updating}>
                {updating ? "Updating User..." : "✅ Update User"}
              </button>
              <button type="button" className="btn btn-lg btn-secondary ms-3" onClick={() => navigate("/user-management")}>
                ❌ Cancel
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default EditUser;
