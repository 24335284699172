import React, { useState, useEffect, useCallback } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { getGRNById, approveGRN } from "../../services/interbranchGrnService";
import { getWarehouseById } from "../../services/warehouseService";
import { toast } from "react-toastify";
import { FaArrowLeft, FaFilePdf, FaFileCsv, FaWarehouse, FaBoxOpen, FaMoneyBillWave, FaCheck } from "react-icons/fa";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import html2canvas from "html2canvas";
import "./InterbranchGRNView.css"; // Ensure this file exists

const InterbranchGRNView = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [grn, setGrn] = useState(null);
    const [loading, setLoading] = useState(true);
    const [sourceBranch, setSourceBranch] = useState(null);
    const [destinationBranch, setDestinationBranch] = useState(null);
    const [comment, setComment] = useState("");

    const fetchGRN = useCallback(async () => {
        try {
            const response = await getGRNById(id);
            if (response.statusCode === 200) {
                setGrn(response.data);
                fetchWarehouseDetails(response.data.sourceBranchId, response.data.destinationBranchId);
            } else {
                toast.error("Failed to fetch GRN details");
            }
        } catch (error) {
            toast.error("Error fetching GRN details");
        } finally {
            setLoading(false);
        }
    }, [id]);

    const fetchWarehouseDetails = async (sourceBranchId, destinationBranchId) => {
        try {
            const sourceResponse = await getWarehouseById(sourceBranchId);
            const destinationResponse = await getWarehouseById(destinationBranchId);
            if (sourceResponse.statusCode === 200) {
                setSourceBranch(sourceResponse.data);
            } else {
                toast.error("Failed to fetch source branch details");
            }
            if (destinationResponse.statusCode === 200) {
                setDestinationBranch(destinationResponse.data);
            } else {
                toast.error("Failed to fetch destination branch details");
            }
        } catch (error) {
            toast.error("Error fetching warehouse details");
        }
    };

    useEffect(() => {
        fetchGRN();
    }, [fetchGRN]);

    if (loading) return <h4 className="text-center mt-4">Loading...</h4>;

    if (!grn) return <h4 className="text-center mt-4">GRN Not Found ❌</h4>;

    // CSV Data
    const csvData = grn ? [
        ["SKU", "Total Quantity", "Cost Price (₹)", "Selling Price (₹)", "Subtotal (₹)", "Tax Amount (₹)"],
        ...grn.receiptItems.map(item => [
            item.sku,
            item.totalQuantity,
            `₹${item.costPrice ? item.costPrice.toFixed(2) : "0.00"}`,
            `₹${item.sellingPrice ? item.sellingPrice.toFixed(2) : "0.00"}`,
            `₹${item.subtotal ? item.subtotal.toFixed(2) : "0.00"}`,
            `₹${item.taxAmount ? item.taxAmount.toFixed(2) : "0.00"}`
        ])
    ] : [];

    // Export to PDF
    const exportToPDF = () => {
        const input = document.getElementById("grn-details-section");
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL("image/png");
            const pdf = new jsPDF();
            pdf.addImage(imgData, "PNG", 10, 10, 180, 100);
            pdf.save(`GRN_Details_${grn.id}.pdf`);
        });
    };

    // Helper function to get status badge class
    const getStatusBadgeClass = (status) => {
        switch (status) {
            case "PENDING":
                return "bg-secondary";
            case "APPROVED":
                return "bg-success";
            case "DISPATCHED":
                return "bg-warning text-dark";
            case "RECEIVED":
                return "bg-primary";
            case "CANCELLED":
                return "bg-danger";
            default:
                return "bg-secondary";
        }
    };

    // Handle Approve Action
    const handleApprove = async () => {
        const userDetails = JSON.parse(localStorage.getItem("userDetails")); // Retrieve the logged-in user's details
        const userId = userDetails?.userId; // Extract userId from userDetails

        try {
            const response = await approveGRN(id, userId, comment);
            toast.success(response.message);
            fetchGRN();
        } catch (error) {
            toast.error("Error approving GRN");
        }
    };

    return (
        <div className="container mt-4">
            {/* 🔹 Page Header */}
            <div className="d-flex justify-content-between align-items-center mb-3">
                <h2 className="text-primary">
                    <FaBoxOpen className="me-2" /> Interbranch Goods Received Note #{grn.id}
                </h2>
                <div className="d-flex gap-2">
                    <button className="btn btn-dark" onClick={() => navigate(-1)}>
                        <FaArrowLeft /> Back
                    </button>
                    <div className="btn-group">
                        <button className="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
                            More Actions ▼
                        </button>
                        <ul className="dropdown-menu">
                            <li>
                                <CSVLink data={csvData} filename={`GRN_Details_${grn.id}.csv`} className="dropdown-item">
                                    <FaFileCsv className="me-2" /> Download as CSV
                                </CSVLink>
                            </li>
                            <li>
                                <button className="dropdown-item" onClick={exportToPDF}>
                                    <FaFilePdf className="me-2" /> Download as PDF
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* 🔹 GRN Details */}
            <div id="grn-details-section">
                <div className="card shadow-lg p-4 mb-4 grn-details-card">
                    <h4 className="text-primary d-flex align-items-center">
                        <FaBoxOpen className="me-2" /> GRN InterBranch Transfer Details
                    </h4>
                    <div className="row">
                        <div className="col-md-6">
                            <table className="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th className="bg-light">Transfer Number</th>
                                        <td>{grn.transferNumber}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-light">Source Branch</th>
                                        <td>{sourceBranch?.name}</td>
                                    </tr>
                                    <tr>
                                        <th className="bg-light">Destination Branch</th>
                                        <td>{destinationBranch?.name}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="col-md-6">
                            <table className="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <th className="bg-light">Status</th>
                                        <td>
                                            <span className={`badge ${getStatusBadgeClass(grn.status)}`}>
                                                {grn.status}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th className="bg-light">Created At</th>
                                        <td>{new Date(grn.receivedAt).toLocaleString()}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                {/* 🔹 Action Section */}
                {grn.status === "PENDING" && (
                    <div className="d-flex gap-2 mb-4">
                        <input
                            type="text"
                            className="form-control"
                            placeholder="Enter approval comment"
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                        <button className="btn btn-success" onClick={handleApprove}>
                            <FaCheck /> Approve
                        </button>
                    </div>
                )}

                {/* 🔹 Branch Details */}
                <div className="row">
                    {/* Source Branch Details */}
                    {sourceBranch && (
                        <div className="col-md-6">
                            <div className="card shadow-lg p-4 mb-4 warehouse-address-section">
                                <h4 className="text-primary"><FaWarehouse /> Source Branch Details</h4>
                                <div>
                                    <h5 className="text-uppercase text-secondary">Source Branch Address</h5>
                                    <p><strong>{sourceBranch.name}</strong></p>
                                    <p>{sourceBranch.address}, {sourceBranch.city}, {sourceBranch.pincode}</p>
                                    <p>{sourceBranch.email} | {sourceBranch.contact}</p>
                                </div>
                            </div>
                        </div>
                    )}

                    {/* Destination Branch Details */}
                    {destinationBranch && (
                        <div className="col-md-6">
                            <div className="card shadow-lg p-4 mb-4 warehouse-address-section">
                                <h4 className="text-primary"><FaWarehouse /> Destination Branch Details</h4>
                                <div>
                                    <h5 className="text-uppercase text-secondary">Destination Branch Address</h5>
                                    <p><strong>{destinationBranch.name}</strong></p>
                                    <p>{destinationBranch.address}, {destinationBranch.city}, {destinationBranch.pincode}</p>
                                    <p>{destinationBranch.email} | {destinationBranch.contact}</p>
                                </div>
                            </div>
                        </div>
                    )}
                </div>

                {/* 🔹 Receipt Items */}
                <div className="card shadow-lg p-4 mb-4 receipt-items-card">
                    <h4 className="text-primary">Receipt Items</h4>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th>SKU</th>
                                    <th>Total Quantity</th>
                                    <th>Cost Price</th>
                                    <th>Selling Price</th>
                                    <th>Subtotal</th>
                                    <th>Tax Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grn.receiptItems.map((item, index) => (
                                    <tr key={index}>
                                        <td>{item.sku}</td>
                                        <td>{item.totalQuantity}</td>
                                        <td>₹{item.costPrice?.toFixed(2)}</td>
                                        <td>₹{item.sellingPrice?.toFixed(2)}</td>
                                        <td>₹{item.subtotal?.toFixed(2)}</td>
                                        <td>₹{item.taxAmount?.toFixed(2)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* 🔹 Item Stocks */}
                <div className="card shadow-lg p-4 mb-4 item-stocks-card">
                    <h4 className="text-primary">Item Stocks</h4>
                    <div className="table-responsive">
                        <table className="table table-bordered table-hover">
                            <thead className="table-dark">
                                <tr>
                                    <th>Batch No</th>
                                    <th>Received Quantity</th>
                                    <th>Cost Price</th>
                                    <th>Selling Price</th>
                                    <th>Subtotal</th>
                                    <th>Tax Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {grn.receiptItems.map((item) =>
                                    item.itemStocks.map((stock, index) => (
                                        <tr key={index}>
                                            <td>{stock.batchNo}</td>
                                            <td>{stock.receivedQuantity}</td>
                                            <td>₹{stock.costPrice?.toFixed(2)}</td>
                                            <td>₹{stock.sellingPrice?.toFixed(2)}</td>
                                            <td>₹{stock.subtotal?.toFixed(2)}</td>
                                            <td>₹{stock.taxAmount?.toFixed(2)}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>

                {/* 🔹 Payment Summary */}
                <div className="card shadow-lg p-4 mb-4 payment-summary">
                    <h4 className="text-primary d-flex align-items-center">
                        <FaMoneyBillWave className="me-2" /> Payment Summary
                    </h4>
                    <table className="table table-bordered table-striped">
                        <tbody>
                            <tr>
                                <th className="bg-light">Subtotal</th>
                                <td>₹{(grn.subtotal ?? 0).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th className="bg-light">CGST</th>
                                <td>₹{(grn.cgstAmount ?? 0).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th className="bg-light">SGST</th>
                                <td>₹{(grn.sgstAmount ?? 0).toFixed(2)}</td>
                            </tr>
                            <tr>
                                <th className="bg-light">Grand Total</th>
                                <td><strong className="text-success">₹{(grn.grandTotal ?? 0).toFixed(2)}</strong></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                {/* 🔹 Status History */}
                {grn.statusHistory && grn.statusHistory.length > 0 && (
                    <div className="card shadow-lg p-4 mb-4 status-history-card">
                        <h4 className="text-primary">Status History</h4>
                        <div className="table-responsive">
                            <table className="table table-bordered table-hover">
                                <thead className="table-dark">
                                    <tr>
                                        <th>Status</th>
                                        <th>Updated By</th>
                                        <th>Comment</th>
                                        <th>Updated At</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {grn.statusHistory.map((history, index) => (
                                        <tr key={index}>
                                            <td>{history.status}</td>
                                            <td>{history.updatedByName}</td>
                                            <td>{history.comment}</td>
                                            <td>{new Date(history.updatedAt).toLocaleString()}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default InterbranchGRNView;