import React, { useEffect, useState, useCallback } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { isTokenExpired } from "../utils/auth"; // Import the utility function
import Topbar from './Topbar'; // ✅ CORRECT – default import

import {
  FaThLarge, FaBox, FaClipboardList,
  FaFileInvoice,FaBars, FaTimes, FaTools, FaShippingFast,
  FaWarehouse, FaTags, FaIndustry, FaClipboardCheck, FaTruck, FaChevronDown, FaAddressBook, FaAddressCard, FaThList, FaFlag, FaFlagCheckered, FaObjectGroup, FaUser,
  FaRegListAlt, FaNewspaper, FaCaravan, FaUserFriends
} from "react-icons/fa";

const Navbar = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [permissions, setPermissions] = useState([]); // New state for permissions
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [dropdowns, setDropdowns] = useState({ catalog: false, operations: false });
  const navigate = useNavigate();
  const location = useLocation();

  const handleLogout = useCallback(() => {
    localStorage.removeItem("authToken");
    localStorage.removeItem("userDetails");
    setIsLoggedIn(false);
    setPermissions([]); // Clear permissions on logout
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token && !isTokenExpired(token)) {
      setIsLoggedIn(true);
    } else {
      handleLogout();
    }
  }, [handleLogout]);

  // New: Update permissions when login state changes
  useEffect(() => {
    const userDetails = localStorage.getItem("userDetails");
    if (userDetails) {
      try {
        const parsedDetails = JSON.parse(userDetails);
        setPermissions(parsedDetails.permissions || []);
      } catch (e) {
        // Handle JSON parsing error if needed
      }
    }
  }, [isLoggedIn]);

  // ✅ Close dropdowns when clicking outside
  useEffect(() => {
    const closeDropdowns = (event) => {
      if (!event.target.closest(".dropdown-menu") && !event.target.closest(".dropdown-toggle")) {
        setDropdowns({ catalog: false, operations: false, purchase: false, orders: false, userManagement: false });
      }
    };
    document.addEventListener("click", closeDropdowns);
    return () => document.removeEventListener("click", closeDropdowns);
  }, []);

  // ✅ Close mobile menu & dropdowns when navigating to a new page
  useEffect(() => {
    setIsMobileMenuOpen(false);
    setDropdowns({ catalog: false, operations: false, purchase: false, orders: false, userManagement: false });
  }, [location.pathname]);

  // ✅ Toggle dropdowns manually
  const toggleDropdown = (menu) => {
    setDropdowns((prev) => {
      const newDropdownState = Object.keys(prev).reduce((acc, key) => {
        acc[key] = key === menu ? !prev[key] : false; // Close others
        return acc;
      }, {});
      return newDropdownState;
    });
  };

  return (
    <>
      {/* Mobile Sidebar Overlay */}
      {isMobileMenuOpen && <div className="overlay" onClick={() => setIsMobileMenuOpen(false)}></div>}
      {/* <nav className="bg-primary text-light border-bottom">
        <div className="d-flex justify-content-between align-items-center py-3 px-5">
          <Link className={`navbar-brand ${styles.logo}`} to="/">
              <img src={logo} alt="Logo" height="25" />
            </Link>
          <div>
            {!isLoggedIn ? (
                  <Link className="nav-link btn btn-warning text-dark px-3" to="/">
                    <FaSignInAlt /> Login
                  </Link>
              ) : (
                  <button className="btn btn-danger btn-sm" onClick={handleLogout}>
                    <FaSignOutAlt /> Logout
                  </button>
              )}
          </div>
        </div>
      </nav> */}
      <Topbar /> 
      <nav className="navbar navbar-expand-lg bg-white text-dark menu-shadow sticky-top shadow-sm">
        <div className="container-fluid">
          {/* Logo */}

          {/* Mobile Menu Button */}
          <button
            className="navbar-toggler"
            type="button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          >
            {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
          </button>

          {/* Desktop & Mobile Menu */}
          <div className={`navbar-collapse ${isMobileMenuOpen ? "show" : "collapse"}`} id="navbarNav">
            <ul className="navbar-nav px-4">
              {/* Show Menu Only If Logged In */}
              {isLoggedIn && (
                <>
                  <li className="nav-item px-1">
                    <Link className={`nav-link ${location.pathname === "/dashboard" ? "active" : ""}`} to="/dashboard">
                      <FaThLarge /> Dashboard
                    </Link>
                  </li>
                  {/* User Management Dropdown - conditionally rendered based on permissions */}
                  {(permissions.includes("USER_MANAGEMENT") ||
                    permissions.includes("ROLE_MANAGEMENT") ||
                    permissions.includes("PERMISSION_MANAGEMENT")) && (
                    <li className="nav-item px-1 dropdown">
                      <button className="nav-link dropdown-toggle" onClick={() => toggleDropdown("userManagement")}>
                        <FaTools /> Admin <FaChevronDown />
                      </button>
                      <ul className={`dropdown-menu ${dropdowns.userManagement ? "show" : ""}`}>
                        {permissions.includes("USER_MANAGEMENT") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/user-management" ? "active" : ""}`} to="/user-management">
                            <FaAddressBook /> User Management
                            </Link>
                          </li>
                        )}
                        {permissions.includes("ROLE_MANAGEMENT") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/role-management" ? "active" : ""}`} to="/role-management">
                              <FaAddressCard /> Role Management
                            </Link>
                          </li>
                        )}
                        {permissions.includes("PERMISSION_MANAGEMENT") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/permission-management" ? "active" : ""}`} to="/permission-management">
                            <FaThList /> Permission Management
                            </Link>
                          </li>
                        )}
                        {/* New menu added under Users */}
                        <li>
                          <Link className={`dropdown-item ${location.pathname === "/admin/country-currency-master" ? "active" : ""}`} to="/admin/country-currency-master">
                          <FaFlag /> Country &amp; Currency
                          </Link>
                        </li>
                        {/* New menu item for Customer Group added under Admin */}
                        <li>
                          <Link className={`dropdown-item ${location.pathname === "/customer-groups" ? "active" : ""}`} to="/customer-groups">
                           <FaObjectGroup /> Customer Group
                          </Link>
                        </li>
                        {/* New menu item for Country Regions */}
                        <li>
                          <Link className={`dropdown-item ${location.pathname === "/country-regions" ? "active" : ""}`} to="/country-regions">
                            <FaFlagCheckered />Country Regions
                          </Link>
                        </li>
                      </ul>
                    </li>
                  )}

                  {/* Orders Dropdown */}
                  {(permissions.includes("ORDER") || permissions.includes("QUOTES")) && (
                    <li className="nav-item px-1 dropdown">
                      <button className="nav-link dropdown-toggle" onClick={() => toggleDropdown("orders")}>
                        <FaFileInvoice /> Orders <FaChevronDown />
                      </button>
                      <ul className={`dropdown-menu ${dropdowns.orders ? "show" : ""}`}>
                        {permissions.includes("ORDER") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/orders" ? "active" : ""}`} to="/orders">
                              <FaClipboardList />Sales Orders
                            </Link>
                          </li>
                        )}
                        {permissions.includes("QUOTES") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/quotes" ? "active" : ""}`} to="/quotes">
                              <FaFileInvoice /> Quotes
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                  {/* 🔹 Purchase Orders Dropdown */}
                  {/* Purchase Dropdown */}
                  {(permissions.includes("PURCHASE") || 
                    permissions.includes("PURCHASE_ORDERS") || 
                    permissions.includes("GOODS_RECEIVED_NOTES") || 
                    permissions.includes("CREATE_GRN") || 
                    permissions.includes("DEBIT_NOTES")) && (
                    <li className="nav-item px-1 dropdown">
                      <button className="nav-link dropdown-toggle" onClick={() => toggleDropdown("purchase")}>
                        <FaClipboardList /> Purchase <FaChevronDown />
                      </button>
                      <ul className={`dropdown-menu ${dropdowns.purchase ? "show" : ""}`}>
                        {permissions.includes("PURCHASE_ORDERS") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/purchase-orders" ? "active" : ""}`} to="/purchase-orders" onClick={() => setIsMobileMenuOpen(false)}>
                              <FaClipboardList /> Purchase Orders
                            </Link>
                          </li>
                        )}
                        {permissions.includes("GOODS_RECEIVED_NOTES") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/grn" ? "active" : ""}`} to="/grn" onClick={() => setIsMobileMenuOpen(false)}>
                              <FaFileInvoice /> GRN (Goods Received Notes)
                            </Link>
                          </li>
                        )}
                        {permissions.includes("CREATE_GRN") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/create-grn" ? "active" : ""}`} to="/create-grn" onClick={() => setIsMobileMenuOpen(false)}>
                              <FaFileInvoice /> Create GRN
                            </Link>
                          </li>
                        )}
                        {permissions.includes("DEBIT_NOTES") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/debit-notes" ? "active" : ""}`} to="/debit-notes">
                              <FaClipboardCheck /> Debit Notes
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}

                  {permissions.includes("CUSTOMERS") && (
                    <li className="nav-item px-1">
                      <Link className={`nav-link ${location.pathname === "/customers" ? "active" : ""}`} to="/customers">
                        <FaUser /> Customers
                      </Link>
                    </li>
                  )}
                  {permissions.includes("SUPPLIER") && (
                    <li className="nav-item px-1">
                      <Link className={`nav-link ${location.pathname === "/suppliers" ? "active" : ""}`} to="/suppliers">
                        <FaUserFriends /> Suppliers
                      </Link>
                    </li>
                  )}

                  {/* Catalogs Dropdown */}
                  {(permissions.includes("CATALOGS") || permissions.includes("PRODUCTS") || permissions.includes("CATEGORIES") || permissions.includes("BRANDS") || permissions.includes("MANUFACTURERS")) && (
                    <li className="nav-item px-1 dropdown">
                      <button className="nav-link dropdown-toggle" onClick={() => toggleDropdown("catalog")}>
                        <FaNewspaper /> Catalogs <FaChevronDown />
                      </button>
                      <ul className={`dropdown-menu ${dropdowns.catalog ? "show" : ""}`}>
                        {permissions.includes("PRODUCTS") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/products" ? "active" : ""}`} to="/products">
                              <FaBox /> Products
                            </Link>
                          </li>
                        )}
                        {permissions.includes("CATEGORIES") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/categories" ? "active" : ""}`} to="/categories">
                              <FaRegListAlt /> Categories
                            </Link>
                          </li>
                        )}
                        {permissions.includes("BRANDS") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/brands" ? "active" : ""}`} to="/brands">
                              <FaTags /> Brands
                            </Link>
                          </li>
                        )}
                        {permissions.includes("MANUFACTURERS") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/manufacturers" ? "active" : ""}`} to="/manufacturers">
                              <FaIndustry /> Manufacturers
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}

                  {/* Operations Dropdown */}
                  {(permissions.includes("OPERATIONS") ||
                    permissions.includes("PICK_&_PACK") ||
                    permissions.includes("WAREHOUSES") ||
                    permissions.includes("INVENTORY") ||
                    permissions.includes("INVENTORY_LEADER") ||
                    permissions.includes("CREDIT_NOTE") ||
                    permissions.includes("INTER_BRANCH_TRANSFER") ||
                    permissions.includes("INTER_BRANCH_GRN") ||
                    permissions.includes("COURIERS")) && (
                    <li className="nav-item px-1 dropdown">
                      <button className="nav-link dropdown-toggle" onClick={() => toggleDropdown("operations")}>
                        <FaShippingFast /> Operations <FaChevronDown />
                      </button>
                      <ul className={`dropdown-menu ${dropdowns.operations ? "show" : ""}`}>
                        {permissions.includes("PICK_&_PACK") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/pick-and-pack" ? "active" : ""}`} to="/pick-and-pack">
                              <FaCaravan /> Pick & Pack
                            </Link>
                          </li>
                        )}
                        {permissions.includes("WAREHOUSES") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/warehouse" ? "active" : ""}`} to="/warehouse">
                              <FaWarehouse /> Warehouses
                            </Link>
                          </li>
                        )}
                        {permissions.includes("INVENTORY") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/inventory" ? "active" : ""}`} to="/inventory">
                              📦 Inventory
                            </Link>
                          </li>
                        )}
                        {permissions.includes("INVENTORY_LEADER") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/inventory-logs" ? "active" : ""}`} to="/inventory-logs">
                              📝 Inventory Leader
                            </Link>
                          </li>
                        )}
                        {permissions.includes("CREDIT_NOTE") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/credit-notes" ? "active" : ""}`} to="/credit-notes">
                              📜 Credit Note
                            </Link>
                          </li>
                        )}
                        {permissions.includes("INTER_BRANCH_TRANSFER") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/interbranch-transfers" ? "active" : ""}`} to="/interbranch-transfers">
                              📦 Inter branch Transfer
                            </Link>
                          </li>
                        )}
                        {permissions.includes("INTER_BRANCH_GRN") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/interbranch-grns" ? "active" : ""}`} to="/interbranch-grns">
                              📦 Inter branch GRN
                            </Link>
                          </li>
                        )}
                        {permissions.includes("COURIERS") && (
                          <li>
                            <Link className={`dropdown-item ${location.pathname === "/couriers" ? "active" : ""}`} to="/couriers">
                              <FaTruck /> Couriers
                            </Link>
                          </li>
                        )}
                      </ul>
                    </li>
                  )}
                </>
              )}

              {/* Login / Logout */}
              {/* {!isLoggedIn ? (
                <li className="nav-item">
                  <Link className="nav-link btn btn-warning text-dark px-3" to="/">
                    <FaSignInAlt /> Login
                  </Link>
                </li>
              ) : (
                <li className="nav-item">
                  <button className="nav-link btn btn-danger text-white px-3" onClick={handleLogout}>
                    <FaSignOutAlt /> Logout
                  </button>
                </li>
              )} */}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;