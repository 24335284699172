import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import config from '../../config';

function EditPermission() {
  const { id } = useParams(); // Permission ID from the URL
  const [permissionName, setPermissionName] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    fetch(`${config.BASE_URL}/api/permissions/${id}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
    })
      .then((response) => response.json())
      .then((data) => setPermissionName(data.data.name))
      .catch((error) => console.error('Error fetching permission details:', error));
  }, [id]);

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${config.BASE_URL}/api/permissions/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ name: permissionName }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to update permission');
        return response.json();
      })
      .then(() => navigate('/permission-management'))
      .catch((error) => console.error('Error updating permission:', error));
  };

  return (
    <div className="container mt-5">
      <h2>Edit Permission</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Permission Name</label>
          <input
            type="text"
            className="form-control"
            value={permissionName}
            onChange={(e) => setPermissionName(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn btn-primary">
          Update Permission
        </button>
      </form>
    </div>
  );
}

export default EditPermission;
