import React, { useState, useEffect } from "react";
import { createGRN } from "../../services/grnService";
import { getPurchaseOrderByPoNumber } from "../../services/purchaseOrderService";
import { toast } from "react-toastify";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPlusCircle, FaSave, FaShoppingCart, FaClipboardList, FaTrash } from "react-icons/fa";
import "./CreateGRN.css"; // Import custom CSS for styling

const CreateGRN = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialPoNumber = queryParams.get("poNumber") || sessionStorage.getItem("poNumber") || "";

  const [poNumber, setPoNumber] = useState(initialPoNumber);
  const [supplierName, setSupplierName] = useState("");
  const [supplierEmail, setSupplierEmail] = useState("");
  const [warehouseName, setWarehouseName] = useState("");
  const [purchaseOrder, setPurchaseOrder] = useState(null);
  const [availableSkus, setAvailableSkus] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState(JSON.parse(sessionStorage.getItem("selectedSkus")) || []);
  const [items, setItems] = useState(JSON.parse(sessionStorage.getItem("items")) || []);

  useEffect(() => {
    if (poNumber) {
      fetchPurchaseOrderDetails(poNumber);
    }
  }, [poNumber]);

  useEffect(() => {
    const savedItems = JSON.parse(sessionStorage.getItem("items")) || [];
  
    // Ensure batchDetails is an array for each item
    const updatedItems = savedItems.map((item) => ({
      ...item,
      batchDetails: Array.isArray(item.batchDetails) ? item.batchDetails : [], // ✅ Ensure batch details exist
    }));
  
    setItems(updatedItems);
  }, []);
  
  useEffect(() => {
    sessionStorage.setItem("selectedSkus", JSON.stringify(selectedSkus));

    const filteredItems = purchaseOrder
      ? purchaseOrder.items
          .filter((item) => selectedSkus.includes(item.sku))
          .map((item) => ({
            ...item,
            receivedQty: item.receivedQty || 0,
            qtyCanceled: item.qtyCanceled || 0,      // new field
            qtyInwarded: item.qtyInwarded || 0,        // new field
            gstAmount: calculateGSTAmount(item.price, item.receivedQty, item.taxPercent),
            rowTotal: calculateRowTotal(item.price, item.receivedQty, item.taxPercent),
            batchDetails: item.batchDetails || [],
          }))
      : [];

    setItems(filteredItems);
    sessionStorage.setItem("items", JSON.stringify(filteredItems));
  }, [selectedSkus, purchaseOrder]);

  const fetchPurchaseOrderDetails = async (poNumber) => {
    try {
      const response = await getPurchaseOrderByPoNumber(poNumber);
      if (response.statusCode === 200) {
        const poData = response.data;
        setSupplierName(`${poData.supplierFirstname || ""} ${poData.supplierLastname || ""}`.trim());
        setWarehouseName(poData.warehouseAddresses?.[0]?.name || "Unknown Warehouse");
        setSupplierEmail(poData.supplierEmail || "");
        setPurchaseOrder(poData);

        const skus = poData.items.map((item) => item.sku);
        setAvailableSkus(skus);

        sessionStorage.setItem("poNumber", poNumber);
        sessionStorage.setItem("availableSkus", JSON.stringify(skus));
      } else {
        toast.error("Invalid PO Number!");
        setPurchaseOrder(null);
        setAvailableSkus([]);
      }
    } catch (error) {
      toast.error("Error fetching PO details.");
    }
  };

  const calculateGSTAmount = (price, receivedQty, taxPercent) => {
    return parseFloat(((price * receivedQty) * (taxPercent / 100)).toFixed(2)) || 0;
  };

  const calculateRowTotal = (price, receivedQty, taxPercent) => {
    return parseFloat(((price * receivedQty) + ((price * receivedQty) * (taxPercent / 100))).toFixed(2)) || 0;
  };

  const handleSkuSelection = (sku) => {
    if (!selectedSkus.includes(sku)) {
      setSelectedSkus([...selectedSkus, sku]);
    }
  };

  const removeSku = (sku) => {
    const updatedSkus = selectedSkus.filter((s) => s !== sku);
    setSelectedSkus(updatedSkus);
  };

  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    value = parseFloat(value) || 0;
    if (field === "receivedQty") {
      if (value < 1) {
        toast.error("Received quantity must be at least 1!");
        return;
      }
      if (value > updatedItems[index].qtyOrdered) {
        toast.error("Received quantity cannot exceed Order Quantity!");
        return;
      }
      const availableQty = updatedItems[index].qtyOrdered - (updatedItems[index].qtyCanceled + updatedItems[index].qtyInwarded);
      if (value > availableQty) {
        toast.error("Received quantity cannot exceed available quantity!");
        return;
      }
      updatedItems[index].receivedQty = value;
      updatedItems[index].gstAmount = calculateGSTAmount(updatedItems[index].price, value, updatedItems[index].taxPercent);
      updatedItems[index].rowTotal = calculateRowTotal(updatedItems[index].price, value, updatedItems[index].taxPercent);
    } else if (field === "qtyCanceled" || field === "qtyInwarded") {  // new branch
      updatedItems[index][field] = value;
    }
    setItems(updatedItems);
    sessionStorage.setItem("items", JSON.stringify(updatedItems));
  };

  const addBatchDetail = (itemIndex) => {
    const updatedItems = [...items];
  
    if (!updatedItems[itemIndex].batchDetails) {
      updatedItems[itemIndex].batchDetails = [];
    }
  
    if (updatedItems[itemIndex].batchDetails.length >= updatedItems[itemIndex].receivedQty) {
      toast.error("Number of batches cannot exceed received quantity!");
      return;
    }
  
    updatedItems[itemIndex].batchDetails.push({
      batchNo: "",
      expiryDate: "",
      manufacturedBy: "",
      mrp: "",
      receivedQty: "",
      srNo: "",
      mfgDate: "",
    });
  
    setItems(updatedItems);
    sessionStorage.setItem("items", JSON.stringify(updatedItems)); // ✅ Save to sessionStorage
  };
  
  const handleBatchChange = (itemIndex, batchIndex, field, value) => {
    const updatedItems = [...items];
  
    if (!updatedItems[itemIndex].batchDetails) {
      updatedItems[itemIndex].batchDetails = [];
    }
  
    const newValue = (field === "receivedQty" || field === "mrp") ? parseFloat(value) || 0 : value;
  
    updatedItems[itemIndex].batchDetails[batchIndex] = {
      ...updatedItems[itemIndex].batchDetails[batchIndex],
      [field]: newValue,
    };
  
    setItems(updatedItems);
    sessionStorage.setItem("items", JSON.stringify(updatedItems)); // ✅ Save after change
  };
  
  const removeBatchDetail = (itemIndex, batchIndex) => {
    const updatedItems = [...items];
  
    updatedItems[itemIndex].batchDetails.splice(batchIndex, 1);
  
    setItems(updatedItems);
    sessionStorage.setItem("items", JSON.stringify(updatedItems)); // ✅ Save changes
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!purchaseOrder) {
      toast.error("No purchase order selected. Please enter a valid PO Number.");
      return;
    }
  
    if (items.length === 0) {
      toast.error("Please add items before submitting.");
      return;
    }
  
    for (const item of items) {
      if (item.receivedQty > 0 && item.batchDetails.length === 0) {
        toast.error(`Please add batch details for item: ${item.name} (SKU: ${item.sku})`);
        return;
      }
  
      for (const batch of item.batchDetails) {
        if (!batch.batchNo || !batch.expiryDate || !batch.manufacturedBy || !batch.mrp || !batch.receivedQty || !batch.srNo || !batch.mfgDate) {
          toast.error(`All batch details must be filled for item: ${item.name} (SKU: ${item.sku})`);
          return;
        }
      }
    }
  
    const grnPayload = {
      entityId: purchaseOrder.id,
      grnItems: items.map((item) => ({
        poItemId: item.id,
        receivedQty: item.receivedQty,
        batchDetails: item.batchDetails.map((batch) => ({
          batchNo: batch.batchNo,
          expiryDate: batch.expiryDate,
          manufacturedBy: batch.manufacturedBy,
          mrp: parseFloat(batch.mrp) || 0,
          srNo: batch.srNo,
          mfgDate: batch.mfgDate,
          receivedQty: parseFloat(batch.receivedQty) || 0,
        })),
      })),
    };
  
    try {
      const response = await createGRN(grnPayload);
  
      if (response.statusCode === 201) {
        toast.success("GRN created successfully!");
        
        sessionStorage.removeItem("poNumber");
        sessionStorage.removeItem("items");
        sessionStorage.removeItem("selectedSkus");
        sessionStorage.removeItem("availableSkus");
  
        navigate(`/grn/view/${response.data.id}`);
      } else {
        toast.error(response.message || "Failed to create GRN. Please try again.");
      }
    } catch (error) {
      console.error("❌ Error creating GRN:", error);
      toast.error("An unexpected error occurred while creating GRN.");
    }
  };
  
  return (
    <div className="container mt-4">
      <h2 className="text-primary">
        <FaClipboardList /> Create GRN
      </h2>
      <form onSubmit={handleSubmit}>
        <div className="row mt-3">
          <div className="col-md-4">
            <label>PO Number</label>
            <input
              type="text"
              className="form-control"
              value={poNumber}
              onChange={(e) => setPoNumber(e.target.value)}
              required
            />
          </div>
          <div className="col-md-4">
            <label>Select SKU(s)</label>
            <select className="form-control" onChange={(e) => handleSkuSelection(e.target.value)}>
              <option value="">Select SKU</option>
              {availableSkus.map((sku) => (
                <option key={sku} value={sku}>
                  {sku}
                </option>
              ))}
            </select>
            <div className="mt-2">
              {selectedSkus.map((sku) => (
                <span key={sku} className="badge bg-primary mx-1">
                  {sku}{" "}
                  <button type="button" className="btn btn-sm btn-danger" onClick={() => removeSku(sku)}>
                    ×
                  </button>
                </span>
              ))}
            </div>
          </div>
        </div>
        {warehouseName && <p><strong>Warehouse:</strong> {warehouseName}</p>}
        {supplierName && <p><strong>Supplier:</strong> {supplierName}</p>}
        {supplierEmail && <p><strong>Email:</strong> {supplierEmail}</p>}

        <h4 className="text-primary mt-4">
          <FaShoppingCart /> GRN Items
        </h4>
        {items.map((item, index) => (
          <div key={index} className="card p-3 mb-3">
            <h5 className="text-secondary">
              {item.name} (SKU: {item.sku})
            </h5>

            <div className="table-responsive">
              <table className="table table-bordered">
                <thead className="table-dark">
                  <tr>
                    <th>Price</th>
                    <th>Order Qty</th>
                    <th>Qty Canceled</th>        {/* new header column */}
                    <th>Qty Inwarded</th>        {/* new header column */}
                    <th>Available Qty</th>        {/* new header column */}
                    <th>Received Qty</th>
                    <th>GST (%)</th>
                    <th>GST Amount</th>
                    <th>Row Total</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{item.price}</td>
                    <td>{item.qtyOrdered}</td>
                    <td>{item.qtyCanceled}</td>        {/* display value only */}
                    <td>{item.qtyInwarded}</td>          {/* display value only */}
                    <td>{item.qtyOrdered - (item.qtyCanceled + item.qtyInwarded)}</td>   {/* computed value */}
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        value={item.receivedQty || ""}
                        max={item.qtyOrdered - (item.qtyCanceled + item.qtyInwarded)}
                        onChange={(e) => handleItemChange(index, "receivedQty", e.target.value)}
                        required
                      />
                    </td>
                    <td>{item.taxPercent}</td>
                    <td>{parseFloat(item.gstAmount).toFixed(2)}</td>
                    <td>{parseFloat(item.rowTotal).toFixed(2)}</td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-outline-primary"
                        onClick={() => addBatchDetail(index)}
                      >
                        <FaPlusCircle /> Add Batch
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            {/* Batch Details Section */}
            {item.batchDetails?.length > 0 && (
              <div className="table-responsive mt-2">
                <table className="table table-bordered">
                  <thead className="table-dark">
                    <tr>
                      <th>Batch No</th>
                      <th>Expiry Date</th>
                      <th>Manufactured By</th>
                      <th>MRP</th>
                      <th>Received Qty</th>
                      <th>CR No.</th>
                      <th>Mfg Date</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {item.batchDetails?.map((batch, batchIndex) => (
                      <tr key={batchIndex}>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={batch.batchNo}
                            onChange={(e) => handleBatchChange(index, batchIndex, "batchNo", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            className="form-control"
                            value={batch.expiryDate}
                            onChange={(e) => handleBatchChange(index, batchIndex, "expiryDate", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={batch.manufacturedBy}
                            onChange={(e) => handleBatchChange(index, batchIndex, "manufacturedBy", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={batch.mrp}
                            onChange={(e) => handleBatchChange(index, batchIndex, "mrp", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            className="form-control"
                            value={batch.receivedQty}
                            onChange={(e) => handleBatchChange(index, batchIndex, "receivedQty", e.target.value)}
                            min="1"
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            value={batch.srNo}
                            onChange={(e) => handleBatchChange(index, batchIndex, "srNo", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <input
                            type="date"
                            className="form-control"
                            value={batch.mfgDate}
                            onChange={(e) => handleBatchChange(index, batchIndex, "mfgDate", e.target.value)}
                            required
                          />
                        </td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => removeBatchDetail(index, batchIndex)}
                          >
                            <FaTrash />
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        ))}

        <button type="submit" className="btn btn-success mt-3">
          <FaSave /> Create GRN
        </button>
      </form>
    </div>
  );
};

export default CreateGRN;