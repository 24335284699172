import React, { useEffect, useState, useCallback } from "react";
import { getPurchaseOrders } from "../../services/purchaseOrderService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaPlus, FaSearch, FaArrowLeft, FaArrowRight, FaEye } from "react-icons/fa";

const PurchaseOrderList = () => {
  const [purchaseOrders, setPurchaseOrders] = useState([]); // ✅ Prevents crash
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    supplierEmail: "",
    supplierName: "",         // Added
    incrementId: "", // new filter for Purchase Order No.
    status: "",
    startDate: "",            // Added
    endDate: "",              // Added
    minTotalAmount: "",       // Added
    maxTotalAmount: "",       // Added
    page: 0,
    size: 10, // Default pagination size
  });

  const fetchPurchaseOrders = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getPurchaseOrders(filters);

      console.log("API Response:", response); // ✅ Debugging Step

      if (response.statusCode === 200) {
        setPurchaseOrders(response.data?.content || []); // ✅ Use `content` array
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch purchase orders!");
      }
    } catch (error) {
      toast.error("Error fetching purchase orders.");
      setPurchaseOrders([]); // ✅ Prevent crash
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchPurchaseOrders();
  }, [fetchPurchaseOrders]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary">Purchase Order Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Streamlines procurement by tracking, approving, and organizing purchase transactions efficiently.
          </p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/purchases/create")}>
            <FaPlus /> Create PO
          </button>
        </div>
      </div>

      {/* 🔍 Search & Filters - updated without border */}
      <div className="card shadow mb-3">
        <div className="card-body">

        
      <div className="row g-3">
        <div className="col-md-2">
          <input
            type="email"
            placeholder="Supplier Email"
            className="form-control shadow-sm"
            value={filters.supplierEmail}
            onChange={(e) =>
              setFilters({ ...filters, supplierEmail: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="Supplier Name"
            className="form-control shadow-sm"
            value={filters.supplierName}
            onChange={(e) =>
              setFilters({ ...filters, supplierName: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <input
            type="text"
            placeholder="Purchase Order No."
            className="form-control shadow-sm"
            value={filters.incrementId}
            onChange={(e) =>
              setFilters({ ...filters, incrementId: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <select
            className="form-select shadow-sm"
            value={filters.status}
            onChange={(e) =>
              setFilters({ ...filters, status: e.target.value })
            }
          >
            <option value="">All Statuses</option>
            <option value="new">New</option>
            <option value="processing">Processing</option>
            <option value="completed">Completed</option>
            <option value="canceled">Canceled</option>
          </select>
        </div>
        <div className="col-md-2">
          <input
            type="date"
            className="form-control shadow-sm"
            value={filters.startDate}
            onChange={(e) =>
              setFilters({ ...filters, startDate: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <input
            type="date"
            className="form-control shadow-sm"
            value={filters.endDate}
            onChange={(e) =>
              setFilters({ ...filters, endDate: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <input
            type="number"
            step="0.01"
            placeholder="Min Total Amount"
            className="form-control shadow-sm"
            value={filters.minTotalAmount}
            onChange={(e) =>
              setFilters({ ...filters, minTotalAmount: e.target.value })
            }
          />
        </div>
        <div className="col-md-2">
          <input
            type="number"
            step="0.01"
            placeholder="Max Total Amount"
            className="form-control shadow-sm"
            value={filters.maxTotalAmount}
            onChange={(e) =>
              setFilters({ ...filters, maxTotalAmount: e.target.value })
            }
          />
        </div>
        <div className="col-md-2 d-flex align-items-center">
          <button
            className="btn btn-primary w-100"
            onClick={fetchPurchaseOrders}
          >
            <FaSearch /> Search
          </button>
        </div>
      </div>
      </div>
      </div>
      <div className="card shadow">
            <div className="card-body">
      {/* 🌀 Loading State */}
      {loading && (
        <div className="text-center my-3">
          <h5>Loading purchase orders... 🌀</h5>
        </div>
      )}

      {/* 📊 Purchase Order Table */}
      {!loading && purchaseOrders.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover table-striped">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>PO Number</th>
                <th>Status</th>
                <th>Superior Status</th>
                <th>Finance Status</th>  {/* New column */}
                <th>Coupon Code</th>
                <th>Seller ID</th>
                <th>Discount (Rs)</th>
                <th>Shipping (Rs)</th>
                <th>Subtotal (Rs)</th>
                <th>Tax (Rs)</th>
                <th>Total (Rs)</th>
                <th>Supplier Email</th>
                <th>Supplier Name</th>
                <th>Payment Terms</th>
                <th>Created By</th>           {/* NEW column */}
                <th>BDE Name</th>             {/* NEW column */}
                <th>Created At</th>
                <th>Updated At</th>
                <th style={{ position: "sticky", right: 0, background: "#343a40", zIndex: 1 }}>Action</th>
              </tr>
            </thead>
            <tbody>
              {purchaseOrders.map((po) => (
                <tr key={po.id}>
                  <td>{po.id}</td>
                  {/* Removed clickable style from PO Number */}
                  <td><span className="text-link" onClick={() => navigate(`/purchase-orders/${po.id}`)} >{po.incrementId}</span></td>
                  <td>
                    <span
                      className={`badge bg-${
                        po.status === "success" ? "success" : "warning"
                      }`}
                    >
                      {po.status.toUpperCase()}
                    </span>
                  </td>
                  <td>
                    {po.superiorStatus === 13
                      ? "Approved"
                      : po.superiorStatus === 14
                      ? "Rejected"
                      : "Pending"}
                  </td>
                  <td>
                    {po.financeStatus === 9
                      ? "Approved"
                      : po.financeStatus === 10
                      ? "Rejected"
                      : "Pending"}
                  </td>
                  <td>{po.couponCode || "-"}</td>
                  <td>{po.sellerId}</td>
                  <td>{po.discountAmount.toFixed(2)}</td>
                  <td>{po.shippingAmount.toFixed(2)}</td>
                  <td>{po.subtotal.toFixed(2)}</td>
                  <td>{po.taxAmount.toFixed(2)}</td>
                  <td>₹{po.grandTotal.toFixed(2)}</td>
                  <td>{po.supplierEmail}</td>
                  <td>
                    {po.supplierFirstname} {po.supplierLastname}
                  </td>
                  <td>{po.paymentTerms}</td>
                  <td>{po.createdByName}</td>       {/* NEW cell */}
                  <td>{po.bdeName}</td>             {/* NEW cell */}
                  <td>{new Date(po.createdAt).toLocaleString()}</td>
                  <td>{new Date(po.updatedAt).toLocaleString()}</td>
                  <td style={{ position: "sticky", right: 0, background: "white" }}>
                    <button
                      className="btn btn-info btn-sm shadow-sm"
                      onClick={() => navigate(`/purchase-orders/${po.id}`)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && purchaseOrders.length === 0 && (
        <div className="text-center my-3">
          <h5>No purchase orders found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary shadow-sm"
          disabled={filters.page === 0}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page - 1 }))
          }
        >
          <FaArrowLeft /> Previous
        </button>
        <span>
          Page {filters.page + 1} of {totalPages}
        </span>
        <button
          className="btn btn-outline-secondary shadow-sm"
          disabled={filters.page + 1 >= totalPages}
          onClick={() =>
            setFilters((prev) => ({ ...prev, page: prev.page + 1 }))
          }
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default PurchaseOrderList;
