import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import config from '../../config';

function AddPermission() {
  const [permissionName, setPermissionName] = useState('');
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();

    fetch(`${config.BASE_URL}/api/permissions`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify({ name: permissionName }),
    })
      .then((response) => {
        if (!response.ok) throw new Error('Failed to add permission');
        return response.json();
      })
      .then(() => navigate('/permission-management')) // Navigate to list after success
      .catch((error) => console.error('Error adding permission:', error));
  };

  return (
    <div className="container mt-5">
      <h2>Add Permission</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label>Permission Name</label>
          <input
            type="text"
            className="form-control"
            value={permissionName}
            onChange={(e) => setPermissionName(e.target.value.toUpperCase())} // Updated to uppercase text
            required
          />
        </div>
        <div className="d-flex">
          <button type="submit" className="btn btn-primary me-2">
            Add Permission
          </button>
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => navigate('/permission-management')}
          >
            Back
          </button>
        </div>
      </form>
    </div>
  );
}

export default AddPermission;
