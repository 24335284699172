import React, { useEffect, useState, useCallback } from "react";
import { getGRNList } from "../../services/grnService";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { FaSearch, FaArrowLeft, FaArrowRight, FaEye, FaFileInvoice, FaPlus } from "react-icons/fa";

const GRNList = () => {
  // Toggle sticky action column here: true for sticky, false for free
  const stickyAction = true;
  
  const [grns, setGRNs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    warehouseId: "",
    purchaseOrderId: "", // ✅ Added Purchase Order ID filter
    poNumber: "",
    startDate: "",
    endDate: "",
    page: 0,
    size: 10, // changed from 5 to 10
  });

  const fetchGRNRecords = useCallback(async () => {
    setLoading(true);
    try {
      const response = await getGRNList(filters);
      if (response.statusCode === 200) {
        setGRNs(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch GRNs!");
      }
    } catch (error) {
      toast.error("Error fetching records.");
      setGRNs([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchGRNRecords();
  }, [fetchGRNRecords]);

  return (
    <div className="container">
      <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaFileInvoice /> GRN Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
            Handles the verification and recording of received goods against purchase orders.
          </p>
        </div>
        <div>
          <button className="btn btn-primary" onClick={() => navigate("/create-grn")}>
            <FaPlus /> Create GRN
          </button>
        </div>
      </div>

      {/* 🔍 Filters */}
      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="number"
                placeholder="🛒 Purchase Order ID"
                className="form-control"
                value={filters.purchaseOrderId}
                onChange={(e) => setFilters({ ...filters, purchaseOrderId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="number"
                placeholder="📦 Warehouse ID"
                className="form-control"
                value={filters.warehouseId}
                onChange={(e) => setFilters({ ...filters, warehouseId: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="text"
                placeholder="🔎 PO Number"
                className="form-control"
                value={filters.poNumber}
                onChange={(e) => setFilters({ ...filters, poNumber: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.startDate}
                onChange={(e) => setFilters({ ...filters, startDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <input
                type="date"
                className="form-control"
                value={filters.endDate}
                onChange={(e) => setFilters({ ...filters, endDate: e.target.value })}
              />
            </div>

            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchGRNRecords}>
                <FaSearch /> Search
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* 📊 Data Table */}
      <div className="card shadow">
        <div className="card-body">
      {!loading && grns.length > 0 && (
        <div className="table-responsive">
          <table
            className="table table-bordered table-hover table-striped bg-white text-dark"
            style={{ position: "relative" }} // added relative positioning
          >
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Purchase Order ID</th>
                <th>PO Number</th>
                <th>Seller ID</th>
                <th>Warehouse ID</th>
                <th>Subtotal</th>
                <th>Status</th>
                <th>CGST Amount</th>
                <th>SGST Amount</th>
                <th>IGST Amount</th>
                <th>Tax Amount</th>
                <th>Grand Total</th>
                <th>Shipping Charges</th>
                <th>Created By</th>
                <th>Created At</th>
                <th
                  style={
                    stickyAction
                      ? {
                          position: "sticky",
                          top: 0,
                          right: 0,
                          backgroundColor: "#343a40",
                          color: "#fff",
                          zIndex: 3,
                          minWidth: "150px",
                          boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
                        }
                      : {}
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {grns.map((record) => (
                <tr key={record.id}>
                  <td>{record.id}</td>
                  <td>{record.purchaseOrderId}</td>
                  <td>{record.poNumber}</td>
                  <td>{record.sellerId}</td>
                  <td>{record.warehouseId}</td>
                  <td>{record.subtotal}</td>
                  <td>{record.isActive === 1 ? "Active" : "Inactive"}</td>
                  <td>{record.cgstAmount ?? "N/A"}</td>
                  <td>{record.sgstAmount ?? "N/A"}</td>
                  <td>{record.igstAmount ?? "N/A"}</td>
                  <td>{record.taxAmount}</td>
                  <td>{record.grandTotal}</td>
                  <td>{record.shippingCharges}</td>
                  <td>{record.createdBy ?? "N/A"}</td>
                  <td>{new Date(record.createdAt).toLocaleString()}</td>
                  <td
                    style={
                      stickyAction
                        ? {
                            position: "sticky",
                            right: 0,
                            background: "#f8f9fa",
                            zIndex: 3,
                            minWidth: "150px",
                            boxShadow: "-2px 0 5px rgba(0,0,0,0.1)",
                          }
                        : {}
                    }
                  >
                    <button
                      className="btn btn-info btn-sm"
                      onClick={() => navigate(`/grn/view/${record.id}`)}
                    >
                      <FaEye /> View
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* ❗ No Data Message */}
      {!loading && grns.length === 0 && (
        <div className="text-center mt-3">
          <h5>No GRNs found ❌</h5>
        </div>
      )}

      {/* Pagination Controls */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div></div>
  );
};

export default GRNList;
