import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/categories`;

// ✅ Get Auth Token
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Handle API Response
const handleResponse = async (response) => {
  if (!response.ok) {
    let errorMessage = "Something went wrong!";
    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
    } catch {
      errorMessage = await response.text();
    }
    console.error("API Error:", errorMessage);
    throw new Error(errorMessage);
  }
  return response.status !== 204 ? response.json() : {};
};

// ✅ Construct Headers
const getHeaders = () => {
  const authToken = getAuthToken();
  const headers = { "Content-Type": "application/json" };
  if (authToken) headers["Authorization"] = `Bearer ${authToken}`;
  return headers;
};

// ✅ Fetch all categories with search & pagination
export const searchCategories = async (filters) => {
  const query = new URLSearchParams(filters).toString();
  const response = await fetch(`${BASE_URL}/search?${query}`, {
    method: "GET",
    headers: getHeaders(),
  });

  return handleResponse(response);
};

// ✅ Fetch all categories without filters
export const getCategories = async () => {
  const response = await fetch(BASE_URL, {
    method: "GET",
    headers: getHeaders(),
  });

  return handleResponse(response);
};

// ✅ Fetch a single category by ID using direct endpoint instead of search URL
export const getCategoryById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
};

// ✅ Create a new category
export const createCategory = async (categoryData) => {
  // Retrieve the logged-in user's details and extract userId
  const userDetails = JSON.parse(localStorage.getItem("userDetails"));
  const userId = userDetails?.userId;
  // Extend payload with createdBy field
  const payload = { ...categoryData, createdBy: userId };

  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: getHeaders(),
    body: JSON.stringify(payload),
  });

  return handleResponse(response);
};

// ✅ Update an existing category
export const updateCategory = async (id, categoryData) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: getHeaders(),
    body: JSON.stringify(categoryData),
  });

  return handleResponse(response);
};

// ✅ Delete a category
export const deleteCategory = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: getHeaders(),
  });

  return handleResponse(response);
};

// ✅ Fetch category tree
export const getCategoryTree = async () => {
  const response = await fetch(`${BASE_URL}/tree`, {
    method: "GET",
    headers: getHeaders(),
  });
  return handleResponse(response);
};
