import config from "../config";

const BASE_URL = `${config.BASE_URL}/api/warehouses`;
const getAuthToken = () => localStorage.getItem("authToken");

// ✅ Generic API Response Handler
const handleResponse = async (response) => {
  if (!response.ok) {
    if (response.status === 403 || response.status === 401) {
      localStorage.removeItem("authToken"); // Clear expired token
      window.location.href = "/login"; // Redirect to login
      throw new Error("Session expired. Please log in again.");
    }

    const errorMessage = await response.text();
    throw new Error(errorMessage || "Something went wrong!");
  }

  return response.status !== 204 ? response.json() : {};
};

// ✅ Fetch all warehouses (No Filters)
export const getAllWarehouses = async () => {
  const response = await fetch(`${BASE_URL}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch warehouses with filters & pagination
export const getWarehouses = async (filters) => {
  const activeFilters = Object.fromEntries(
    Object.entries(filters).filter(([_, value]) => value !== "")
  );
  
  const query = new URLSearchParams(activeFilters).toString();
  const url = query ? `${BASE_URL}/search?${query}` : `${BASE_URL}`;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Fetch warehouse by ID
export const getWarehouseById = async (id) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  return handleResponse(response);
};

// ✅ Create a new warehouse
export const createWarehouse = async (warehouseData) => {
  const response = await fetch(BASE_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(warehouseData),
  });

  return handleResponse(response);
};

// ✅ Update warehouse
export const updateWarehouse = async (id, warehouseData) => {
  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(warehouseData),
  });

  return handleResponse(response);
};

// ✅ Delete warehouse with confirmation
export const deleteWarehouse = async (id) => {
  if (!window.confirm("Are you sure you want to delete this warehouse?")) return;

  const response = await fetch(`${BASE_URL}/${id}`, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });

  if (response.status === 204) {
    return { message: "Warehouse deleted successfully!" };
  }

  return handleResponse(response);
};
