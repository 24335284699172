import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Bar, Line } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';
import { Container, Row, Col, Card } from "react-bootstrap";
import styles from "./Dashboard.module.css";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const Dashboard = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Fetch user details from localStorage
    const storedUser = localStorage.getItem("userDetails");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    } else {
      navigate("/");
    }
  }, [navigate]);

  // Sample data for charts
  const salesData = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
    datasets: [
      {
        label: "Sales",
        data: [12000, 15000, 14000, 17000, 16000, 18000],
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
    ],
  };

  const ordersData = {
    labels: ["Week 1", "Week 2", "Week 3", "Week 4"],
    datasets: [
      {
        label: "Orders",
        data: [30, 45, 60, 50],
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };

  return (
    <Container className={`${styles.dashboardContainer}`}>
      {/* Welcome Message */}
      <div className="text-start mb-4">
        <h1 className={styles.welcomeTitle}>Welcome, {user ? user.username : "User"}!</h1>
        <p className={styles.welcomeSubtitle}>
          Manage your store efficiently & keep track of your business growth 📈.
        </p>
      </div>

      {/* Dashboard Cards */}
      <Row className="mb-4">
        <Col md={3}>
          <Card className="shadow p-3 text-center mb-4">
            <h5 className="text-success fw-bold mt-2">💰 Total Sales</h5>
            <h3 className="fw-bold">12,54000</h3>
            <p className="text-muted">+5.3% this month</p>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow p-3 text-center mb-4">
            <h5 className="text-primary fw-bold mt-2">📦 New Orders</h5>
            <h3 className="fw-bold">120</h3>
            <p className="text-muted">Pending: 15</p>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow p-3 text-center  mb-4">
            <h5 className="text-warning fw-bold mt-2">👥 Customers</h5>
            <h3 className="fw-bold">5,670</h3>
            <p className="text-muted">+150 this week</p>
          </Card>
        </Col>
        <Col md={3}>
          <Card className="shadow p-3 text-center  mb-4">
            <h5 className="text-secondary fw-bold mt-2">👥 Suppliers</h5>
            <h3 className="fw-bold">320</h3>
            <p className="text-muted">+10 this week</p>
          </Card>
        </Col>
     
        <Col md={6}>
          <Card className="shadow p-3  mb-4">
            <h5 className="text-danger fw-bold text-center">📊 Sales Overview</h5>
            <Bar data={salesData} />
          </Card>
        </Col>
        <Col md={6}>
          <Card className="shadow p-3  mb-4">
            <h5 className="text-info fw-bold text-center">📈 Orders Overview</h5>
            <Line data={ordersData} />
          </Card>
        </Col>
      
        <Col md={6}>
          <Card className="shadow p-3 text-center  mb-4">
            <h5 className="text-danger fw-bold">🏪 Inventory Status</h5>
            <h3 className="fw-bold">32,000 Items</h3>
            <p className="text-muted">Stock levels stable</p>
          </Card>
        </Col>
        <Col md={6}>
          <Card className="shadow p-3 text-center  mb-4">
            <h5 className="text-info fw-bold">💳 Revenue</h5>
            <h3 className="fw-bold">78000,300</h3>
            <p className="text-muted">Projected revenue this quarter</p>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;