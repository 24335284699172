import React, { useEffect, useState, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { getBrands, deleteBrand } from "../services/brandService"; // ✅ Import deleteBrand
import { toast } from "react-toastify";
import { FaEye, FaEdit, FaTrash, FaPlus, FaArrowLeft, FaArrowRight, FaTags } from "react-icons/fa";

const BrandList = () => {
  const [brands, setBrands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();

  const [filters, setFilters] = useState({
    name: "",
    urlKey: "",
    isActive: "",
    page: 0,
    size: 10,
  });

  // NEW: State for user's permissions
  const [userPermissions, setUserPermissions] = useState([]);

  const fetchBrands = useCallback(async () => {
    setLoading(true);
    try {
      const activeFilters = Object.fromEntries(
        Object.entries(filters).filter(([_, value]) => value !== "")
      );

      const response = await getBrands(activeFilters);
      if (response.statusCode === 200) {
        setBrands(response.data?.content || []);
        setTotalPages(response.data?.totalPages || 1);
      } else {
        toast.error("Failed to fetch brands!");
      }
    } catch (error) {
      toast.error("Error fetching brands.");
      setBrands([]);
    } finally {
      setLoading(false);
    }
  }, [filters]);

  useEffect(() => {
    fetchBrands();
  }, [fetchBrands]);

  useEffect(() => {
    // Load user's permissions from localStorage
    const details = localStorage.getItem("userDetails");
    if(details) {
      try {
        const parsed = JSON.parse(details);
        setUserPermissions(parsed.permissions || []);
      } catch(e) {
        // handle error if needed
      }
    }
  }, []);

  // ✅ Function to delete a brand
  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this brand?")) {
      try {
        await deleteBrand(id);
        toast.success("Brand deleted successfully!");
        fetchBrands(); // Refresh list
      } catch (error) {
        toast.error("Failed to delete brand.");
      }
    }
  };

  return (
    <div className="container">
       {/* Page Title & Add Button */}
       <div className="d-flex justify-content-between align-items-center ">
        <div>
          <h1 className="welcomeTitle text-primary"><FaTags /> Brand Management</h1>
          <p className="welcomeSubtitle d-none d-md-block">
          Create, edit, and maintain all your product brand details in one place.
          </p>
        </div>
        <div>
        {userPermissions.includes("ADD_BRAND") && (
          <button className="btn btn-primary" onClick={() => navigate("/brands/add")}>
            <FaPlus /> Add Brand
          </button>
        )}
        </div>
      </div>

      {/* Filters */}

      <div className="card shadow mb-3">
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-2">
              <input
                type="text"
                placeholder="Brand Name"
                className="form-control"
                value={filters.name}
                onChange={(e) => setFilters({ ...filters, name: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <input
                type="text"
                placeholder="URL Key"
                className="form-control"
                value={filters.urlKey}
                onChange={(e) => setFilters({ ...filters, urlKey: e.target.value })}
              />
            </div>
            <div className="col-md-2">
              <select
                className="form-control"
                value={filters.isActive}
                onChange={(e) => setFilters({ ...filters, isActive: e.target.value })}
              >
                <option value="">All</option>
                <option value="1">Active</option>
                <option value="0">Inactive</option>
              </select>
            </div>
            <div className="col-md-2">
              <button className="btn btn-primary w-100" onClick={fetchBrands}>
                Search
              </button>
            </div>
          </div>
        </div></div>


        <div className="card shadow mb-3">
        <div className="card-body">
      {/* Brand Table */}
      {!loading && brands.length > 0 && (
        <div className="table-responsive">
          <table className="table table-bordered table-hover">
            <thead className="table-dark">
              <tr>
                <th>ID</th>
                <th>Name</th>
                <th>URL Key</th>
                <th>Sort Order</th>
                <th>Is Active</th>
                <th>Created At</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {brands.map((brand) => (
                <tr key={brand.id}>
                  <td>{brand.id}</td>
                  <td>{brand.name}</td>
                  <td>{brand.urlKey}</td>
                  <td>{brand.sortOrder}</td>
                  <td>{brand.isActive ? "Active" : "Inactive"}</td>
                  <td>{brand.createdAt ? new Date(brand.createdAt).toLocaleDateString() : "N/A"}</td>
                  <td>
                    {/* Conditionally render actions */}
                    {userPermissions.includes("VIEW_BRAND") && (
                      <button
                        className="btn btn-outline-secondary btn-sm me-2"
                        onClick={() => navigate(`/brands/view/${brand.id}`)}
                      >
                        <FaEye /> View
                      </button>
                    )}
                    {userPermissions.includes("EDIT_BRAND") && (
                      <button
                        className="btn btn-warning btn-sm me-2"
                        onClick={() => navigate(`/brands/edit/${brand.id}`)}
                      >
                        <FaEdit /> Edit
                      </button>
                    )}
                    {userPermissions.includes("DELETE_BRAND") && (
                      <button
                        className="btn btn-danger btn-sm"
                        onClick={() => handleDelete(brand.id)}
                      >
                        <FaTrash /> Delete
                      </button>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {/* Pagination */}
      <div className="d-flex justify-content-between align-items-center">
        <button
          className="btn btn-outline-secondary"
          disabled={filters.page === 0}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page - 1 }))}
        >
          <FaArrowLeft /> Previous
        </button>

        <span>Page {filters.page + 1} of {totalPages}</span>

        <button
          className="btn btn-outline-secondary"
          disabled={filters.page + 1 >= totalPages}
          onClick={() => setFilters((prev) => ({ ...prev, page: prev.page + 1 }))}
        >
          Next <FaArrowRight />
        </button>
      </div>
    </div>
    </div>
    </div>
  );
};

export default BrandList;
