import config from "../config";

// ✅ Get Roles with Pagination & Filters
export const getRoles = async (filters) => {
  try {
    const queryParams = new URLSearchParams(filters).toString();
    const response = await fetch(`${config.BASE_URL}/api/roles/search?${queryParams}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch roles");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching roles:", error.message);
    return { statusCode: 500, message: error.message, data: null };
  }
};

// ✅ Get Role by ID
export const getRoleById = async (roleId) => {
  try {
    const response = await fetch(`${config.BASE_URL}/api/roles/${roleId}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to fetch role");
    }
    return await response.json();
  } catch (error) {
    console.error("Error fetching role:", error.message);
    return { statusCode: 500, message: error.message, data: null };
  }
};

// ✅ Create a New Role
export const createRole = async (roleData) => {
  try {
    const response = await fetch(`${config.BASE_URL}/api/roles`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(roleData),
    });

    if (!response.ok) {
      throw new Error("Failed to create role");
    }
    return await response.json();
  } catch (error) {
    console.error("Error creating role:", error.message);
    return { statusCode: 500, message: error.message, data: null };
  }
};

// ✅ Update an Existing Role
export const updateRole = async (roleId, roleData) => {
  try {
    const response = await fetch(`${config.BASE_URL}/api/roles/${roleId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
      body: JSON.stringify(roleData),
    });

    if (!response.ok) {
      throw new Error("Failed to update role");
    }
    return await response.json();
  } catch (error) {
    console.error("Error updating role:", error.message);
    return { statusCode: 500, message: error.message, data: null };
  }
};

// ✅ Delete Role
export const deleteRole = async (roleId) => {
  try {
    const response = await fetch(`${config.BASE_URL}/api/roles/${roleId}`, {
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("authToken")}`,
      },
    });

    if (!response.ok) {
      throw new Error("Failed to delete role");
    }
    return await response.json();
  } catch (error) {
    console.error("Error deleting role:", error.message);
    return { statusCode: 500, message: error.message, data: null };
  }
};
